import React from 'react';
import PropTypes from 'prop-types';
import './FooterPage.scss';

const FooterPage = () => (
  <div className="FooterPage">
    FooterPage Component
  </div>
);

FooterPage.propTypes = {};

FooterPage.defaultProps = {};

export default FooterPage;
