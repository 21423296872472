import React, { useEffect, useReducer, useState, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { Col, Container, Row, Form, Table } from "react-bootstrap";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import ArrowBack from "../../img/icons/iconArrowBack.svg";
import { NavLink } from "react-router-dom";
import TableReport from "../../components/TableReport/TableReport";
import { headersUploadDocs } from "../../constants/headersTables";
import { reducer } from "../../helpers/reducer";
import ExportCSV from "../../components/ExportCSV/ExportCSV";
import { GET } from "../../constants/ApiRest";
import { ReactComponent as Done } from "../../img/icons/status/descargado.svg";
import { ReactComponent as Process } from "../../img/icons/status/guardado.svg";
import { ReactComponent as Clock } from "../../img/icons/status/no-guardado.svg";
import usePagination from "../../components/PaginationTable/usePagination";
import PaginationTable from "../../components/PaginationTable/PaginationTable";
import "./UploadDocs.scss";

const init = () => {
  return [];
};

const mergeDataCompany = async (toMerge, merged) => {
  return toMerge.map((out) => {
    const found = merged.find((el) => el.id === out.company.id);
    return { ...out, company: { ...out.company, username: found?.username } };
  });
};

const UploadDocs = () => {
  const [state, dispatch] = useReducer(reducer, [], init);
  const [political, setPolitical] = useState({
    departments: [{ id: "0", name: "TODOS" }],
    towns: [{ id: "0", name: "TODOS" }],
    department_selected: 0,
    town_selected: 0,
  });
  const [towns, setTowns] = useState([]);
  const [nit, setNit] = useState(0);

  const dataCompany = (departments, towns, company) => {
    const dataCompany = [];
    company.map((el) => {
      dataCompany.push({
        id: el.id,
        idForm: el.idForm,
        status: el.status,
        company: {
          department: departments.find((dpto) => dpto.id === el.company.deparment_id),
          town: towns.find((town) => town.id === el.company.town_id),
          username: el.company.username,
          nit: el.company.nit,
          name: el.company.name,
        },
      });
    });
    dispatch({ type: "UploadDocs", payload: dataCompany });
  };

 /*  useEffect(() => {
    (async () => {
      const { data: dptos } = await GET(
        `${process.env.REACT_APP_POLITICAL_SEGMENTATION_ENDPOINT}/13/children`
      );
      setPolitical((poli) => ({ ...poli, departments: [...poli.departments, ...dptos] }));

      const { data: towns } = await GET(
        `${process.env.REACT_APP_POLITICAL_SEGMENTATION_ENDPOINT}/2/towns`
      );
      setTowns(towns);

      const { data: statusCompany } = await GET(
        `${process.env.REACT_APP_COMPANY_ENDPOINT}/status/all`
      );
      const { data: company } = await GET(
        `${process.env.REACT_APP_REPORT_ENDPOINT}/report/companies/all`
      );
      const dataCompanyComplete = await mergeDataCompany(statusCompany, company);

      dataCompany(dptos, towns, dataCompanyComplete);
    })();
  }, []); */

  const handleSubmit = async (e) => {
    e.preventDefault();

    dispatch({ type: "emptyState", payload: [] });
    const url = process.env.REACT_APP_REPORT_ENDPOINT;

    if (political.department_selected !== 0 && political.town_selected === 0) {
      const { data } = await GET(
        `${url}/report/applies/department/${political.department_selected}`
      );
      dispatch({ type: "UploadDocs", payload: data });
    } else if (political.department_selected !== 0 && political.town_selected !== 0) {
      const { data } = await GET(`${url}/report/applies/town/${political.town_selected}`);
      dispatch({ type: "UploadDocs", payload: data });
    } else {
      const { data } = await GET(`${url}/report/applies/all`);
      dispatch({ type: "UploadDocs", payload: data });
    }
  };

  const handleSubmitNit = async (e) => {
    e.preventDefault();
    dispatch({ type: "emptyState", payload: [] });
    const { data } = await GET(`${process.env.REACT_APP_COMPANY_ENDPOINT}/company/byNit/${nit}`);
    const idCompany = data.id;

    const { data: statusCompany } = await GET(
      `${process.env.REACT_APP_COMPANY_ENDPOINT}/status/${idCompany}`
    );

    dataCompany(political.departments, towns, statusCompany);
  };

  const handleNit = ({ target }) => {
    setNit(target.value);
  };

  const handleDepartment = async ({ target }) => {
    const { data } = await GET(
      `${process.env.REACT_APP_POLITICAL_SEGMENTATION_ENDPOINT}/${target.value}/children`
    );
    setPolitical((poli) => ({
      ...poli,
      department_selected: parseInt(target.value),
      towns: [...data],
    }));
  };

  const handleTown = async ({ target }) => {
    setPolitical((poli) => ({ ...poli, town_selected: parseInt(target.value) }));
  };

  const [page, setPage] = useState(1);
  /*const { slice, range } = usePagination(state, page, 10, 1);*/
  let PageSize = 6;
  const currentTableData = useMemo(() => {
    const firstPageIndex = (page - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    //if(state == null){
      //return [];
    //}else{
      return state.slice(firstPageIndex, lastPageIndex);
    //}
  }, [ state ,page]);

  return (
    <Container className="container">
      <Row>
        <div>
          <Breadcrumbs
            view={[
              { name: "Inicio", path: "/graphics" },
              { name: "Reportes", path: "/init/reports" },
              { name: "Documentos Cargados", path: "" },
            ]}
          ></Breadcrumbs>
          <NavLink to="/init/reports" className="styleArrowLink">
            <img src={ArrowBack} width="50" />
          </NavLink>
        </div>
      </Row>
      <Row className="justify-content-center" style={{ marginBottom: "1rem" }}>
        <Col md={{ span: 5 }}>
          <h3 style={{ textAlign: "center", color: "#3A4297" }}>Reporte de Documentos Cargados</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form onSubmit={handleSubmitNit}>
            <Form.Row>
              <Form.Group as={Col} controlId="nit-text">
                <Form.Label>Nit</Form.Label>
                <Form.Control type="text" onChange={handleNit} />
              </Form.Group>
              <button
                className="btn btn-primary ml-3"
                style={{ height: "2.5rem", alignSelf: "center" }}
                type="submit"
              >
                Buscar
              </button>
            </Form.Row>
          </Form>
        </Col>
        <Col className="pt-4 d-flex justify-content-end" style={{ height: "4.5em" }}>
          <ExportCSV
            headers={headersUploadDocs}
            csvData={state}
            fileName={"Reporte de Documentos Cargados"}
          />
        </Col>
      </Row>
      <Row>
        <Col style={{ overflow: "auto" }} className="table-responsive">
          <Table striped bordered className="report-table" style={{ marginTop: "3rem", background: "white"}}>
            <thead>
              <tr>
                {headersUploadDocs.map((header, i) => (
                  <th key={i}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {
                /*slice*/currentTableData.map((row, i) => {
                  return (
                    <tr key={`row-${i}`}>
                      {row.map((value, i) => (
                        <td key={`td-${i}`}>
                          {value === "No Guardado" ? (
                            <div
                              // className="td-div"
                              // style={{ display: "flex", alignItems: "flex-start" }}
                            >
                              <Clock style={{ width: "7.5rem", marginRight: "0.3rem" }} />
                              
                            </div>
                          ) : value === "Guardado" ? (
                            <div
                              // className="td-div"
                              // style={{ display: "flex", alignItems: "flex-start" }}
                            >
                              <Process style={{ width: "7.5rem", marginRight: "0.3rem" }} />
                              
                            </div>
                          ) : value === "Descargado" ? (
                            <div
                              // className="td-div"
                              // style={{ display: "flex", alignItems: "flex-start" }}
                            >
                              <Done style={{ width: "7.5rem", marginRight: "0.3rem" }} />
                              
                            </div>
                          ) : (
                            value
                          )}
                        </td>
                      ))}
                    </tr>
                  );
                })
              }
            </tbody>
          </Table>
          <PaginationTable /*range={range} slice={slice} setPage={setPage} page={page}*/className="pagination-bar"
            currentPage={page}
            totalCount={state.length}
            pageSize={PageSize}
            onPageChange={page => setPage(page)} />
          {/*  <TableReport headers={headersUploadDocs} content={state} links={6} /> */}
          <h2 style={{ textAlign:  'center', marginTop: '5rem' }}>PÁGINA EN MANTENIMIENTO...</h2>
        </Col>
      </Row>
    </Container>
  );
};

UploadDocs.propTypes = {};

UploadDocs.defaultProps = {};

export default UploadDocs;
