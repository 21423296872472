import React from 'react';
import PropTypes from 'prop-types';
import './HeaderPage.scss';

const HeaderPage = () => (
  <div className="HeaderPage">
    HeaderPage Component
  </div>
);

HeaderPage.propTypes = {};

HeaderPage.defaultProps = {};

export default HeaderPage;
