import React, { useState, useEffect } from "react";
import "./PDFMorbidity.scss";
import { Container, Row, Col, Spinner, Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";

import { GET, POSTNORMAL } from "../../constants/ApiRest";

//import components
import PdfReportGenerator from "../../components/PdfReportGenerator/PdfReportGenerator";
import FormMorbidity from "../../components/FormMorbidity/FormMorbidity";

//import data
// import data from "./data";
import data from "../../abstract/morbidity";
//import data from "../../abstract/psychosocial";
//import data from "../../abstract/socioDemographic";
// import data from "../../abstract/dangerMatrix";

const PDFMorbidity = ({ setLogin }) => {
  let location = useLocation();

  const [viewPDF, setViewPDF] = useState(false);
  const [cover, setCover] = useState([]);
  const [toc, setToc] = useState([]);
  const [content, setContent] = useState([]);
  const [isData, setisData] = useState(false);

  //Mes de descarga del informe
  const monthNames = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  const date_application = new Date();
  const date = `${monthNames[date_application.getMonth()]}, ${date_application.getFullYear()}`;

  //datos que se colocan al pdf

  const [init, setInit] = useState({
    name: "",
    date: date,
    city: "",
    department: "",
    lic: "N° 1701 del 15 septiembre 2016",
    idDepartment: "",
    idCity: "",
  });

  const [contentS, setContentS] = useState({
    CompanyName: "",
    NIT: "",
    CIIU: "",
    questAplication: "",
    questAplicationNum: "",
    questPorcent: "",
    conclusions: "",
    sector: "",
    idSector: "",
    workers: "",
    conclusions: "",
  });

  const [chartTables, setChartTables] = useState([
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
    }, // 0
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: `Del total de la población encuestada, se aplicaron (TOTAL DE TRABAJADORES ENCUESTADOS) cuestionarios nórdicos de los cuales el (% de genero femenino)  (# total en número del género femenino) son trabajadores de género femenino y el ((% de género masculino) (# total en número del género masculino) de los trabajadores son del género masculino.`,
    }, // 1
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "",
    }, // 2
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "",
    }, // 3
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
    }, // 4 GRAFICA 4 AREA DE TRABAJO
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "",
    }, // 5
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "",
    }, // 6
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "",
    }, // 7
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis:
        "A nivel de miembro inferior se evidencia que (sumatoria del # de personas total en der-izq y ambos) trabajadores manifestaron síntomas en esta región del cuerpo, siendo (segmento corporal que mas síntomas reportaron)  el segmento corporal mas afectado con el (% del mas afectado) del total de los encuestados, donde prevalece la molestia a nivel de (segmento con la lateralidad mas afectada); seguido se encuentra (segundo segmento mas afectado) con el (% del segundo mas afectado) siendo la lateralidad (nombrar lateralidad) la mas afectada y finalmente (tercer segmento mas afectado) con el (% del tercer segmento mas afectado).",
    }, // 8
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "",
    }, // 9
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis:
        "En los trabajadores que han presentado molestias en los últimos 12 meses, prevalece la sintomatología con duración (  ) principalmente a nivel de (segmentos con mayor cantidad), seguido de duración (   ) principalmente a nivel de (    ) y finalmente duración (    ) la cual prevalece a nivel de (    ).",
    }, // 10
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
    }, //11
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "",
    }, //12
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
    }, //13
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "",
    }, //14
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "",
    }, // 15
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
    }, // 16
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "",
    }, // 17
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "",
    }, // 18
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis:
        "De acuerdo a la clasificación de la población se logra concluir que la población que reporta ser sintomática se encuentra principalmente en el área de (    ) con un total de (# de sintomáticos del área mas sintomática) trabajadores que manifestaron síntomas lo que equivale al (%) del total de sintomáticos; mientras que en el área (  ) se encontraron un total de (# de trabajadores sintomáticos de esa área).",
    }, // 19

    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
    }, // 20
    {
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "",
    }, // 21
    {
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],

      analysis: "",
    }, // 22
    {
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis:
        "De acuerdo a la clasificación se logra concluir que la población que reporta ser sintomática se encuentra principalmente en el cargo de (    ) con (#) trabajadores en total lo que corresponde al (%) de la población sintomática,  en segundo lugar prevalece el cargo de (    ) con (# ) trabajadores lo que equivale al (%)  y finalmente en el cargo de (    ) con un total de (#) trabajadores siendo el (%) de la población sintomática.",
    }, // 23
  ]);

  const [conclusions, setConclusions] = useState({
    percentWomens: "",
    percentMens: "",
    percentOperative: "",
    percentAdministrative: "",
    rangeAge: "",
    percentIMCNormal: "",
    sumSymptomatology: "",
    percentAsymptomatic: "",
    percentSymptomatic: "",
    symptomsTwelveDays: "",
    symptomaticArea: "",
  });

  //Obtener formulario
  useEffect(() => {
    // 36 , 122 , 176 , 292 , 437 1204 location.state.idCompany
    console.log(location.state);
    GET(
      `${process.env.REACT_APP_REPORT_ENDPOINT}/formless/data/${location.state.idCompany}/${location.state.form_id}`
    )
      .then((result) => {
        const data = result.data;
        setInitialData(data);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.data.reload === true) {
            POSTNORMAL(process.env.REACT_APP_FILE_LOADER_ENDPOINT + "validate", {
              idCompany: location.state.idCompany,
              nitCompany: location.state.dataCompany.nit,
              form_id: location.state.form_id,
            })
              .then((response) => {
                window.location.reload();
              })
              .catch(console.log(err));
          }
        }
        console.log(err);
      });
  }, []);

  //Obtener empresa
  useEffect(() => {
    GET(`${process.env.REACT_APP_COMPANY_ENDPOINT}/company/getnit/${location.state.idCompany}}`)
      .then((result) => {
        const data = result.data;
        setInit({
          ...init,
          name: data.name,
          idDepartment: data.deparment_id,
          idCity: data.town_id,
        });
        contentS.CompanyName = data.name;
        contentS.NIT = data.nit;
        contentS.idSector = data.main_activity;
        contentS.workers = data.workers;
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //Obtener ciudad
  useEffect(() => {
    if (init.idDepartment !== "") {
      GET(`${process.env.REACT_APP_POLITICAL_SEGMENTATION_ENDPOINT}/${init.idDepartment}/children`)
        .then((result) => {
          const data = result.data;
          const nameCity = data.find((el) => el.id === init.idCity);
          setInit({ ...init, city: nameCity.name });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [init.idDepartment]);

  //Obtener departamento
  useEffect(() => {
    if (init.idDepartment !== "") {
      GET(`${process.env.REACT_APP_POLITICAL_SEGMENTATION_ENDPOINT}/13/children`)
        .then((result) => {
          const nameDepartment = result.data.find((el) => el.id === init.idDepartment);
          setInit({ ...init, department: nameDepartment.name });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [init.city]);

  //Obtener sector
  useEffect(() => {
    if (contentS.idSector !== "") {
      GET(`${process.env.REACT_APP_SECTORS}/sector/category/${contentS.idSector}`)
        .then((result) => {
          contentS.sector = result.data[0].sector;
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [contentS.idSector]);

  //Obtener fecha del informe
  useEffect(() => {
    GET(
      `${process.env.REACT_APP_ANSWERS_ENDPOINT}/company/${location.state.idCompany}/${location.state.form_id}`
    )
      .then((result) => {
        const orderData = Object.values(result.data).reverse();
        const date = orderData[0].CREATED_AT;
        const date_application = new Date(date).toLocaleDateString();
        // setContentS({ ...contentS, questAplication: date_application })
        contentS.questAplication = date_application;
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //Obtener porcentaje de muestra
  useEffect(() => {
    if (contentS.questAplicationNum !== "") {
      const percent = Math.round((contentS.questAplicationNum / contentS.workers) * 100);
      //  setContentS({ ...contentS, questPorcent: percent })
      contentS.questPorcent = percent;
    }
  }, [contentS.questAplicationNum]);

  const GeneratePDF = (key, value) => {
    setViewPDF(!viewPDF);
    const auxTable = [...chartTables];
    auxTable[5].table = value.data1.map((val) => Object.values(val));
    auxTable[20].table = value.data2.map((val) => Object.values(val));
    auxTable[23].table = value.data3.map((val) => Object.values(val));
    //contentS.conclusions = value.conclution;
    // contentS.resultsAnalysis = value.resultsAnalysis;
    contentS.conclusions = `
    •	Se realizó la aplicación de encuesta de morbilidad sentida por medio del cuestionario nórdico modificado en la empresa ${init.name.toUpperCase()} del municipio de ${init.city.toUpperCase()} con una muestra del ${
      contentS.questPorcent
    }%; de las cuales el ${Math.round(
      conclusions.percentMens
    )}% son de género masculino y el ${Math.round(
      conclusions.percentWomens
    )}% restante son del género femenino, lo anterior no incrementa los resultados de sintomatologia general ya que con relación al genero existen diferencias, que sugieren que hay mayor prevalencia de los DME en el genero femenino. En el plano biologico la diferencia puede deberse a que las mujeres tienen diferentes condiciones de fuerza muscular y dimensiones antropometricas, ademas de la influencia de factores hormonales que pueden interactuar con las diferentes condiciones de trabajo y mecanismos psicobiologicos producto de los aspectos organizacionales del trabajo, y de las exposicion extralaboral con relacíon a las tareas domesticas que genera acumulacion de fatiga en las diferentes estructuras.\n
    • De las personas encuestadas se encuentra que el ${Math.round(
      conclusions.percentOperative
    )}% pertenecen al área operativa; mientras que el ${Math.round(
      conclusions.percentAdministrative
    )}% restante pertenece al área administrativa, prevalece el cargo de XXX.\n
    •	En cuanto al grupo etario se encuentra que predominan las edades ${
      conclusions.rangeAge
    } años,  los anteriores resultados pueden representar un factor de riesgo para el aumento de la sintomatología, ya que con el aumento de la edad los procesos biológicos y su recuperación de la fatiga son más largos y acompañados de procesos de envejecimiento celular pueden desencadenar enfermedades a nivel osteomuscular.\n
    •	De acuerdo al IMC se encuentra personal en rango ANORMAL con el XX%; seguido de IMC NORMAL con el ${
      conclusions.percentIMCNormal
    }% restante; es importante tener en cuenta que un IMC anormal pueden incrementar en esta población el riesgo de padecer enfermedades a nivel musculo-esquelético, ya que en las personas que tienen un IMC aumentado se ha identificado alteraciones metabólicas y por consiguiente endocrinas que generan cambios negativos a nivel celular en los tejidos y desarrollo de enfermedades crónicas y de alta severidad como HTA, Diabetes, enfermedades cardiacas, ECV, etc, alteraciones que se pueden ver reflejadas en la calidad de vida de los trabajadores. Las enfermedades musculo esqueléticas que pueden estar asociadas al sobrepeso y la obesidad son el dolor lumbar y la osteoartritis e hiperuricemia, entre otros, dados por el exceso de carga en las articulaciones que determinan la falla tisular. Con relación a lo anterior se indica que la normalización del IMC en población con estas patologías puede ser una estrategia de intervención que disminuye la sintomatología retrasa el daño en las articulaciones.\n
    •	De los trabajadores encuestados que presentaron sintomatología se define en total ${
      conclusions.sumSymptomatology
    } síntomas  el cual  se clasifican como molestias leves principalmente; entre la sintomatología reportada  NO se presentó impedimento para realizar sus actividades laborales y de la vida diaria.\n
    •	En cuanto a la prevalencia de la sintomatología predomina ${
      conclusions.symptomsTwelveDays
    },  este es un aspecto importante a la hora de orientar la intervención teniendo en cuenta que cuando se trata de dolor agudo, es el resultado de una activación de sistemas nociceptivos que se presenta por corto plazo y tiene una función de protección biológica, mientras un dolor crónico, es un síntoma persistente y por un tiempo prolongado como resultado de una alteración en una estructura asociada con un daño tisular presente o potencial por lo general asociado a componentes psicológicos.\n
    •	Entre los diferentes segmentos corporales prevalece la sintomatología a nivel de XXX principalmente en XXX\n.
    •	El ${Math.round(
      conclusions.percentSymptomatic
    )}% de la población encuestada es SINTOMÁTICA, mientras que el ${Math.round(
      conclusions.percentAsymptomatic
    )}% restante es ASINTOMÁTICA, encontrando la población con síntomas principalmente en el área ${
      conclusions.symptomaticArea
    } en el cargo de XXXX.`;
    setChartTables(auxTable);
    GenerateData();
  };

  //19 dato a cambiar manual
  const GenerateData = () => {
    const { cover: cov, toc: t, content: cont } = data(init, contentS, chartTables);
    setCover(cov);
    setToc(t);
    setContent(cont);
  };

  const setInitialData = (data) => {
    contentS.questAplicationNum = data.jsonAnswer.workersSurvey;
    setisData(true);

    const tmpChartTables = [...chartTables];

    // Gráfico 1: Distribución por género
    tmpChartTables[0].table = [
      ["Masculino", data.jsonAnswer.percentMens],
      ["Femenino", data.jsonAnswer.percentWomens],
      ["Otro", data.jsonAnswer.percentOtherGenre],
    ];

    // Tabla 1: Distribución por género
    tmpChartTables[1].table = [
      ["Masculino", data.jsonAnswer.mens],
      ["Femenino", data.jsonAnswer.womens],
      ["Otro", data.jsonAnswer.otherGenre],
    ];

    // Tabla 1: Distribución por género - Analisis
    tmpChartTables[1].analysis = `Del total de la población encuestada, se aplicaron ${
      data.jsonAnswer.workersSurvey
    } cuestionarios nórdicos de los cuales el ${Math.round(
      data.jsonAnswer.percentWomens
    )}% pertenecen al género femenino (${data.jsonAnswer.womens}), mientras el ${Math.round(
      data.jsonAnswer.percentMens
    )}% pertenecen al género masculino (${data.jsonAnswer.mens}).`;

    //Tabla 2. Rango de edad
    const rango = (tmpChartTables[2].table = [
      ["Entre 18 y 25", data.jsonAnswer.ageRange18],
      ["Entre 26 y 35", data.jsonAnswer.ageRange26],
      ["Entre 36 y 45", data.jsonAnswer.ageRange36],
      ["Entre 46 y 55", data.jsonAnswer.ageRange46],
      ["Mayor a 55", data.jsonAnswer.ageRange56],
      [
        "Total",
        data.jsonAnswer.ageRange18 +
          data.jsonAnswer.ageRange26 +
          data.jsonAnswer.ageRange36 +
          data.jsonAnswer.ageRange46 +
          data.jsonAnswer.ageRange56,
      ],
    ]);

    const a = rango.concat();
    a.pop();
    const order = a.sort((a, b) => b[1] - a[1]);

    const totalEtario =
      data.jsonAnswer.ageRange18 +
      data.jsonAnswer.ageRange26 +
      data.jsonAnswer.ageRange36 +
      data.jsonAnswer.ageRange46 +
      data.jsonAnswer.ageRange56;

    tmpChartTables[2].analysis =
      order[0][1] === totalEtario
        ? `Tras realizar la aplicación del cuestionario nórdico, se identifica que el rango de edad que más prevalece es el de ${
            order[0][0]
          } con el ${Math.round((order[0][1] / totalEtario) * 100)}%`
        : ` Tras realizar la aplicación del cuestionario nórdico, se identifica que el rango de edad que más prevalece es el de ${
            order[0][0]
          } con el ${Math.round((order[0][1] / totalEtario) * 100)}%, seguido de ${
            order[1][0]
          } con el ${Math.round((order[1][1] / totalEtario) * 100)}% y finalmente ${
            order[2][0]
          } con el ${Math.round((order[2][1] / totalEtario) * 100)}%.`;

    //Tabla 3. Índice de masa corporal
    const masa = (tmpChartTables[3].table = [
      ["Bajo de peso", data.jsonAnswer.imcThin],
      ["Normal", data.jsonAnswer.imcNormal],
      ["Sobrepeso", data.jsonAnswer.imcFat],
      ["Obesidad grado I", data.jsonAnswer.imcFat1],
      ["Obesidad grado II", data.jsonAnswer.imcFat2],
      ["Obesidad grado III", data.jsonAnswer.imcFat3],
      [
        "Total",
        data.jsonAnswer.imcThin +
          data.jsonAnswer.imcNormal +
          data.jsonAnswer.imcFat +
          data.jsonAnswer.imcFat1 +
          data.jsonAnswer.imcFat2 +
          data.jsonAnswer.imcFat3,
      ],
    ]);

    const a06 = masa.concat();
    a06.pop();
    const order06 = a06.sort((a, b) => b[1] - a[1]);

    const total06 =
      data.jsonAnswer.imcThin +
      data.jsonAnswer.imcNormal +
      data.jsonAnswer.imcFat +
      data.jsonAnswer.imcFat1 +
      data.jsonAnswer.imcFat2 +
      data.jsonAnswer.imcFat3;

    const percentNormal = Math.round((data.jsonAnswer.imcNormal / total06) * 100);

    tmpChartTables[3].analysis = `En cuanto a la clasificación de IMC se encuentra que el ${Math.round(
      (order06[0][1] / total06) * 100
    )}% de la población presenta una clasificación ${order06[0][0].toUpperCase()}, seguido de ${order06[1][0].toUpperCase()} con el ${Math.round(
      (order06[1][1] / total06) * 100
    )}% y finalmente ${order06[2][0].toUpperCase()} con el ${Math.round(
      (order06[2][1] / total06) * 100
    )}%.`;

    //Tabla 4. Área de trabajo
    tmpChartTables[4].table = [
      ["Administrativo", data.jsonAnswer.percentAdministrative],
      ["Operativo", data.jsonAnswer.percentOperative],
    ];

    const area = (tmpChartTables[21].table = [
      ["Administrativo", data.jsonAnswer.administrativeArea],
      ["Operativo", data.jsonAnswer.OperativeArea],
      ["Total", data.jsonAnswer.administrativeArea + data.jsonAnswer.OperativeArea],
    ]);

    const a21 = area.concat();
    a21.pop();
    const order21 = a21.sort((a, b) => b[1] - a[1]);

    const total21 =
      data.jsonAnswer.imcThin +
      data.jsonAnswer.imcNormal +
      data.jsonAnswer.imcFat +
      data.jsonAnswer.imcFat1 +
      data.jsonAnswer.imcFat2 +
      data.jsonAnswer.imcFat3;

    tmpChartTables[21].analysis = `El ${Math.round(
      (order21[0][1] / total21) * 100
    )}% de la población encuestada pertenece al area ${
      order21[0][0].substr(0, order21[0][0].length - 1) + "a"
    }; mientras que el ${Math.round((order21[1][1] / total21) * 100)}% corresponden al area ${
      order21[1][0].substr(0, order21[1][0].length - 1) + "a"
    }.`;

    // Tabla 5: Cargo
    tmpChartTables[22].table = [
      ["Analista", data.jsonAnswer.analista, data.jsonAnswer.percentanalista],
      ["Asistente", data.jsonAnswer.asistente, data.jsonAnswer.percentasistente],
      ["Auxiliar", data.jsonAnswer.auxiliar, data.jsonAnswer.percentauxiliar],
      ["Auxiliar Administrativo", data.jsonAnswer.auxAdm, data.jsonAnswer.percentauxAdm],
      ["Auxiliar Operativo", data.jsonAnswer.auxOpe, data.jsonAnswer.percentauxOpe],
      ["Atención Al Cliente", data.jsonAnswer.atenCliente, data.jsonAnswer.percentatenCliente],
      ["Almacenista", data.jsonAnswer.almacenista, data.jsonAnswer.percentalmacenista],
      ["Ayudante", data.jsonAnswer.ayudante, data.jsonAnswer.percentayudante],
      ["Administrador", data.jsonAnswer.administrador, data.jsonAnswer.percentadministrador],
      ["Auditor", data.jsonAnswer.auditor, data.jsonAnswer.percentauditor],
      ["Asesor Call Center", data.jsonAnswer.AsesorCallCen, data.jsonAnswer.percentAsesorCallCen],
      ["Asesor", data.jsonAnswer.asesor, data.jsonAnswer.percentasesor],
      ["Aprendiz", data.jsonAnswer.aprendiz, data.jsonAnswer.percentaprendiz],
      ["Barista", data.jsonAnswer.barista, data.jsonAnswer.percentbarista],
      ["Bodeguero", data.jsonAnswer.bodeguero, data.jsonAnswer.percentbodeguero],
      ["Carpintero", data.jsonAnswer.carpintero, data.jsonAnswer.percentcarpintero],
      ["Cajero", data.jsonAnswer.cajero, data.jsonAnswer.percentcajero],
      ["Coordinador", data.jsonAnswer.coordinador, data.jsonAnswer.percentcoordinador],
      ["Cocinero", data.jsonAnswer.cocinero, data.jsonAnswer.percentcocinero],
      ["Chef", data.jsonAnswer.chef, data.jsonAnswer.percentchef],
      ["Cortador", data.jsonAnswer.cortador, data.jsonAnswer.percentcortador],
      ["Contador", data.jsonAnswer.contador, data.jsonAnswer.percentcontador],
      ["Conductor", data.jsonAnswer.conductor, data.jsonAnswer.percentconductor],
      ["Contratista ", data.jsonAnswer.contratista, data.jsonAnswer.percentcontratista],
      [
        "Director Regional",
        data.jsonAnswer.directorRegional,
        data.jsonAnswer.percentdirectorRegional,
      ],
      ["Director De Area", data.jsonAnswer.directorArea, data.jsonAnswer.percentdirectorArea],
      ["Director General", data.jsonAnswer.directorGeneral, data.jsonAnswer.percentdirectorGeneral],
      ["Despachador", data.jsonAnswer.despachador, data.jsonAnswer.percentdespachador],
      ["Diseño", data.jsonAnswer.diseño, data.jsonAnswer.percentdiseño],
      ["Dibujante", data.jsonAnswer.dibujante, data.jsonAnswer.percentdibujante],
      ["Domiciliario", data.jsonAnswer.domiciliario, data.jsonAnswer.percentdomiciliario],
      ["Ensamblador", data.jsonAnswer.ensamblador, data.jsonAnswer.percentensamblador],
      ["Encargado Sst", data.jsonAnswer.encargadosst, data.jsonAnswer.percentencargadosst],
      ["Estilista", data.jsonAnswer.estilista, data.jsonAnswer.percentestilista],
      ["Editor", data.jsonAnswer.editor, data.jsonAnswer.percenteditor],
      ["Facturación ", data.jsonAnswer.facturacion, data.jsonAnswer.percentfacturacion],
      ["Gerente", data.jsonAnswer.gerente, data.jsonAnswer.percentgerente],
      ["Hornero", data.jsonAnswer.hornero, data.jsonAnswer.percenthornero],
      ["Instructor", data.jsonAnswer.instructor, data.jsonAnswer.percentinstructor],
      ["Ingeniero", data.jsonAnswer.ingeniero, data.jsonAnswer.percentingeniero],
      ["Jefe De Area", data.jsonAnswer.jefeArea, data.jsonAnswer.percentjefeArea],
      ["Jardinero", data.jsonAnswer.jardinero, data.jsonAnswer.percentjardinero],
      ["Logistica", data.jsonAnswer.logistica, data.jsonAnswer.percentlogistica],
      ["Lider", data.jsonAnswer.lider, data.jsonAnswer.percentlider],
      ["Mesero", data.jsonAnswer.mesero, data.jsonAnswer.percentmesero],
      ["Mecanico", data.jsonAnswer.mecanico, data.jsonAnswer.percentmecanico],
      ["Mensajero", data.jsonAnswer.mensajero, data.jsonAnswer.percentmensajero],
      ["Maestro De Obra", data.jsonAnswer.maestroObra, data.jsonAnswer.percentmaestroObra],
      ["Mercaderista", data.jsonAnswer.mercaderista, data.jsonAnswer.percentmercaderista],
      ["Operario ", data.jsonAnswer.operario, data.jsonAnswer.percentoperario],
      ["Operario De Aseo", data.jsonAnswer.operarioAseo, data.jsonAnswer.percentoperarioAseo],
      [
        "Operario De Maquina",
        data.jsonAnswer.operarioMaquina,
        data.jsonAnswer.percentoperarioMaquina,
      ],
      ["Oficios Varios", data.jsonAnswer.oficiosVarios, data.jsonAnswer.percentoficiosVarios],
      ["Obrero", data.jsonAnswer.obrero, data.jsonAnswer.percentobrero],
      ["Oficial", data.jsonAnswer.oficial, data.jsonAnswer.percentoficial],
      ["Practicante", data.jsonAnswer.practicante, data.jsonAnswer.percentpracticante],
      [
        "Profesional En Salud",
        data.jsonAnswer.profesionalSalud,
        data.jsonAnswer.percentprofesionalSalud,
      ],
      ["Procesador", data.jsonAnswer.procesador, data.jsonAnswer.percentprocesador],
      ["Pintor", data.jsonAnswer.pintor, data.jsonAnswer.percentpintor],
      ["Recepcionista", data.jsonAnswer.recepcionista, data.jsonAnswer.percentrecepcionista],
      [
        "Representante Legal",
        data.jsonAnswer.representanteLegal,
        data.jsonAnswer.percentrepresentanteLegal,
      ],
      ["Supervisor ", data.jsonAnswer.supervisor, data.jsonAnswer.percentsupervisor],
      ["Secretario/a", data.jsonAnswer.secretario, data.jsonAnswer.percentsecretario],
      [
        "Servicio Al Cliente",
        data.jsonAnswer.servicioCliente,
        data.jsonAnswer.percentservicioCliente,
      ],
      ["Seguridad", data.jsonAnswer.seguridad, data.jsonAnswer.percentseguridad],
      ["Soporte Tecnico", data.jsonAnswer.soporteTecnico, data.jsonAnswer.percentsoporteTecnico],
      ["Supernumerario", data.jsonAnswer.supernumerario, data.jsonAnswer.percentsupernumerario],
      ["Tecnico", data.jsonAnswer.tecnicoCargo, data.jsonAnswer.percenttecnicoCargo],
      ["Vendedor", data.jsonAnswer.vendedor, data.jsonAnswer.percentvendedor],
      ["Total", data.jsonAnswer.workersSurvey, "100%"],
    ];

    //Gráfica 5. Distribución por ocupación actual
    tmpChartTables[5].table = [
      ["Analista", data.jsonAnswer.analista],
      ["Asistente", data.jsonAnswer.asistente],
      ["Auxiliar", data.jsonAnswer.auxiliar],
      ["Auxiliar Administrativo", data.jsonAnswer.auxAdm],
      ["Auxiliar Operativo", data.jsonAnswer.auxOpe],
      ["Atención Al Cliente", data.jsonAnswer.atenCliente],
      ["Almacenista", data.jsonAnswer.almacenista],
      ["Ayudante", data.jsonAnswer.ayudante],
      ["Administrador", data.jsonAnswer.administrador],
      ["Auditor", data.jsonAnswer.auditor],
      ["Asesor Call Center", data.jsonAnswer.AsesorCallCen],
      ["Asesor", data.jsonAnswer.asesor],
      ["Aprendiz", data.jsonAnswer.aprendiz],
      ["Barista", data.jsonAnswer.barista],
      ["Bodeguero", data.jsonAnswer.bodeguero],
      ["Carpintero", data.jsonAnswer.carpintero],
      ["Cajero", data.jsonAnswer.cajero],
      ["Coordinador", data.jsonAnswer.coordinador],
      ["Cocinero", data.jsonAnswer.cocinero],
      ["Chef", data.jsonAnswer.chef],
      ["Cortador", data.jsonAnswer.cortador],
      ["Contador", data.jsonAnswer.contador],
      ["Conductor", data.jsonAnswer.conductor],
      ["Contratista ", data.jsonAnswer.contratista],
      ["Director Regional", data.jsonAnswer.directorRegional],
      ["Director De Area", data.jsonAnswer.directorArea],
      ["Director General", data.jsonAnswer.directorGeneral],
      ["Despachador", data.jsonAnswer.despachador],
      ["Diseño", data.jsonAnswer.diseño],
      ["Dibujante", data.jsonAnswer.dibujante],
      ["Domiciliario", data.jsonAnswer.domiciliario],
      ["Ensamblador", data.jsonAnswer.ensamblador],
      ["Encargado Sst", data.jsonAnswer.encargadosst],
      ["Estilista", data.jsonAnswer.estilista],
      ["Editor", data.jsonAnswer.editor],
      ["Facturación ", data.jsonAnswer.facturacion],
      ["Gerente", data.jsonAnswer.gerente],
      ["Hornero", data.jsonAnswer.hornero],
      ["Instructor", data.jsonAnswer.instructor],
      ["Ingeniero", data.jsonAnswer.ingeniero],
      ["Jefe De Area", data.jsonAnswer.jefeArea],
      ["Jardinero", data.jsonAnswer.jardinero],
      ["Logistica", data.jsonAnswer.logistica],
      ["Lider", data.jsonAnswer.lider],
      ["Mesero", data.jsonAnswer.mesero],
      ["Mecanico", data.jsonAnswer.mecanico],
      ["Mensajero", data.jsonAnswer.mensajero],
      ["Maestro De Obra", data.jsonAnswer.maestroObra],
      ["Mercaderista", data.jsonAnswer.mercaderista],
      ["Operario ", data.jsonAnswer.operario],
      ["Operario De Aseo", data.jsonAnswer.operarioAseo],
      ["Operario De Maquina", data.jsonAnswer.operarioMaquina],
      ["Oficios Varios", data.jsonAnswer.oficiosVarios],
      ["Obrero", data.jsonAnswer.obrero],
      ["Oficial", data.jsonAnswer.oficial],
      ["Practicante", data.jsonAnswer.practicante],
      ["Profesional En Salud", data.jsonAnswer.profesionalSalud],
      ["Procesador", data.jsonAnswer.procesador],
      ["Pintor", data.jsonAnswer.pintor],
      ["Recepcionista", data.jsonAnswer.recepcionista],
      ["Representante Legal", data.jsonAnswer.representanteLegal],
      ["Supervisor ", data.jsonAnswer.supervisor],
      ["Secretario/a", data.jsonAnswer.secretario],
      ["Servicio Al Cliente", data.jsonAnswer.servicioCliente],
      ["Seguridad", data.jsonAnswer.seguridad],
      ["Soporte Tecnico", data.jsonAnswer.soporteTecnico],
      ["Supernumerario", data.jsonAnswer.supernumerario],
      ["Tecnico", data.jsonAnswer.tecnicoCargo],
      ["Vendedor", data.jsonAnswer.vendedor],
    ];

    const newTableFive = tmpChartTables[22].table.concat();
    newTableFive.pop();

    const copyOcupacion = newTableFive;
    const orderOcupacion = copyOcupacion.sort((a, b) => b[1] - a[1]);

    tmpChartTables[22].analysis =
      orderOcupacion[0][2] === "100%"
        ? `El ${orderOcupacion[0][2]} de los trabajadores encuestados ocupan el cargo de ${orderOcupacion[0][0]}.`
        : `El ${orderOcupacion[0][2]} de los trabajadores encuestados ocupan el cargo de ${orderOcupacion[0][0]}, el ${orderOcupacion[1][2]} de los trabajadores encuestados ocupan el cargo de ${orderOcupacion[1][0]}, el ${orderOcupacion[2][2]} de los trabajadores encuestados ocupan el cargo de ${orderOcupacion[2][0]} y finalmente el ${orderOcupacion[3][2]} de los trabajadores encuestados ocupan el cargo de ${orderOcupacion[3][0]}`;

    //TABLA 6: Cuello y espalda

    //ANALISIS CUELLO Y ESPALDA
    const cuello = (tmpChartTables[6].table = [
      [
        "CUELLO/NUCA",
        ...data.jsonAnswer.symptomsNeck,
        data.jsonAnswer.symptomsNeck[0] + data.jsonAnswer.symptomsNeck[1],
      ],
      [
        "ESPALDA MEDIA",
        ...data.jsonAnswer.symptomsMiddleBack,
        data.jsonAnswer.symptomsMiddleBack[0] + data.jsonAnswer.symptomsMiddleBack[1],
      ],
      [
        "ESPALDA BAJA",
        ...data.jsonAnswer.symptomsLowerBack,
        data.jsonAnswer.symptomsLowerBack[0] + data.jsonAnswer.symptomsLowerBack[1],
      ],
      [
        "Total",
        data.jsonAnswer.symptomsNeck[0] +
          data.jsonAnswer.symptomsMiddleBack[0] +
          data.jsonAnswer.symptomsLowerBack[0],
        data.jsonAnswer.symptomsNeck[1] +
          data.jsonAnswer.symptomsMiddleBack[1] +
          data.jsonAnswer.symptomsLowerBack[1],
        data.jsonAnswer.symptomsNeck[0] +
          data.jsonAnswer.symptomsNeck[1] +
          data.jsonAnswer.symptomsMiddleBack[0] +
          data.jsonAnswer.symptomsMiddleBack[1] +
          data.jsonAnswer.symptomsLowerBack[0] +
          data.jsonAnswer.symptomsLowerBack[1],
      ],
    ]);
    const total6 =
      data.jsonAnswer.symptomsNeck[0] +
      data.jsonAnswer.symptomsMiddleBack[0] +
      data.jsonAnswer.symptomsLowerBack[0];

    const a6 = cuello.concat();
    a6.pop();
    const order6 = a6.sort((a, b) => b[1] - a[1]);

    tmpChartTables[6].analysis = `A nivel de columna se evidencia que se presenta en total ${total6} síntomas, los cuales son reportados en primer lugar a nivel de ${order6[0][0]}. `;

    // Tabla 7. Sintomatología en miembros superiores
    tmpChartTables[7].table = [
      [
        "HOMBRO",
        ...data.jsonAnswer.symptomsShoulder,
        data.jsonAnswer.symptomsShoulder[0] +
          data.jsonAnswer.symptomsShoulder[1] +
          data.jsonAnswer.symptomsShoulder[2],
      ],
      [
        "CODO",
        ...data.jsonAnswer.symptomsElbow,
        data.jsonAnswer.symptomsElbow[0] +
          data.jsonAnswer.symptomsElbow[1] +
          data.jsonAnswer.symptomsElbow[2],
      ],
      [
        "MUÑECA",
        ...data.jsonAnswer.symptomsWrist,
        data.jsonAnswer.symptomsWrist[0] +
          data.jsonAnswer.symptomsWrist[1] +
          data.jsonAnswer.symptomsWrist[2],
      ],
      [
        "Total",
        data.jsonAnswer.symptomsShoulder[0] +
          data.jsonAnswer.symptomsElbow[0] +
          data.jsonAnswer.symptomsWrist[0],
        data.jsonAnswer.symptomsShoulder[1] +
          data.jsonAnswer.symptomsElbow[1] +
          data.jsonAnswer.symptomsWrist[1],
        data.jsonAnswer.symptomsShoulder[2] +
          data.jsonAnswer.symptomsElbow[2] +
          data.jsonAnswer.symptomsWrist[2],
        data.jsonAnswer.symptomsShoulder[3] +
          data.jsonAnswer.symptomsElbow[3] +
          data.jsonAnswer.symptomsWrist[3],
        data.jsonAnswer.symptomsShoulder[0] +
          data.jsonAnswer.symptomsShoulder[1] +
          data.jsonAnswer.symptomsShoulder[2] +
          data.jsonAnswer.symptomsElbow[0] +
          data.jsonAnswer.symptomsElbow[1] +
          data.jsonAnswer.symptomsElbow[2] +
          data.jsonAnswer.symptomsWrist[0] +
          data.jsonAnswer.symptomsWrist[1] +
          data.jsonAnswer.symptomsWrist[2],
      ],
    ];
    const newHombro = [
      [
        "HOMBRO",
        data.jsonAnswer.symptomsShoulder[0] +
          data.jsonAnswer.symptomsShoulder[1] +
          data.jsonAnswer.symptomsShoulder[2],
      ],
      [
        "CODO",
        data.jsonAnswer.symptomsElbow[0] +
          data.jsonAnswer.symptomsElbow[1] +
          data.jsonAnswer.symptomsElbow[2],
      ],
      [
        "MUÑECA",
        data.jsonAnswer.symptomsWrist[0] +
          data.jsonAnswer.symptomsWrist[1] +
          data.jsonAnswer.symptomsWrist[2],
      ],
    ];

    const order7 = newHombro.sort((a, b) => b[1] - a[1]);

    const total7 =
      data.jsonAnswer.symptomsShoulder[0] +
      data.jsonAnswer.symptomsShoulder[1] +
      data.jsonAnswer.symptomsShoulder[2] +
      data.jsonAnswer.symptomsElbow[0] +
      data.jsonAnswer.symptomsElbow[1] +
      data.jsonAnswer.symptomsElbow[2] +
      data.jsonAnswer.symptomsWrist[0] +
      data.jsonAnswer.symptomsWrist[1] +
      data.jsonAnswer.symptomsWrist[2];

    tmpChartTables[7].analysis =
      total7 === 0
        ? `A nivel de miembro superior se evidencia que se presenta en total ${total7} síntomas.`
        : `A nivel de miembro superior se evidencia que se presenta en total ${total7} síntoma(s), los cuales son reportados en primer lugar a nivel de ${order7[0][0]}.`;

    //Tabla 8: Sintomatología en miembros inferiores
    tmpChartTables[8].table = [
      [
        "CADERA",
        ...data.jsonAnswer.symptomsHip,
        data.jsonAnswer.symptomsHip[0] +
          data.jsonAnswer.symptomsHip[1] +
          data.jsonAnswer.symptomsHip[2],
      ],
      [
        "RODILLA",
        ...data.jsonAnswer.symptomsKnee,
        data.jsonAnswer.symptomsKnee[0] +
          data.jsonAnswer.symptomsKnee[1] +
          data.jsonAnswer.symptomsKnee[2],
      ],
      [
        "TOBILLO",
        ...data.jsonAnswer.symptomsAnkle,
        data.jsonAnswer.symptomsAnkle[0] +
          data.jsonAnswer.symptomsAnkle[1] +
          data.jsonAnswer.symptomsAnkle[2],
      ],
      [
        "Total",
        data.jsonAnswer.symptomsHip[0] +
          data.jsonAnswer.symptomsKnee[0] +
          data.jsonAnswer.symptomsAnkle[0],
        data.jsonAnswer.symptomsHip[1] +
          data.jsonAnswer.symptomsKnee[1] +
          data.jsonAnswer.symptomsAnkle[1],
        data.jsonAnswer.symptomsHip[2] +
          data.jsonAnswer.symptomsKnee[2] +
          data.jsonAnswer.symptomsAnkle[2],
        data.jsonAnswer.symptomsHip[3] +
          data.jsonAnswer.symptomsKnee[3] +
          data.jsonAnswer.symptomsAnkle[3],
        data.jsonAnswer.symptomsHip[0] +
          data.jsonAnswer.symptomsHip[1] +
          data.jsonAnswer.symptomsHip[2] +
          data.jsonAnswer.symptomsKnee[0] +
          data.jsonAnswer.symptomsKnee[1] +
          data.jsonAnswer.symptomsKnee[2] +
          data.jsonAnswer.symptomsAnkle[0] +
          data.jsonAnswer.symptomsAnkle[1] +
          data.jsonAnswer.symptomsAnkle[2],
      ],
    ];

    const newCadera = [
      [
        "CADERA",
        data.jsonAnswer.symptomsHip[0] +
          data.jsonAnswer.symptomsHip[1] +
          data.jsonAnswer.symptomsHip[2],
      ],
      [
        "RODILLA",
        data.jsonAnswer.symptomsKnee[0] +
          data.jsonAnswer.symptomsKnee[1] +
          data.jsonAnswer.symptomsKnee[2],
      ],
      [
        "TOBILLO",
        data.jsonAnswer.symptomsAnkle[0] +
          data.jsonAnswer.symptomsAnkle[1] +
          data.jsonAnswer.symptomsAnkle[2],
      ],
    ];

    const order8 = newCadera.sort((a, b) => b[1] - a[1]);

    const total8 =
      data.jsonAnswer.symptomsHip[0] +
      data.jsonAnswer.symptomsHip[1] +
      data.jsonAnswer.symptomsHip[2] +
      data.jsonAnswer.symptomsKnee[0] +
      data.jsonAnswer.symptomsKnee[1] +
      data.jsonAnswer.symptomsKnee[2] +
      data.jsonAnswer.symptomsAnkle[0] +
      data.jsonAnswer.symptomsAnkle[1] +
      data.jsonAnswer.symptomsAnkle[2];

    tmpChartTables[8].analysis =
      total8 === 0
        ? `A nivel de miembro inferior se evidencia que se presenta en total ${total8} síntomas`
        : `A nivel de miembro inferior se evidencia que se presenta en total ${total8} síntoma(s), los cuales son reportados en primer lugar a nivel de ${order8[0][0]}.`;

    // Tabla 9: Impedimento para realizar su rutina habitual generada por la sintomatología

    tmpChartTables[9].table = [
      [
        "CUELLO/NUCA",
        ...data.jsonAnswer.symptomsNeckCont,
        data.jsonAnswer.symptomsNeckCont[0] +
          data.jsonAnswer.symptomsNeckCont[1] +
          data.jsonAnswer.symptomsNeckCont[2],
      ],
      [
        "ESPALDA MEDIA",
        ...data.jsonAnswer.symptomsMiddleBackCont,
        data.jsonAnswer.symptomsMiddleBackCont[0] +
          data.jsonAnswer.symptomsMiddleBackCont[1] +
          data.jsonAnswer.symptomsMiddleBackCont[2],
      ],
      [
        "ESPALDA BAJA",
        ...data.jsonAnswer.symptomsLowerBackCont,
        data.jsonAnswer.symptomsLowerBackCont[0] +
          data.jsonAnswer.symptomsLowerBackCont[1] +
          data.jsonAnswer.symptomsLowerBackCont[2],
      ],
      [
        "HOMBRO",
        ...data.jsonAnswer.symptomsShoulderCont,
        data.jsonAnswer.symptomsShoulderCont[0] +
          data.jsonAnswer.symptomsShoulderCont[1] +
          data.jsonAnswer.symptomsShoulderCont[2],
      ],
      [
        "CODO",
        ...data.jsonAnswer.symptomsElbowCont,
        data.jsonAnswer.symptomsElbowCont[0] +
          data.jsonAnswer.symptomsElbowCont[1] +
          data.jsonAnswer.symptomsElbowCont[2],
      ],
      [
        "MUÑECA",
        ...data.jsonAnswer.symptomsWristCont,
        data.jsonAnswer.symptomsWristCont[0] +
          data.jsonAnswer.symptomsWristCont[1] +
          data.jsonAnswer.symptomsWristCont[2],
      ],
      [
        "CADERA",
        ...data.jsonAnswer.symptomsHipCont,
        data.jsonAnswer.symptomsHipCont[0] +
          data.jsonAnswer.symptomsHipCont[1] +
          data.jsonAnswer.symptomsHipCont[2],
      ],
      [
        "RODILLA",
        ...data.jsonAnswer.symptomsKneeCont,
        data.jsonAnswer.symptomsKneeCont[0] +
          data.jsonAnswer.symptomsKneeCont[1] +
          data.jsonAnswer.symptomsKneeCont[2],
      ],
      [
        "TOBILLO/PIE",
        ...data.jsonAnswer.symptomsAnkleCont,
        data.jsonAnswer.symptomsAnkleCont[0] +
          data.jsonAnswer.symptomsAnkleCont[1] +
          data.jsonAnswer.symptomsAnkleCont[2],
      ],
      [
        "Total",
        data.jsonAnswer.symptomsNeckCont[0] +
          data.jsonAnswer.symptomsMiddleBackCont[0] +
          data.jsonAnswer.symptomsLowerBackCont[0] +
          data.jsonAnswer.symptomsShoulderCont[0] +
          data.jsonAnswer.symptomsElbowCont[0] +
          data.jsonAnswer.symptomsWristCont[0] +
          data.jsonAnswer.symptomsHipCont[0] +
          data.jsonAnswer.symptomsKneeCont[0] +
          data.jsonAnswer.symptomsAnkleCont[0],
        data.jsonAnswer.symptomsNeckCont[1] +
          data.jsonAnswer.symptomsMiddleBackCont[1] +
          data.jsonAnswer.symptomsLowerBackCont[1] +
          data.jsonAnswer.symptomsShoulderCont[1] +
          data.jsonAnswer.symptomsElbowCont[1] +
          data.jsonAnswer.symptomsWristCont[1] +
          data.jsonAnswer.symptomsHipCont[1] +
          data.jsonAnswer.symptomsKneeCont[1] +
          data.jsonAnswer.symptomsAnkleCont[1],
        data.jsonAnswer.symptomsNeckCont[2] +
          data.jsonAnswer.symptomsMiddleBackCont[2] +
          data.jsonAnswer.symptomsLowerBackCont[2] +
          data.jsonAnswer.symptomsShoulderCont[2] +
          data.jsonAnswer.symptomsElbowCont[2] +
          data.jsonAnswer.symptomsWristCont[2] +
          data.jsonAnswer.symptomsHipCont[2] +
          data.jsonAnswer.symptomsKneeCont[2] +
          data.jsonAnswer.symptomsAnkleCont[2],
        data.jsonAnswer.symptomsNeckCont[0] +
          data.jsonAnswer.symptomsNeckCont[1] +
          data.jsonAnswer.symptomsNeckCont[2] +
          data.jsonAnswer.symptomsMiddleBackCont[0] +
          data.jsonAnswer.symptomsMiddleBackCont[1] +
          data.jsonAnswer.symptomsMiddleBackCont[2] +
          data.jsonAnswer.symptomsLowerBackCont[0] +
          data.jsonAnswer.symptomsLowerBackCont[1] +
          data.jsonAnswer.symptomsLowerBackCont[2] +
          data.jsonAnswer.symptomsShoulderCont[0] +
          data.jsonAnswer.symptomsShoulderCont[1] +
          data.jsonAnswer.symptomsShoulderCont[2] +
          data.jsonAnswer.symptomsElbowCont[0] +
          data.jsonAnswer.symptomsElbowCont[1] +
          data.jsonAnswer.symptomsElbowCont[2] +
          data.jsonAnswer.symptomsWristCont[0] +
          data.jsonAnswer.symptomsWristCont[1] +
          data.jsonAnswer.symptomsWristCont[2] +
          data.jsonAnswer.symptomsHipCont[0] +
          data.jsonAnswer.symptomsHipCont[1] +
          data.jsonAnswer.symptomsHipCont[2] +
          data.jsonAnswer.symptomsKneeCont[0] +
          data.jsonAnswer.symptomsKneeCont[1] +
          data.jsonAnswer.symptomsKneeCont[2] +
          data.jsonAnswer.symptomsAnkleCont[0] +
          data.jsonAnswer.symptomsAnkleCont[1] +
          data.jsonAnswer.symptomsAnkleCont[2],
      ],
    ];

    const total9 =
      data.jsonAnswer.symptomsNeckCont[0] +
      data.jsonAnswer.symptomsMiddleBackCont[0] +
      data.jsonAnswer.symptomsLowerBackCont[0] +
      data.jsonAnswer.symptomsShoulderCont[0] +
      data.jsonAnswer.symptomsElbowCont[0] +
      data.jsonAnswer.symptomsWristCont[0] +
      data.jsonAnswer.symptomsHipCont[0] +
      data.jsonAnswer.symptomsKneeCont[0] +
      data.jsonAnswer.symptomsAnkleCont[0];

    const newCuello = [
      ["CUELLO/NUCA", ...data.jsonAnswer.symptomsNeckCont],
      ["ESPALDA MEDIA", ...data.jsonAnswer.symptomsMiddleBackCont],
      ["ESPALDA BAJA", ...data.jsonAnswer.symptomsLowerBackCont],
      ["HOMBRO", ...data.jsonAnswer.symptomsShoulderCont],
      ["CODO", ...data.jsonAnswer.symptomsElbowCont],
      ["MUÑECA", ...data.jsonAnswer.symptomsWristCont],
      ["CADERA", ...data.jsonAnswer.symptomsHipCont],
      ["RODILLA", ...data.jsonAnswer.symptomsKneeCont],
      ["TOBILLO/PIE", ...data.jsonAnswer.symptomsAnkleCont],
    ];

    const order9 = newCuello.sort((a, b) => b[1] - a[1]);

    tmpChartTables[9].analysis =
      total9 === 0
        ? `Del 100% de los síntomas manifestados, ninguno reporta impedimento para realizar sus rutinas habituales.`
        : `Del 100% de los síntomas manifestados, SI se reporta impedimento para realizar sus rutinas habituales por ${total9} de los síntomas manifestados, encontrando estos principalmente a nivel de ${order9[0][0]}.`;

    // Tabla 10: Días que ha tenido las molestias en los últimos 12 meses

    tmpChartTables[10].table = [
      [
        "CUELLO/NUCA",
        ...data.jsonAnswer.symptomsNeckMonth,
        data.jsonAnswer.symptomsNeckMonth[0] +
          data.jsonAnswer.symptomsNeckMonth[1] +
          data.jsonAnswer.symptomsNeckMonth[2] +
          data.jsonAnswer.symptomsNeckMonth[3],
      ],
      [
        "ESPALDA MEDIA",
        ...data.jsonAnswer.symptomsMiddleBackMonth,
        data.jsonAnswer.symptomsMiddleBackMonth[0] +
          data.jsonAnswer.symptomsMiddleBackMonth[1] +
          data.jsonAnswer.symptomsMiddleBackMonth[2] +
          data.jsonAnswer.symptomsMiddleBackMonth[3],
      ],
      [
        "ESPALDA BAJA",
        ...data.jsonAnswer.symptomsLowerBackMonth,
        data.jsonAnswer.symptomsLowerBackMonth[0] +
          data.jsonAnswer.symptomsLowerBackMonth[1] +
          data.jsonAnswer.symptomsLowerBackMonth[2] +
          data.jsonAnswer.symptomsLowerBackMonth[3],
      ],
      [
        "HOMBRO",
        ...data.jsonAnswer.symptomsShoulderMonth,
        data.jsonAnswer.symptomsShoulderMonth[0] +
          data.jsonAnswer.symptomsShoulderMonth[1] +
          data.jsonAnswer.symptomsShoulderMonth[2] +
          data.jsonAnswer.symptomsShoulderMonth[3],
      ],
      [
        "CODO",
        ...data.jsonAnswer.symptomsElbowMonth,
        data.jsonAnswer.symptomsElbowMonth[0] +
          data.jsonAnswer.symptomsElbowMonth[1] +
          data.jsonAnswer.symptomsElbowMonth[2] +
          data.jsonAnswer.symptomsElbowMonth[3],
      ],
      [
        "MUÑECA",
        ...data.jsonAnswer.symptomsWristMonth,
        data.jsonAnswer.symptomsWristMonth[0] +
          data.jsonAnswer.symptomsWristMonth[1] +
          data.jsonAnswer.symptomsWristMonth[2] +
          data.jsonAnswer.symptomsWristMonth[3],
      ],
      [
        "CADERA",
        ...data.jsonAnswer.symptomsHipMonth,
        data.jsonAnswer.symptomsHipMonth[0] +
          data.jsonAnswer.symptomsHipMonth[1] +
          data.jsonAnswer.symptomsHipMonth[2] +
          data.jsonAnswer.symptomsHipMonth[3],
      ],
      [
        "RODILLA",
        ...data.jsonAnswer.symptomsKneeMonth,
        data.jsonAnswer.symptomsKneeMonth[0] +
          data.jsonAnswer.symptomsKneeMonth[1] +
          data.jsonAnswer.symptomsKneeMonth[2] +
          data.jsonAnswer.symptomsKneeMonth[3],
      ],
      [
        "TOBILLO/PIE",
        ...data.jsonAnswer.symptomsAnkleMonth,
        data.jsonAnswer.symptomsAnkleMonth[0] +
          data.jsonAnswer.symptomsAnkleMonth[1] +
          data.jsonAnswer.symptomsAnkleMonth[2] +
          data.jsonAnswer.symptomsAnkleMonth[3],
      ],
      [
        "Total",
        data.jsonAnswer.symptomsNeckMonth[0] +
          data.jsonAnswer.symptomsMiddleBackMonth[0] +
          data.jsonAnswer.symptomsLowerBackMonth[0] +
          data.jsonAnswer.symptomsShoulderMonth[0] +
          data.jsonAnswer.symptomsElbowMonth[0] +
          data.jsonAnswer.symptomsWristMonth[0] +
          data.jsonAnswer.symptomsHipMonth[0] +
          data.jsonAnswer.symptomsKneeMonth[0] +
          data.jsonAnswer.symptomsAnkleMonth[0],
        data.jsonAnswer.symptomsNeckMonth[1] +
          data.jsonAnswer.symptomsMiddleBackMonth[1] +
          data.jsonAnswer.symptomsLowerBackMonth[1] +
          data.jsonAnswer.symptomsShoulderMonth[1] +
          data.jsonAnswer.symptomsElbowMonth[1] +
          data.jsonAnswer.symptomsWristMonth[1] +
          data.jsonAnswer.symptomsHipMonth[1] +
          data.jsonAnswer.symptomsKneeMonth[1] +
          data.jsonAnswer.symptomsAnkleMonth[1],
        data.jsonAnswer.symptomsNeckMonth[2] +
          data.jsonAnswer.symptomsMiddleBackMonth[2] +
          data.jsonAnswer.symptomsLowerBackMonth[2] +
          data.jsonAnswer.symptomsShoulderMonth[2] +
          data.jsonAnswer.symptomsElbowMonth[2] +
          data.jsonAnswer.symptomsWristMonth[2] +
          data.jsonAnswer.symptomsHipMonth[2] +
          data.jsonAnswer.symptomsKneeMonth[2] +
          data.jsonAnswer.symptomsAnkleMonth[2],
        data.jsonAnswer.symptomsNeckMonth[3] +
          data.jsonAnswer.symptomsMiddleBackMonth[3] +
          data.jsonAnswer.symptomsLowerBackMonth[3] +
          data.jsonAnswer.symptomsShoulderMonth[3] +
          data.jsonAnswer.symptomsElbowMonth[3] +
          data.jsonAnswer.symptomsWristMonth[3] +
          data.jsonAnswer.symptomsHipMonth[3] +
          data.jsonAnswer.symptomsKneeMonth[3] +
          data.jsonAnswer.symptomsAnkleMonth[3],
        data.jsonAnswer.symptomsNeckMonth[0] +
          data.jsonAnswer.symptomsNeckMonth[1] +
          data.jsonAnswer.symptomsNeckMonth[2] +
          data.jsonAnswer.symptomsNeckMonth[3] +
          data.jsonAnswer.symptomsMiddleBackMonth[0] +
          data.jsonAnswer.symptomsMiddleBackMonth[1] +
          data.jsonAnswer.symptomsMiddleBackMonth[2] +
          data.jsonAnswer.symptomsMiddleBackMonth[3] +
          data.jsonAnswer.symptomsLowerBackMonth[0] +
          data.jsonAnswer.symptomsLowerBackMonth[1] +
          data.jsonAnswer.symptomsLowerBackMonth[2] +
          data.jsonAnswer.symptomsLowerBackMonth[3] +
          data.jsonAnswer.symptomsShoulderMonth[0] +
          data.jsonAnswer.symptomsShoulderMonth[1] +
          data.jsonAnswer.symptomsShoulderMonth[2] +
          data.jsonAnswer.symptomsShoulderMonth[3] +
          data.jsonAnswer.symptomsElbowMonth[0] +
          data.jsonAnswer.symptomsElbowMonth[1] +
          data.jsonAnswer.symptomsElbowMonth[2] +
          data.jsonAnswer.symptomsElbowMonth[3] +
          data.jsonAnswer.symptomsWristMonth[0] +
          data.jsonAnswer.symptomsWristMonth[1] +
          data.jsonAnswer.symptomsWristMonth[2] +
          data.jsonAnswer.symptomsWristMonth[3] +
          data.jsonAnswer.symptomsHipMonth[0] +
          data.jsonAnswer.symptomsHipMonth[1] +
          data.jsonAnswer.symptomsHipMonth[2] +
          data.jsonAnswer.symptomsHipMonth[3] +
          data.jsonAnswer.symptomsKneeMonth[0] +
          data.jsonAnswer.symptomsKneeMonth[1] +
          data.jsonAnswer.symptomsKneeMonth[2] +
          data.jsonAnswer.symptomsKneeMonth[3] +
          data.jsonAnswer.symptomsAnkleMonth[0] +
          data.jsonAnswer.symptomsAnkleMonth[1] +
          data.jsonAnswer.symptomsAnkleMonth[2] +
          data.jsonAnswer.symptomsAnkleMonth[3],
      ],
    ];

    const newTable10 = [
      ["Entre 1 y 7 días", tmpChartTables[10].table[9][1]],
      ["Entre 8 y 30 días", tmpChartTables[10].table[9][2]],
      ["Más de 30 días", tmpChartTables[10].table[9][3]],
      ["Siempre", tmpChartTables[10].table[9][4]],
    ];

    const nOrder10 = newTable10.sort((a, b) => b[1] - a[1]);

    conclusions.symptomsTwelveDays = nOrder10[0][0];
    tmpChartTables[10].analysis = `En los trabajadores que han presentado molestias en los últimos 12 meses, prevalece la sintomatología con duración ${
      nOrder10[0][0]
    } en primer lugar con un total de ${
      nOrder10[0][1]
    } reportes lo cual corresponde al ${Math.round(
      (nOrder10[0][1] / tmpChartTables[10].table[9][5]) * 100
    )}% del total de estos.`;

    //ANALISIS MOLESTIAS
    const totalSeven =
      data.jsonAnswer.symptomsSevensDaysYes +
      data.jsonAnswer.symptomsSevensDaysNo +
      data.jsonAnswer.symptomsSevensDaysNA;

    const porSeSi = (data.jsonAnswer.symptomsSevensDaysYes * 100) / totalSeven;
    const porSeNo = (data.jsonAnswer.symptomsSevensDaysNo * 100) / totalSeven;
    const portSeNa = (data.jsonAnswer.symptomsSevensDaysNA * 100) / totalSeven;

    tmpChartTables[11].table = [
      ["SI", porSeSi],
      ["NO", porSeNo],
      ["N.A", portSeNa],
    ]; // Gráfico 11: Molestia que se ha presentado en los últimos 7 días

    tmpChartTables[12].table = [
      ["SI", data.jsonAnswer.symptomsSevensDaysYes],
      ["NO", data.jsonAnswer.symptomsSevensDaysNo],
      ["N.A", data.jsonAnswer.symptomsSevensDaysNA],
      ["Total", totalSeven], // Tabla 11. Molestia que se ha presentado en los últimos 7 días
    ];

    tmpChartTables[12].analysis = `Durante los últimos 7 días se reportan en total (${
      data.jsonAnswer.symptomsSevensDaysYes
    }) trabajadores que manifiestan SI haber presentado molestias por la sintomatología reportada lo que equivale al ${Math.round(
      porSeSi
    )}% de las personas encuestadas; mientras que el ${Math.round(porSeNo)}% de los trabajadores (${
      data.jsonAnswer.symptomsSevensDaysNo
    }) refiere NO presentar síntomas durante los últimos 7 días;  en el ${Math.round(
      portSeNa
    )}% de la población este ítem no aplica.`;

    const totalTreat =
      data.jsonAnswer.treatmentYes + data.jsonAnswer.treatmentNo + data.jsonAnswer.treatmentNA;

    const porTrSi = (data.jsonAnswer.treatmentYes * 100) / totalTreat;
    const porTrNo = (data.jsonAnswer.treatmentNo * 100) / totalTreat;
    const portTrNa = (data.jsonAnswer.treatmentNA * 100) / totalTreat;

    tmpChartTables[13].table = [
      ["SI", porTrSi],
      ["NO", porTrNo],
      ["N.A", portTrNa],
    ];
    //Gráfico 12: Ha recibido tratamiento por estas molestias

    tmpChartTables[14].table = [
      ["SI", data.jsonAnswer.treatmentYes],
      ["NO", data.jsonAnswer.treatmentNo],
      ["N.A", data.jsonAnswer.treatmentNA],
      ["Total", totalTreat],
    ];
    // Tabla 12. Ha recibido tratamiento por estas molestias

    tmpChartTables[14].analysis = `Del total de trabajadores encuestados (${totalTreat}), se ha recibido tratamiento por el ${Math.round(
      porTrSi
    )}% de la población; mientras que el ${Math.round(
      porTrNo
    )}% reporta no haber consultado por estas; en el ${Math.round(
      portTrNa
    )}% de la población este ítem no aplica.`;
    tmpChartTables[15].table = [
      [
        "CUELLO/NUCA",
        ...data.jsonAnswer.QualificationNeck,
        data.jsonAnswer.QualificationNeck[0] +
          data.jsonAnswer.QualificationNeck[1] +
          data.jsonAnswer.QualificationNeck[2] +
          data.jsonAnswer.QualificationNeck[3],
      ],
      [
        "ESPALDA MEDIA",
        ...data.jsonAnswer.QualificationMiddleBack,
        data.jsonAnswer.QualificationMiddleBack[0] +
          data.jsonAnswer.QualificationMiddleBack[1] +
          data.jsonAnswer.QualificationMiddleBack[2] +
          data.jsonAnswer.QualificationMiddleBack[3],
      ],
      [
        "ESPALDA BAJA",
        ...data.jsonAnswer.QualificationLowerBack,
        data.jsonAnswer.QualificationLowerBack[0] +
          data.jsonAnswer.QualificationLowerBack[1] +
          data.jsonAnswer.QualificationLowerBack[2] +
          data.jsonAnswer.QualificationLowerBack[3],
      ],
      [
        "HOMBRO",
        ...data.jsonAnswer.QualificationShoulder,
        data.jsonAnswer.QualificationShoulder[0] +
          data.jsonAnswer.QualificationShoulder[1] +
          data.jsonAnswer.QualificationShoulder[2] +
          data.jsonAnswer.QualificationShoulder[3],
      ],
      [
        "CODO",
        ...data.jsonAnswer.QualificationElbow,
        data.jsonAnswer.QualificationElbow[0] +
          data.jsonAnswer.QualificationElbow[1] +
          data.jsonAnswer.QualificationElbow[2] +
          data.jsonAnswer.QualificationElbow[3],
      ],
      [
        "MUÑECA",
        ...data.jsonAnswer.QualificationWrist,
        data.jsonAnswer.QualificationWrist[0] +
          data.jsonAnswer.QualificationWrist[1] +
          data.jsonAnswer.QualificationWrist[2] +
          data.jsonAnswer.QualificationWrist[3],
      ],
      [
        "CADERA",
        ...data.jsonAnswer.QualificationHip,
        data.jsonAnswer.QualificationHip[0] +
          data.jsonAnswer.QualificationHip[1] +
          data.jsonAnswer.QualificationHip[2] +
          data.jsonAnswer.QualificationHip[3],
      ],
      [
        "RODILLA",
        ...data.jsonAnswer.QualificationKnee,
        data.jsonAnswer.QualificationKnee[0] +
          data.jsonAnswer.QualificationKnee[1] +
          data.jsonAnswer.QualificationKnee[2] +
          data.jsonAnswer.QualificationKnee[3],
      ],
      [
        "TOBILLO/PIE",
        ...data.jsonAnswer.QualificationAnkle,
        data.jsonAnswer.QualificationAnkle[0] +
          data.jsonAnswer.QualificationAnkle[1] +
          data.jsonAnswer.QualificationAnkle[2] +
          data.jsonAnswer.QualificationAnkle[3],
      ],
      [
        "Total",
        data.jsonAnswer.QualificationNeck[0] +
          data.jsonAnswer.QualificationMiddleBack[0] +
          data.jsonAnswer.QualificationLowerBack[0] +
          data.jsonAnswer.QualificationShoulder[0] +
          data.jsonAnswer.QualificationElbow[0] +
          data.jsonAnswer.QualificationWrist[0] +
          data.jsonAnswer.QualificationHip[0] +
          data.jsonAnswer.QualificationKnee[0] +
          data.jsonAnswer.QualificationAnkle[0],
        data.jsonAnswer.QualificationNeck[1] +
          data.jsonAnswer.QualificationMiddleBack[1] +
          data.jsonAnswer.QualificationLowerBack[1] +
          data.jsonAnswer.QualificationShoulder[1] +
          data.jsonAnswer.QualificationElbow[1] +
          data.jsonAnswer.QualificationWrist[1] +
          data.jsonAnswer.QualificationHip[1] +
          data.jsonAnswer.QualificationKnee[1] +
          data.jsonAnswer.QualificationAnkle[1],
        data.jsonAnswer.QualificationNeck[2] +
          data.jsonAnswer.QualificationMiddleBack[2] +
          data.jsonAnswer.QualificationLowerBack[2] +
          data.jsonAnswer.QualificationShoulder[2] +
          data.jsonAnswer.QualificationElbow[2] +
          data.jsonAnswer.QualificationWrist[2] +
          data.jsonAnswer.QualificationHip[2] +
          data.jsonAnswer.QualificationKnee[2] +
          data.jsonAnswer.QualificationAnkle[2],
        data.jsonAnswer.QualificationNeck[3] +
          data.jsonAnswer.QualificationMiddleBack[3] +
          data.jsonAnswer.QualificationLowerBack[3] +
          data.jsonAnswer.QualificationShoulder[3] +
          data.jsonAnswer.QualificationElbow[3] +
          data.jsonAnswer.QualificationWrist[3] +
          data.jsonAnswer.QualificationHip[3] +
          data.jsonAnswer.QualificationKnee[3] +
          data.jsonAnswer.QualificationAnkle[3],
        data.jsonAnswer.QualificationNeck[0] +
          data.jsonAnswer.QualificationNeck[1] +
          data.jsonAnswer.QualificationNeck[2] +
          data.jsonAnswer.QualificationNeck[3] +
          data.jsonAnswer.QualificationMiddleBack[0] +
          data.jsonAnswer.QualificationMiddleBack[1] +
          data.jsonAnswer.QualificationMiddleBack[2] +
          data.jsonAnswer.QualificationMiddleBack[3] +
          data.jsonAnswer.QualificationLowerBack[0] +
          data.jsonAnswer.QualificationLowerBack[1] +
          data.jsonAnswer.QualificationLowerBack[2] +
          data.jsonAnswer.QualificationLowerBack[3] +
          data.jsonAnswer.QualificationShoulder[0] +
          data.jsonAnswer.QualificationShoulder[1] +
          data.jsonAnswer.QualificationShoulder[2] +
          data.jsonAnswer.QualificationShoulder[3] +
          data.jsonAnswer.QualificationElbow[0] +
          data.jsonAnswer.QualificationElbow[1] +
          data.jsonAnswer.QualificationElbow[2] +
          data.jsonAnswer.QualificationElbow[3] +
          data.jsonAnswer.QualificationWrist[0] +
          data.jsonAnswer.QualificationWrist[1] +
          data.jsonAnswer.QualificationWrist[2] +
          data.jsonAnswer.QualificationWrist[3] +
          data.jsonAnswer.QualificationHip[0] +
          data.jsonAnswer.QualificationHip[1] +
          data.jsonAnswer.QualificationHip[2] +
          data.jsonAnswer.QualificationHip[3] +
          data.jsonAnswer.QualificationKnee[0] +
          data.jsonAnswer.QualificationKnee[1] +
          data.jsonAnswer.QualificationKnee[2] +
          data.jsonAnswer.QualificationKnee[3] +
          data.jsonAnswer.QualificationAnkle[0] +
          data.jsonAnswer.QualificationAnkle[1] +
          data.jsonAnswer.QualificationAnkle[2] +
          data.jsonAnswer.QualificationAnkle[3],
      ],
    ];

    const total15 = tmpChartTables[15].table.concat();
    const sum = total15[9][2] + total15[9][3] + total15[9][4];

    tmpChartTables[15].analysis = `Las clasificaciones del dolor según las estadísticas se distribuyen de la siguiente manera: leve (${
      total15[9][2]
    }) ${Math.round((total15[9][2] / sum) * 100)}%, moderado (${total15[9][3]}) ${Math.round(
      (total15[9][3] / sum) * 100
    )}% y molestias muy fuertes con un total de (${total15[9][4]}) ${Math.round(
      (total15[9][4] / sum) * 100
    )}%, lo que equivale a un total de ${sum} síntomas.`;

    //  Gráfico 13: Clasificación del dolor
    //  Tabla 13. Clasificación del dolor
    tmpChartTables[16].table = [
      ["SINTOMÁTICO", data.jsonAnswer.percentSymptomatic],
      ["ASINTOMÁTICO", data.jsonAnswer.percentAsymptomatic],
    ];

    //Gráfico 14: Clasificación de la población

    tmpChartTables[17].table = [
      ["SINTOMÁTICO", data.jsonAnswer.symptomatic],
      ["ASINTOMÁTICO", data.jsonAnswer.asymptomatic],
      ["Total", data.jsonAnswer.symptomatic + data.jsonAnswer.asymptomatic],
    ];

    const newTable17 = tmpChartTables[17].table.concat();
    newTable17.pop();

    const newOrder17 = newTable17.sort((a, b) => b[1] - a[1]);

    const totalAsySy = data.jsonAnswer.symptomatic + data.jsonAnswer.asymptomatic;

    tmpChartTables[17].analysis = `La clasificación de la población demuestra que el ${Math.round(
      (newOrder17[0][1] / totalAsySy) * 100
    )}% de los trabajadores (${newOrder17[0][1]}) se catalogan como ${
      newOrder17[0][0]
    }S, mientras que el ${Math.round((newOrder17[1][1] / totalAsySy) * 100)}% restante (${
      newOrder17[1][1]
    }) se catalogan como ${newOrder17[1][0]}S.`;
    // Tabla 14. Clasificación de la población

    tmpChartTables[18].table = [
      ["ADMINISTRATIVO", data.jsonAnswer.symptomaticAdmin, data.jsonAnswer.asymptomaticAdmin],
      ["OPERATIVO", data.jsonAnswer.symptomaticOper, data.jsonAnswer.asymptomaticOper],
    ];
    //  Gráfico 15: Clasificación Vs Área

    tmpChartTables[19].table = [
      [
        "SINTOMÁTICO",
        data.jsonAnswer.symptomaticAdmin,
        data.jsonAnswer.symptomaticOper,
        data.jsonAnswer.symptomaticAdmin + data.jsonAnswer.symptomaticOper,
      ],
      [
        "ASINTOMÁTICO",
        data.jsonAnswer.asymptomaticAdmin,
        data.jsonAnswer.asymptomaticOper,
        data.jsonAnswer.asymptomaticAdmin + data.jsonAnswer.asymptomaticOper,
      ],
      [
        "TOTAL",
        data.jsonAnswer.symptomaticAdmin + data.jsonAnswer.asymptomaticAdmin,
        data.jsonAnswer.symptomaticOper + data.jsonAnswer.asymptomaticOper,
        data.jsonAnswer.symptomaticAdmin +
          data.jsonAnswer.symptomaticOper +
          data.jsonAnswer.asymptomaticAdmin +
          data.jsonAnswer.asymptomaticOper,
      ],
    ]; //Tabla 15: Clasificación Vs Área

    const total19 = data.jsonAnswer.symptomaticAdmin + data.jsonAnswer.symptomaticOper;

    const newTable19 = [
      ["Administrativa", data.jsonAnswer.symptomaticAdmin],
      ["Operativa", data.jsonAnswer.symptomaticOper],
    ];

    const order19 = newTable19.sort((a, b) => b[1] - a[1]);

    conclusions.symptomaticArea = order19[0][0];

    tmpChartTables[19].analysis = `De acuerdo a la clasificación de la población se logra concluir que la población que reporta ser sintomática se encuentra en el área Operativa con el ${Math.round(
      (data.jsonAnswer.symptomaticOper / total19) * 100
    )}% del total de sintomáticos y en el área Administrativa con el ${Math.round(
      (data.jsonAnswer.symptomaticAdmin / total19) * 100
    )}%.`;

    /*
        tmpChartTables[20].table = [
          ["SINTOMÁTICO", data.jsonAnswer.symptomatic],
          ...data.jsonAnswer.symptomaticPosition.map((e) => [e, 1]),
          ["ASINTOMÁTICO", data.jsonAnswer.asymptomatic],
        ];
        //  Gráfico 16: Clasificación Vs Cargo
    
        tmpChartTables[23].table = [
          ["SINTOMÁTICO", data.jsonAnswer.symptomatic],
          ...data.jsonAnswer.symptomaticPosition.map((e) => [e, 1]),
          ["ASINTOMÁTICO", data.jsonAnswer.asymptomatic],
          ["Total general", 1],
        ];
        */
    setChartTables([...tmpChartTables]);
    setConclusions({
      ...conclusions,
      percentWomens: data.jsonAnswer.percentWomens,
      percentMens: data.jsonAnswer.percentMens,
      percentAdministrative: data.jsonAnswer.percentAdministrative,
      percentOperative: data.jsonAnswer.percentOperative,
      rangeAge: `${order[0][0]}`,
      percentIMCNormal: percentNormal,
      sumSymptomatology: sum,
      percentSymptomatic: data.jsonAnswer.percentSymptomatic,
      percentAsymptomatic: data.jsonAnswer.percentAsymptomatic,
    });
  };

  /// FUNCIONALIDAD PDF TO WORD

  const downloadWord = () => {
    var link = document.createElement("a");
    link.href = "https://www.ilovepdf.com/es/pdf_a_word";
    link.target = "_blank";
    link.click();
  };

  return (
    <Container className="container">
      <Row className="justify-content-center mb-2">
        <Col md={{ span: 5 }}>
          <h3 style={{ textAlign: "center", color: "#3A4297" }}>ENCUESTAS DE MORBILIDAD SENTIDA</h3>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={6}>
          {viewPDF && <PdfReportGenerator content={content} cover={cover} toc={toc} />}
        </Col>
        <Col md={6} className="d-flex flex-column align-items-center justify-center">
          <Button variant="info" className="mb-2 btn-word" block onClick={downloadWord}>
            Ir al convertidor
          </Button>
          {isData ? (
            <FormMorbidity
              GeneratePDF={GeneratePDF}
              viewPDF={viewPDF}
              dataCharts={[...chartTables]}
            />
          ) : (
            <>
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
              Este proceso puede tardar algunos segundos. Por favor, no recargue la página.
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
};

PDFMorbidity.propTypes = {};

PDFMorbidity.defaultProps = {};

export default PDFMorbidity;
