import React from "react";
import "./ProgressBar.scss";

//Components native React
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

//Components and views

//Images
import { ReactComponent as GeneralDataIcon } from "./../../img/icons/iconPaper.svg";
import { ReactComponent as Diagnostic } from "./../../img/icons/iconDiagnostic.svg";
import { ReactComponent as ActionPlan } from "./../../img/icons/iconMap.svg";
import { ReactComponent as Report } from "./../../img/icons/iconGraphics.svg";
import { ReactComponent as ConsultationDocument } from "./../../img/icons/iconDocument.svg";
import { ReactComponent as Help } from "./../../img/icons/iconHelp.svg";


const ProgressBar = (props) => {
  //Hooks Definition

  //  Variable to redirect page

  const assignIcon = {
    1: (
      <GeneralDataIcon
        className={`sm-iconSize sm-iconComplete1`}
      />
    ),
    2: (
      <Diagnostic
        className={`sm-iconSize ${props.percentage == "100" ? "sm-iconComplete2" : ""}`}
      ></Diagnostic>
    ),
    3: (
      <ActionPlan
        className={`sm-iconSize ${props.percentage == "100" ? "sm-iconComplete3" : ""}`}
      />
    ),
    4: (
      <Report
        className={`sm-iconSize ${props.percentage == "100" ? "sm-iconComplete4" : ""}`}
      />
    ),
    5: (
      <ConsultationDocument
        className={`sm-iconSize ${props.percentage == "100" ? "sm-iconComplete5" : ""}`}
      />
    ),
    6: <Help  className={`sm-iconSize sm-iconComplete6`} />,
  };

  return (
    <div
    className={`ProgressBar sm-graphics-${props.subclass} sm-lineBottom`}
    >
      <CircularProgressbarWithChildren
        value={props.percentage}
        strokeWidth={12}
        styles={buildStyles({
          strokeLinecap: "butt",
        })}
      >
        {assignIcon[props.subclass]}
        <div className={`sm-sizePercentage sm-graphics-${props.subclass}`}>
          {/* <strong>{props.percentage}%</strong> */}
        </div>
      </CircularProgressbarWithChildren>
    </div>
  );
};

export default ProgressBar;
