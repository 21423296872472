import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col, Button, Spinner } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import ArrowBack from "../../img/icons/iconArrowBack.svg";
import { useLocation } from "react-router-dom";

import { GET, POSTNORMAL } from "../../constants/ApiRest";

//import components
import PdfReportGenerator from "../../components/PdfReportGenerator/PdfReportGenerator";
import FormSelfEvaluation from "../../components/FormSelfEvaluation/FormSelfEvaluation";

//import data
import data from "../../abstract/selfEvaluation";
import TablePdf from "../../components/TablePdf/TablePdf";

const PdfSelfEvaluation = ({ setLogin }) => {
  let location = useLocation();
  const [listPlan, setListPlan] = useState([]);
  const [NIT, setNIT] = useState("");

  useEffect(() => {
    GET(`${process.env.REACT_APP_COMPANY_ENDPOINT}/company/getnit/${location.state.idCompany}}`)
      .then((result) => {
        const data = result.data;
        setNIT(data.nit);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleListPlan = () => {
    GET(
      `${process.env.REACT_APP_REPORT_ENDPOINT}/formless/data/plan/${location.state.idCompany}/${location.state.form_id}`
    )
      .then((result) => {
        setListPlan(result.data.list);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.data.reload === true) {
            POSTNORMAL(process.env.REACT_APP_FILE_LOADER_ENDPOINT + "validate", {
              idCompany: location.state.idCompany,
              nitCompany: location.state.dataCompany.nit,
              form_id: location.state.form_id,
            })
              .then((response) => {
                window.location.reload();
                alert('Presione el botón nuevamente')
              })
              .catch(console.log(err));
          }
        }
        console.log(err);
      });
  };
  const downloadWord = () => {
    var link = document.createElement("a");
    link.href = "https://www.ilovepdf.com/es/pdf_a_word";
    link.target = "_blank";
    link.click();
  };

  return (
    <Container className="container">
      <Row className="justify-content-center mb-2">
        <Col md={{ span: 5 }}>
          <h5 style={{ textAlign: "center", color: "#3A4297" }}>PLAN DE BIOSEGURIDAD</h5>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col
          md={6}
          className="d-flex flex-column align-items-center justify-center table-responsive"
        >
          <Button variant="info" className="mb-2 btn-word" block onClick={downloadWord}>
            Ir al convertidor
          </Button>
          <Button id="reload_btn" variant="info" className="mb-2" block onClick={handleListPlan}>
            Plan de mejoramiento
          </Button>
          <TablePdf headers={["A mejorar"]} content={listPlan} />
        </Col>
      </Row>
    </Container>
  );
};

PdfSelfEvaluation.propTypes = {};

PdfSelfEvaluation.defaultProps = {};

export default PdfSelfEvaluation;
