import React from 'react';
import ReactPaginate from "react-paginate";
import PropTypes from 'prop-types';
import './Pagination.scss';

export const Pagination = ({ pageCount, handlePage }) => (
  <div className="Pagination">
    <ReactPaginate
          previousLabel={"Anterior"}
          previousClassName={"page-link"}
          nextLabel={"Siguiente"}
          nextClassName={"page-link"}
          breakLabel={"..."}
          breakClassName={"page-item"}
          pageCount={pageCount}
          marginPagesDisplayed={1}
          pageRangeDisplayed={4}
          onPageChange={handlePage}
          containerClassName={"pagination"}
          pageClassName={"page-item"}
          activeClassName={"active"}
          breakLinkClassName={"page-link"}
          pageLinkClassName={"page-link"}
        />
  </div>
);

Pagination.propTypes = {};

Pagination.defaultProps = {};
