import React, { useState } from "react";
// import PropTypes from "prop-types";
// import { selfEvaluation } from "../../abstract/selfEvaluation";
// import { dangerMatrix } from "../../abstract/dangerMatrix";
// import { socrioDemographic } from "../../abstract/socioDemographic";
// import { morbidity } from "../../abstract/morbidity";
// import { physhosocial } from "../../abstract/psychosocial";

import "./Formless.scss";
import ChartReport from "../../components/ChartReport/ChartReport";
import HeaderPage from "../../components/HeaderPage/HeaderPage";
import BodyPage from "../../components/BodyPage/BodyPage";
import FooterPage from "../../components/FooterPage/FooterPage";

const Formless = ({ json }) => {
  // const [state, setstate] = useState(initialState)

  return (
    <>
      <HeaderPage />
      <BodyPage />
      <FooterPage />
      {/* <ChartReport type={"bar"}/> */}
    </>
  );
};

Formless.propTypes = {};

Formless.defaultProps = {};

export default Formless;
