import React, { useEffect, useState } from "react";

//Components native React
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

//Components and views
import LoginPage from "./views/LoginPage/LoginPage";
import GraphicsPage from "./views/GraphicsPage/GraphicsPage";
import DiagnosisCompany from "./views/DiagnosisCompany/DiagnosisCompany";
import Diagnose from "./views/Diagnose/Diagnose";
import Navbar from "./components/Navbar/Navbar";
import ViewsPrivates from "./components/ViewsPrivates/ViewsPrivates";
import GeneralData from "./views/GeneralData/GeneralData";
import Companies from "./views/Companies/Companies";
import Formless from "./views/Formless/Formless";
import { Documents } from "./views/Documents/Documents";
import { Reports } from "./views/Reports/Reports";
import { GET } from "./constants/ApiRest";

// import PdfReportGenerator from "./components/PdfReportGenerator/PdfReportGenerator";

export default function RouterPage() {
  const [login, setLogin] = useState({
    state: false,
    initializing: true,
    authorizations: localStorage.getItem("autUser")
      ? localStorage
          .getItem("autUser")
          .split(",")
          .map((value) => value.toString())
      : [],
  });

  useEffect(() => {
    /* if(!login.state)localStorage.removeItem("token"); */
  })

  const SetStateRoute = (state) => {
    setLogin({
      ...login,
      state,
    });
    if(!state)localStorage.removeItem("token");
  };

  const SetStateRouteLogin = (state, authorizations = []) => {
    setLogin({
      ...login,
      state,
      authorizations,
    });
    if(!state)localStorage.removeItem("token");
  };

  useEffect(() => {
    if (localStorage.getItem("token") === null || localStorage.getItem("token") === "") {
      setLogin({ ...login, state: false, initializing: false });
    } else {
      GET(`${process.env.REACT_APP_CHECK_LOGIN_API}`)
        .then((result) => {
          if (result.status >= 200 && result.status <= 299) {
            setLogin({ ...login, state: true, initializing: false });
          } else {
            setLogin({ ...login, state: false, initializing: false });
          }
        })
        .catch((error) => setLogin({ ...login, state: false, initializing: false }));
    }
  }, [setLogin]);
  //
  // A wrapper for <Route> that redirects to the login
  // screen if you're not yet authenticated.
  const PrivateRoute = ({ children, ...rest }) => {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          login.state ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location },
              }}
            />
          )
        }
      />
    );
  };

  if (login.initializing) {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12 vh-100 d-flex flex-column justify-content-center align-items-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Cargando...</span>
            </div>
            <p>Cargando</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Router>
      <Navbar login={ login } SetStateRoute={SetStateRoute}></Navbar>
      <Switch>
        <PrivateRoute path="/init/graphics">
          <GraphicsPage login={login} setLogin={SetStateRoute} />
        </PrivateRoute>
        {/*<PrivateRoute path="/diagnosis">
          <DiagnosisCompany setLogin={SetStateRoute} />
        </PrivateRoute>
        <PrivateRoute path="/diagnose">
          <Diagnose setLogin={SetStateRoute} />
        </PrivateRoute>
        <PrivateRoute path="/companies">
          <Companies setLogin={SetStateRoute} />
        </PrivateRoute>
        <PrivateRoute path="/generaldata">
          <GeneralData setLogin={SetStateRoute} />
        </PrivateRoute>
        <PrivateRoute path="/documents">
          <Documents setLogin={SetStateRoute} />
        </PrivateRoute>
        <PrivateRoute path="/reports">
          <Reports setLogin={SetStateRoute} />
        </PrivateRoute>
        <PrivateRoute path="/formless">
          <Formless setLogin={SetStateRoute} />
        </PrivateRoute>
        <Route exact path="/pdf">
          <PdfReportGenerator />
        </Route>
        <Route exact path="/">
          <Redirect to="/login" />
        </Route> */}
        <Route
          path="/login"
          render={({ location }) =>
            !login.state ? (
              <LoginPage setLogin={SetStateRouteLogin} />
            ) : (
              <Redirect
                to={{
                  pathname: "/init/graphics",
                  state: { from: location },
                }}
              />
            )
          }
        ></Route>
        <PrivateRoute path="/init">
          <ViewsPrivates login={login} SetStateRouteLogin={SetStateRouteLogin}></ViewsPrivates>
        </PrivateRoute>
        <Route path="*">
          <Redirect to="/login" />
        </Route>
      </Switch>
    </Router>
  );
}
