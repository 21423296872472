import React from "react";
import "./ViewsPrivates.scss";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import DiagnosisCompany from "./../../views/DiagnosisCompany/DiagnosisCompany";
import Diagnose from "./../../views/Diagnose/Diagnose";
import ReferenceDocuments from "../../views/ReferenceDocuments/ReferenceDocuments";
import RepoDocuments from "../../views/RepoDocuments/RepoDocuments";
import GeneralData from "./../../views/GeneralData/GeneralData";
import Companies from "./../../views/Companies/Companies";
import CreateUser from "./../../views/CreateUser/CreateUser";
import UpdateUser from "./../../views/UpdateUser/UpdateUser";
import ReassignUser from "./../../views/ReassignUser/ReassignUser";
import Documents from "./../../views/Documents/Documents";
import { Reports } from "./../../views/Reports/Reports";
import Formless from "./../../views/Formless/Formless";
import CompanyLinked from "../../views/CompanyLinked/CompanyLinked";
import FormatApplies from "../../views/FormatApplies/FormatApplies";
//import PdfReportGenerator from "../PdfReportGenerator/PdfReportGenerator";
import PdfSocioDemographic from "./../../views/PdfSocioDemographic/PdfSocioDemographic";
import PDFDocument from "../../views/PDFComponent/PDFComponent";
import DelCompany from "../../views/DeleteCompany/DeleteCompany";
import UploadDocs from "../../views/UploadDocs/UploadDocs";

const ViewsPrivates = ({ login, SetStateRouteLogin }) => {
  let match = useRouteMatch();
  return (
    <Switch>
      <Route
        path={`${match.path}/generaldata`}
        render={({ location }) =>
          login.authorizations.includes("1") ? (
            <GeneralData setLogin={SetStateRouteLogin} />
          ) : (
            <Redirect
              to={{
                pathname: "/init/graphics",
                state: { from: location },
              }}
            />
          )
        }
      />
      <Route
        path={`${match.path}/companies`}
        render={({ location }) =>
          login.authorizations.includes("1") ? (
            <Companies setLogin={SetStateRouteLogin} />
          ) : (
            <Redirect
              to={{
                pathname: "/init/graphics",
                state: { from: location },
              }}
            />
          )
        }
      />
      <Route
        path={`${match.path}/diagnosis`}
        render={({ location }) =>
          login.authorizations.includes("2") ? (
            <DiagnosisCompany setLogin={SetStateRouteLogin} />
          ) : (
            <Redirect
              to={{
                pathname: "/init/graphics",
                state: { from: location },
              }}
            />
          )
        }
      />
      <Route
        path={`${match.path}/diagnose`}
        render={({ location }) =>
          login.authorizations.includes("2") ? (
            <Diagnose setLogin={SetStateRouteLogin} />
          ) : (
            <Redirect
              to={{
                pathname: "/init/graphics",
                state: { from: location },
              }}
            />
          )
        }
      />
      <Route
        path={`${match.path}/repo_documents`}
        render={({ location }) =>
          login.authorizations.includes("1") ? (
            <RepoDocuments setLogin={SetStateRouteLogin} />
          ) : (
            <Redirect
              to={{
                pathname: "/init/reference_documents",
                state: { from: location },
              }}
            />
          )
        }
      />
      <Route
        path={`${match.path}/reference_documents`}
        render={({ location }) =>
          login.authorizations.includes("1") ? (
            <ReferenceDocuments setLogin={SetStateRouteLogin} />
          ) : (
            <Redirect
              to={{
                pathname: "/init/graphics",
                state: { from: location },
              }}
            />
          )
        }
      />
      <Route
        path={`${match.path}/document`}
        render={({ location }) =>
          /* login.authorizations.includes("3") */ true ? (
            // <Documents setLogin={SetStateRouteLogin} />
            <Documents setLogin={SetStateRouteLogin} />
          ) : (
            <Redirect
              to={{
                pathname: "/init/graphics",
                state: { from: location },
              }}
            />
          )
        }
      />
      <Route
        path={`${match.path}/pdf`}
        render={({ location }) =>
          true ? (
            <PDFDocument setLogin={SetStateRouteLogin} />
          ) : (
            <Redirect
              to={{
                pathname: "/init/graphics",
                state: { from: location },
              }}
            />
          )
        }
      />
      <Route
        path={`${match.path}/reports`}
        render={({ location }) =>
          login.authorizations.includes("4") ? (
            <Reports setLogin={SetStateRouteLogin} />
          ) : (
            <Redirect
              to={{
                pathname: "/init/graphics",
                state: { from: location },
              }}
            />
          )
        }
      />
      <Route
        path={`${match.path}/formless`}
        render={({ location }) =>
          login.authorizations.includes("5") ? (
            <Formless setLogin={SetStateRouteLogin} />
          ) : (
            <Redirect
              to={{
                pathname: "/init/graphics",
                state: { from: location },
              }}
            />
          )
        }
      />
      <Route
        path={`${match.path}/companylinked`}
        render={({ location }) =>
          login.authorizations.includes("4") ? (
            <CompanyLinked setLogin={SetStateRouteLogin} />
          ) : (
            <Redirect
              to={{
                pathname: "/init/graphics",
                state: { from: location },
              }}
            />
          )
        }
      />
      <Route
        path={`${match.path}/formatapplies`}
        render={({ location }) =>
          login.authorizations.includes("4") ? (
            <FormatApplies setLogin={SetStateRouteLogin} />
          ) : (
            <Redirect
              to={{
                pathname: "/init/graphics",
                state: { from: location },
              }}
            />
          )
        }
      />
      <Route
        path={`${match.path}/uploadDocs`}
        render={({ location }) =>
          login.authorizations.includes("4") ? (
            <UploadDocs setLogin={SetStateRouteLogin} />
          ) : (
            <Redirect
              to={{
                pathname: "/init/graphics",
                state: { from: location },
              }}
            />
          )
        }
      />
      <Route
        path={`${match.path}/createuser`}
        render={({ location }) =>
          login.authorizations.includes("5") ? (
            <CreateUser setLogin={SetStateRouteLogin} />
          ) : (
            <Redirect
              to={{
                pathname: "/init/graphics",
                state: { from: location },
              }}
            />
          )
        }
      />
      <Route
        path={`${match.path}/updateuser`}
        render={({ location }) =>
          login.authorizations.includes("5") ? (
            <UpdateUser setLogin={SetStateRouteLogin} />
          ) : (
            <Redirect
              to={{
                pathname: "/init/graphics",
                state: { from: location },
              }}
            />
          )
        }
      />
      <Route
        path={`${match.path}/reassignuser`}
        render={({ location }) =>
          login.authorizations.includes("5") ? (
            <ReassignUser setLogin={SetStateRouteLogin} />
          ) : (
            <Redirect
              to={{
                pathname: "/init/graphics",
                state: { from: location },
              }}
            />
          )
        }
      />
      <Route
        path={`${match.path}/delcompany`}
        render={({ location }) =>
          login.authorizations.includes("5") ? (
            <DelCompany setLogin={SetStateRouteLogin} />
          ) : (
            <Redirect
              to={{
                pathname: "/init/graphics",
                state: { from: location },
              }}
            />
          )
        }
      />
    </Switch>
  );
};

export default ViewsPrivates;
