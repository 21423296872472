import React, { useEffect, useReducer, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import ArrowBack from "../../img/icons/iconArrowBack.svg";
import { NavLink } from 'react-router-dom';
import TableReport from '../../components/TableReport/TableReport';
import { headersLinked } from '../../constants/headersTables'
import { GET } from "../../constants/ApiRest";
import { reducer } from '../../helpers/reducer'
import ExportCSV from '../../components/ExportCSV/ExportCSV';
import { Col, Container, Form, Row } from 'react-bootstrap';
import PaginationTable from "../../components/PaginationTable/PaginationTable";


import './CompanyLinked.scss';

const init = () => {
  return [];
}

const CompanyLinked = () => {

  const [state, dispatch] = useReducer(reducer, [], init);
  const [political, setPolitical] = useState({
    departments: [{ id: "0", name: "TODOS" }],
    towns: [{ id: "0", name: "TODOS" }],
    department_selected: 0,
    town_selected: 0,
  })
  const [nit, setNit] = useState(0);

  useEffect( () => {
    (async () => {
      const { data: data2 } = await GET(`${process.env.REACT_APP_POLITICAL_SEGMENTATION_ENDPOINT}/13/children`);
      setPolitical( poli => ({ ...poli, departments: [ ...poli.departments, ...data2 ] }) );

      const { data } = await GET( `${process.env.REACT_APP_REPORT_ENDPOINT}/report/companies/all` );
      dispatch( { type: 'rowsLinked', payload: data } );
      // setRow(data);

      
    })()
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault();

    dispatch( { type: 'emptyState', payload: [] } );
    const url = process.env.REACT_APP_REPORT_ENDPOINT;

    if( political.department_selected !== 0 && political.town_selected === 0 ){
      const { data } = await GET( `${url}/report/companies/department/${ political.department_selected }` );
      dispatch( { type: 'rowsLinked', payload: data } );
    }else if ( political.department_selected !== 0 && political.town_selected !== 0 ) {
      const { data } = await GET( `${url}/report/companies/town/${ political.town_selected }` );
      dispatch( { type: 'rowsLinked', payload: data } );
    }else {
      const { data } = await GET( `${url}/report/companies/all` );
      dispatch( { type: 'rowsLinked', payload: data } );
    }
  }

  const handleDepartment = async ({ target }) => {
    // console.log(e.target.value)
    const {data} = await GET(`${process.env.REACT_APP_POLITICAL_SEGMENTATION_ENDPOINT}/${target.value}/children`)
    setPolitical( poli => ({ ...poli, department_selected: parseInt(target.value), towns: [ ...data ] }) )
  }

  const handleTown = async ({ target }) => {
    setPolitical( poli => ({ ...poli, town_selected: parseInt(target.value) } ));
  }

  const handleNit = ({ target }) => {
    setNit( target.value )
  }

  const handleSubmitNit = async (e) => {

    e.preventDefault();
    // console.log(`${process.env.REACT_APP_REPORT_ENDPOINT}/report/applies/nit/${nit}`)
    dispatch( { type: 'emptyState', payload: [] } );
    const { data } = await GET( `${process.env.REACT_APP_REPORT_ENDPOINT}/report/companies/nit/${nit}` );
    // console.log(data)
    dispatch( { type: 'rowsLinked', payload: data } );

  }

  const [page, setPage] = useState(1);
  /*const { slice, range } = usePagination(state, page, 10, 1);*/
  let PageSize = 6;
  const currentTableData = useMemo(() => {
    const firstPageIndex = (page - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    //if(state == null){
      //return [];
    //}else{
      return state.slice(firstPageIndex, lastPageIndex);
    //}
  }, [ state ,page]);

  return (
    <Container className="container">
      <Row>
        <div>
          <Breadcrumbs
            view={[
              { name: "Inicio", path: "/graphics" },
              { name: "Reportes", path: "/init/reports" },
              { name: "Empresas vinculadas", path: "" },
            ]}
          ></Breadcrumbs>
          <NavLink to="/init/reports" className="styleArrowLink">
            <img src={ArrowBack} width="50" />
          </NavLink>
        </div>
      </Row>
      <Row className="justify-content-center">
        <Col md={{ span: 5 }}>
          <h3 style={{ textAlign: "center", color: "#3A4297" }}>Empresas vinculadas</h3>
        </Col>
      </Row>
      <Row>
      <Col>
            <Form onSubmit={ handleSubmit }>
              <Form.Row>
              <Form.Group as={ Col } controlId="deparment-select">
                <Form.Label>Departamento</Form.Label>
                <Form.Control as="select" onChange={ handleDepartment }>
                  {
                    political.departments.length > 0 &&
                      political.departments.map( ( department, i ) => (
                        <option key={ `depOpt-${i}` } value={ department.id }>{ department.name }</option>
                       ))
                  }
                </Form.Control>
              </Form.Group>
              <Form.Group as={ Col } controlId="town-select">
                <Form.Label>Municipio</Form.Label>
                <Form.Control as="select" onChange={ handleTown }>
                  {
                    political.towns.length > 0 &&
                      political.towns.map( ( town, i ) => (
                        <option key={ `towOpt-${i}` } value={ town.id }>{town.name}</option>
                      ) )
                  }
                </Form.Control>

              </Form.Group>
                <button className="btn btn-primary ml-3" style={{ height: "2.5rem", alignSelf: "center" }} type="submit">Buscar</button>
              </Form.Row>
            </Form>
        </Col>
        <Col className="pt-4 d-flex justify-content-end" style={{ height: "4.5em" }}>
          <ExportCSV headers={ headersLinked } csvData={ state } fileName={"Empresas vinculadas"} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form onSubmit={ handleSubmitNit }>
              <Form.Row>
              <Form.Group as={ Col } controlId="nit-text">
                <Form.Label>Nit</Form.Label>
                <Form.Control type="text" onChange={ handleNit } />
              </Form.Group>
                <button className="btn btn-primary ml-3" style={{ height: "2.5rem", alignSelf: "center" }} type="submit">Buscar</button>
              </Form.Row>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col style={{ overflow: "auto"}} className="table-responsive" >
          <TableReport headers={ headersLinked } content={ currentTableData } links={3} />
          <PaginationTable /*range={range} slice={slice} setPage={setPage} page={page}*/className="pagination-bar"
            currentPage={page}
            totalCount={state.length}
            pageSize={PageSize}
            onPageChange={page => setPage(page)} />
        </Col>
      </Row>
    </Container>
  );
};

CompanyLinked.propTypes = {};

CompanyLinked.defaultProps = {};

export default CompanyLinked;
