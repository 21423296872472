import React, { useState } from "react";
import "./LoginPage.scss";

//Components native React
import { Row, Col, Form, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Components and views
import { POSTNORMAL } from "../../constants/ApiRest";

//Images
import iconUserLogin from "../../img/icons/iconUserLogin.svg";
import iconUserPass from "../../img/icons/iconUserPass.svg";

const LoginPage = ({ setLogin }) => {
  //Hooks Definition
  const [validated, setValidated] = useState(false);
  const [user, setUser] = useState({ username: "", password: "" });

  //  Variable to redirect page
  const HISTORY = useHistory();

  //Submit button login
  async function handleSubmit(event) {
    event.preventDefault();
    let form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      await POSTNORMAL(`${process.env.REACT_APP_LOGIN_API}`, user)
        .then((result) => {
          trueLog(result);
        })
        .catch((error) => {
          // console.log(error);
          event.preventDefault();
          event.stopPropagation();
          setValidated(false);
          setLogin(false);

          toast.error("🔐 ¡Credenciales incorrectas!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  }

  const trueLog = async (result) => {
    // console.log("result", result.data);
    const token = result.data.token;
    const idUser = result.data.idUser;
    const autUser = result.data.permission?.map((value) => value.toString());
    const userRole = result.data.role;
    localStorage.setItem("token", token);
    localStorage.setItem("idUser", idUser);
    localStorage.setItem("autUser", autUser);
    localStorage.setItem("userRole", userRole);
    await setValidated(true);
    setLogin(true, autUser);
    HISTORY.replace("/graphics");
  };

  //change value in input
  const handleChangeInput = (e) => {
    const valueInput = e.target.value;
    const nameInput = e.target.name;
    setUser({ ...user, [nameInput]: valueInput });
  };

  return (
    <div className="LoginPage">
      <ToastContainer />
      <div className="m-0 p-0">
        <Row className="justify-content-center align-self-center text-center w-100 m-0">
          <Col md={8}>
            <p className="sm-titleLogin mt-5">Bienvenido a SST-SMART®</p>
            <p className="p-4">
              Herramienta de gestión técnica para el desarrollo de actividades de Seguridad y Salud
              en el Trabajo de la Fundación Nuevo Horizonte.
            </p>
          </Col>
        </Row>
        <Row className="justify-content-center align-self-center text-center mt-4 w-100 m-0">
          <Col md={4} xs={10}>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Usuario</Form.Label>
                <Row>
                  <Col className="p-0 d-flex">
                    <img
                      src={iconUserLogin}
                      className="sm-iconLogin mr-2"
                      alt="logo input usuario"
                    />
                    <Form.Control
                      onChange={handleChangeInput}
                      name="username"
                      type="text"
                      className="sm-inputLogin"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Debe llenar el campo
                    </Form.Control.Feedback>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group controlId="formBasicPassword">
                <Form.Label>Contraseña</Form.Label>
                <Row>
                  <Col className="p-0 d-flex">
                    <img
                      src={iconUserPass}
                      className="sm-iconLogin mr-2"
                      alt="logo input contraseña"
                    />
                    <Form.Control
                      onChange={handleChangeInput}
                      name="password"
                      type="password"
                      className="sm-inputLogin"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Debe llenar el campo
                    </Form.Control.Feedback>
                  </Col>
                </Row>
              </Form.Group>
              <Button className="sm-buttonLogin my-3 " variant="warning" type="submit">
                ENTRAR
              </Button>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default LoginPage;
