export const headersLinked = [
  "DEPARTAMENTO",
  "MUNICIPIO",
  "USUARIO",
  "FECHA DE VINCULACION",
  "ESTADO",
  "NIT",
  "RAZON SOCIAL",
  "RIESGO",
  "N° TRABAJADORES",
  "DIRECCION SEDE PRINCIPAL",
  "TELEFONO",
  "CELULAR",
  "DIRECCION WEB",
  "EMAIL",
  "RESPONSABLE DEL SG-SST",
  "INDICADOR",
  "CODIGO ACTIVIDAD",
  "ACTIVIDAD ECONOMICA",
  "RUT",
  "CAMARA Y COMERCIO",
  "SEGURIDAD SOCIAL",
];

export const headersApplies = [
  "DEPARTAMENTO",
  "MUNICIPIO",
  "USUARIO",
  "FECHA DE VINCULACION",
  "INDICADOR",
  "ESTADO",
  "NIT",
  "RAZON SOCIAL",
  "RIESGO",
  "N° TRABAJADORES",
  "CODIGO ACTIVIDAD",
  "ACTIVIDAD ECONOMICA",
  "AUTOEVALUACION",
  "MATRIZ DE PELIGROS",
  "PERFIL SOCIODEMOGRAFICO",
  "MORBILIDAD SENTIDA",
  "EFECTOS PSICOSOCIALES",
  "PROTOCOLO DE BIOSEGURIDAD",
];

export const headersUploadDocs = [
  "DEPARTAMENTO",
  "MUNICIPIO",
  "USUARIO",
  "NIT",
  "RAZON SOCIAL",
  "AUTOEVALUACION",
  "M. PELIGROS",
  "PERFIL SD",
  "M. SENTIDA",
  "E. PSICOSOCIALES",
  "P. BIOSEGURIDAD",
];
