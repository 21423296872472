import React from "react";
import "./ModalSaveResponse.scss";
import { Button, Modal } from "react-bootstrap";
import checkImage from "../../img/icons/check.svg";
import errorImage from "../../img/icons/error.svg";

const ModalSaveResponse = ({ state, show, handleClose }) => (
  <>
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton className="text-center d-flex justify-content-center align-items-center">
        {state ? (
          <Modal.Title className="w-100">
            {" "}
            <img src={checkImage} alt="correcto" style={{ width: "50px" }} />
          </Modal.Title>
        ) : (
          <Modal.Title className="w-100">
             <img src={errorImage} alt="error" style={{ width: "50px" }} />
          </Modal.Title>
        )}
      </Modal.Header>
      <Modal.Body>
      {state ? (
             <p className="sm-text-err-modal">
             Sus datos han sido guardados con exito.
               </p>
        ) : (
          <p className="sm-text-err-modal">
          Lo sentimos, ocurrieron errores al
          guardar la empresa, por favor vuelva a intentarlo de nuevo .
            </p>
        )}

      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  </>
);

ModalSaveResponse.propTypes = {};

ModalSaveResponse.defaultProps = {};

export default ModalSaveResponse;
