import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./CreateUser.scss";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";

//request
import { GET, POSTNORMAL } from "../../constants/ApiRest";

const CreateUser = ({ setLogin }) => {
  const [userData, setUserData] = useState({});
  const [department, setDepartment] = useState([]);
  const [town, setTown] = useState([]);
  const [role, setRole] = useState([]);
  const [validated, setValidated] = useState(false);
  const [show, setShow] = useState(false);

  //FormData
  const [fetchedData, setfetchedData] = useState({});

  useEffect(() => {
    GET(`${process.env.REACT_APP_POLITICAL_SEGMENTATION_ENDPOINT}/13/children`).then((res) => {
      if (res.data) setDepartment(res.data, "name");
    });

    GET(`${process.env.REACT_APP_USER}/user/rol`).then((res) => {
      if (res.data)
      setRole(res.data.sendRole.filter((rol)=> rol !== '5-Admin'));
    });
  }, []);

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setUserData({ ...userData, [name]: value });
  };

  const handleDepartmentChange = (event) => {
    setTown([]);
    let deparment = event.target.value;
    const auxDataTarget = event.target.name;
    const auxDataForm = { ...fetchedData, [auxDataTarget]: deparment };
    setfetchedData(auxDataForm);
    if (deparment) {
      //Petición para traer los municipios con respecto al departamento elegido
      GET(`${process.env.REACT_APP_POLITICAL_SEGMENTATION_ENDPOINT}/${deparment}/children`).then(
        (res) => {
          if (res.data) {
            setTown(res.data, "name");
          }
        }
      );
    } else {
      setTown([]);
    }
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    POSTNORMAL(`${process.env.REACT_APP_USER}/user/create`, userData)
      .then((res) => {
        // console.log(res);
      })
      .catch((error) => {
        setShow(true);
        // console.log(error);
      });
    setValidated(true);
    event.preventDefault();
  };

  return (
    <Container className="CreateUser">
      {!show || (
        <Alert variant="danger" onClose={() => setShow(false)} dismissible>
          <Alert.Heading>¡Oh, vaya!, ha ocurrido un error al crear un usuario.</Alert.Heading>
          <p>Prueba crearlo en 10 minutos.</p>
          <p>Si el error persiste, comunícate con soporte técnico.</p>
        </Alert>
      )}
      <Row>
        <Col>
          <h3 className="mb-4">Creación de usuario</h3>
        </Col>
      </Row>
      <Form noValidate validated={validated} onSubmit={handleSubmit} className="container-fluid">
        <Form.Row>
          <Form.Group as={Col} md="6" controlId="formGridName">
            <Form.Label>Nombres</Form.Label>
            <Form.Control
              onChange={handleInputChange}
              type="text"
              name="names"
              required
              placeholder="Nombre..."
            />
            <Form.Control.Feedback>¡Se ve muy bien!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Por favor, colocar nombre completo.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formGridSurname">
            <Form.Label>Apellidos</Form.Label>
            <Form.Control
              onChange={handleInputChange}
              type="text"
              name="surname"
              required
              placeholder="Apellido..."
            />
            <Form.Control.Feedback>¡Se ve muy bien!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Por favor, colocar apellido completo.
            </Form.Control.Feedback>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} md="8" controlId="formGridEmail">
            <Form.Label>Correo</Form.Label>
            <Form.Control
              onChange={handleInputChange}
              type="email"
              name="email"
              required
              placeholder="Correo@correo.com"
            />
            <Form.Control.Feedback>¡Se ve muy bien!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Por favor, colocar un correo valido.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="formGridNationalId">
            <Form.Label>Cedula de ciudadania</Form.Label>
            <Form.Control
              onChange={handleInputChange}
              type="text"
              name="national_id"
              required
              placeholder="1234567"
            />
            <Form.Control.Feedback>¡Se ve muy bien!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Por favor, colocar identificador.
            </Form.Control.Feedback>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} md="6" controlId="formGridUsername">
            <Form.Label>Nombre de usuario</Form.Label>
            <Form.Control
              onChange={handleInputChange}
              type="text"
              name="username"
              required
              placeholder="Usuario"
            />
            <Form.Control.Feedback>¡Se ve muy bien!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Por favor, colocar nombre de usuario.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formGroupPassword">
            <Form.Label>Contraseña</Form.Label>
            <Form.Control
              onChange={handleInputChange}
              type="password"
              name="password"
              required
              placeholder="Password"
            />
            <Form.Control.Feedback>¡Se ve muy bien!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Por favor, colocar contraseña
            </Form.Control.Feedback>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} md="6">
            <Form.Label>Rol del usuario</Form.Label>
            <Form.Control onChange={handleInputChange} as="select" name="userRole" required>
              <option></option>
              {role.map((val, i) => (
                <option key={`userRole-${i}`} value={val.split("-")[0]}>
                  {val.split("-")[1]}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback>¡Se ve muy bien!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Por favor, Seleccionar un rol.
            </Form.Control.Feedback>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} md="3">
            <Form.Label>Departamento</Form.Label>
            <Form.Control onChange={handleDepartmentChange} as="select" name="Departament" required>
              <option></option>
              {department.map((value) => (
                <option key={value.id} value={value.id}>
                  {value.name}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback>¡Se ve muy bien!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Por favor, seleccionar departamento.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="3">
            <Form.Label>Municipio</Form.Label>
            <Form.Control
              onChange={handleInputChange}
              as="select"
              name="town_id"
              disabled={town.length === 0}
              required
            >
              <option></option>
              {town.map((value, i) => (
                <option key={value.id} value={value.id}>
                  {value.name}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback>¡Se ve muy bien!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Por favor, Seleccionar municipio.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formGridAddress">
            <Form.Label>Dirección</Form.Label>
            <Form.Control
              onChange={handleInputChange}
              type="text"
              name="address"
              required
              placeholder="Kr 1g #45-69"
            />
            <Form.Control.Feedback>¡Se ve muy bien!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Por favor, colocar la dirección.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} required controlId="formGridPhone">
            <Form.Label>Telefono</Form.Label>
            <Form.Control
              onChange={handleInputChange}
              type="tel"
              name="phone"
              required
              placeholder="0000"
            />
            <Form.Control.Feedback>¡Se ve muy bien!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Por favor, colocar el telefono.
            </Form.Control.Feedback>
          </Form.Group>
        </Form.Row>
        <Button className="mt-4" variant="primary" size="lg" block type="submit">
          Crear
        </Button>
      </Form>
    </Container>
  );
};

CreateUser.propTypes = {};

CreateUser.defaultProps = {};

export default CreateUser;
