import React, { useState, useEffect, useReducer } from "react";
import "./ReferenceDocuments.scss";
import { Container, Row, Col, Form } from "react-bootstrap";
import Accordion from "../../components/Accordion/Accordion";
import AccordionMobile from "../../components/AccordionMobile/AccordionMobile";
import ArrowBack from "../../img/icons/iconArrowBack.svg";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import { NavLink, useHistory } from "react-router-dom";
//request
import { Pagination } from "../../components/Pagination/Pagination";
import { reducer } from "../../helpers/reducer";
import { GET } from "../../constants/ApiRest";

const init = () => {
  return [];
};

function ReferenceDocuments({ setLogin }) {
  const [show, setShow] = useState([false]);
  const [target, setTarget] = useState(null);
  const [WindosW, setWindosW] = useState(window.innerWidth);
  const [pageCount, setPageCount] = useState(0);
  const [, setRender] = useState(0);
  const [compani, dispatch] = useReducer(reducer, [], init);
  const [nit, setNit] = useState(0);

  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      // CODE
    } else {
      history.push("/login");
    }
  }, []);

  const handleResize = (e) => {
    setWindosW(window.innerWidth);
  };

  useEffect(() => {
    if (
      localStorage.getItem("userRole").includes(3) ||
      localStorage.getItem("userRole").includes(5) ||
      localStorage.getItem("userRole").includes(2)
    ) {
      (async function () {
        GET(`${process.env.REACT_APP_COMPANY_ENDPOINT}/company/allcompanies`)
          .then((res) => {
            dispatch({ type: "addSort", payload: res.data });
            setPageCount(Math.ceil(res.data.length / 6));
            return res.data;
          })
          .then((res) => {
            Array.isArray(res)
              ? setShow(
                  res.map(() => {
                    return false;
                  })
                )
              : setShow([false]);
          })
          .catch((error) => {
            console.log(error);
          });
      })();
    } else {
      (async function () {
        const idUser = localStorage.getItem("idUser");
        GET(`${process.env.REACT_APP_COMPANY_ENDPOINT}/company/${idUser}`)
          .then((res) => {
            dispatch({ type: "addSort", payload: res.data });
            setPageCount(Math.ceil(res.data.length / 6));
            return res.data;
          })
          .then((res) => {
            Array.isArray(res)
              ? setShow(
                  res.map(() => {
                    return false;
                  })
                )
              : setShow([false]);
          })
          .catch((error) => {
            console.log(error);
          });
      })();
    }
  }, []);

  const handleSubmitNit = async (e) => {
    e.preventDefault();
    const { data } = await GET(
      `${process.env.REACT_APP_COMPANY_ENDPOINT}/company/formless/bynit/${nit}`
    );
    dispatch({ type: "addSort", payload: data });
  };

  const handleNit = ({ target }) => {
    setNit(target.value);
  };

  const handlePage = ({ selected }) => {
    const action = {
      type: "change",
      payload: selected, //cambiar
    };

    dispatch(action);
    setRender(selected);
  };

  const buttons = [
    { title: "Autoevaluación SG-SST", key: "Autoevaluacion" },
    { title: "Matriz de Peligros", key: "Matriz" },
    { title: "Perfil Sociodemográfico", key: "Perfil" },
    { title: "Morbilidad Sentida", key: "Morbilidad" },
    { title: "Efectos Psicosociales", key: "Efectos" },
    { title: "Protocolo de Bioseguridad", key: "Protocolo" },
  ];

  return (
    <Container className="ReferenceDocuments">
      <Row>
        <div>
          <Breadcrumbs
            view={[
              { name: "Inicio", path: "/graphics" },
              { name: "Documentos de Consulta", path: "" },
            ]}
          ></Breadcrumbs>
          <NavLink to="/init/graphics" className="styleArrowLink">
            <img src={ArrowBack} width="50" />
          </NavLink>
        </div>
      </Row>
      <Row className="justify-content-center">
        <Col md={{ span: 5 }}>
          <h3 style={{ textAlign: "center", color: "#3A4297" }}>Documentos de Consulta</h3>
          {compani[0]?.length > 0 ? (
            <p style={{ textAlign: "center" }}>
              En este módulo están almacenados los informes y planes de acción, los cuales son
              producto del proceso de sistematización y análisis de cada herramienta diagnóstica
              aplicada en cada una de las empresas.
            </p>
          ) : (
            <h2 className="mt4" style={{ textAlign: "center" }}>
              No hay datos existentes
            </h2>
          )}
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col md="auto">
          <Form onSubmit={handleSubmitNit}>
            <Form.Row>
              <Form.Group as={Col} controlId="nit-text">
                <Form.Label>Nit</Form.Label>
                <Form.Control type="text" onChange={handleNit} />
              </Form.Group>
              <button
                className="btn btn-primary ml-3"
                style={{ height: "2.5rem", alignSelf: "center" }}
                type="submit"
              >
                Buscar
              </button>
            </Form.Row>
          </Form>
        </Col>
      </Row>
      <Row className="justify-content-center">
        {WindosW < 992 ? (
          <Col md={8} className="styleAccordion">
            <AccordionMobile compani={compani[0]} buttons={buttons}></AccordionMobile>
          </Col>
        ) : (
          <Col md={4} className="styleAccordion">
            {Array.isArray(compani[0])
              ? compani[1]?.map((value, i) => {
                  return (
                    <Accordion
                      key={i + "accordion"}
                      show={show[i]}
                      shows={show}
                      index={i}
                      target={target}
                      // targets={target}
                      title={value.company.name}
                      imageUrl={
                        value.company.files.length !== 0 ? value.company.files[0].url : null
                      }
                      idCompany={value.company.id}
                      dataCompany={value.company}
                      setS={setShow}
                      setT={setTarget}
                      buttons={buttons}
                      nit={value.company.nit}
                      path={"repo_documents"}
                      origin={0}
                    />
                  );
                })
              : null}
          </Col>
        )}
      </Row>
      <Row className="justify-content-center align-self-center text-center w-100 m-0 p-2">
        <Pagination pageCount={pageCount} handlePage={handlePage} />
      </Row>
    </Container>
  );
}

ReferenceDocuments.propTypes = {};

ReferenceDocuments.defaultProps = {};

export default ReferenceDocuments;
