import React from 'react';
import PropTypes from 'prop-types';
import { Bar, Pie } from 'react-chartjs-2'

import './ChartReport.scss';


const ChartReport = ({ type }) => {

  return (
    <>
      {
        type === 'bar' ?
        <Bar /> :
        <Pie />
      }
    </>
  )
};

ChartReport.propTypes = {};

ChartReport.defaultProps = {};

export default ChartReport;
