import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./FormDangerMatrix.scss";
import { Form, Button } from "react-bootstrap";
import ReactChart from "../ReactChart/ReactChart";

//import component


const FormDangerMatrix = ({ GeneratePDF, dataCharts }) => {
  const [data, setData] = useState({
    conclution: "",
    resultsAnalysis: "",
    set1: [["", "", ""]],
    set2: [["", "", ""]],
    set3: [[""]],
    set4: [[""]],
    set5: [["", "", ""]],
    set6: [[""]],
    set7: [[""]],
    analysis: [""],
  });
  const [chartsAllBolean, setchartsBolean] = useState([]);

  const handleSubmit = (event) => {
    event.preventDefault();
    GeneratePDF(1, data);
  };

  //graficas
  const [arrayOfCharts, setArrayOfCharts] = useState([]);

  useEffect(() => {
    data.analysis = dataCharts.map((val) => {
      return val.analysis;
    });
    data.set1 = dataCharts[7].table;
    data.set2 = dataCharts[8].table;
    data.set3 = dataCharts[9].table;
    data.set4 = dataCharts[10].table;
    data.set5 = dataCharts[11].table;
    data.set6 = dataCharts[12].table;
    data.set7 = dataCharts[13].table;
    setData({ ...data });
    setchartsBolean(dataCharts.map((val) => !!val));
  }, [dataCharts]);

  useEffect(() => {
    chart();
    setchartsBolean(chartsAllBolean.map((val) => false));
  }, [data]);

  const chart = () => {
    let auxArrayOfCharts = [...arrayOfCharts];
    dataCharts.map((c, i) => {
      renderObjTable(c, i, auxArrayOfCharts);
    });
    setArrayOfCharts(auxArrayOfCharts);
  };

  const [isDisableButton, setIsDisableButton] = useState(true);
  const updateChart = (indexId, isReady, auxChartsAllBolean) => {
    auxChartsAllBolean[indexId] = isReady;
    if (auxChartsAllBolean.length - 1 == indexId) {
      setIsDisableButton(auxChartsAllBolean.includes(false));
    }
  };

  const renderCharts = () => {
    const pies = [0, 1];
    const auxChartsAllBolean = [...chartsAllBolean];
    return arrayOfCharts.map((t, i) => (
      <ReactChart
        key={i}
        dataChartPdfReportGenerator={dataChartPdfReportGenerator}
        dataCharts={t.chartsData}
        optionsCharts={t.chartsOptions}
        value={i}
        typeP={pies.includes(i) ? 4 : 1}
        isCharReady={updateChart}
        auxChartsAllBolean={auxChartsAllBolean}
      />
    ));
  };

  const dataChartPdfReportGenerator = async (data, i) => {
    const base64Image = data;

    dataCharts[i].chart = await base64Image;
  };

  const renderObjTable = (value, index, auxArrayOfCharts) => {
    let arrayValue = value.table;
    let labelArray = !!arrayValue ? arrayValue.map((x) => x[0]) : [""];
    let labelDataArray = !!arrayValue
      ? arrayValue.map((x) => {
          return x[1] ? x[1].toString().replace(/%/, "").replace(/-/, "") : 0;
        })
      : ["none"];
    let chartObj = {
      chartsData: {
        labels: labelArray,
        animation: {
          duration: 0,
        },
        hover: {
          animationDuration: 0, // duration of animations when hovering an item
        },
        responsiveAnimationDuration: 0, // animation duration after a resize
        datasets: [
          {
            label: "",
            data: labelDataArray,
            backgroundColor: "#33AEEF",
            borderWidth: 4,
          },
        ],
      },
      chartsOptions: {
        title: {
          display: true,
          text: "Grafica",
          fontSize: 20,
        },
        legend: {
          display: true,
        },
      },
    };
    auxArrayOfCharts[index] = chartObj;
  };

  //fin graficas

  return (
    <Form onSubmit={handleSubmit} className="w-100">
      <Button variant="danger" type="submit" block disabled={isDisableButton} block>
        Visualizar PDF
      </Button>
      {renderCharts()}
    </Form>
  );
};

FormDangerMatrix.propTypes = { GeneratePDF: PropTypes.func };

FormDangerMatrix.defaultProps = { GeneratePDF: function blank(params) {} };

export default FormDangerMatrix;
