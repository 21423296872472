import React from "react";
import "./ModalFileUp.scss";

import { Button, Modal, Form } from "react-bootstrap";

const ModalFileUp = (props) => {
  const uploadFile = (e) => {
    // console.log(e.target.files[0])
    props.setTemplate(e.target.files[0]);
  };

  return (
    <>
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{props.title === "" ? "Subir planilla" : props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>
                {props.msg === "" ? "Selecciona el archivo excel que desea subir" : props.msg}
              </Form.Label>
              <Form.Control
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/pdf"
                className="sm-buttonFileUp mt-2"
                type="file"
                onChange={uploadFile}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            Cancelar
          </Button>
          <Button
            variant="primary"
            href={props.url}
            onClick={props.acceptModal}
            disabled={props.loadingFile}
          >
            {props.loadingFile ? "Subiendo archivo..." : "Subir"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalFileUp;
