import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./ReassignUser.scss";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import ModalConfirm from "../../components/ModalConfirm/ModalConfirm";



//request
import { DELETE, GET, POSTNORMAL, PUTNORMAL, PUTJSON } from "../../constants/ApiRest";

const ReassignUser = ({ setLogin }) => {
  const [user, setUser] = useState([{ id: 0, username: "" }]);
  const [userData, setUserData] = useState({});
  const [department, setDepartment] = useState([]);
  const [town, setTown] = useState([]);
  const [role, setRole] = useState([]);
  // const [validated, setValidated] = useState(false);
  const [show, setShow] = useState(false);
  // const [showSend, setShowSend] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [showUpdateError, setShowUpdateError] = useState(false);
  // const [showSendDelete, setShowSendDelete] = useState(false);
  const [companies, setCompanies] = useState([])
  const [alertMessage, setAlert] = useState("");

  //FormData
  const [fetchedData, setfetchedData] = useState({});

  useEffect(() => {
    GET(`${process.env.REACT_APP_POLITICAL_SEGMENTATION_ENDPOINT}/13/children`).then((res) => {
      if (res.data) setDepartment(res.data, "name");
    });

    GET(`${process.env.REACT_APP_USER}/user/rol`).then((res) => {
      if (res.data) setRole(res.data.sendRole);
    });

    GET(`${process.env.REACT_APP_USER}/user`).then((res) => {
      if (res.data) setUser(res.data);
    });
  }, []);

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setUserData({ ...userData, [name]: value });
  };


  const searchCompanies = (idcompanyUser) => {

    GET(`${process.env.REACT_APP_COMPANY_ENDPOINT}/company/${idcompanyUser}`)
      .then((res) => {
        if (res.statusText !== "OK") {
          setLogin(false);
        } else {
          if (res.data) {
            if (res.data !== "la sesión ha expirado") {
              // setCompany(res.data, "name");
              setAlert("");
              setCompanies(res.data);
              setCheckedState(new Array(res.data.length).fill(false));
              // dispatch( { type: "addSort", payload: res.data } )
              // setPageCount( Math.ceil( res.data.length / 6 ) )
              // console.log("data companies", res.data);
            } else {
              // setCompany([]);
              // dispatch({ action: [] });
            }
          }
        }
      })
      .catch((error) => {
        if (error.request.response !== "There are no companies.") {
          setLogin(false);
        }
        setAlert("El Usuario NO Tiene empresas asignadas")
        setCompanies([]);
        console.log(error.request.response);
      })
  }

  const [assignUser, setAssignUser] = useState({});

  const handleInputSetNewUser = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    POSTNORMAL(`${process.env.REACT_APP_USER}/user`, { [name]: value })
      .then((res) => {
        const data = res.data;
        const dataRes = {
          email: data.email,
          userId: data.id,
          username: data.username,
          ...data.person,
          userRole: data.role.id,
        };
        // setUserData(dataRes);
        // console.log("assignUser", dataRes);
        setAssignUser(dataRes);
        // searchCompanies(data.id);
      })
      .catch((error) => {
        setShow(true);
      });
  }


  const [targetUser, setTargetUser] = useState("");

  const handleInputChangeUser = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setTargetUser(target);
    setShowUpdateError(false);
    setShowUpdate(false);

    POSTNORMAL(`${process.env.REACT_APP_USER}/user`, { [name]: value })
      .then((res) => {
        const data = res.data;
        const dataRes = {
          email: data.email,
          userId: data.id,
          username: data.username,
          ...data.person,
          userRole: data.role.id,
        };
        setUserData(data);
        // console.log("user", data);
        searchCompanies(data.id);
      })
      .catch((error) => {
        setShow(true);
      });
  };

  // const handleDepartmentChange = (event) => {
  //   setTown([]);
  //   let deparment = event.target.value;
  //   const auxDataTarget = event.target.name;
  //   const auxDataForm = { ...fetchedData, [auxDataTarget]: deparment };
  //   setfetchedData(auxDataForm);
  //   if (deparment) {
  //     //Petición para traer los municipios con respecto al departamento elegido
  //     GET(`${process.env.REACT_APP_POLITICAL_SEGMENTATION_ENDPOINT}/${deparment}/children`).then(
  //       (res) => {
  //         if (res.data) {
  //           setTown(res.data, "name");
  //         }
  //       }
  //     );
  //   } else {
  //     setTown([]);
  //   }
  // };

  // const handleSubmitDelete = (event) => { };

  // const handleSubmit = (event) => {
  //   const form = event.currentTarget;
  //   if (form.checkValidity() === false) {
  //     event.preventDefault();
  //     event.stopPropagation();
  //   }

  //   PUTNORMAL(`${process.env.REACT_APP_USER}/user/modifys`, userData)
  //     .then((res) => {
  //       // console.log(res);
  //       setShowSend(true);
  //     })
  //     .catch((error) => {
  //       setShow(true);
  //       // console.log(error);
  //     });

  //   setValidated(true);
  //   event.preventDefault();
  // };

  // const handleDelete = (e) => {

  //   DELETE(`${process.env.REACT_APP_USER}/user/delete`, { userId: userData.userId })
  //     .then((res) => {
  //       // console.log('y si te traigo rosas', res)
  //       setShowSendDelete(true)
  //     })
  //     .catch(error => setShowUpdate(true))

  //   // console.log('de cualquier forma se me van a marchitar')



  //   e.preventDefault();
  // }

  // const [isChecked, setIsChecked] = useState(false);

  // const handleOnChange = () => {
  //   setIsChecked(!isChecked);
  // };
  const [checkedState, setCheckedState] = useState(
    new Array(companies.length).fill(false)
  );

  const handleOnChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );

    setCheckedState(updatedCheckedState);
  }

  const [reassignData, setReassignData] = useState({});

  const handleAssignUser = () => {

    setShowUpdateError(false);
    setShowUpdate(false);

    let selectedCompanies = [];
    let user = userData;
    let userassign = assignUser;

    checkedState.map((checkedCompany, index) => {
      if (checkedCompany) {
        // console.log("selected company", companies[index].company.id);
        selectedCompanies.push(companies[index].company.id);
      }
    })

    if (selectedCompanies.length == 0) {
      // console.log("ERROR, NO HAY COMPAÑIAS POR CAMBIAR");
      setAlert("No has seleccionado que empresas se van a reasignar")
      setShowUpdateError(true);
      return;
    }

    if (userassign.userId == null) {
      // console.log("ERROR, NO USUARIO ASIGNADO");
      setAlert("Debes seleccionar el usuario que recibe las empresas")
      setShowUpdateError(true);
      return;
    }

    if (user.id === userassign.userId) {
      // console.log("ERROR, MISMO USUARIO");
      setAlert("El usuario a reasignar es el mismo que esta en posesión de las empresas")
      setShowUpdateError(true);
      return;
    }

    let compamsg = selectedCompanies.length == 1 ? 'empresa' : 'empresas'

    let message = `Se confirma la reasignación de ${selectedCompanies.length} ${compamsg} al usuario ${userassign.username}`;

    let dataAssign = {
      "idOldUser": user.id,
      "idCompanies": selectedCompanies,
      "idNewUser": userassign.userId
    }

    setReassignData({
      message: message,
      dataAssign: dataAssign
    })
    // console.log("-------------- start ----------------");
    // console.log(dataAssign);

    setShowModal(true);
  }

  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);

  const acceptModal = () => {

    setShowUpdateError(false);
    setShowUpdate(false);


    // send data

    if (reassignData.dataAssign == null || reassignData.dataAssign == undefined) {
      setAlert("No hay Datos")
      setShowUpdateError(true);

      // close with error
      handleClose();
      return;
    }

    // set ENDPOINT
    PUTJSON(`${process.env.REACT_APP_COMPANY_ENDPOINT}/usercompany/reassign`, reassignData.dataAssign)
      .then((res) => {
        if (res.status === 200) {
          setAlert(res.data)

          setTimeout(function () {
            setShowUpdateError(false);
            setShowUpdate(false);
          }, 3000);

          // setting data stored
          setReassignData({}); // setting companies reassigned
          setUserData({}); // setting user data
          setCompanies([]); // setting companies to empty
          targetUser.value = ""; // first combobox value -> ""
          setTargetUser(""); // setting combobox stored
        } else {
          setAlert("Revisar consola")
          console.log(res);
        }

        // temporal ----------------------------------------------------
        // setAlert("test completed")
        // temporal ----------------------------------------------------

        setShowUpdate(true);

        // close modal
        handleClose();

      })
      .catch((error) => {
        console.log(error);
        setAlert("Error en la reasignación")
        setShowUpdateError(true);

        // close modal
        handleClose();
      })
  }

  return (
    <Container className="ReassignUser">
      {!show || (
        <Alert variant="danger" onClose={() => setShow(false)} dismissible>
          <Alert.Heading>¡Oh, vaya!, ha ocurrido un error al crear un usuario.</Alert.Heading>
          <p>Prueba crearlo en 10 minutos.</p>
          <p>Si el error persiste, comunícate con soporte técnico.</p>
        </Alert>
      )}
      {!showUpdate || (
        <Alert variant="success" onClose={() => setShowUpdate(false)} dismissible>
          <Alert.Heading>{alertMessage}</Alert.Heading>
          {/* <p>Prueba eliminarlo en 10 minutos.</p> */}
          {/* <p>Si el error persiste, comunícate con soporte técnico.</p> */}
        </Alert>
      )}
      {!showUpdateError || (
        <Alert variant="danger" onClose={() => setShowUpdateError(false)} dismissible>
          <Alert.Heading>{alertMessage}</Alert.Heading>
          {/* <p>Prueba eliminarlo en 10 minutos.</p> */}
          {/* <p>Si el error persiste, comunícate con soporte técnico.</p> */}
        </Alert>
      )}
      <Row>
        <Col>
          <h3 className="mb-4">Reasignar Empresas</h3>
        </Col>
      </Row>

      <Row>
        <Col md="6">
          <Form.Group as={Col} md="4" className="px-0">
            <Form.Label>Usuario</Form.Label>
            <Form.Control onChange={handleInputChangeUser} as="select" name="userId" required>
              <option></option>
              {user.map((value) => (
                <option key={value.id} value={value.id}>
                  {value.username}
                </option>
              ))}
            </Form.Control>
            {/* <Form.Control.Feedback>¡Se ve muy bien!</Form.Control.Feedback>
        <Form.Control.Feedback type="invalid">
          Por favor, Seleccionar municipio.
        </Form.Control.Feedback> */}
          </Form.Group>
        </Col>
        <Col md="6">
          {
            companies.length == 0 ? (<div style={{ display: "none" }}></div>) : (
              <>
                {/* <h5 className="my-4">Asignar a Usuario</h5> */}
                <Form.Group as={Col} md="4" className="px-0">
                  <Form.Label>Usuario Nuevo</Form.Label>
                  <Form.Control onChange={handleInputSetNewUser} as="select" name="userId" required>
                    <option></option>
                    {user.map((value) => (
                      <option key={value.id} value={value.id}>
                        {value.username}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>

                <div className="py-3 text-center text-md-left">
                  <Button className="primary" onClick={handleAssignUser}>Transferir a usuario</Button>
                </div>
              </>
            )
          }
        </Col>
      </Row>



      {showModal && (
        <ModalConfirm
          show={showModal}
          handleClose={handleClose}
          url=""
          msg={reassignData.message}
          title="Confirmar Reasignación"
          acceptModal={acceptModal}
        />
      )}

      {/* {console.log(!Object.keys(userData).length > 0)} */}
      {Object.keys(userData).length > 2 ? (
        <>
          <div>
            {companies.length == 0 ? (
              <h1 className="text-grey">{alertMessage}</h1>
            ) : (
              <div>
                <h5 className="mb-4">Empresas Asignadas</h5>
                <table className="col-12 col-md-10">
                  {companies.map((value, index) => (
                    <tr>
                      <td>
                        <div className="content-td-input-label">
                          <div className="content-input-check" key={"companies-input" + index}>
                            <input className="inputCom click-hover"
                              type="checkbox"
                              id={value.company.id}
                              name={value.company.id}
                              value={value.company.id}
                              checked={checkedState[index]}
                              onChange={() => handleOnChange(index)}
                            />
                          </div>
                          <div className="content-labels" key={"companies-input" + index}>
                            <label className="click-hover label-company-name" htmlFor={value.company.id}>
                              {value.company.name}
                              <br />
                              <span>NIT:&nbsp;{value.company.nit}</span>
                            </label>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </table>
              </div>
            )}
          </div>
        </>
      ) : (
        <div></div>
      )}
    </Container>
  );
};

ReassignUser.propTypes = {};

ReassignUser.defaultProps = {};

export default ReassignUser;
