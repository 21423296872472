import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./Navbar.scss";
import { Navbar as NavBar, Nav, NavDropdown, Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
// Import Imgs
import logo from "../../img/iconApp (1).svg";
import logoMinTrabajo from '../../img/logoMinTrabajo.png'

const Navbar = ({ login: auth, SetStateRoute }) => {
  let location = useLocation();
  const [login, setLogin] = useState(!!localStorage.getItem("token") ? true : false);

  useEffect(() => {
    funcSetLogin();
  }, [location]);

  const funcSetLogin = () => setLogin(!!localStorage.getItem("token") ? true : false);

  return (
    <>
      <NavBar
        collapseOnSelect
        expand="lg"
        bg="dark"
        variant="dark"
        className="navbar-bg-transparent"
      >
        {/* <Container> */}
          <NavBar.Brand href={login ? "/" : "/graphics"}>
            <img src={logo} alt="Fundacion nuevo horizonte" className="img-logo-navbar" />
          </NavBar.Brand>
          <NavBar.Brand>
            <img src={logoMinTrabajo} alt="Valle invencible" className="img-logo-navbar" />
          </NavBar.Brand>
          <NavBar.Toggle aria-controls="responsive-navbar-nav" />
          <NavBar.Collapse id="responsive-navbar-nav">
            <Nav className="ml-auto">
              {login &&
                <Nav.Link href="/graphics" className="text-color-grey text-center">
                  Inicio |
                </Nav.Link>
              }
              {auth.authorizations.includes("5") &&
                <NavDropdown className="text-color-grey" title={<span className="text-color-grey" >Acciones |</span>}>
                  <NavDropdown.Item href="/init/createuser">Crear usuarios</NavDropdown.Item>
                  <NavDropdown.Item href="/init/updateuser">Actualizar usuarios</NavDropdown.Item>
                  <NavDropdown.Item href="/init/reassignuser">Reasignar empresas</NavDropdown.Item>
                  <NavDropdown.Item href="/init/delcompany">Eliminar empresa</NavDropdown.Item>
                </NavDropdown>
              }
              {login &&
                <Nav.Link
                  eventKey={2}
                  onClick={() => {
                    localStorage.removeItem("token");
                    localStorage.removeItem("autUser");
                    SetStateRoute(false);
                  }}
                  href="/"
                  className="text-color-grey text-center button-log-yellow"
                >
                  Cerrar Sesión
                </Nav.Link>
              }
            </Nav>
          </NavBar.Collapse>
        {/* </Container> */}
      </NavBar>
    </>
  );
};

Navbar.propTypes = {};

Navbar.defaultProps = { login: PropTypes.bool };

export default Navbar;
