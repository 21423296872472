import React, { useState, useEffect, useReducer } from "react";
import "./Diagnose.scss";
import { Container, Row, Col } from "react-bootstrap";
import Accordion from "../../components/Accordion/Accordion";
import AccordionMobile from "../../components/AccordionMobile/AccordionMobile";
import ArrowBack from "../../img/icons/iconArrowBack.svg";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import { NavLink, useHistory } from "react-router-dom";

//request
import { Pagination } from "../../components/Pagination/Pagination";
import { reducer } from "../../helpers/reducer";
import { GET } from "../../constants/ApiRest";
import axios from "axios";

const init = () => {
  return [];
}

function Diagnose({ setLogin }) {
  const [show, setShow] = useState([false]);
  const [target, setTarget] = useState(null);
  const [WindosW, setWindosW] = useState(window.innerWidth);
  const [pageCount, setPageCount] = useState(0);
  const [, setRender] = useState(0);
  const [compani, dispatch] = useReducer(reducer, [], init);
  // const [compani, setCompani] = useState([]);

  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      /* GET(`${process.env.REACT_APP_CHECK_LOGIN_API}`)
        .then((result) => {
          if (result.status >= 199 && result.status <= 299) {
            // console.log(200);
            setLogin(true);
          } else {
            history.push("/login");
            setLogin(false);
          }
        })
        .catch((error) => setLogin(false)); */
    } else {
      history.push("/login");
    }
  }, []);

  const handleResize = (e) => {
    setWindosW(window.innerWidth);
  };

  // useEffect(() => {
  //   let token =
  //     "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7fSwiaWF0IjoxNTk4MDIxNzY1LCJleHAiOjE1OTgwMjUzNjV9.XjRi8h0ki8cf0uDKJpkefEBuHUdXywwjc8Ffcp5PwaY";

  //   //Petición para traer las comapañias de la base de datos
  //   axios
  //     .get("http://localhost:3003/company/all", { headers: { authorization: token } })
  //     .then((res) => {
  //       if (res.data) {
  //         if (res.data != "la sesión ha expirado") {
  //           setCompani(res.data, "name");
  //         } else {
  //           setCompani([]);
  //         }
  //       }
  //     });

  //   window.addEventListener("resize", handleResize);
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // });

  useEffect(() => {
    (async function () {
      const idUser = localStorage.getItem("idUser");
      // console.log('localstorage', localStorage.getItem('idUser'));
      GET(`${process.env.REACT_APP_COMPANY_ENDPOINT}/company/${idUser}`)
        .then((res) => {
          // console.log(res.data);
          // setCompani(res.data);
          dispatch({ type: "addSort", payload: res.data })
          setPageCount(Math.ceil(res.data.length / 6))
          return res.data;
          // console.log(res.data)
        })
        .then((res) => {
          Array.isArray(res)
            ? setShow(
              res.map(() => {
                return false;
              })
            )
            : setShow([false]);
        })
        .catch((error) => {
          // setLogin(false);
        });

      // setCompani([])
    })();
  }, []);

  const handlePage = ({ selected }) => {

    const action = {
      type: 'change',
      payload: selected //cambiar
    }

    dispatch(action);
    setRender(selected)
  }

  //puede que venga de la BD
  /* const buttons = [
    "Autoevaluación SG-SST",
    "Matriz de Peligros",
    "Perfil Sociodemográfico",
    "Morbilidad Sentida",
    "Peligros Psicosociales",
    "Protocolo de Bioseguridad",
  ]; */
  const buttons = [
    { title: "Autoevaluación SG-SST" /* , val: 50 */, key: "Autoevaluacion", id: 0 },
    { title: "Matriz de Peligros" /* , val: 30 */, key: "Matriz", id: 1 },
    { title: "Perfil Sociodemográfico" /* , val: 90 */, key: "Perfil", id: 2 },
    { title: "Morbilidad Sentida" /* , val: 40 */, key: "Morbilidad", id: 3 },
    { title: "Efectos Psicosociales",/* val: 10*/ key: "Efectos", id: 4 },
    { title: "Protocolo de Bioseguridad" /* , val: 25 */, key: "Protocolo", id: 5 },
  ];

  return (
    <Container className="Diagnose">
      <Row>
        <div>
          <Breadcrumbs
            view={[
              { name: "Inicio", path: "/graphics" },
              { name: "Diagnóstico", path: "" },
            ]}
          ></Breadcrumbs>
          <NavLink to="/init/graphics" className="styleArrowLink">
            <img src={ArrowBack} width="50" />
          </NavLink>
        </div>
      </Row>
      <Row className="justify-content-center">
        <Col md={{ span: 5 }}>
          <h3 style={{ textAlign: "center", color: "#3A4297" }}>Diagnóstico</h3>
          {compani[0]?.length > 0 ? (
            <p style={{ textAlign: "center" }}>
              Estas son las empresas creadas en el módulo anterior, las cuales podrá iniciar a
              gestionar a través de las herramientas diseñadas para tal fin.
            </p>
          ) : (
            <h2 className="mt4" style={{ textAlign: "center" }}>
              No hay datos existentes
            </h2>
          )}
          { }
        </Col>
      </Row>
      <Row className="justify-content-center">
        {WindosW < 992 ? (
          <Col md={8} className="styleAccordion">
            <AccordionMobile compani={compani[0]} buttons={buttons}></AccordionMobile>
          </Col>
        ) : (
          <Col md={4} className="styleAccordion">
            {Array.isArray(compani[0])
              ? compani[1]?.map((value, i) => {
                return (
                  <Accordion
                    key={i + "accordion"}
                    show={show[i]}
                    shows={show}
                    index={i}
                    target={target}
                    // targets={target}
                    title={value.company.name}
                    imageUrl={
                      value.company.files.length !== 0 ? value.company.files[0].url : null
                    }
                    idCompany={value.company.id}
                    dataCompany={value.company}
                    setS={setShow}
                    setT={setTarget}
                    buttons={buttons}
                    nit={value.company.nit}
                    path={'diagnosis'}
                    origin={1}
                  />
                );
              })
              : console.log("fail")}

            {/* <Accordion
              show={show}
              target={target}
              title={"Empresa 2"}
              setS={setShow}
              setT={setTarget}
              buttons={buttons}
            /> */}
          </Col>
        )}
      </Row>
      <Row className="justify-content-center align-self-center text-center w-100 m-0 p-2">
        <Pagination pageCount={pageCount} handlePage={handlePage} />
      </Row>
    </Container>
  );
}

Diagnose.propTypes = {};

Diagnose.defaultProps = {};

export default Diagnose;
