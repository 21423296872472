import React from "react";
import "./ModalConfirm.scss";

import { Button, Modal, Form } from "react-bootstrap";

const ModalConfirm = (props) => {
  //   <ModalConfirm
  //   show={showModal}
  //   handleClose={handleClose}
  //   url=""
  //   msg=""
  //   title=""
  //   acceptModal={acceptModal}
  //   setTemplate={setTemplate}
  // />

  // const uploadFile = (e) => {
  //   // console.log(e.target.files[0])
  //   props.setTemplate(e.target.files[0]);
  // }

  return (
    <>
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{props.title === "" ? "Modal Confirm" : props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label>{props.msg === "" ? "Modal Message" : props.msg}</label>
          {/* <Form>
            <Form.Group>
              <Form.Control accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/pdf" className="sm-buttonFileUp mt-2" type="file" onChange={uploadFile} />
            </Form.Group>
          </Form> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            Cancelar
          </Button>
          <Button
            variant={props.typeBtn ? "danger" : "primary"}
            href={props.url}
            onClick={props.acceptModal}
          >
            {props.typeBtn ? "Eliminar" : "Subir"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalConfirm;
