import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./PdfDangerMatrix.scss";
import { Container, Row, Col, Spinner, Button, Toast } from "react-bootstrap";

import { NavLink } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import ArrowBack from "../../img/icons/iconArrowBack.svg";
import { useLocation } from "react-router-dom";

import { GET, POSTNORMAL } from "../../constants/ApiRest";

//import components
import PdfReportGenerator from "../../components/PdfReportGenerator/PdfReportGenerator";
import FormDangerMatrix from "../../components/FormDangerMatrix/FormDangerMatrix";

//import data
// import data from "./data";
//import data from "../../abstract/morbidity";
//import data from "../../abstract/psychosocial";
// import data from "../../abstract/socioDemographic";
import data from "../../abstract/dangerMatrix";
import TablePdf from "../../components/TablePdf/TablePdf";

const PdfDangerMatrix = () => {
  let location = useLocation();

  const [viewPDF, setViewPDF] = useState(false);
  const [cover, setCover] = useState([]);
  const [toc, setToc] = useState([]);
  const [content, setContent] = useState([]);
  const [isData, setisData] = useState(false);
  const [listPlan, setListPlan] = useState([]);

  const monthNames = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  const date_application = new Date();
  const date = `${monthNames[date_application.getMonth()]}, ${date_application.getFullYear()}`;

  // Data on PDF
  const [init, setInit] = useState({
    name: "",
    advisor: "",
    date: date,
    department: "",
    idDepartment: "",
    idCity: "",
    city: "",
    lic: "N° 1701 del 15 septiembre 2016",
  });

  // Company info
  const [contentS, setContentS] = useState({
    CompanyName: "",
    NIT: "",
    direction: "",
    phone: "",
    celPhone: "",
    CIIU: "",
    economicActi: "",
    riskClass: "",
    colaboratosNum: "",
    legalRepresent: "",
    cordinatorSGSST: "",
    questAplication: "",
    correspondingClass: [],
    correspondingClassTotal: [],
    riskLevels: ["ss", "dd"],
    resultsAnalysis: "pp",
    conclusions: "gg",
  });

  // Initial chartTables info
  const [chartTables, setChartTables] = useState([
    {
      chart: "data:image/png; base64, ",
      titleTable: [],
      table: [[""]],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      titleTable: [],
      table: [[""]],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      titleTable: [],
      table: [[""]],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      titleTable: [],
      table: [[""]],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      titleTable: [],
      table: [[""]],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      titleTable: [],
      table: [[""]],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      titleTable: [],
      table: [[""]],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      titleTable: [],
      table: [[""]],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      titleTable: [],
      table: [[""]],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      titleTable: [],
      table: [[""]],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      titleTable: [],
      table: [[""]],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      titleTable: [],
      table: [[""]],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      titleTable: [],
      table: [[""]],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      titleTable: [],
      table: [[""]],
      analysis: "cositas varias",
    },
  ]);

  useEffect(() => {
    GET(
      `${process.env.REACT_APP_REPORT_ENDPOINT}/formless/data/${location.state.idCompany}/${location.state.form_id}`
    )
      .then((result) => {
        setInitialData(result.data);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.data.reload === true) {
            POSTNORMAL(process.env.REACT_APP_FILE_LOADER_ENDPOINT + "validate", {
              idCompany: location.state.idCompany,
              nitCompany: location.state.dataCompany.nit,
              form_id: location.state.form_id,
            })
              .then((response) => {
                window.location.reload();
              })
              .catch(console.log(err));
          }
        }
        console.log(err);
      });
  }, []);

  //Obtener empresa
  useEffect(() => {
    GET(`${process.env.REACT_APP_COMPANY_ENDPOINT}/company/getnit/${location.state.idCompany}}`)
      .then((result) => {
        const data = result.data;
        setInit({
          ...init,
          name: data.name,
          idDepartment: data.deparment_id,
          idCity: data.town_id,
          advisor: data.responsible,
        });
        contentS.CompanyName = data.name;
        contentS.NIT = data.nit;
        contentS.idSector = data.main_activity;
        contentS.workers = data.workers;
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //Obtener ciudad
  useEffect(() => {
    if (init.idDepartment !== "") {
      GET(`${process.env.REACT_APP_POLITICAL_SEGMENTATION_ENDPOINT}/${init.idDepartment}/children`)
        .then((result) => {
          const data = result.data;
          const nameCity = data.find((el) => el.id === init.idCity);
          setInit({ ...init, city: nameCity.name });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [init.idDepartment]);

  //Obtener departamento
  useEffect(() => {
    if (init.idDepartment !== "") {
      GET(`${process.env.REACT_APP_POLITICAL_SEGMENTATION_ENDPOINT}/13/children`)
        .then((result) => {
          const nameDepartment = result.data.find((el) => el.id === init.idDepartment);
          setInit({ ...init, department: nameDepartment.name });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [init.city]);

  //Obtener sector
  useEffect(() => {
    if (contentS.idSector !== "") {
      GET(`${process.env.REACT_APP_SECTORS}/sector/category/${contentS.idSector}`)
        .then((result) => {
          contentS.sector = result.data[0].sector;
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [contentS.idSector]);

  const setInitialData = (data) => {
    console.log(data);
    // Refence ID by class name
    const controlTables = {
      BIOLOGICO: 7,
      BIOMECANICOS: 8,
      CONDICIONES_DE_SEGURIDAD: 9,
      FENOMENOS_NATURALES: 10,
      FISICO: 11,
      PSICOSOCIAL: 12,
      QUIMICO: 13,
    };

    // Tables titles
    const controlTitles = {
      admin: "Administrativos",
      epp: "E.P.P",
      engineer: "Ingenieria",
      sust: "Sustitución",
      elim: "Eliminación",
    };

    // Toggle Circular Progress
    setisData(true);

    const tmpChartTables = [...chartTables];

    let adminRiskLvl = { I: 0, II: 0, III: 0, IV: 0 };
    let operativeRiskLvl = { I: 0, II: 0, III: 0, IV: 0 };

    let correspondingClass = [];
    let correspondingClassTotal = [];

    let list = { I: {}, II: {}, III: {}, IV: {} };

    Object.keys(data.jsonAnswer).map(async function (key) {
      if (key === "admin") {
        correspondingClass[0] = data.jsonAnswer[key].totalRiskClass;
        correspondingClassTotal[0] = data.jsonAnswer[key].totalRisks;
      } else if (key === "operative") {
        correspondingClass[1] = data.jsonAnswer[key].totalRiskClass;
        correspondingClassTotal[1] = data.jsonAnswer[key].totalRisks;
      }

      Object.keys(data.jsonAnswer[key].percentRiskLvl).map(function (risk) {
        let percentRiskLvl = data.jsonAnswer[key].percentRiskLvl;

        Object.keys(percentRiskLvl[risk].clasess).map((clase) => {
          let riskLvlMessage = `Nivel de Riesgo Tipo ${risk}: ${(
            (percentRiskLvl[risk].total / data.jsonAnswer[key].totalEmployees) *
            100
          ).toFixed(1)}% correspondientes a peligros de carácter ${clase} (`;

          let totalRisksInClasess = "";

          list[risk][clase] = [];

          for (let i = 0; i < percentRiskLvl[risk]["clasess"][clase]["risks"].length; i++) {
            totalRisksInClasess += percentRiskLvl[risk]["clasess"][clase]["risks"][i].risk;

            let list2 = percentRiskLvl[risk]["clasess"][clase]["risks"][i].risk + ": ";
            list2 += percentRiskLvl[risk]["clasess"][clase]["risks"][i].source + ".\n";

            if (!list[risk][clase].some((element) => element === list2)) {
              list[risk][clase].push(list2);
            }

            if (i !== percentRiskLvl[risk]["clasess"][clase]["risks"].length - 1) {
              totalRisksInClasess += ", ";
            }
          }

          for (let i = 0; i < percentRiskLvl[risk]["clasess"][clase]["controls"].length; i++) {
            const control = percentRiskLvl[risk]["clasess"][clase]["controls"][i];
            const controTitle = controlTitles[control];
            if (!!tmpChartTables[controlTables[clase]].titleTable) {
              let arrayTemp = tmpChartTables[controlTables[clase]].titleTable;
              let idx = arrayTemp.indexOf(controTitle); // Vidate if this current value existing on array titles
              if (idx == -1) {
                tmpChartTables[controlTables[clase]].titleTable.push(controTitle); // Table title
                let newidx = tmpChartTables[controlTables[clase]].titleTable.length - 1; // Capture new idx
                tmpChartTables[controlTables[clase]].table[0][newidx] = ""; // Empty initial value on table
              }
            } else if (!!!tmpChartTables[controlTables[clase]].titleTable.includes(controTitle)) {
              tmpChartTables[controlTables[clase]].titleTable = [
                ...tmpChartTables[controlTables[clase]].titleTable,
                controTitle,
              ];
            }
          }

          riskLvlMessage += totalRisksInClasess;

          if (risk === "I") {
            riskLvlMessage += `), los cuales se deben intervenir de manera prioritaria y las actividades se deben restringir hasta ejecutar controles en el peligro. \n`;
          } else if (risk === "II" || risk === "III") {
            riskLvlMessage += `), que deben intervenirse y adoptar medidas de control específicas. \n`;
          } else if (risk === "IV") {
            riskLvlMessage += `), se recomienda hacer seguimiento continuo y/o fortalecer control(es). \n`;
          }

          if (key === "admin") {
            adminRiskLvl[risk] = riskLvlMessage;
          } else if (key === "operative") {
            operativeRiskLvl[risk] = riskLvlMessage;
          }
        });

        list[risk][
          "titulo"
        ] = `Los peligros en el nivel de riesgo ${risk} están dados por peligros de orden:\n`;
      });
    });

    Object.values(controlTables).map(function (i) {
      if (!(tmpChartTables[i].titleTable && tmpChartTables[i].titleTable[0])) {
        tmpChartTables[i].table = false;
      }
    });

    Object.keys(adminRiskLvl).map(function (key) {
      if (adminRiskLvl[key] === 0) {
        adminRiskLvl[
          key
        ] = ` Nivel de Riesgo Tipo ${key}: 0% de existir en futuras intervenciones deben intervenirse y adoptar medidas de control específicas.\n`;
      }

      if (operativeRiskLvl[key] === 0) {
        operativeRiskLvl[
          key
        ] = ` Nivel de Riesgo Tipo ${key}: 0% de existir en futuras intervenciones deben intervenirse y adoptar medidas de control específicas.\n`;
      }
    });

    setContentS({
      ...contentS,
      /* riskLevels: [stringAdmin, stringOperative, msg], */
      riskLevels: [adminRiskLvl, operativeRiskLvl, list],
      correspondingClass: correspondingClass,
      correspondingClassTotal: correspondingClassTotal,
    });

    // tmpChartTables[0].table[0] = "Nivel de riesgo administrativo";
    // tmpChartTables[1].table[0] = "Nivel de riesgo operativo";

    Object.keys(data.jsonAnswer).map(function (key) {
      Object.keys(adminRiskLvl).map(function (risk, index) {
        let result;
        if (data.jsonAnswer[key].percentRiskLvl[risk] && data.jsonAnswer[key].totalEmployees != 0) {
          result =
            (data.jsonAnswer[key].percentRiskLvl[risk].total /
              data.jsonAnswer[key].totalEmployees) *
              100 +
            "";
        } else {
          result = "0";
        }

        if (key === "admin") {
          tmpChartTables[0].table[index] = [`Nivel ${risk}`, result];
        } else if (key === "operative") {
          tmpChartTables[1].table[index] = [`Nivel ${risk}`, result];
        }
      });
    });

    setChartTables([...tmpChartTables]);
  };

  const GeneratePDF = (key, value) => {
    setViewPDF(!viewPDF);
    let auxTable = [...chartTables];
    contentS.conclusions = value.conclution;
    contentS.resultsAnalysis = value.resultsAnalysis;
    if (!!value.set1) auxTable[7].table = value.set1.map((val) => Object.values(val));
    if (!!value.set2) auxTable[8].table = value.set2.map((val) => Object.values(val));
    if (!!value.set3) auxTable[9].table = value.set3.map((val) => Object.values(val));
    if (!!value.set4) auxTable[10].table = value.set4.map((val) => Object.values(val));
    if (!!value.set5) auxTable[11].table = value.set5.map((val) => Object.values(val));
    if (!!value.set6) auxTable[12].table = value.set6.map((val) => Object.values(val));
    if (!!value.set7) auxTable[13].table = value.set7.map((val) => Object.values(val));
    setChartTables(auxTable);
    GenerateData();
  };

  //19 dato a cambiar manual
  const GenerateData = function () {
    const { cover: cov, toc: t, content: cont } = data(init, contentS, chartTables);
    setCover(cov);
    setToc(t);
    setContent(cont);
  };

  const handleListPlan = () => {
    GET(
      `${process.env.REACT_APP_REPORT_ENDPOINT}/formless/data/plan/${location.state.idCompany}/${location.state.form_id}`
    ).then((result) => setListPlan(result.data.list));
  };

  const downloadWord = () => {
    var link = document.createElement("a");
    link.href = "https://www.ilovepdf.com/es/pdf_a_word";
    link.target = "_blank";
    link.click();
  };

  return (
    <Container className="container">
      <Row className="justify-content-center mb-2">
        <Col md={{ span: 5 }}>
          <h5 style={{ textAlign: "center", color: "#3A4297" }}>MATRIZ DE PELIGROS</h5>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={6}>
          {viewPDF && <PdfReportGenerator content={content} cover={cover} toc={toc} />}
        </Col>
        <Col md={6} className="d-flex flex-column align-items-center justify-center">
          <Button variant="info" className="mb-2 btn-word" onClick={downloadWord} block>
            Ir al convertidor
          </Button>

          <Button variant="info" className="mb-2" block onClick={handleListPlan}>
            Plan de mejoramiento
          </Button>
          <div className="table-responsive" style={{ maxHeight: "38rem" }}>
            <TablePdf headers={["A mejorar"]} content={listPlan} />
          </div>
          {isData ? (
            <FormDangerMatrix
              GeneratePDF={GeneratePDF}
              viewPDF={viewPDF}
              dataCharts={[...chartTables]}
            />
          ) : (
            <>
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
              Este proceso puede tardar algunos segundos. Por favor, no recargue la página.
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default PdfDangerMatrix;
