import React from "react";
import PropTypes from "prop-types";
import "./ButtonWithIcon.scss";
import { Button } from "react-bootstrap";

const ButtonWithIcon = ({ svg, text, color, clic, toogle = false }) => (
  <Button onClick={clic} variant="light" size="lg" disabled={toogle} block className="d-flex align-items-center bg-transparent">
    <img src={svg} alt={svg} style={{ width: "3em"}} className="mr-4" />
    <h4 style={{ color: color }}>{text}</h4>
  </Button>
);

ButtonWithIcon.propTypes = {};

ButtonWithIcon.defaultProps = {
  svg: PropTypes.object,
  text: PropTypes.string,
  clic: PropTypes.func,
  color: PropTypes.string,
};

export default ButtonWithIcon;
