import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./FormSelfEvaluation.scss";
import { Form, Button } from "react-bootstrap";
import ReactChart from "../ReactChart/ReactChart";

//import component


const FormSelfEvaluation = ({ GeneratePDF, dataCharts }) => {
  const [data, setData] = useState({
    conclution: "",
    resultsAnalysis: "",
    action: "",
    distributionCharges: [{ Columna1: "", Columna2: "", Columna3: "" }],
    distributionPersonalBackground: [{ Columna1: "", Columna2: "", Columna3: "" }],
  });
  const [chartsAllBolean, setchartsBolean] = useState([])
  /* const [inputArray, setInputArray] = useState([{ Dato1: "", Dato2: "", Dato3: "" }]);
  const [inputArray2, setInputArray2] = useState([{ Dato1: "", Dato2: "", Dato3: "" }]); */


  const handleSubmit = (event) => {
    event.preventDefault();
    GeneratePDF(1, data);

  };

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setData({ ...data, [name]: value });
  };

  //graficas
  useEffect(() => {
    data.analysis = dataCharts.map((val) => {
      return val.analysis;
    });
    setData({ ...data });
    setchartsBolean(dataCharts.map(val => !!val))
  }, [dataCharts]);

  const [arrayOfCharts, setArrayOfCharts] = useState([]);
  useEffect(() => {
    chart();
    setchartsBolean(chartsAllBolean.map(val => false));
  }, [data]);

  const chart = () => {
    let auxArrayOfCharts = [...arrayOfCharts];
    dataCharts.map((c, i) => {
      renderObjTable(c, i, auxArrayOfCharts);
    });
    setArrayOfCharts(auxArrayOfCharts)
  };

  const renderObjTable = async (value, index, auxArrayOfCharts) => {
    let arrayValue = value.table;
    let labelArray = arrayValue.map((x) => x[0]);
    let labelDataArray = arrayValue.map((x) => {
      return x[1].toString().replace(/%/, "").replace(/-/, "");
    });
    let labelDataArray2 = arrayValue[0][2]
      ? arrayValue.map((x) => {
        return x[2].toString().replace(/%/, "").replace(/-/, "");
      })
      : null;
    // console.log(value)
    let chartObj = {
      chartsData: {
        labels: labelArray,
        animation: {
          duration: 0,
        },
        hover: {
          animationDuration: 0, // duration of animations when hovering an item
        },
        responsiveAnimationDuration: 0, // animation duration after a resize
        datasets: [
          {
            label: "RESULTADO ESPERADO",
            data: labelDataArray,
            backgroundColor: "#33AEEF",
            borderWidth: 4,
            order: 2
          },
        ],
      },
      chartsOptions: {
        legend: { display: false, position: "bottom" },
        title: {
          display: false,
          text: "Grafica",
          fontSize: 20,
        }
      },
      height: value.height
    };
    if (labelDataArray2) {
      chartObj.chartsData.datasets.push({
        label: "RESULTADO OBTENIDO",
        data: labelDataArray2,
        backgroundColor: "#445588",
        borderWidth: 4,
        order: 1
      });
      chartObj.chartsOptions.legend = { display: true }
      if (index === 2) {
        chartObj.chartsOptions.scales = { xAxes: [{ ticks: { min: 0, max: 100 } }] }
      }
      else if (index == 3) {
        chartObj.chartsOptions.scale = { ticks: { beginAtZero: true, showLabelBackdrop: false, max: 100, stepSize: 20, /* maxTicksLimit:5  */ } }
        chartObj.chartsData.datasets.forEach(element => {
          element.borderColor = element.backgroundColor;
          element.fill = false;
        })
      }
    }
    else if (index === 1) {
      chartObj.chartsOptions.scales = { yAxes: [{ ticks: { min: 0, max: 100 } }] }
    }
    auxArrayOfCharts[index] = chartObj;
  };


  const [isDisableButton, setIsDisableButton] = useState(true);
  const updateChart = (indexId, isReady, auxChartsAllBolean) => {
    auxChartsAllBolean[indexId] = isReady;
    if (auxChartsAllBolean.length - 1 == indexId) {
      // console.log(auxChartsAllBolean);
      setIsDisableButton(auxChartsAllBolean.includes(false));
    }
  }

  const renderCharts = () => {
    const auxChartsAllBolean = [...chartsAllBolean];
    return arrayOfCharts.map((t, i) => (
      <ReactChart
        key={i}
        dataChartPdfReportGenerator={dataChartPdfReportGenerator}
        dataCharts={t.chartsData}
        optionsCharts={t.chartsOptions}
        value={i}
        typeP={i}
        isCharReady={updateChart}
        height={t.height}
        auxChartsAllBolean={auxChartsAllBolean}
      />
    ));
  };




  const dataChartPdfReportGenerator = async (data, i) => {
    const base64Image = data;
    dataCharts[i].chart = await base64Image;
  };
  //fin graficas

  return (
    <Form onSubmit={handleSubmit} className="w-100">
      <Button variant="danger" type="submit" disabled={isDisableButton} block>
        Visualizar PDF
      </Button>
      {renderCharts()}
    </Form>


  );
};

FormSelfEvaluation.propTypes = { GeneratePDF: PropTypes.func };

FormSelfEvaluation.defaultProps = { GeneratePDF: function blank(params) { } };

export default FormSelfEvaluation;
