import React, { useState, useEffect } from "react";
import "./DiagnosisCompany.scss";
import { Container, Row, Col } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";
import { POST, PUTNORMAL } from "../../constants/ApiRest";

// Import components
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import NavVertical from "../../components/NavVertical/NavVertical";
import ButtonWithIcon from "../../components/ButtonWithIcon/ButtonWithIcon";
import ArrowBack from "../../img/icons/iconArrowBack.svg";
import svg from "../../img/iconArrowDownload.svg";
import svg2 from "../../img/iconArrowUp.svg";
import iconHelp from "../../img/icons/iconHelp2.svg";
import ModalFileUp from "../../components/ModalFileUp/ModalFileUp";
import ModalUploadResponse from "../../components/ModalUploadResponse/ModalUploadResponse";

// TOOLS APIs
import GcpTools from "../../constants/GcpTools";

const DiagnosisCompany = ({ setLogin }) => {
  let location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [showModalResponse, setShowModalResponse] = useState(false);
  const [StateUpload, setStateUpload] = useState(true);
  const [errors, setErrors] = useState([]);
  const [code, setCode] = useState();
  const [idTemplate, setIdTemplate] = useState();
  const [template, setTemplate] = useState();
  const [loadingFile, setLoadingFile] = useState(false);

  const handleClose = () => setShowModal(false);

  useEffect(() => {
    setCode(location.state.ActiveKey);
  }, []);

  useEffect(() => {
    if (code === "Autoevaluacion") {
      idTemplateAutoEvaluation();
    } else if (code === "Matriz") {
      setIdTemplate(1);
    } else if (code === "Perfil") {
      setIdTemplate(2);
    } else if (code === "Morbilidad") {
      setIdTemplate(3);
    } else if (code === "Protocolo") {
      setIdTemplate(5);
    } else if (code === "Efectos") {
      setIdTemplate(4);
    }
  }, [code]);

  const downloadCode = (url) => {
    var link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.download = url.substr(url.lastIndexOf("/") + 1);
    link.click();
  };

  const idTemplateAutoEvaluation = async () => {
    let risk = location.state.dataCompany.risk_id;
    let works = location.state.dataCompany.workers;
    if (risk <= 3 && works <= 10) {
      return setIdTemplate(6);
    } else if (risk <= 3 && works > 10 && works <= 50) {
      return setIdTemplate(7);
    } else if (risk <= 5 && works >= 50) {
      return setIdTemplate(0);
    } else if (risk == 4 || (risk == 5 && works < 50)) {
      return setIdTemplate(0);
    }
  };

  const selectAutoEvaluation = async () => {
    let risk = location.state.dataCompany.risk_id;
    let works = location.state.dataCompany.workers;

    if (risk <= 3 && works <= 10) {
      return downloadCode(GcpTools.AUTO7E);
    } else if (risk <= 3 && works > 10 && works <= 50) {
      return downloadCode(GcpTools.AUTO21E);
    } else if (risk <= 5 && works > 50) {
      return downloadCode(GcpTools.AUTO60E);
    } else if (risk == 4 || (risk == 5 && works < 50)) {
      return downloadCode(GcpTools.AUTO60E);
    }
  };

  const downloadFileInstruction = async () => {
    switch (code) {
      case "Autoevaluacion":
        downloadCode(GcpTools.IN_AUTOE);
        break;
      case "Matriz":
        downloadCode(GcpTools.IN_MATRIZ_PELIGROS);
        break;
      case "Perfil":
        downloadCode(GcpTools.IN_SOCIODEMOGRAFICO);
        break;
      case "Morbilidad":
        downloadCode(GcpTools.IN_MORBILIDAD_SENTIDA);
        break;
      case "Protocolo":
        downloadCode(GcpTools.IN_PROTOCOLO_BIOSEGURIDAD);
        break;
      case "Efectos":
        downloadCode(GcpTools.IN_EFECTOS_PSICOSOCIALES);
        break;
      default:
        break;
    }
  };

  const downloadFile = async () => {
    switch (code) {
      case "Autoevaluacion":
        selectAutoEvaluation();
        break;
      case "Matriz":
        downloadCode(GcpTools.MATRIZ_PELIGROS);
        break;
      case "Perfil":
        downloadCode(GcpTools.SOCIODEMOGRAFICO);
        break;
      case "Morbilidad":
        downloadCode(GcpTools.MORBILIDAD_SENTIDA);
        break;
      case "Protocolo":
        downloadCode(GcpTools.PROTOCOLO_BIOSEGURIDAD);
        break;
      case "Efectos":
        downloadCode(GcpTools.EFECTOS_PSICOSOCIALES);
        break;
      default:
        break;
    }

    const data = {
      idCompany: location.state.idCompany,
      idForm: idTemplate === 6 || idTemplate === 7 ? 0 : idTemplate,
      status: "En ejecución",
    };

    const status = await PUTNORMAL(
      `${process.env.REACT_APP_COMPANY_ENDPOINT}/company/status/`,
      data
    );
  };

  async function upFile() {
    setShowModal(true);
  }

  async function acceptModal() {
    setLoadingFile(true);
    let formData = new FormData();
    formData.append("file", template);
    formData.append("idcompany", location.state.idCompany);
    let req = await POST(
      process.env.REACT_APP_FILE_LOADER_ENDPOINT + `upload/${idTemplate}`,
      formData
    );
    if (req.statusText === "OK") {
      setLoadingFile(false);
      if (req.data === "Información guardada") {
        setStateUpload(true);
        const data = {
          idCompany: location.state.idCompany,
          idForm: idTemplate === 6 || idTemplate === 7 ? 0 : idTemplate,
          status: "Ejecutado",
        };
        const status = await PUTNORMAL(
          `${process.env.REACT_APP_COMPANY_ENDPOINT}/company/status/`,
          data
        );
      } else {
        setLoadingFile(false);
        setErrors(req.data);
        setStateUpload(false);
      }
    } else {
      setLoadingFile(false);
      setStateUpload(false);
    }

    if (req.statusText !== "OK") {
      setLoadingFile(false);
      setLogin(false);
    }

    stateUploadFileShow();
    setShowModal(false);
  }

  function stateUploadFileShow() {
    setShowModalResponse(true);
  }
  function stateUploadFileClose() {
    setShowModalResponse(false);
  }

  return (
    <div className="DiagnosisCompany">
      <Container>
        <Row>
          <Col>
            <Breadcrumbs
              view={[
                { name: "Inicio", path: "/graphics" },
                { name: location.state.title || "Empresa", path: "/diagnose" },
                { name: "Diagnóstico", path: "/diagnosis" },
              ]}
            ></Breadcrumbs>
            <NavLink to="/init/diagnose" className="styleArrowLink" style={{ width: "100px" }}>
              <img src={ArrowBack} width="50" />
            </NavLink>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={6}>
            <h5 className="sm-h-titleLogin text-left">Empresa: {location.state.title || ""}</h5>
            <NavVertical Active={location.state.ActiveKey} setCode={setCode}></NavVertical>
          </Col>
          <Col md={6}>
            <h2 className="sm-h-titleLogin text-center">Herramientas de Gestión SST</h2>
            <p className="sm-text text-center">
              Estas son las herramientas de gestión técnica en SST las cuales debe aplicar en cada
              empresa, descargando, diligenciando y cargando correctamente los archivos Excel de
              cada plantilla.
            </p>
            <ButtonWithIcon
              /* clic={stateUploadFileShow} */
              clic={downloadFile}
              svg={svg}
              text="Descargar Plantilla para Completar"
              color="#253b82"
            />
            <ButtonWithIcon
              /* clic={stateUploadFileShow} */
              clic={downloadFileInstruction}
              svg={iconHelp}
              text="Instructivo para llenar plantillas"
              color="#162b75"
            />

            <ButtonWithIcon
              clic={upFile}
              svg={svg2}
              text="Subir Plantilla Terminada"
              color="#0b193f"
            />
          </Col>
        </Row>
      </Container>

      {showModal && (
        <ModalFileUp
          show={showModal}
          handleClose={handleClose}
          url=""
          msg=""
          title=""
          acceptModal={acceptModal}
          setTemplate={setTemplate}
          loadingFile={loadingFile}
        />
      )}
      {showModalResponse && (
        <ModalUploadResponse
          show={showModalResponse}
          url=""
          // title={"Se ha subido el Archivo con éxito"}
          handleClose={stateUploadFileClose}
          state={StateUpload}
          error={errors}
        />
      )}
    </div>
  );
};

DiagnosisCompany.propTypes = {};

DiagnosisCompany.defaultProps = {};

export default DiagnosisCompany;
