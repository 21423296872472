import React, { useEffect, useRef } from "react";
import { Bar, HorizontalBar, Radar, Pie } from "react-chartjs-2";
import 'chartjs-plugin-datalabels';

const  getColors = (length)=>{
  const colorPalette = ['#33AEEF','#F0BF1A','#3E54F0','#F0774A','#26F0CA', '#0f7c40', '#df2222', '#da35ce', '#7c1bad', '#dae539', '#b9b4e9']
  let colors = [];
  for(let i = 0; i < length; i++) {
    colors.push(colorPalette[i % colorPalette.length]);
  }
  return colors;
}

const ReactChart = ({
  dataChartPdfReportGenerator,
  dataCharts,
  optionsCharts,
  value,
  typeP = 1,
  isPercentage=true,
  isCharReady=()=>{console.log('si se puede')},
  height=600,
  auxChartsAllBolean
}) => {
  const chartRef = useRef(null);
  optionsCharts.plugins= {
    datalabels: {
       display: typeP!=3,
       color: 'black',
       formatter: function (value) {
         return `${Math.round(value)}${(isPercentage?'%':'')}`;
      },
      font: {
        weight: 'bold',
        size: 16,
      }
    }
 }
 async function  sendData()  {
    const base64Image = await chartRef.current.chartInstance.toBase64Image(); //png to base64
    await dataChartPdfReportGenerator(base64Image, value); //pasar data del form al component padre Login
    isCharReady(value,true, auxChartsAllBolean)
  }

  // useEffect(() => {
  //   isCharReady(value,false)
  // }, [dataCharts])

  const renderChart = () => {
    switch (typeP) {
      case 0:
      case 1:
        return (
          <Bar
            data={dataCharts}
            options={{
              ...optionsCharts,
              title:{display:false},
              bezierCurve: false,
              animation: { onComplete: () => sendData(), onProgress: ({currentStep}) => {}},//if(currentStep<=1)isCharReady(value,false)}},
            }}
            ref={chartRef}
          />
        );
        break;
      case 2:
        return (
          <HorizontalBar
            data={dataCharts}
            options={{
              ...optionsCharts,
              title:{display:false},
              bezierCurve: false,
              animation: { onComplete: () => sendData(), onProgress: ({currentStep}) => {}},//if(currentStep<=1)isCharReady(value,false)}},
            }}
            ref={chartRef}
          />
        );
        break;
      case 3:
        return (
          <Radar
            data={dataCharts}
            options={{
              ...optionsCharts,
              title:{display:false},
              bezierCurve: false,
              animation: { onComplete: () => sendData(), onProgress: ({currentStep}) => {}},//if(currentStep<=1)isCharReady(value,false)}},
            }}
            ref={chartRef}
          />
        );
        break;
      case 4:{
        dataCharts.datasets = dataCharts.datasets.map(d=>{return {...d,backgroundColor: getColors(d.data.length)}})
        return (
          <Pie
            data={dataCharts}
            options={{
              ...optionsCharts,
              title:{display:false},
              bezierCurve: false,
              animation: { onComplete: () => sendData(), onProgress: ({currentStep}) => {}},//if(currentStep<=1)isCharReady(value,false)}},
            }}
            ref={chartRef}
          />
        );
      }
    break;
    }
  };

  return (
    <div
      className="position-absolute"
      style={{ top: -50000000, opacity: 0, width: 800, height }}
    >
      {renderChart()}
    </div>
  );
};

export default ReactChart;

