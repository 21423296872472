const morbility = (
  init = {
    name: "",
    date: "/ /",
    department: "",
    city: "",
    lic: "",
  },
  content = {
    CompanyName: "",
    NIT: "",
    CIIU: "",
    questAplication: "",
    questAplicationNum: "",
    questPorcent: "",
    conclusions: "",
    sector: "",
  },
  chartTables = [
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: `Del total de la población encuestada, se aplicaron ${chartTables[1].table.reduce(
        (a, [c, d]) => (a[0] || a) + c
      )} cuestionarios nórdicos de los cuales el (% de genero femenino) (# total en número del género femenino) son trabajadores de género femenino y el ((% de género masculino) (# total en número del género masculino) de los trabajadores son del género masculino.`,
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
  ]
) => {
  return {
    cover: [
      "INFORME TÉCNICO ENCUESTAS DE MORBILIDAD SENTIDA",
      init.name.toUpperCase(),
      `ASESORADO POR: \nFUNDACIÓN NUEVO HORIZONTE\n LICENCIA SST ${init.lic.toUpperCase()}`,
      // `${init.department.toUpperCase()}\n${init.city.toUpperCase()}\n${init.date.toUpperCase()}`,
      init.date.toUpperCase(),
    ],
    toc: [
      {
        name: "TABLA DE CONTENIDO",
        children: [
          {
            name: `\n 1. IDENTIFICACIÓN DE LA EMPRESA`,
            page: 3,
          },
          {
            name: "2. INTRODUCCIÓN",
            page: 3,
          },
          {
            name: "3. OBJETIVO GENERAL",
            page: 3,
            children: [
              {
                name: "3.1. OBJETIVOS ESPECÍFICOS",
                page: 4,
              },
            ],
          },
          {
            name: "4. MARCO TEORICO",
            page: 4,
            children: [
              {
                name: "4.1 MARCO NORMATIVO EN COLOMBIA",
                page: 6,
              },
              {
                name: "4.2 MARCO CONCEPTUAL",
                page: 6,
              },
            ],
          },
          {
            name: "5. METODOLOGÍA",
            page: 7,
          },
          {
            name: "6. RESULTADOS",
            page: 8,
            children: [
              {
                name: "6.1. DATOS GENERALES",
                page: 8,
              },
              {
                name: "6.2. SINTOMATOLOGÍA",
                page: 13,
              },
              {
                name: "6.3. CLASIFICACIÓN",
                page: 20,
              },
            ],
          },
          {
            name: "7. CONCLUSIONES",
            page: 23,
          },
          {
            name: "8. RECOMENDACIONES",
            page: 25,
          },
          {
            name: "9. BIBLIOGRAFÍA",
            page: 31,
          },
        ],
      },
    ],
    content: [
      {
        type: "text",
        value: "1. IDENTIFICACIÓN DE LA EMPRESA",
        text_type: 6,
      },
      {
        type: "table",
        value: {
          headers: [],
          weights: [0.35],
          rows: [
            { col1: "RAZÓN SOCIAL:", col2: `${content.CompanyName.toUpperCase()}` },
            { col1: "NIT:", col2: `${content.NIT.toUpperCase()}` },
            { col1: "Departamento:", col2: `${init.department.toUpperCase()}` },
            { col1: "Municipio:", col2: `${init.city}` },
            { col1: "Sector:", col2: `${content.sector.toUpperCase()}` },
          ],
        },
        name_table: "",
      },
      {
        type: "text",
        value: "2. INTRODUCCION",
        text_type: 6,
      },
      {
        type: "text",
        value: `Los trabajadores constituyen la mitad de la población del mundo y son los máximos contribuyentes al desarrollo económico y social. Su salud no está condicionada sólo por los peligros en el lugar de trabajo, sino también por factores sociales e individuales que pueden desencadenar posibles alteraciones en su estado de salud.`,
      },
      {
        type: "text",
        value: `Por esta razón las empresas han creado intervenciones eficaces para proteger y promover la salud en el lugar de trabajo, por medio de estrategias que ayuden a identificar las condiciones actuales y así mismo priorizar hacia las principales condiciones que pueden generar y desencadenar posibles alteraciones en la salud tanto física como mental de los trabajadores.`,
      },
      {
        type: "text",
        value: `En esta oportunidad se realiza el diagnóstico de la población trabajadora a través del desarrollo de la encuesta de morbilidad sentida siendo esta un dato estadístico importante para comprender la evolución o retroceso de alguna enfermedad, las razones de su surgimiento y las posibles soluciones, para priorizar las condiciones osteomusculares y posteriormente redactar informe con descripción de los hallazgos y las recomendaciones para el plan de acción a implementar sobre los aspectos evaluados.`,
      },
      {
        type: "text",
        value: `Con los resultados consolidados de las evaluaciones realizadas a los trabajadores se orientarán las decisiones administrativas y las acciones en salud y seguridad, facilitando el direccionamiento óptimo de los trabajadores.`,
      },
      {
        type: "text",
        value: "3. OBJETIVO GENERAL",
        text_type: 6,
      },
      {
        type: "text",
        value: `Emitir un concepto técnico y general de las condiciones del sistema musculoesquelético a través del auto reporte de sintomatología de los trabajadores, pertenecientes a la empresa ${init.name.toUpperCase()} perteneciente al municipio de ${
          init.city
        } del departamento de ${init.department.toUpperCase()} durante el año 2022, basados en los datos recopilados según aplicación de cuestionario nórdico ajustado.`,
      },
      {
        type: "text",
        value: `\n3.1 OBJETIVOS ESPECÍFICOS`,
        text_type: 6,
      },
      {
        type: "list",
        value: `Proveer información sobre la sintomatología actual del trabajador, según su auto reporte en el cuestionario nórdico ajustado.`,
      },
      {
        type: "list",
        value: `Clasificar a los trabajadores según el nivel de riesgo individual y el cargo u ocupación a desempeñar para categorizar las prioridades en el plan de acción.`,
      },
      {
        type: "list",
        value: `Emitir el concepto técnico, de las condiciones del sistema musculo esquelético a partir de los resultados del cuestionario nórdico ajustado.`,
      },
      {
        type: "list",
        value: `Realizar un plan de acción ajustado a las necesidades de la empresa de acuerdo a los hallazgos encontrados en el análisis del auto reporte de los trabajadores encuestados.`,
      },
      {
        type: "text",
        value: "4. MARCO TEORICO",
        text_type: 6,
      },
      {
        type: "text",
        value: `La prevención se define como “Medidas destinadas no solamente a prevenir la aparición de la enfermedad, tales como la reducción de factores de riesgo, sino también a detener su avance y atenuar sus consecuencias una vez establecida” (OMS ,1998), también se define como “Acciones orientadas a la erradicación, eliminación, o minimización del impacto de la enfermedad y la discapacidad». Incluye medidas sociales, políticas, económicas y terapéuticas.`,
      },
      {
        type: "text",
        value: `Cada persona tiene la probabilidad de adquirir diversas alteraciones de salud dependiendo de sus factores individuales, sociales y relación con el medio así mismo como los DME, relacionados con el trabajo, los cuales comprenden un grupo heterogéneo de diagnósticos que incluyen alteraciones de músculos, tendones, vainas tendinosas, síndromes de atrapamientos nerviosos, alteraciones articulares y neurovasculares. Hacen parte de un grupo de condiciones que la Organización Mundial de la Salud (OMS) define como “Desórdenes relacionados con el trabajo”, porque ellos pueden ser causados tanto por exposiciones ocupacionales como por exposiciones no ocupacionales.`,
      },
      {
        type: "text",
        value: `A pesar de esto, existe un número importante de estudios epidemiológicos que muestran evidencia de asociación entre varios DME y factores físicos relacionados con el trabajo o una combinación de factores (NAS 1998 Bernard 1997). La dificultad radica en que la evaluación del riesgo aportada en los trabajos varía desde el auto reporte de los trabajadores hasta la realización de estudios ergonómicos altamente complejos y tecnificados. Incluyen actividades prolongadas y repetitivas, ejercitación fuerte, posturas estáticas o forzadas, vibración, estrés físico localizado, vibración y temperaturas bajas (Bernard 1997). En otros estudios se han relacionado factores de la organización del trabajo con síntomas en los miembros superiores (Bonger 1993, Moon y Sauter 1996) VernPutz – Anderson (1994) definió el daño como trauma acumulado y las denominó Lesiones por Trauma Acumulativo o LTA, otra denominación frecuente de estas entidades. Esta nominación combina el concepto de “acumulación” que indica que la lesión se ha desarrollado gradualmente a través de un período de tiempo, como resultado de un esfuerzo repetido en alguna parte del cuerpo. Este concepto se basa en la teoría de que cada repetición de alguna actividad produce algún micro-trauma resultado del deterioro de la estructura.`,
      },
      {
        type: "text",
        value: `La Conferencia Internacional del Trabajo realizada en 2002 conceptualizó como enfermedades del sistema músculo-esquelético aquéllas causadas por determinadas actividades laborales o por los factores de riesgo presentes en el ambiente de trabajo, como movimientos rápidos o repetitivos, esfuerzos excesivos y concentración de fuerzas mecánicas, posturas incorrectas o sin neutralidad, vibraciones y presencia de frío en el ambiente de trabajo. Inicialmente el trabajador puede manifestar solamente cansancio y dolores al final del turno de trabajo, pero conforme evoluciona el cuadro pasa a presentar periodos álgidos y debilidad en la región corporal afectada, transformándose en una lesión permanente que lo incapacita para ejecutar su actividad laboral.`,
      },
      {
        type: "text",
        value: `Entre las múltiples repercusiones ocasionadas por las lesiones músculo esqueléticas entre los trabajadores, debido a los factores ocupacionales, se distinguen básicamente: la modificación de la calidad de vida del trabajador, el ausentismo y la disminución productiva, las incapacidades temporales o permanentes, el aumento de los costos económicos, de los cuidados a la salud, los cambios en las perspectivas y actitudes psicosociales individuales, familiares y sociales. Esas lesiones se manifiestan en personas de ambos generos, de cualquier edad, acentuándose en las edades de mayor productividad económica, cuando las condiciones en el lugar de trabajo no son una garantía de comodidad, productividad, seguridad y salud.`,
      },
      {
        type: "text",
        value: `Además a esto, los DME relacionadas con el trabajo se refiere a todas aquellas en las que el medio ambiente laboral y la ejecución del trabajo impliquen diferentes mecanismos que influyen para la contribución significativa de lesiones osteomusculares, ya sean por repetición, fuerza y posturas inadecuadas o prolongados de tiempo, las cuales pueden llegar a aumentar su evolución por las condiciones individuales de trabajo.`,
      },
      {
        type: "text",
        value: `Los DME relacionados con el trabajo comprenden un grupo heterogéneo de diagnósticos que incluyen alteraciones de músculos, tendones, vainas tendinosas, síndromes de atrapamientos nerviosos, alteraciones articulares y neurovasculares, hacen parte de un grupo de condiciones que la Organización Mundial de la Salud (OMS) define como “Desórdenes relacionados con el trabajo”, porque ellos pueden ser causados tanto por exposiciones ocupacionales como por exposiciones no ocupacionales.`,
      },
      {
        type: "list",
        value:
          "Los factores individuales: capacidad funcional del trabajador, hábitos, antecedentes., etc.",
      },
      {
        type: "list",
        value: "Los factores ligados a las condiciones de trabajo: fuerza, posturas y movimientos.",
      },
      {
        type: "list",
        value:
          "Los factores organizacionales: organización del trabajo, jornadas, horarios, pausas, ritmo y carga de trabajo.",
      },
      {
        type: "list",
        value:
          "Los factores relacionados con las condiciones ambientales de los puestos y sistemas de trabajo: temperatura, vibración entre otros.",
      },
      {
        type: "text",
        value: `\n4.1. MARCO NORMATIVO EN COLOMBIA`,
        text_type: 6,
      },
      {
        type: "table",
        value: {
          headers: ["NORMA", "INSTITUCIÓN", "TITULO", "AÑO"],
          weights: [0.15, 0.25, 0.5, 0.1],
          rows: [
            {
              col1: "Ley 9",
              col2: "Ministerio de salud",
              col3: "Normas para preservar, conservar y mejorar la salud de los individuos en sus ocupaciones",
              col4: "1979",
            },
            {
              col1: "Decreto Ley 1295",
              col2: "Ministerio de la protección social",
              col3: "Por la cual se determina la organización y administración del sistema general de riesgos profesionales",
              col4: "1994",
            },
            {
              col1: "Decreto 614",
              col2: "Ministerio de trabajo y seguridad social",
              col3: "Bases para la organización y administración de salud ocupacional en el país",
              col4: "1984",
            },
            {
              col1: "Resolución 2844",
              col2: "Ministerio de la protección social",
              col3: "Guías de atención integral de salud ocupacional basadas en la evidencia",
              col4: "2006",
            },
            {
              col1: "Resolución 1013",
              col2: "Ministerio de la protección social",
              col3: "Guías de atención integral de salud ocupacional basadas en la evidencia",
              col4: "2008",
            },
            {
              col1: "Decreto 1072",
              col2: "Ministerio del trabajo",
              col3: "Por medio del cual se expide el Decreto Único Reglamentario del Sector Trabajo, capitulo 6, sistema de gestión de la seguridad y salud en el trabajo.",
              col4: "2015",
            },
          ],
        },
        name_table: "Tabla Marco Normativa en Colombia",
      },
      {
        type: "text",
        value: "4.2. MARCO CONCEPTUAL",
        text_type: 7,
      },
      {
        type: "text",
        value:
          "Cuestionario Nórdico: es una herramienta usada para la detección de síntomas músculo esqueléticos como el dolor, el malestar, el entumecimiento u hormigueo.",
      },
      {
        type: "text",
        value:
          "Morbilidad sentida: Definición dada por el individuo a partir de la percepción de síntomas y molestias (Callagham, 2014).",
      },
      {
        type: "text",
        value:
          "Recurso humano: Se denomina recursos humanos (Rh) al trabajo que aporta el conjunto de los empleados o colaboradores de esa organización, pero lo más frecuente es llamar así a la función que se ocupa de seleccionar, contratar, formar, emplear y retener a los colaboradores de laorganización. (Callagham, 2014).",
      },
      {
        type: "text",
        value:
          "Estrés: del latín stringere <<apretar>>, a través de su derivado en inglés stress 'fatiga de material') es una reacción fisiológica del organismo en el que entran en juego diversos mecanismos de defensa para afrontar una situación que se percibe como amenazante o de demanda incrementada. (Salud180, s.f.).",
      },
      {
        type: "text",
        value:
          "Desórdenes Musculoesqueléticos: Los trastornos musculo esqueléticos normalmente afectan a la espalda, cuello, hombros y extremidades superiores, aunque también pueden afectar a las extremidades inferiores. Comprenden cualquier daño o trastorno de las articulaciones y otros tejidos. Los problemas de salud abarcan desde pequeñas molestias y dolores a cuadros médicos más graves que obligan a solicitar la baja laboral e incluso a recibir tratamiento médico. En los casos más crónicos, pueden dar como resultado una discapacidad y la necesidad de dejar de trabajar. (Agencia Europea para la salud y el trabajo, 2015).",
      },
      {
        type: "text",
        value:
          "Dentro de la indagación propuesta se encuentran los elementos relacionados con la presión de trabajo para la obtención de resultados según las metas de productividad propuesta, que genera problemas musculares de tensión y movimiento osteomuscular, así mismo se da por trastornos musculoesqueléticos, son lesiones y trastornos que afectan el movimiento del cuerpo humano o del sistema musculoesquelético (es decir, músculos, tendones, ligamentos, nervios, discos, vasos sanguíneos, etc.), Estos trastornos incluyen:",
      },
      {
        type: "text",
        value: `
  a. Síndrome del túnel carpiano
  b. Tendinitis
  c. Tensión muscular / tendinosa
  d. Esguince de ligamentos
  e. Síndrome del cuello tensionado
  f. Compresión de salida torácica
  g. Tendinitis del manguito rotador
  h. Epicondilitis
  i. Síndrome del túnel radial
  j. Neuritis digital
  k. Dedo de gatillo / pulgar
  l. Síndrome de De Quervain
  m. Síndrome mecánico de la espalda
  n. Enfermedad degenerativa del disco
  o. Disco herniado / con ruptura
  `,
      },
      {
        type: "text",
        value: "5. METODOLOGÍA",
        text_type: 6,
      },
      {
        type: "text",
        value: `Durante la fecha de ${content.questAplication.toUpperCase()}, se realizó aplicación de Cuestionario Nórdico ajustado en la empresa ${init.name.toUpperCase()} del municipio de ${
          init.city
        } del departamento del ${init.department.toUpperCase()} a ${
          content.questAplicationNum
        } trabajadores, correspondiendo a una muestra del ${
          content.questPorcent
        }%, el profesional a cargo realiza el diligenciamiento del cuestionario nórdico ajustado por medio del aplicativo SMART SST.`,
      },
      {
        type: "text",
        value: `Para la coordinación de la actividad se establece contacto con la persona encargada del SGSST de la empresa.`,
      },
      {
        type: "text",
        value:
          "La encuesta de morbilidad sentida ajustada nos brinda resultados subjetivos acerca del estado de salud osteomuscular del trabajador, y determina unos resultados del estado general con respecto a sintomatología osteomuscular del total de los trabajadores de la empresa.",
      },
      {
        type: "text",
        value:
          "La aplicación del cuestionario nórdico ajustado se realiza de manera individual tiene una duración de 15 minutos aproximadamente por persona, en este se registraron inicialmente datos generales del trabajador donde se tiene en cuenta información tal como: rango de edad, género, estatura, peso para determinar IMC (Índice de Masa Corporal), cargo, área y posteriormente se hacen preguntas relacionadas con la sintomatología osteomuscular.",
      },
      {
        type: "text",
        value:
          "Se pasa al análisis, interpretación e integración de los resultados, para ello se hicieron las conexiones de las categorías y las subcategorías, haciéndose una verificación con el marco conceptual existente, por medio de búsquedas en bases de datos, gráficas y análisis de la información para posterior entrega de recomendaciones y plan de acción empresarial.",
      },
      {
        type: "text",
        value: "6. RESULTADOS",
        text_type: 6,
      },
      {
        type: "text",
        value: `Se evaluaron ${
          content.questAplicationNum
        } trabajadores pertenecientes a la empresa ${init.name.toUpperCase()} perteneciente al municipio de ${
          init.city
        } del a ${init.department.toUpperCase()} la cual hace parte del sector de ${content.sector.toUpperCase()}.`,
      },
      {
        type: "text",
        value: "A continuación, se presentan los resultados obtenidos:",
      },
      {
        type: "text",
        value: "\n\n\n\n\n\n\n\n\n6.1 DATOS GENERALES:",
        text_type: 6,
      },
      {
        type: "text",
        value: "Gráfico 1: Distribución por género",
        text_type: 8,
      },
      {
        type: "image",
        value: `${chartTables[0].chart}`,
      }, // Gráfico 1: Distribución por género
      {
        type: "table",
        value: {
          headers: ["GÉNERO", "CANTIDAD"],
          rows: chartTables[1].table.map((val) => {
            return { col1: val[0], col2: val[1] };
          }),
        },
        name_table: `Tabla 1. Distribución por género`,
      }, // Tabla 1: Distribución por género"
      {
        type: "text",
        value: `\n${chartTables[1].analysis}`,
      },
      {
        type: "text",
        value: "\n\n\n\n\n\nGráfico 2: Rango de edad",
        text_type: 8,
      },
      {
        type: "image",
        value: `\n${chartTables[2].chart}`,
      }, // Gráfico 2: Rango de edad
      {
        type: "table",
        value: {
          headers: ["RANGO", "TOTAL"],
          rows: chartTables[2].table.map((val) => {
            return { col1: val[0], col2: val[1] };
          }),
        },
        name_table: `Tabla 2. Rango de edad`,
      }, // Tabla 2: Rango de edad
      {
        type: "text",
        value: `\n${chartTables[2].analysis}`,
      },
      {
        type: "text",
        value: "Gráfico 3: Índice de masa corporal",
        text_type: 8,
      },
      {
        type: "image",
        value: `\n${chartTables[3].chart}`,
      }, // Tabla 3: Índice de masa corporal
      {
        type: "table",
        value: {
          headers: ["IMC", "TOTAL"],
          rows: chartTables[3].table.map((val) => {
            return { col1: val[0], col2: val[1] };
          }),
        },
        name_table: `Tabla 3. Índice de masa corporal`,
      }, // Gráfico 3: Índice de masa corporal
      {
        type: "text",
        value: `\n${chartTables[3].analysis}`,
      },
      {
        type: "text",
        value: "Gráfico 4: Área de trabajo",
        text_type: 8,
      },
      {
        type: "image",
        value: `${chartTables[4].chart}`,
      }, // Gráfico 4: Área de trabajo
      {
        type: "table",
        value: {
          headers: ["ÁREA DE TRABAJO", "TOTAL"],
          rows: chartTables[21].table.map((val) => {
            return { col1: val[0], col2: val[1] };
          }),
        },
        name_table: `Tabla 4. Área de trabajo`,
      }, // Tabla 4: Área de trabajo
      {
        type: "text",
        value: `\n${chartTables[21].analysis}`,
      },
      {
        type: "text",
        value: "\n\n\n\n\n\n\n\n\n\nGráfico 5: Cargo",
        text_type: 8,
      },

      {
        type: "image",
        value: `${chartTables[5].chart}`,
      }, // Gráfico 5: Cargo
      {
        type: "table",
        value: {
          headers: ["CARGO", "TOTAL"],
          rows: chartTables[22].table.map((val) => {
            return { col1: val[0], col2: val[1] };
          }),
        },
        name_table: "Tabla 5. Cargo",
      }, // Tabla 5: Cargo
      {
        type: "text",
        value: `\n${chartTables[22].analysis}`,
      },
      {
        type: "text",
        value: "\n\n\n\n\n\n\n\n\n\n\n\n\n6.2 SINTOMATOLOGÍA",
        text_type: 6,
      },
      {
        type: "text",
        value: "Gráfico 6: Cuello y espalda",
        text_type: 8,
      },
      {
        type: "image",
        value: `${chartTables[6].chart}`,
      }, // Gráfico 6: Cuello y espalda

      {
        type: "table",
        value: {
          headers: ["", "SI", "NO", "Total"],
          rows: chartTables[6].table.map((val) => {
            return { col1: val[0], col2: val[1], col3: val[2], col4: val[3] };
          }),
        },
        name_table: "Tabla 6. Cuello y espalda",
      }, // Tabla 6. Cuello y espalda

      {
        type: "text",
        value: `\n${chartTables[6].analysis}`,
      },
      {
        type: "text",
        value: "Gráfico 7: Sintomatología en Miembros superiores",
        text_type: 8,
      },

      {
        type: "image",
        value: `${chartTables[7].chart}`,
      }, // Gráfico 7: Sintomatología en Miembros superiores

      {
        type: "table",
        value: {
          weights: ["1", "0.8", "1", "0.7", "0.3", "1"],
          headers: [
            "MIEMBROS SUPERIORES",
            "DERECHO",
            "IZQUIERDO",
            "AMBOS",
            "NO",
            "TOTAL SÍNTOMAS",
          ],
          rows: chartTables[7].table.map((val) => {
            return {
              col1: val[0],
              col2: val[1],
              col3: val[2],
              col4: val[3],
              col5: val[4],
              col6: val[5],
            };
          }),
        },
        name_table: "Tabla 7. Sintomatología en miembros superiores",
      }, // Tabla 7: Sintomatología en Miembros superiores

      {
        type: "text",
        value: `\n${chartTables[7].analysis}`,
      },

      {
        type: "text",
        value: "\n\n\n\n\n\n\n\n\nGráfico 8: Sintomatología en Miembros inferiores",
        text_type: 8,
      },
      {
        type: "image",
        value: `${chartTables[8].chart}`,
      }, // Gráfico 8: Sintomatología en Miembros inferiores

      {
        type: "table",
        value: {
          weights: ["1", "0.8", "1", "0.7", "0.3", "1"],
          headers: ["MIEMBROS INFERIORES", "DERECHO", "IZQUIERDO", "AMBOS", "NO", "TOTAL SÍNTOMAS"],
          rows: chartTables[8].table.map((val) => {
            return {
              col1: val[0],
              col2: val[1],
              col3: val[2],
              col4: val[3],
              col5: val[4],
              col6: val[5],
            };
          }),
        },
        name_table: "Tabla 8: Sintomatología en miembros inferiores",
      }, // Tabla 8: Sintomatología en miembros inferiores

      {
        type: "text",
        value: `\n${chartTables[8].analysis}`,
      },
      {
        type: "text",
        value:
          "Gráfico 9: Impedimento para realizar su rutina habitual generado por la sintomatología",
        text_type: 8,
      },

      {
        type: "image",
        value: chartTables[9].chart,
      }, // Gráfico 9: Impedimento para realizar su rutina habitual generado por la sintomatología

      {
        type: "table",
        value: {
          headers: ["SEGMENTO CORPORAL", "SI", "NO", "N.A", "Total"],
          rows: chartTables[9].table.map((val) => {
            return { col1: val[0], col2: val[1], col3: val[2], col4: val[3], col5: val[4] };
          }),
        },
        name_table:
          "\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\nTabla 9: Impedimento para realizar su rutina habitual generada por la sintomatología",
      }, // Tabla 9: Impedimento para realizar su rutina habitual generada por la sintomatología
      {
        type: "text",
        value: `\n${chartTables[9].analysis}`,
      },

      {
        type: "text",
        value: "\n\n\n\n\n\n\n\n\nGráfico 10: Días que ha tenido las molestias en los últimos 12 meses",
        text_type: 8,
      },
      {
        type: "image",
        value: `${chartTables[10].chart}`,
      }, //Gráfico 10: Días que ha tenido las molestias en los últimos 12 meses
      {
        type: "table",
        value: {
          weights: ["1", "0.8", "1", "0.7", "0.7", "0.7"],
          headers: [
            "SEGMENTO CORPORAL",
            "ENTRE 1 - 7 DÍAS",
            "ENTRE 8 A 30 DÍAS",
            "MAS DE 30 DÍAS",
            "SIEMPRE",
            "TOTAL",
          ],
          rows: chartTables[10].table.map((val) => {
            return {
              col1: val[0],
              col2: val[1],
              col3: val[2],
              col4: val[3],
              col5: val[4],
              col6: val[5],
            };
          }),
        },
        name_table: "\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\nTabla 10: Días que ha tenido las molestias en los últimos 12 meses",
      }, // Tabla 10: Días que ha tenido las molestias en los últimos 12 meses
      {
        type: "text",
        value: `\n${chartTables[10].analysis}`,
      },
      {
        type: "text",
        value: "\n\n\n\n\n\n\n\n\nGráfico 11: Molestia que se ha presentado en los últimos 7 días",
        text_type: 8,
      },
      {
        type: "image",
        value: `${chartTables[11].chart}`,
      }, // Gráfico 11: Molestia que se ha presentado en los últimos 7 días

      {
        type: "table",
        value: {
          headers: ["SINTOMATOLOGÍA DURANTE LOS ÚLTIMOS 7 DÍAS", "Total"],
          rows: chartTables[12].table.map((val) => {
            return { col1: val[0], col2: val[1] };
          }),
        },
        name_table: "Tabla 11. Molestia que se ha presentado en los últimos 7 días",
      }, // Tabla 11. Molestia que se ha presentado en los últimos 7 días

      {
        type: "text",
        value: `\n${chartTables[12].analysis}`,
      },

      {
        type: "text",
        value: "\n\n\n\n\nGráfico 12: Ha recibido tratamiento por estas molestias",
        text_type: 8,
      },
      {
        type: "image",
        value: `${chartTables[13].chart}`,
      }, //Gráfico 12: Ha recibido tratamiento por estas molestias
      {
        type: "table",
        value: {
          headers: ["TRATAMIENTO POR LAS MOLESTIAS MANIFESTADAS", "Total"],
          rows: chartTables[14].table.map((val) => {
            return { col1: val[0], col2: val[1] };
          }),
        },
        name_table: "Tabla 12. Ha recibido tratamiento por estas molestias",
      }, // Tabla 12. Ha recibido tratamiento por estas molestias
      {
        type: "text",
        value: `\n${chartTables[14].analysis}`,
      },
      {
        type: "text",
        value: "6.3 CLASIFICACIÓN",
        text_type: 6,
      },
      {
        type: "text",
        value: "Gráfico 13: Clasificación del dolor",
        text_type: 8,
      },
      {
        type: "image",
        value: `${chartTables[15].chart}`,
      }, // Gráfico 13: Clasificación del dolor
      {
        type: "table",
        value: {
          weights: ["1", "0.8", "0.6", "1", "0.7", "0.7"],
          headers: ["SEGMENTO CORPORAL", "SIN MOLESTIA", "LEVE", "MODERADO", "MUY FUERTE", "TOTAL"],
          rows: chartTables[15].table.map((val) => {
            return {
              col1: val[0],
              col2: val[1],
              col3: val[2],
              col4: val[3],
              col5: val[4],
              col6: val[5],
            };
          }),
        },
        name_table: "\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\nTabla 13. Clasificación del dolor",
      }, // Tabla 13. Clasificación del dolor
      {
        type: "text",
        value: `\n${chartTables[15].analysis}`,
      },

      {
        type: "text",
        value: "\n\n\n\n\n\n\n\n\nGráfico 14: Clasificación de la población",
        text_type: 8,
      },
      {
        type: "image",
        value: `${chartTables[16].chart}`,
      },
      //Gráfico 14: Clasificación de la población
      {
        type: "table",
        value: {
          headers: ["CLASIFICACIÓN", "TOTAL"],
          rows: chartTables[17].table.map((val) => {
            return { col1: val[0], col2: val[1] };
          }),
        },
        name_table: "Tabla 14. Clasificación de la población",
      },
      // Tabla 14. Clasificación de la población
      {
        type: "text",
        value: `\n${chartTables[17].analysis}`,
      },
      {
        type: "text",
        value: "\n\n\n\n\n\n\nGráfico 15: Clasificación Vs Área",
        text_type: 8,
      },
      {
        type: "image",
        value: `${chartTables[18].chart}`,
      },
      // Gráfico 15: Clasificación Vs Área
      {
        type: "table",
        value: {
          headers: ["", "Administrativa", "Operativa", "Total"],
          rows: chartTables[19].table.map((val) => {
            return { col1: val[0], col2: val[1], col3: val[2], col4: val[3] };
          }),
        },
        name_table: "Tabla 15: Clasificación Vs Área",
      }, //Tabla 14: Clasificación Vs Área
      {
        type: "text",
        value: `\n${chartTables[19].analysis}`,
      },
      /*
            {
              type: "text",
              value: "Gráfico 16: Clasificación Vs Cargo",
              text_type: 8,
            },
            {
              type: "image",
              value: `${chartTables[20].chart}`,
            },
            {
              type: "table",
              value: {
                headers: ["Etiquetas de fila", "Cuenta de CLASIFICACIÓN DEL TRABAJADOR"],
                rows: chartTables[23].table.map((val) => {
                  return { col1: val[0], col2: val[1] };
                }),
              },
              name_table: "Tabla 16: Clasificación Vs Cargo",
            },
            {
              type: "text",
              value: `\n${chartTables[23].analysis}`,
            },*/
      {
        type: "text",
        value: "\n\n\n\n7. CONCLUSIONES",
        text_type: 6,
      },
      {
        type: "text",
        value: content.conclusions,
      },
      {
        type: "text",
        value: "8. RECOMENDACIONES",
        text_type: 6,
      },
      {
        type: "text",
        value: ``,
      },
    ],
  };
};
export default morbility;
