import React from "react";
import "./PdfReportGenerator.scss";

import { Document, Page, View, Image, Text, PDFViewer, StyleSheet, Font } from "@react-pdf/renderer";

import {
  Table,
  TableHeader,
  TableBody,
  TableCell,
  DataTableCell,
} from "@david.kucsai/react-pdf-table";

import { Container, Row, Col } from "react-bootstrap";
// import documentBackground from "../../img/pdf/back.jpg";
// import documentHeader from "../../img/pdf/head.jpg";
// import documentFooter from "../../img/pdf/foot.jpg";
//import documentFull from "../../img/pdf/full.png";

import TextArea from "./tmpTextAreaDetector";

Font.register({
  family: 'Helvetica-Bold',
  fonts: [
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 }
  ]
});
Font.registerHyphenationCallback(word => {
  if (typeof word == 'string')
    return [word]
});

const styles = StyleSheet.create({
  page: { padding: "100 60" },
  pageFixedBackground: {
    position: "absolute",
    bottom: "0",
    left: 0,
    right: 0,
  },
  pageBackground: {
    bottom: "25%",
    left: 0,
    right: 0,
  },
  pageHeader: {
    position: "absolute",
    top: "-52%",
    left: 0,
    right: 0,
  },
  pageFooter: {
    position: "absolute",
    bottom: "0",
    left: 0,
    right: 0,
  },
  pageFull: {
    position: "absolute",
    bottom: "0",
    left: 0,
    right: 0,
    height: "850pt"
  },
  pageNumbers: {
    bottom: "10",
    right: 10,
    textAlign: "right",
    marginRight: 15,
    fontSize: "10",
  },
  img: {
    paddingHorizontal: 20,
    paddingBottom: 20,
  },
});

const listStyles = StyleSheet.create({
  0: { marginLeft: 30, fontSize: 12, textAlign: "left", marginBottom: 3, marginTop: 3 },
  1: { marginLeft: 50, fontSize: 12, textAlign: "left", marginBottom: 3, marginTop: 3 },
});

const textStyles = StyleSheet.create({
  0: { fontSize: 12, textAlign: "justify", marginBottom: 15, fontWeight: "normal" },
  1: {
    fontSize: 32,
    textAlign: "center",
    textTransform: "uppercase",
    marginBottom: 10,
    marginTop: 10,
    fontWeight: "600",
    fontFamily: "Helvetica-Bold",
    color: "#162B75",
  },
  2: {
    fontSize: 28,
    textAlign: "center",
    textTransform: "uppercase",
    marginBottom: 10,
    marginTop: 10,
    fontWeight: "600",
    fontFamily: "Helvetica-Bold",
    color: "#162B75",
  },
  3: {
    fontSize: 25,
    textAlign: "center",
    textTransform: "capitalize",
    marginBottom: 10,
    marginTop: 10,
    fontWeight: "600",
    fontFamily: "Helvetica-Bold",
    color: "#44546A",
  },
  4: {
    fontSize: 22,
    textAlign: "center",
    textTransform: "capitalize",
    marginBottom: 10,
    marginTop: 10,
    fontWeight: "600",
    fontFamily: "Helvetica-Bold",
    color: "#5B9BD5",
  },
  5: {
    fontSize: 18,
    textAlign: "center",
    textTransform: "capitalize",
    marginBottom: 10,
    marginTop: 10,
    fontWeight: "600",
    fontFamily: "Helvetica-Bold",
  },
  6: {
    fontSize: 16,
    textAlign: "center",
    textTransform: "capitalize",
    marginBottom: 10,
    marginTop: 10,
    fontFamily: "Helvetica-Bold",
    fontWeight: 700,
  },
  7: {
    fontSize: 14,
    textAlign: "center",
    textTransform: "capitalize",
    marginBottom: 10,
    marginTop: 10,
    fontWeight: "600",
    fontFamily: "Helvetica-Bold",
  },
  8: {
    fontSize: 12,
    textAlign: "center",
    textTransform: "capitalize",
    marginBottom: 10,
    marginTop: 10,
    fontWeight: "600",
    fontFamily: "Helvetica-Bold",
  },
  10: {
    fontSize: 12,
    textAlign: "left",
    marginBottom: 10,
    marginTop: 10,
    fontWeight: "600",
    fontFamily: "Helvetica-Bold",
  },
  img: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 10,
    marginTop: 10,
    fontWeight: 600,
    fontFamily: "Helvetica-Bold",
  },
});

const tableOfContentsStyles = StyleSheet.create({
  titles: {
    textAlign: "center",
    marginBottom: "10",
    fontFamily: "Helvetica-Bold",
    // fontSize: 16
  },
  items: {
    marginBottom: "5",
    // fontSize: 16
  },
  itemsPage: {
    height: 0,
    textAlign: "right",
    right: "0",
    top: "-25",
  },
});
const tableStyles = StyleSheet.create({
  header: {
    fontFamily: 'Helvetica-Bold',
    textAlign: "center",
    backgroundColor: "#BCDAE4",
    padding: "5"
  },
  body: {
    padding: "5"
  }
});

const PdfReportGenerator = ({ content, cover, toc }) => {
  let tableOfContentsItemsCounter = 0;

  const renderCover = () => {
    return cover.map((t) => <Text style={textStyles[5]}>{t}</Text>);
  };
  const renderTableOfContents = (list, l = 0) => {
    if (list) {
      return list.map(({ name, children, page }) => {
        const baseStyles = l ? tableOfContentsStyles.items : tableOfContentsStyles.titles;
        return (
          <React.Fragment>
            <Text style={{ marginLeft: l * 14, ...baseStyles }}>{name}</Text>
            <Text style={tableOfContentsStyles.itemsPage}>{page}</Text>

            {renderTableOfContents(children, l + 1)}
          </React.Fragment>
        );
      });
    }
  };

  const renderPdfSection = ({ type, value, breakable = false, avoid = false, ...options }) => {
    switch (type) {
      case "text":
        const { text_type = 0 } = options;
        return (
          <Text
            key={type + value}
            style={textStyles[text_type]}
            render={({ pageNumber }) => {
              const totalItems = content.filter((x) => x && x.type == "text" && x.text_type > 0).length;

              if ((text_type > 0, tableOfContentsItemsCounter < totalItems)) {
                tableOfContentsItemsCounter++;
              }
              return value;
            }}
            break={breakable}
          />
        );
        break;
      case "list":
        const { list_type = 0 } = options;
        return (
          <Text
            key={type + value}
            style={listStyles[list_type]}
            render={({ pageNumber }) => {
              return `• ${value}`;
            }}
            break={breakable}
          />
        );
        break;
      case "image":
        const { title_image = "" } = options;
        return (
          <React.Fragment>
            <Text
              key={type + value}
              style={textStyles.img}
              render={({ pageNumber }) => {
                return `${title_image}`;
              }}
              break={breakable}
            />
            <Image src={value} key={type + value} style={styles.img} />
          </React.Fragment>
        );
        break;
      case "table":
        const { headers, rows, weights } = value;
        const { name_table = "" } = options;
        return (
          <React.Fragment>
            <Text
              key={type + value}
              style={listStyles.list}
              render={({ pageNumber }) => {
                return `${name_table}`;
              }}
              break={breakable}
            />
            {!avoid && <Table data={rows} key={type + value}>
              <TableHeader textAlign={"center"}>
                {headers.map((h, i) => {
                  const weight = weights ? weights[i] : undefined
                  return <TableCell style={tableStyles.header} key={"tc" + h} weighting={weight}>{h}</TableCell>
                })}
              </TableHeader>
              <TableBody>
                {Object.entries(rows[0]).map(([k, v], i) => {
                  const styles = typeof (v) == 'string' && v.length >= 4 ? { textAlign: "justify" } : { textAlign: "center", }
                  const weight = weights ? weights[i] : undefined
                  return (
                    <DataTableCell getContent={(r) => r[k]} key={"dtc" + k} style={{ ...tableStyles.body, ...styles }} weighting={weight} />
                  )
                })}
              </TableBody>
            </Table>}
          </React.Fragment>
        );

      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <PDFViewer style={{ height: "800px", width: "100%" }}>
        <Document>
          <Page style={styles.page} size="A4" wrap>
            <View
              style={styles.pageFixedBackground}
              fixed
              render={({ pageNumber, totalPages }) => (
                <React.Fragment>
                  {/* <Image style={styles.pageBackground} src={documentBackground} />
                  <Image style={styles.pageFooter} src={documentFooter} />
                  <Image style={styles.pageHeader} src={documentHeader} /> */}
                  {/**<Image style={styles.pageFull} src={documentFull} /> */}
                  <Text
                    style={styles.pageNumbers}
                    render={({ pageNumber, totalPages }) => `${pageNumber}`}
                  />
                </React.Fragment>
              )}
            />
            {cover && (
              <View
                style={{
                  ...textStyles[6],
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  height: "100%",
                  textAlign: "center",
                }}
              >
                {renderCover()}
              </View>
            )}
            {toc && <View break={cover}>{renderTableOfContents(toc)}</View>}
            <View break={toc}>
              {content.map((c) => {
                if (c)
                  return renderPdfSection(c);
              })}
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </React.Fragment>
  );
};

PdfReportGenerator.propTypes = {};

PdfReportGenerator.defaultProps = {};

export default PdfReportGenerator;
