import React, { useState } from "react";
import PropTypes from "prop-types";
import "./InputFormTable.scss";
import { Form, Button, Row, Col } from "react-bootstrap";

const InputFormTable = ({ title, inputArray, setInputArray, defaultCols }) => {
  /*  const [inputArray, setInputArray] = useState([
    { Dato1: "", Dato2: "", Dato3: "" },
    { Dato1: "", Dato2: "", Dato3: "" },
  ]); */
  const handleInputChange = (e) => {
    const target = e.target;
    let auxArray = [...inputArray];
    let arrayNameAndPosition = target.name.split("-");
    let value = target.value;
    auxArray[arrayNameAndPosition[1]][arrayNameAndPosition[0]] = value;
    setInputArray(auxArray);
  };

  const handleArrayAdd = () => {
    let auxArray = [...inputArray];
    auxArray.push(defaultCols);
    setInputArray(auxArray);
  };
  const handleArrayDelete = () => {
    if (inputArray.length > 1) {
      let auxArray = [...inputArray];
      auxArray.pop();
      setInputArray(auxArray);
    }
  };

  return (
    <div>
      <h5>{title}</h5>
      {inputArray.map((val, i) => (
        <Row key={`rowTable-${i}`}>
          {val.map((val2, j) => (
            <>
            
              <Form.Group
                key={`${i}h-${j}`}
                as={Col}
                sm={12}
                md
                controlId={`exampleForm.ControlInput${j}`}
              >
                <label>{`Fila-${i+1}`}</label>
                <Form.Label>{`Columna-${j + 1}`}</Form.Label>
                <Form.Control
                  type="text"
                  name={`${j}-${i}`}
                  value={val2}
                  onChange={handleInputChange}
                  placeholder="..."
                />
              </Form.Group>
            </>
          ))}
        </Row>
      ))}
      <Row>
        <Col>
          <Button variant="primary" onClick={handleArrayAdd} block>
            Agregar casilla
          </Button>
        </Col>
        <Col>
          <Button variant="primary" onClick={handleArrayDelete} block>
            Eliminar casilla
          </Button>
        </Col>
      </Row>
      <hr />
    </div>
  );
};

InputFormTable.propTypes = {
  title: PropTypes.string,
  inputArray: PropTypes.array,
  setInputArray: PropTypes.func,
  defaultCols: PropTypes.object,
};

InputFormTable.defaultProps = {
  title: "Titulo",
  inputArray: [
    { Dato1: "", Dato2: "", Dato3: "" },
    { Dato1: "", Dato2: "", Dato3: "" },
  ],
  defaultCols: { Columna1: "", Columna2: "", Columna3: "" },
  setInputArray: function blank(data) {},
};

export default InputFormTable;
