import React, { useState, useEffect } from "react";
import "./InputGeneralData.scss";

import { useHistory } from "react-router-dom";

//Components native React
import { Row, Col, Form, Button, Card } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";

//import image deafult
import defaultLogo from "../../img/defaultLogo.jpg";
import { ReactComponent as Photo } from "../../img/icons/photo2.svg";

//Components and views
import ModalSaveResponse from "../ModalSaveResponse/ModalSaveResponse";
import { GET, POST } from "../../constants/ApiRest";
//Images

const InputGeneralData = ({ nameArray }) => {
  const timeElapsed = Date.now();
  const today = new Date(timeElapsed);

  const initialFetchedDataState = {
    nit: "",
    name: "",
    address: "",
    town_id: "",
    deparment_id: "",
    web_address: null,
    email: "",
    phone: "",
    phone2: "",
    phone3: "",
    responsible: "",
    indicator_emp: "",
    phone4: "",
    fax: "",
    membershipDate: today.toISOString().substring(0, 10),
    risk_id: "",
    workers: "",
    state_id: 1,
    main_activity: "",
    logo: 1,
    rut: 2,
    arl: 3,
    socialsecurity: 4,
    idUser: "",
  };

  let history = useHistory();

  const [thumbnail, setThumbnail] = useState(null);
  const [thumbnailFiles, setThumbnailFiles] = useState(null);
  const [show, setShow] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [stateSave, setStateSave] = useState(false);

  const [validated, setValidated] = useState(false);
  const [filesName, setFilesName] = useState([]);

  //HooksSelect
  const [identificador, setIdentificador] = useState([]);
  const [risk, setRisk] = useState([]);
  //const [state, setState] = useState([]);
  const [department, setDepartment] = useState([]);
  const [town, setTown] = useState([]);

  const [economicAtivity, setEconomicActivity] = useState("");

  const [validatedPhone, setValidadtePhone] = useState(true);

  //FormData
  const [fetchedData, setfetchedData] = useState(initialFetchedDataState);

  useEffect(() => {
    setIdentificador([{ value: "Gestor" }, { value: "Comunicaciones" }]);

    setRisk([{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }]);
    /*
    setState([
      { value: "Activo", id: 1 },
      { value: "Inactivo", id: 2 },
    ]);
  */
    // console.log(localStorage.getItem("idUser"))
    // Set del id del usuario para hacer el post
    //fetch para colocar un file por defecto
    fetch(defaultLogo)
      .then((res) => res.arrayBuffer())
      .then((buf) => new File([buf], "defaultLogo.jpg", { type: "img/jpg" }))
      .then((file) => {
        const auxDataForm = { ...fetchedData, idUser: localStorage.getItem("idUser"), 1: file };
        setfetchedData(auxDataForm);
      });

    //Petición para traer departamentos se manda 13 por ser el ID del pais en la base de datos
    GET(`${process.env.REACT_APP_POLITICAL_SEGMENTATION_ENDPOINT}/13/children`).then((res) => {
      if (res.data) setDepartment(res.data, "name");
    });
  }, []);

  const handleClose = () => {
    history.push("/companies");
  };

  const handleChangeInput = (e) => {
    const auxDataValue = e.target.value;
    const auxDataTarget = e.target.name;
    const auxDataForm = { ...fetchedData, [auxDataTarget]: auxDataValue };
    setfetchedData(auxDataForm);
  };

  const handleCodeActivityChangeInput = (event) => {
    const auxDataValue = event.target.value;
    const auxDataTarget = event.target.name;

    if (auxDataValue) {
      //Petición para traer la actividad economica con respecto al codigo de la actividad
      GET(`${process.env.REACT_APP_SECTORS}/sector/category/${auxDataValue}`).then((res) => {
        if (res.data.length > 0) {
          setShowAlert(false);
          setEconomicActivity(res.data[0].sector);
        } else {
          setShowAlert(true);
          setEconomicActivity("");
        }
      });
    }
    const auxDataForm = { ...fetchedData, [auxDataTarget]: auxDataValue };
    setfetchedData(auxDataForm);
  };

  const handleChange = (event) => {
    setThumbnail(URL.createObjectURL(event.target.files[0]));
    const auxDataValue = event.target.files[0];
    const auxDataTarget = event.target.name;
    const auxDataForm = { ...fetchedData, [auxDataTarget]: auxDataValue };
    setfetchedData(auxDataForm);
  };

  const handleFilesChange = (event, position) => {
    let arrayFiles = [...filesName];
    arrayFiles[position] = event.target.files[0].name;
    setFilesName(arrayFiles);

    const auxDataValue = event.target.files[0];
    const auxDataTarget = event.target.name;
    const auxDataForm = { ...fetchedData, [auxDataTarget]: auxDataValue };
    setfetchedData(auxDataForm);
  };

  const handleDepartmentChange = (event) => {
    setTown([]);
    let deparment = event.target.value;
    const auxDataTarget = event.target.name;
    const auxDataForm = { ...fetchedData, [auxDataTarget]: deparment };
    setfetchedData(auxDataForm);
    if (deparment) {
      //Petición para traer los municipios con respecto al departamento elegido
      GET(`${process.env.REACT_APP_POLITICAL_SEGMENTATION_ENDPOINT}/${deparment}/children`).then(
        (res) => {
          if (res.data) setTown(res.data, "name");
        }
      );
    } else {
      setTown([]);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (fetchedData.phone !== "" || fetchedData.phone3 !== "") {
      setValidadtePhone(false);
    } else setValidadtePhone(true);

    if (form.checkValidity() === false || showAlert == true) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      let data = new FormData();

      Object.entries(fetchedData).map((value) => {
        if (value[1]) {
          data.append(value[0], value[1]);
        }
      });

      // Petición para Guardar la compañia
      POST(`${process.env.REACT_APP_COMPANY_ENDPOINT}/company/new`, data)
        .then((res) => {
          // console.log(res);
          setShow(true);
          setStateSave(true);
        })
        .catch((err) => {
          // console.log(err);
          setShow(true);
          setStateSave(false);
        });
    }

    setValidated(true);
  };

  return (
    <div className="InputGeneralData">
      <ModalSaveResponse state={stateSave} show={show} handleClose={handleClose} />
      <Form className="container" noValidate validated={validated} onSubmit={handleSubmit}>
        <Row>
          <Col md={6}>
            <Form.Group>
              <Form.Label>*NIT</Form.Label>
              <Form.Control
                onChange={handleChangeInput}
                name="nit"
                type="text"
                placeholder="Ingrese el NIT de la empresa"
                required
              />
              <Form.Control.Feedback type="invalid">
                Debe ingresar el Nit de la empresa
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>*Dirección Sede Principal</Form.Label>
              <Form.Control
                className="firstLetterMayus"
                onChange={handleChangeInput}
                name="address"
                type="text"
                placeholder="Dirección Sede Principal"
                required
              />
              <Form.Control.Feedback type="invalid">
                Debe ingresar la dirección de la sede
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <Form.Group>
              <Form.Label>Dirección web</Form.Label>
              <Form.Control
                onChange={handleChangeInput}
                name="web_address"
                type="text"
                placeholder="Ingrese la dirección web de la empresa"
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group>
              <Form.Label>*Teléfono</Form.Label>
              <Form.Control
                onChange={handleChangeInput}
                name="phone"
                type="text"
                placeholder="Ingrese el teléfono de la empresa"
                required={validatedPhone}
              />
              <Form.Control.Feedback type="invalid">
                Debe ingresar el telefono de la empresa
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>*Celular</Form.Label>
              <Form.Control
                onChange={handleChangeInput}
                name="phone3"
                type="text"
                placeholder="Ingrese el teléfono de la empresa"
                required={validatedPhone}
              />
              <Form.Control.Feedback type="invalid">
                Debe ingresar el celular de la empresa
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Group>
              <Form.Label>*Responsable SG-SST</Form.Label>
              <Form.Control
                onChange={handleChangeInput}
                name="responsible"
                type="text"
                placeholder="Ingrese el nombre del responsable del SG-SST"
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>*Indicador EMP</Form.Label>
              <Form.Control onChange={handleChangeInput} name="indicator_emp" as="select" required>
                <option></option>
                {identificador.map((value, i) => (
                  <option key={i}>{value.value}</option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Debe seleccionar un valor
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Group>
              <Form.Label>*Riesgo</Form.Label>
              <Form.Control onChange={handleChangeInput} name="risk_id" as="select" required>
                <option></option>
                {risk.map((value, i) => (
                  <option key={i}>{value.value}</option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Debe seleccionar un valor
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>*Trabajadores</Form.Label>
              <Form.Control
                onChange={handleChangeInput}
                name="workers"
                type="text"
                placeholder="Ingrese el numero de trabajadores"
                required
              />
              <Form.Control.Feedback type="invalid">
                Debe ingresar el numero de trabajadores
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <Form.Group>
              <Form.Label>*Razón social</Form.Label>
              <Form.Control
                className="firstLetterMayus"
                onChange={handleChangeInput}
                name="name"
                type="text"
                placeholder="Ingrese la razón social de la empresa"
                required
              />
              <Form.Control.Feedback type="invalid">
                Debe ingresar la razón social de los empresa
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group>
              <Form.Label>*Departamento</Form.Label>
              <Form.Control
                className="firstLetterMayus"
                onChange={(e) => handleDepartmentChange(e)}
                name="deparment_id"
                as="select"
                required
              >
                <option></option>
                {department.map((value, i) => (
                  <option key={i} value={value.id}>
                    {value.name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Debe seleccionar un departamento
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>*Municipio</Form.Label>
              <Form.Control
                className="firstLetterMayus"
                onChange={handleChangeInput}
                name="town_id"
                as="select"
                disabled={town.length === 0}
                required
              >
                <option></option>
                {town.map((value, i) => (
                  <option value={value.id}>{value.name}</option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Debe seleccionar un municipio
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group>
              <Form.Label>*Email</Form.Label>
              <Form.Control
                onChange={handleChangeInput}
                name="email"
                type="email"
                placeholder="Ingrese un email"
                required
              />
              <Form.Control.Feedback type="invalid">Debe ingresar un Email</Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group>
              <Form.Label>*Fecha de Vinculación</Form.Label>
              <Form.Control
                disabled
                //  onChange={handleChangeInput}
                name="membershipDate"
                // type="date"
                required
                value={today.toLocaleDateString().substring(0, 9)}
              />
              <Form.Control.Feedback type="invalid">Debe ingresar una fecha</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        {/** 
          <Col md={6}>
            <Form.Group>
              <Form.Label>*Estado</Form.Label>
              <Form.Control
                className="firstLetterMayus"
                onChange={handleChangeInput}
                name="state_id"
                as="select"
                required
              >
                <option></option>
                {state.map((value, i) => (
                  <option value={value.id} key={i}>
                    {value.value}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">Debe ingresar un Estado</Form.Control.Feedback>
            </Form.Group>
          </Col>*/}

        <Row>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Código Actividad</Form.Label>
              <Form.Control
                onChange={handleCodeActivityChangeInput}
                name="main_activity"
                type="text"
                placeholder="Ingrese el código de la actividad"
                //required
              />
              {/**  <Form.Control.Feedback type="invalid">Debe ingresar un Código</Form.Control.Feedback>*/}
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Actividad Ecónomica</Form.Label>
              <Form.Control
                className="firstLetterMayus"
                onChange={handleChangeInput}
                name="desc_activity"
                value={economicAtivity || ""}
                type="text"
                placeholder="Actividad ecónomica"
                /*disabled
              required*/
              />
              {/** 
              <Form.Control.Feedback type="invalid">
                Debe ingresar una Actividad Ecónomica
              </Form.Control.Feedback>*/}
            </Form.Group>
          </Col>
        </Row>
        {showAlert && (
          <Row>
            <Col md={12}>
              <Alert variant="danger">
                <p className="mb-0">Debe ingresar una actividad economica valida</p>
              </Alert>
            </Col>
          </Row>
        )}
        <Row className="text-center my-3 ">
          <Col className="sm-logoArea" md={6} lg={4} sm={12} xs={12}>
            <Form.Group className="h-100 w-100">
              <Row className="text-center d-flex justify-content-center">
                {thumbnail != null ? (
                  <img src={thumbnail} className="styleThumbnail pb-2 mt-2" />
                ) : (
                  <div>
                    <Photo className="sm-logoImg p-0 h-100 my-4 " />
                  </div>
                  // <img src={imageDefault} className="styleThumbnail pb-2" />
                )}
              </Row>
              <Row className="text-center d-flex justify-content-center">
                <Form.Label className="p-0 m-0" style={{ width: "12em", height: "3em" }}>
                  <a className="styleUploadButton px-4 py-2">Subir logo</a>{" "}
                  <Form.File
                    name="1"
                    accept="image/*"
                    onChange={handleChange}
                    style={{ display: "none" }}
                  ></Form.File>{" "}
                </Form.Label>
              </Row>
            </Form.Group>
          </Col>

          <Col className="my-3" md={6} lg={8} xs={12}>
            <Row>
              <Col>
                <Form.Group>
                  <Form.File
                    name="2"
                    label={filesName[0] || "Subir RUT"}
                    accept="application/pdf"
                    lang="es"
                    custom
                    onChange={(e) => handleFilesChange(e, 0)}
                    data-browse="Elegir archivo"
                 //   required
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group>
                  <Form.File
                    name="3"
                    label={filesName[1] || "Subir Cámara y Comercio"}
                    accept="application/pdf"
                    lang="es"
                    custom
                    onChange={(e) => handleFilesChange(e, 1)}
                    data-browse="Elegir archivo"
                  //  required
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group>
                  <Form.File
                    name="4"
                    label={filesName[2] || "Subir seguridad Social"}
                    accept="application/pdf"
                    lang="es"
                    custom
                    onChange={(e) => handleFilesChange(e, 2)}
                    data-browse="Elegir archivo"
                   // required
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                {thumbnailFiles
                  ? Object.values(thumbnailFiles).map((value) => {
                      // console.log(value);
                      // console.log(value.type);
                      return (
                        <Card className="m-2">
                          <Card.Body className="p-0">{value.name}</Card.Body>
                        </Card>
                      );
                    })
                  : ""}
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="text-center mb-5 justify-content-center">
          <Col md={6}>
            <Button type="submit" variant="warning" className="sm-buttonSaveForm">
              Guardar
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default InputGeneralData;
