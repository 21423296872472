// +--------------------+
// |        GCP         |
// +--------------------+
let GCP = "https://storage.googleapis.com/sst-seguridad-trabajo/smart-sst-company-ie/herramientas";

// +--------------------+
// |       TOOLS        |
// +--------------------+
const AUTO7E = `${GCP}/autoevaluacion/IN_F01_EVALUACION_7E.xlsx`;
const AUTO21E = `${GCP}/autoevaluacion/IN_F02_EVALUACION_21E.xlsx`;
const AUTO60E = `${GCP}/autoevaluacion/IN_F03_EVALUACION_60E.xlsx`;
const SOCIODEMOGRAFICO = `${GCP}/sociodemografico/sociodemografico_v4.xlsx`;
const EFECTOS_PSICOSOCIALES = `${GCP}/efectos_psicosociales/IN_F11_EFECTOS_PSICOSOCIALES.xlsx`;
const MATRIZ_PELIGROS = `${GCP}/matriz_peligros/IN_F05_MATRIZ_PELIGROS.xlsx`;
const MORBILIDAD_SENTIDA = `${GCP}/morbilidad_sentida/IN_F09_CUESTIONARIO_NORDICO_v5.xlsx`;
const PROTOCOLO_BIOSEGURIDAD = `${GCP}/protocolo_bioseguridad/IN_I06_PROTOCOLO_BIOSEGURIDAD_v11032022.xlsx`;

// +---------------------------+
// |       INSTRUCTIONS        |
// +---------------------------+
const IN_AUTOE = `${GCP}/autoevaluacion/IN_I01_RESOLUCION_0312.pdf`;
const IN_SOCIODEMOGRAFICO = `${GCP}/sociodemografico/IN_I03_CARACTERIZACI%C3%A0N_SOCIODEMOGRAFICO.pdf`;
const IN_EFECTOS_PSICOSOCIALES = `${GCP}/efectos_psicosociales/IN_I05_EFECTOS_PSICOSOCIALES.pdf`;
const IN_MATRIZ_PELIGROS = `${GCP}/matriz_peligros/IN_I02_MATRIZ_PELIGROS.pdf`;
const IN_MORBILIDAD_SENTIDA = `${GCP}/morbilidad_sentida/INSTRUCTIVO_BIOMEC_ALS.pdf`;
const IN_PROTOCOLO_BIOSEGURIDAD = `${GCP}/protocolo_bioseguridad/IN_I06_PROTOCOLO_BIOSEGURIDAD.pdf`;

export default {
  AUTO7E,
  AUTO21E,
  AUTO60E,
  SOCIODEMOGRAFICO,
  EFECTOS_PSICOSOCIALES,
  MATRIZ_PELIGROS,
  MORBILIDAD_SENTIDA,
  PROTOCOLO_BIOSEGURIDAD,
  IN_AUTOE,
  IN_SOCIODEMOGRAFICO,
  IN_EFECTOS_PSICOSOCIALES,
  IN_MATRIZ_PELIGROS,
  IN_MORBILIDAD_SENTIDA,
  IN_PROTOCOLO_BIOSEGURIDAD
};
