import React, { useState, useEffect, useReducer } from "react";
import "./Companies.scss";
import { Row, Col, Button } from "react-bootstrap";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import ArrowBack from "../../img/icons/iconArrowBack.svg";
// import ReactPaginate from "react-paginate";
import { Pagination } from '../../components/Pagination/Pagination';

import { NavLink, Link } from "react-router-dom";
import { GET } from "../../constants/ApiRest";
import { reducer } from "../../helpers/reducer";

// import 'bootstrap/dist/css/bootstrap.min.css';

//provisional empresas
// const company = ["Empresa", "Empresa", "Empresa", "Empresa", "Empresa", "Empresa"];

const init = () => {
  return [];
}

function Companies({ setLogin }) {
  // const [company, setCompany] = useState([]);
  const [ pageCount, setPageCount ] = useState(0);
  const [ company, dispatch ] = useReducer(reducer, [], init)
  const [ , setRender ] = useState(0);

  useEffect(() => {
    const idUser = localStorage.getItem("idUser");
    //Petición para traer las comapañias de la base de datos
    GET(`${process.env.REACT_APP_COMPANY_ENDPOINT}/company/${idUser}`)
      .then((res) => {
        if (res.statusText !== "OK") {
          setLogin(false);
        } else {
          if (res.data) {
            if (res.data !== "la sesión ha expirado") {
              // setCompany(res.data, "name");
              dispatch( { type: "addSort", payload: res.data } )
              setPageCount( Math.ceil( res.data.length / 6 ) )
              // console.log(res.data)
            } else {
              // setCompany([]);
              dispatch({ action: [] });
            }
          }
        }
      })
      .catch((error) => {
        if (error.request.response !== "There are no companies.") {
          setLogin(false);
        }
        // console.log(error.request.response);
      });
  }, []);

  const handlePage = ({ selected }) => {

    const action = {
      type: 'change',
      payload: selected //cambiar
    }

    dispatch( action );
    setRender(selected)
  }

  return (
    <div className="Companies container">
      <Row className="w-100">
        <div className="ml-4">
          <Breadcrumbs
            view={[
              { name: "Inicio", path: "/graphics" },
              { name: "Empresas", path: "" },
            ]}
          ></Breadcrumbs>
          <NavLink to="/init/graphics" className="styleArrowLink">
            <img src={ArrowBack} width="50" />
          </NavLink>
        </div>
      </Row>
      <Row className="justify-content-center align-self-center text-center w-100">
        <Col md={12}>
          <h3 className="sm-titleCompanies" style={{ textAlign: "center", color: "#3A4297" }}>
            Empresas
          </h3>
        </Col>
      </Row>
      <Row className="justify-content-center align-self-center text-center w-100 m-0 p-2">
        <Col md={6} lg={5} className="p-0">
          {/* <ButtonGroup className="styleButtonGroup" vertical> */}
          {
            //aqui iria un map para colocar la lista de empresas
            company[1]?.map((title, i) => (
              <Row className="sm-buttonlist m-0" key={"button-" + i}>
                <Col className="m-0 p-0 d-flex align-items-center sm-logoContent" md={2} xs={2}>
                  <img
                    className="w-100"
                    src={`${process.env.REACT_APP_COMPANY_ENDPOINT}${title.company.files[0].url}`}
                  ></img>
                </Col>
                <Col className="p-0 m-0" md={10} xs={10}>
                  <Link
                    to={{
                      pathname: "/init/generaldata",
                      state: {
                        name: title.company.name,
                        array: title.company,
                      },
                    }}
                    className="styleNavLink"
                  >
                    <Button key={i} className="styleButton">
                      <p className="sm-titleButton">{title.company.name}</p>
                      <p className="sm-titleNit">Nit: {title.company.nit}</p>
                    </Button>
                  </Link>
                </Col>
              </Row>
            ))
          }
          <Row className="w-100 m-0">
            <Link
              to={{
                pathname: "/init/generaldata",
                state: {
                  name: "empresa",
                },
              }}
              className="styleNavLink"
            >
              <Button className="styleButtonAdd">Agregar Empresa</Button>
            </Link>
          </Row>
        </Col>
      </Row>
      <Row className="justify-content-center align-self-center text-center w-100 m-0 p-2">
        <Pagination pageCount={ pageCount } handlePage={ handlePage }  />
        {/* <ReactPaginate
          previousLabel={"Anterior"}
          previousClassName={"page-link"}
          nextLabel={"Siguiente"}
          nextClassName={"page-link"}
          breakLabel={"..."}
          breakClassName={"page-item"}
          pageCount={pageCount}
          marginPagesDisplayed={1}
          pageRangeDisplayed={4}
          onPageChange={handlePage}
          containerClassName={"pagination"}
          pageClassName={"page-item"}
          activeClassName={"active"}
          breakLinkClassName={"page-link"}
          pageLinkClassName={"page-link"}
        /> */}
      </Row>
    </div>
  );
}

Companies.propTypes = {};

Companies.defaultProps = {};

export default Companies;
