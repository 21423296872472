import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Form, Button } from "react-bootstrap";
import ReactChart from "../ReactChart/ReactChart";

//import component

import InputFormTable from "./../InputFormTable/InputFormTable";

const FormPsychosocial = ({ GeneratePDF, dataCharts }) => {
  const [isChartCharge, setIsChartCharge] = useState(false);
  const [newPngFrom64, setNewPngFrom64] = useState([]);
  const [dataChart, setDataChart] = useState({});
  const [optionsChart, setOptionsChart] = useState({});
  const [data, setData] = useState({
    conclution: "",
    resultsAnalysis: "",
    distributionCharges: [["", "", ""]],
    distributionPersonalBackground: [["", "", ""]],
    analysis: [],
  });
  const [chartsAllBolean, setchartsBolean] = useState([]);
  /* const [inputArray, setInputArray] = useState([{ Dato1: "", Dato2: "", Dato3: "" }]);
  const [inputArray2, setInputArray2] = useState([{ Dato1: "", Dato2: "", Dato3: "" }]); */

  const setInputArray = (dataCurrent) => {
    let auxArray = { ...data };
    auxArray["distributionCharges"] = dataCurrent;
    setData(auxArray);
  };

  useEffect(() => {
    // console.log("change please");
    // data.distributionCharges = dataCharts[19].table;
    // data.distributionPersonalBackground = dataCharts[31].table;
    // data.analysis = dataCharts.map();
    setData({ ...data });
    setchartsBolean(dataCharts.map((val) => !!val));
  }, [dataCharts]);

  const setInputArray2 = (dataCurrent) => {
    let auxArray = { ...data };
    auxArray["distributionPersonalBackground"] = dataCurrent;
    setData(auxArray);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    GeneratePDF(1, data);
  };

  const handleInputChangeArray = (event) => {
    let auxData = { ...data.analysis };
    const target = event.target;
    const value = target.value;
    const name = target.name.split("-");
    auxData[name[1]] = value;
    setData({ ...data, analysis: auxData });
  };

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setData({ ...data, [name]: value });
  };

  //graficas
  const [arrayOfCharts, setArrayOfCharts] = useState([]);
  useEffect(() => {
    chart();
    setchartsBolean(chartsAllBolean.map((val) => false));
  }, [data]);

  const chart = () => {
    let auxArrayOfCharts = [...arrayOfCharts];
    dataCharts.map((c, i) => {
      renderObjTable(c, i, auxArrayOfCharts);
    });
    setArrayOfCharts(auxArrayOfCharts);
  };

  const renderObjTable = (value, index, auxArrayOfCharts) => {
    let arrayValue = value.table;
    let labelArray = arrayValue.map((x) => x[0]);
    let labelDataArray = arrayValue.map((x) => {
      return x[1] ? x[1].toString().replace(/%/, "").replace(/-/, "") : "";
    });
    let chartObj = {
      chartsData: {
        labels: labelArray,
        animation: {
          duration: 0,
        },
        hover: {
          animationDuration: 0, // duration of animations when hovering an item
        },
        responsiveAnimationDuration: 0, // animation duration after a resize
        datasets: [
          {
            label: "",
            data: labelDataArray,
            backgroundColor: "rgb(54, 162, 235)",
            borderWidth: 4,
            stack: 1,
          },
        ],
      },
      chartsOptions: {
        title: {
          display: true,
          text: "Grafica",
          fontSize: 20,
        },
        legend: {
          display: true,
        },
      },
    };

    if (index == 1) {
      let dataHalf = arrayValue.map((x) => x[2]);
      let dataHigh = arrayValue.map((x) => x[3]);

      chartObj.chartsData.datasets = [
        {
          label: "Alto",
          data: dataHigh,
          stack: "Stack",
          backgroundColor: [
            "rgba(205, 57, 25)", //ALTO ROJO
            "rgba(205, 57, 25)", //ALTO ROJO
            "rgba(205, 57, 25)", //ALTO ROJO
            "rgba(205, 57, 25)", //ALTO ROJO
            "rgba(205, 57, 25)", //ALTO ROJO
            "rgba(205, 57, 25)", //ALTO ROJO
          ],
        },
        {
          label: "Medio",
          data: dataHalf,
          stack: "Stack",
          backgroundColor: [
            "rgba(253, 241, 97)", //MEDIO AMARILLO
            "rgba(253, 241, 97)", //MEDIO AMARILLO
            "rgba(253, 241, 97)", //MEDIO AMARILLO
            "rgba(253, 241, 97)", //MEDIO AMARILLO
            "rgba(253, 241, 97)", //MEDIO AMARILLO
            "rgba(253, 241, 97)", //MEDIO AMARILLO
          ],
        },
        {
          label: "Bajo",
          data: labelDataArray,
          stack: "Stack",
          backgroundColor: [
            "rgba(140, 176, 18)", //BAJO VERDE
            "rgba(140, 176, 18)", //BAJO VERDE
            "rgba(140, 176, 18)", //BAJO VERDE
            "rgba(140, 176, 18)", //BAJO VERDE
            "rgba(140, 176, 18)", //BAJO VERDE
            "rgba(140, 176, 18)", //BAJO VERDE
          ],
        },
      ];
    } else if (index != 1) {
      chartObj.chartsData.datasets = [
        {
          type: "bar",
          label: "Trabajadores Sintomaticos",
          data: labelDataArray,
          backgroundColor: "rgb(54, 162, 235)",
        },
        {
          type: "line",
          label: "Umbral",
          data: [40, 40],
          fill: false,
          borderColor: "rgb(255, 99, 132)",
        },
      ];
    }
    auxArrayOfCharts[index] = chartObj;
  };

  const [isDisableButton, setIsDisableButton] = useState(true);
  const updateChart = (indexId, isReady, auxChartsAllBolean) => {
    auxChartsAllBolean[indexId] = isReady;
    if (auxChartsAllBolean.length - 1 == indexId) {
      // console.log(auxChartsAllBolean);
      setIsDisableButton(auxChartsAllBolean.includes(false));
    }
  };

  const renderCharts = () => {
    const pies = [];
    const horizontal = [];
    let type = 1;
    const auxChartsAllBolean = [...chartsAllBolean];
    return arrayOfCharts.map(function (t, i) {
      if (pies.includes(i)) {
        t.chartsOptions.legend = { display: true, position: "bottom" };
        type = 4;
      } else if (horizontal.includes(i)) {
        type = 2;
      } else {
        type = 1;
      }
      return (
        <ReactChart
          key={i}
          dataChartPdfReportGenerator={dataChartPdfReportGenerator}
          dataCharts={t.chartsData}
          optionsCharts={t.chartsOptions}
          value={i}
          typeP={type}
          isCharReady={updateChart}
          auxChartsAllBolean={auxChartsAllBolean}
        />
      );
    });
  };

  const dataChartPdfReportGenerator = async (data, i) => {
    const base64Image = data;
    dataCharts[i].chart = await base64Image;
  };
  //fin graficas
  const downloadWord = () => {
    var link = document.createElement("a");
    link.href = "https://www.ilovepdf.com/es/pdf_a_word";
    link.target = "_blank";
    link.click();
  };

  return (
    <Form onSubmit={handleSubmit} className="w-100">
      <Button variant="info" className="mb-2 btn-word" block onClick={downloadWord}>
        Ir al convertidor
      </Button>
      <Button variant="danger" type="submit" disabled={isDisableButton} block>
        Visualizar PDF
      </Button>
      {/* charts */}
      {renderCharts()}
    </Form>
  );
};

FormPsychosocial.propTypes = { GeneratePDF: PropTypes.func };

FormPsychosocial.defaultProps = { GeneratePDF: function blank(params) {} };

export default FormPsychosocial;
