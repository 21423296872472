import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Form, Button } from "react-bootstrap";
import ReactChart from "../ReactChart/ReactChart";

//import component

import InputFormTable from "./../InputFormTable/InputFormTable";

const FormMorbidity = ({ GeneratePDF, dataCharts }) => {
  const [isChartCharge, setIsChartCharge] = useState(false);
  const [newPngFrom64, setNewPngFrom64] = useState([]);
  const [dataChart, setDataChart] = useState({});
  const [optionsChart, setOptionsChart] = useState({});
  const [data, setData] = useState({
    conclution: "",
    resultsAnalysis: "",
    data1: [["", "", ""]],
    data2: [["", "", ""]],
    data3: [["", "", ""]],
    analysis: [""],
  });
  const [chartsAllBolean, setchartsBolean] = useState([]);

  const handleSubmit = (event) => {
    event.preventDefault();
    GeneratePDF(1, data);
  };

  const setInputArray = (dataCurrent) => {
    let auxArray = { ...data };
    auxArray["data1"] = dataCurrent; /* 6 */
    setData(auxArray);
  };

  const setInputArray2 = (dataCurrent) => {
    let auxArray = { ...data };
    auxArray["data2"] = dataCurrent; /* 6 */
    setData(auxArray);
  };

  const setInputArray3 = (dataCurrent) => {
    let auxArray = { ...data };
    auxArray["data3"] = dataCurrent; /* 6 */
    setData(auxArray);
  };

  const handleInputChangeArray = (event) => {
    let auxData = [...data.analysis];
    const target = event.target;
    const value = target.value;
    const name = target.name.split("-");
    auxData[name[1]] = value;
    setData({ ...data, analysis: auxData });
  };

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setData({ ...data, [name]: value });
  };

  useEffect(() => {
    data.analysis = dataCharts.map((val) => {
      return val.analysis;
    });
    data.data1 = dataCharts[5].table;
    data.data2 = dataCharts[20].table;
    data.data3 = dataCharts[23].table;
    setchartsBolean(dataCharts.map((val) => !!val));
    chart();
  }, [dataCharts]);

  // graficas
  const [arrayOfCharts, setArrayOfCharts] = useState([]);

  useEffect(() => {
    setchartsBolean(chartsAllBolean.map((val) => false));
  }, [data]);

  const chart = () => {
    const auxArrayOfCharts = [...arrayOfCharts];
    dataCharts.map((c, i) => {
      renderObjTable(c, i, auxArrayOfCharts);
    });
    setArrayOfCharts(auxArrayOfCharts);
  };

  const renderObjTable = (value, index, auxArrayOfCharts) => {
    const arrayValue = value.table;
    const labelArray = arrayValue.map((x) => x[0]);
    const labelDataArray = arrayValue.map((x) => {
      return x[1].toString().replace(/%/, "").replace(/-/, "");
    });

    const labelDataArray2 =
      arrayValue[0][2] != undefined
        ? arrayValue.map((x) => {
          return x[2].toString().replace(/%/, "").replace(/-/, "");
        })
        : null;

    const labelDataArray3 =
      arrayValue[0][3] != undefined
        ? arrayValue.map((x) => {
          return x[3].toString().replace(/%/, "").replace(/-/, "");
        })
        : null;

    const labelDataArray4 =
      arrayValue[0][4] != undefined
        ? arrayValue.map((x) => {
          return x[4].toString().replace(/%/, "").replace(/-/, "");
        })
        : null;
    const labelDataArray5 =
      arrayValue[0][5] != undefined
        ? arrayValue.map((x) => {
          return x[5].toString().replace(/%/, "").replace(/-/, "");
        })
        : null;

    let chartObj;
    switch (index) {
      case 18: {
        chartObj = {
          chartsData: {
            labels: labelArray,
            animation: {
              duration: 0,
            },
            hover: {
              animationDuration: 0, // duration of animations when hovering an item
            },
            responsiveAnimationDuration: 0, // animation duration after a resize
            datasets: [
              {
                label: "SINTOMATICO",
                data: labelDataArray,
                backgroundColor: "#33AEEF",
                borderWidth: 4,
              },
              {
                label: "ASINTOMATICO",
                data: labelDataArray2,
                backgroundColor: "#445588",
                borderWidth: 4,
              },
            ],
          },
          chartsOptions: {
            title: {
              display: true,
              text: "Grafica",
              fontSize: 20,
            },
            legend: {
              display: true,
            },
          },
        };
        break;
      }
      case 15: {
        chartObj = {
          chartsData: {
            labels: labelArray,
            animation: {
              duration: 0,
            },
            hover: {
              animationDuration: 0, // duration of animations when hovering an item
            },
            responsiveAnimationDuration: 0, // animation duration after a resize
            datasets: [
              {
                label: "SEGMENTO CORPORAL",
                data: labelDataArray,
                backgroundColor: "#33AEEF",
                borderWidth: 4,
              },
              {
                label: "SIN MOLESTIA",
                data: labelDataArray2,
                backgroundColor: "#445588",
                borderWidth: 4,
              },
              {
                label: "LEVE",
                data: labelDataArray3,
                backgroundColor: "#493711",
                borderWidth: 4,
              },
              {
                label: "MODERADO",
                data: labelDataArray4,
                backgroundColor: "#4c9b19",
                borderWidth: 4,
              },
              {
                label: "MUY FUERTE",
                data: labelDataArray5,
                backgroundColor: "#1f1f1f1f",
                borderWidth: 4,
              },
            ],
          },
          chartsOptions: {
            title: {
              display: true,
              text: "Grafica",
              fontSize: 20,
            },
            legend: {
              display: true,
            },
          },
        };
        break;
      }
      case 10: {
        chartObj = {
          chartsData: {
            labels: labelArray,
            animation: {
              duration: 0,
            },
            hover: {
              animationDuration: 0, // duration of animations when hovering an item
            },
            responsiveAnimationDuration: 0, // animation duration after a resize
            datasets: [
              {
                label: "ENTRE 1 - 7 DÍAS",
                data: labelDataArray,
                backgroundColor: "#33AEEF",
                borderWidth: 4,
              },
              {
                label: "ENTRE 8 - 30 DÍAS",
                data: labelDataArray2,
                backgroundColor: "#445588",
                borderWidth: 4,
              },
              {
                label: "MAS DE 30 DÍAS",
                data: labelDataArray3,
                backgroundColor: "#493711",
                borderWidth: 4,
              },
              {
                label: "SIEMPRE",
                data: labelDataArray4,
                backgroundColor: "#4c9b19",
                borderWidth: 4,
              },
            ],
          },
          chartsOptions: {
            title: {
              display: true,
              text: "Grafica",
              fontSize: 20,
            },
            legend: {
              display: true,
            },
          },
        };
        break;
      }
      case 9: {
        chartObj = {
          chartsData: {
            labels: labelArray,
            animation: {
              duration: 0,
            },
            hover: {
              animationDuration: 0, // duration of animations when hovering an item
            },
            responsiveAnimationDuration: 0, // animation duration after a resize
            datasets: [
              {
                label: "SI",
                data: labelDataArray,
                backgroundColor: "#33AEEF",
                borderWidth: 4,
              },
              {
                label: "NO",
                data: labelDataArray2,
                backgroundColor: "#445588",
                borderWidth: 4,
              },
              {
                label: "N.A",
                data: labelDataArray3,
                backgroundColor: "#493711",
                borderWidth: 4,
              },
            ],
          },
          chartsOptions: {
            title: {
              display: true,
              text: "Grafica",
              fontSize: 20,
            },
            legend: {
              display: true,
            },
          },
        };
        break;
      }
      case 8: {
        chartObj = {
          chartsData: {
            labels: labelArray,
            animation: {
              duration: 0,
            },
            hover: {
              animationDuration: 0, // duration of animations when hovering an item
            },
            responsiveAnimationDuration: 0, // animation duration after a resize
            datasets: [
              {
                label: "DERECHO",
                data: labelDataArray,
                backgroundColor: "#33AEEF",
                borderWidth: 4,
              },
              {
                label: "IZQUIERDO",
                data: labelDataArray2,
                backgroundColor: "#445588",
                borderWidth: 4,
              },
              {
                label: "AMBOS",
                data: labelDataArray3,
                backgroundColor: "#493711",
                borderWidth: 4,
              },
              {
                label: "NO",
                data: labelDataArray4,
                backgroundColor: "#4c9b19",
                borderWidth: 4,
              },
            ],
          },
          chartsOptions: {
            title: {
              display: true,
              text: "Grafica",
              fontSize: 20,
            },
            legend: {
              display: true,
            },
          },
        };
        break;
      }
      case 7: {
        chartObj = {
          chartsData: {
            labels: labelArray,
            animation: {
              duration: 0,
            },
            hover: {
              animationDuration: 0, // duration of animations when hovering an item
            },
            responsiveAnimationDuration: 0, // animation duration after a resize
            datasets: [
              {
                label: "DERECHO",
                data: labelDataArray,
                backgroundColor: "#33AEEF",
                borderWidth: 4,
              },
              {
                label: "IZQUIERDO",
                data: labelDataArray2,
                backgroundColor: "#445588",
                borderWidth: 4,
              },
              {
                label: "AMBOS",
                data: labelDataArray3,
                backgroundColor: "#493711",
                borderWidth: 4,
              },
              {
                label: "NO",
                data: labelDataArray4,
                backgroundColor: "#4c9b19",
                borderWidth: 4,
              },
            ],
          },
          chartsOptions: {
            title: {
              display: true,
              text: "Grafica",
              fontSize: 20,
            },
            legend: {
              display: true,
            },
          },
        };
        break;
      }
      case 6: {
        chartObj = {
          chartsData: {
            labels: labelArray,
            animation: {
              duration: 0,
            },
            hover: {
              animationDuration: 0, // duration of animations when hovering an item
            },
            responsiveAnimationDuration: 0, // animation duration after a resize
            datasets: [
              {
                label: "SI",
                data: labelDataArray,
                backgroundColor: "#33AEEF",
                borderWidth: 4,
              },
              {
                label: "NO",
                data: labelDataArray2,
                backgroundColor: "#445588",
                borderWidth: 4,
              },
            ],
          },
          chartsOptions: {
            title: {
              display: true,
              text: "Grafica",
              fontSize: 20,
            },
            legend: {
              display: false,
            },
          },
        };
        break;
      }
      default: {
        chartObj = {
          chartsData: {
            labels: labelArray,
            animation: {
              duration: 0,
            },
            hover: {
              animationDuration: 0, // duration of animations when hovering an item
            },
            responsiveAnimationDuration: 0, // animation duration after a resize
            datasets: [
              {
                label: "",
                data: labelDataArray,
                backgroundColor: "#33AEEF",
                borderWidth: 4,
              },
            ],
          },
          chartsOptions: {
            scales: {
              yAxes: [
                {
                  display: true,
                  ticks: {
                    suggestedMin: 0,
                    // minimum will be 0, unless there is a lower value.
                    // OR //
                    beginAtZero: true, // minimum value will be 0.
                  },
                },
              ],
            },
            title: {
              display: true,
              text: "Grafica",
              fontSize: 20,
            },
            legend: {
              display: false,
            },
          },
        };
        break;
      }
    }

    auxArrayOfCharts[index] = chartObj;
  };

  const [isDisableButton, setIsDisableButton] = useState(true);
  const updateChart = (indexId, isReady, auxChartsAllBolean) => {
    auxChartsAllBolean[indexId] = isReady;
    if (auxChartsAllBolean.length - 1 == indexId) {
      setIsDisableButton(auxChartsAllBolean.includes(false));
    }
  };

  const renderCharts = () => {
    const pies = [0, 4, 11, 13, 16];
    const horizontal = [];
    const isNotPercentage = [2, 3, 5, 6, 7, 8, 9, 10, 15, 18, 20];
    let type = 1;
    const auxChartsAllBolean = [...chartsAllBolean];
    return arrayOfCharts.map((t, i) => {
      if (pies.includes(i)) {
        t.chartsOptions.legend = { display: true, position: "bottom" };
        type = 4;
      } else if (horizontal.includes(i)) {
        type = 2;
      } else {
        type = 1;
      }
      return (
        <ReactChart
          key={i}
          dataChartPdfReportGenerator={dataChartPdfReportGenerator}
          dataCharts={t.chartsData}
          optionsCharts={t.chartsOptions}
          value={i}
          typeP={type}
          isPercentage={!isNotPercentage.includes(i)}
          isCharReady={updateChart}
          auxChartsAllBolean={auxChartsAllBolean}
        />
      );
    });
  };

  const dataChartPdfReportGenerator = async (data, i) => {
    const base64Image = data;
    dataCharts[i].chart = await base64Image;
  };
  //fin graficas

  return (
    <Form onSubmit={handleSubmit} className="w-100">
      <Button variant="danger" type="submit" block disabled={isDisableButton} block>
        Visualizar PDF
      </Button>
      {/*
      <InputFormTable
        inputArray={data.data1}
        setInputArray={setInputArray}
        title="Distribución por cargos"
        defaultCols={data.data1[data.data1.length - 1]}
      />
      <InputFormTable
        inputArray={data.data2}
        setInputArray={setInputArray2}
        title="Gráfica Clasificación Vs Cargo"
        defaultCols={data.data2[data.data2.length - 1]}
      />

      <InputFormTable
        inputArray={data.data3}
        setInputArray={setInputArray3}
        title="Tabla Clasificación Vs Cargo"
        defaultCols={data.data3[data.data3.length - 1]}
      />*/}

      {/*data.analysis.map((inputData, i) => {
        return inputData != undefined ? (
          <Form.Group key={`analisis-${i}`} controlId="exampleForm.ControlTextarea1">
            <Form.Label>ANÁLISIS DE RESULTADOS (tabla-{i + 1})</Form.Label>
            <Form.Control
              as="textarea"
              onChange={handleInputChangeArray}
              name={`analysis-${i}`}
              value={inputData}
              rows={3}
            />
          </Form.Group>
        ) : (
          ""
        );
      })}
      <Form.Group controlId="exampleForm.ControlTextarea1">
        <Form.Label>Conclusiones</Form.Label>
        <Form.Control as="textarea" onChange={handleInputChange} name="conclution" rows={3} />
      </Form.Group>
      {/* charts */}
      {renderCharts()}
    </Form>
  );
};

FormMorbidity.propTypes = { GeneratePDF: PropTypes.func };

FormMorbidity.defaultProps = { GeneratePDF: function blank(params) { } };

export default FormMorbidity;
