import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import './TablePdf.scss';

const TablePdf = ({ header, content=[] }) => (
  <Table striped bordered style={{ maxHeight: "38rem" }} >
    <thead>
      <tr>
        <th>{ header }</th>
      </tr>
    </thead>
    <tbody>
      {
        content.map( row => (
          <tr>
            <td>{ row }</td>
          </tr>
        ) )
      }
    </tbody>
  </Table>
);

TablePdf.propTypes = {};

TablePdf.defaultProps = {};

export default TablePdf;
