import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./PdfSocioDemographic.scss";
import { Container, Row, Col, Spinner, Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import ArrowBack from "../../img/icons/iconArrowBack.svg";
import { useLocation } from "react-router-dom";
import { GET, POSTNORMAL } from "./../../constants/ApiRest";

//import components
import PdfReportGenerator from "./../../components/PdfReportGenerator/PdfReportGenerator";
import FormSocioDemographic from "./../../components/FormSocioDemographic/FormSocioDemographic";

//import data
// import data from "./data";
//import data from "../../abstract/morbidity";
//import data from "../../abstract/psychosocial";
import data from "./../../abstract/socioDemographic";
//import data from "../../abstract/dangerMatrix";

const PdfSocioDemographic = ({ setLogin }) => {
  let location = useLocation();

  const [viewPDF, setViewPDF] = useState(false);
  const [cover, setCover] = useState([]);
  const [toc, setToc] = useState([]);
  const [content, setContent] = useState([]);
  const [isData, setisData] = useState(false);

  //Mes de descarga del informe
  const monthNames = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  const date_application = new Date();
  const date = `${monthNames[date_application.getMonth()]}, ${date_application.getFullYear()}`;

  //datos que se colocan al pdf
  const [init, setInit] = useState({
    name: "",
    advisor: "",
    date: date,
    idDepartment: "",
    idCity: "",
    department: "",
    city: "",
    lic: "N° 1701 del 15 septiembre 2016",
  });
  const [contentS, setContentS] = useState({
    CompanyName: "",
    NIT: "",
    direction: "",
    phone: "",
    celPhone: "",
    CIIU: "",
    economicActi: "",
    riskClass: "",
    colaboratosNum: "",
    // legalRepresent: "",
    cordinatorSGSST: "",
    questAplication: "",
    resultsAnalysis: "",
    conclusions: "",
  });
  const [chartTables, setChartTables] = useState([
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: `La edad de la mayoría de los funcionarios de la empresa ${contentS.CompanyName}, oscila entre los (  ) y los (  ) años rango dentro del cual se agrupa el (%)`,
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis:
        "Según el tipo de documento se encontró que la mayoría de los funcionarios de la empresa cuenta con cedula, con un (%) de la población.",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis:
        "La población que labora en la empresa (), es predominantemente de género masculino con un (%) de hombres y (%) de mujeres, Otros (%)",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis:
        "La población que labora en la empresa (), es predominantemente de raza mestizo con un (%).",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis:
        "Dentro de la población trabajadora de la empresa (), predominan los funcionarios solteros, representan el (%), casados el (%), los que viven en unión libre, separados y los viudos con un (%) cada uno.",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis:
        "La observación del domicilio de los funcionarios examinados permite dar claridad a la alta frecuencia del factor de riesgo de accidentalidad vial.",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis:
        "Se evaluó el tipo de medio de transporte que utilizan los empleados de la empresa para desplazarse y se obtuvieron los siguientes resultados.",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis:
        "Se evidenció que el (%) de los funcionarios de la empresa () reside en zona urbana y el (%) en zona rural",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis:
        "Se evidencio que el (%) de la población habitan en vivienda familiar, el otro (%) en vivienda familiar y solo un (%) en vivienda alquilada.",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis:
        "se evidencia que el (%) de la población trabajadora cuenta con el servicio de energía, (%) con acueducto, (%) con alcantarillado, lo que indica déficit en los servicios básicos en la población general.",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis:
        "De la población trabajadora, el mayor porcentaje se encuentra en el estrato socioeconómico (  ) con un (%).",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis:
        "Según la clasificación si es o no cabeza de familia, la mayor proporción cumplen este criterio.",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis:
        "En este ítem sobre con quien se comparte la responsabilidad económica de la familia, se encontró que el (%) de la población trabajadora, la comparte con la ().",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis:
        "Solo el (%) de la población total de trabajadores no tiene hijos, el restante (%) si tienen hijos.",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "Se evidencia que el (%) de los trabajadores tienen personas a cargo.",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "De la población general de trabajadores, el (%) de estos, cuenta con ().",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "De las personas a cargo, se evidencia que el (%) de estas son menores de edad.",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: `De los (encuestados) trabajadores evaluados de la empresa (), () funcionarios que corresponden al (%) de la población, tienen estudios de pregrado.
      El segundo lugar lo ocupan quienes tienen estudios de (): () empleados que corresponden al (%). En tercer lugar, están los trabajadores con estudios de (), son 29 y corresponden al (%). El cuarto puesto corresponde a los funcionarios que tienen estudios técnicos (), corresponden al (%). El quinto lugar está representado por las personas que tienen estudios de (), son () y corresponden al (%) de la población.`,
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1", "d"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis:
        "Se ve una alta prevalencia con en personal que tiene vínculo con contrato Fijo o Indefinido, siendo el 70% de la población, por ende, prioridad en nuestro análisis, ya que dicho personal desempeña funciones dentro de las instalaciones de la empresa. Por otro lado, una baja prevalencia en vínculos laborales como, Prestación de servicios con 15%, Obra labor 10% y otros 0%, para un total del 100%",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis:
        "Se establecieron () cargos entre los funcionarios de la empresa () cuya distribución se representa así:",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis:
        "Se establecieron () categorías por quinquenios, para evaluar la antigüedad de vinculación de los funcionarios de la empresa () y se obtuvieron los siguientes resultados:",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis:
        "De acuerdo con los datos recolectados, se buscaba encontrar en qué proporción se distribuía el (%) de la población de trabajadores. Se halló que un (%) era Operativos y el otro (%) Administrativo, esto de acuerdo la cantidad de empleados por área. Es importante saber dicha distribución, para poder tener un panorama más claro de las condiciones en las que se encuentra expuesto un empleado.",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis:
        "Se muestra la distribución de empleado y cómo de un total poblacional, se halla una distribución dentro de las categorías de Jornada laboral, Diurno (%), Nocturno (%) y por Turnos (%)",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis:
        "Permite conocer el tiempo de exposición de la población, dependiendo de las diferentes variables de su puesto o lugar de trabajo.",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis:
        "Permite conocer el tiempo de exposición de la población, dependiendo de las diferentes variables de su puesto o lugar de trabajo.",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis:
        "de la utilización del tiempo libre de la población estudiada evidenció los siguientes resultados: El (%) de los trabajadores utiliza el tiempo libre en la práctica deportiva, el (%) en actividades clasificadas como “Otras”, dentro de las cuales se incluyen: paseos, ver televisión, actividades religiosas, estudio, turismo y continuación de la jornada laboral. El (%) refirió que dedica parte de su tiempo libre a caminar.",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: `Se consideraron dos variables para el estudio de los hábitos de la población y se encontró la siguiente distribución:

      El (%) de los trabajadores examinados consume licor y el (%) consume cigarrillo`,
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "Como considera su estado de salud en general",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis:
        "Con el análisis de los antecedentes personales de la población trabajadora de la empresa (), se evidenciaron los siguientes hallazgos:",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: `
      Una vez verificada la información sobre la percepción que tienen los funcionarios sobre la relación que tiene su trabajo con los síntomas que presentan en los últimos seis (6) meses, se encontró que el 54 % (68 personas) de la población examinada refiere síntomas que asocia con el desempeño del trabajo y el 46 % (59 personas) negó la presencia de síntomas relacionados con la labor que desempeña.
      `,
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis:
        "La población examinada fue analizada según el tipo de alimentación que consume; el (%) manifestó tener una dieta balanceada, el (%) dijo tener una alimentación no balanceada y el (%) no definió claramente sus costumbres alimentarias.",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis:
        "En alguna oportunidad, ha recibido alguna inducción o capacitación en seguridad y salud en el trabajo.",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "Conoce a que referencia el término ¿“procedimiento de trabajo seguro”?",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "¿Alguna vez ha sufrido un accidente de trabajo?",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "",
    },
  ]);

  useEffect(() => {
    //console.log(location.state.idCompany);
    GET(
      `${process.env.REACT_APP_REPORT_ENDPOINT}/formless/data/${location.state.idCompany}/${location.state.form_id}`
    )
      .then((result) => {
        setInitialContent(result.data);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.data.reload === true) {
            POSTNORMAL(process.env.REACT_APP_FILE_LOADER_ENDPOINT + "validate", {
              idCompany: location.state.idCompany,
              nitCompany: location.state.dataCompany.nit,
              form_id: location.state.form_id,
            })
              .then((response) => {
                window.location.reload();
              })
              .catch(console.log(err));
          }
        }
        console.log(err);
      });
  }, []);

  //Obtener empresa
  useEffect(() => {
    GET(`${process.env.REACT_APP_COMPANY_ENDPOINT}/company/getnit/${location.state.idCompany}}`)
      .then((result) => {
        const data = result.data;
        setInit({
          ...init,
          name: data.name,
          idDepartment: data.deparment_id,
          idCity: data.town_id,
          advisor: data.responsible,
        });
        contentS.CompanyName = data.name;
        contentS.NIT = data.nit;
        contentS.idSector = data.main_activity;
        contentS.workers = data.workers;
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //Obtener ciudad
  useEffect(() => {
    if (init.idDepartment !== "") {
      GET(`${process.env.REACT_APP_POLITICAL_SEGMENTATION_ENDPOINT}/${init.idDepartment}/children`)
        .then((result) => {
          const data = result.data;
          const nameCity = data.find((el) => el.id === init.idCity);
          setInit({ ...init, city: nameCity.name });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [init.idDepartment]);

  //Obtener departamento
  useEffect(() => {
    if (init.idDepartment !== "") {
      GET(`${process.env.REACT_APP_POLITICAL_SEGMENTATION_ENDPOINT}/13/children`)
        .then((result) => {
          const nameDepartment = result.data.find((el) => el.id === init.idDepartment);
          setInit({ ...init, department: nameDepartment.name });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [init.city]);

  const GeneratePDF = (key, value) => {
    setViewPDF(!viewPDF);
    switch (key) {
      case 1:
        let auxTable = [...chartTables];
        auxTable[19].table = value.distributionCharges.map((val) => Object.values(val));
        auxTable[30].table = value.distributionPersonalBackground.map((val) => Object.values(val));
        auxTable.map((val, i) => {
          auxTable[i].analysis = value.analysis[i];
        });
        contentS.resultsAnalysis = value.resultsAnalysis;
        contentS.conclusions = `•	La población trabajadora de la empresa ${init.name.toUpperCase()}, es equilibrada ya que el género Masculino (XX%) y femenino (XX%). Con edades que oscilan entre los XX años y XX años. 

        •	El XX% de la muestra analizada, utiliza como medio de transporte moto, automóvil o bicicleta, esto debe conllevar a intervenciones preventivas de la empresa en términos de Seguridad Vial, insistiendo en el cumplimiento de las normas de tránsito.
        
        •	Se evidencia que el XX% de la población analizada cuenta con las condiciones mínimas de calidad de vida, tales como residir en la ciudad (Cali) lo cual hace más viable el acceso a servicios tales como salud, educación y recreación, y al contar todos los servicios públicos básicos, se accede a las condiciones de higiene, seguridad, preparación de alimentación y actividades de recreación. 
        
        •	Dentro de la población estudiada de la empresa ${init.name.toUpperCase()}, se evidencia que el XX% de la población cuenta con personas a cargo y de estas el XX% son menores de edad, por lo que se hace importante fomentar actividades de recreación en pro del bienestar del colaborador y su familia.
        
        •	El XX% de la empresa ${init.name.toUpperCase()} realiza prácticas deportivas en su tiempo libre 
        
        •	En la empresa ${init.name.toUpperCase()} se evidenció en la población a estudio hábitos tales como fumar el XX%, así como el  consumo de bebidas alcohólicas con un XX% consume alcohol.
        
        •	El XX% de la población de la empresa ${init.name.toUpperCase()} manifestó que le han diagnosticado una o más enfermedades crónicas no transmisibles.  
        
        •	En la población encuestada el  XX% (XX personas) manifestaron presentar molestias en los últimos 6 meses, como son HTA.DM, alteraciones en la agudeza visual. 
        
        •	La empresa ${init.name.toUpperCase()}, la distribución de las molestias en los últimos 6 meses el XX% fue dolor de cabeza, seguido por dolor en el cuello y dolores musculares, lo que hace importante capacitar al personal en pausas activas y posturas adecuadas. 
        
        •	El XX% se alimenta más de tres veces al día, es importante educar sobre estilos de vida saludable para así ayudar a disminuir la incidencia de las enfermedades crónicas no transmisibles
        
        •	El XX% de la muestra de trabajadores de la empresa ${init.name.toUpperCase()}, tiene conocimiento sobre las actividades de Seguridad y Salud en el Trabajo teniendo claro algunos conceptos sobre trabajo seguro, pero aun así se recomienda realizar capacitación sobre el sistema de gestión de seguridad y salud en el trabajo.

        •	La empresa ${init.name.toUpperCase()}, cuenta con el XX% de trabajadores que no han presentado accidente durante la ejecución de su oficio. De la población que presento eventos el de mayor prevalencia fueron las caídas. Por esta razón es importante realizar capacitaciones al personal para autocuidado, detectar condiciones inseguras.

•	El XX% se generó el accidente con máquinas, equipos o herramientas, es importante realizar capacitaciones para el correcto uso de las mismas.  

•	El XX% de la población encuestada indico que la parte del cuerpo afectada fue las manos - brazos, tronco, piernas o pies, cabeza.

•	Los trabajadores de la empresa ${init.name.toUpperCase()} se encuentran expuesto en un XX% a posturas inadecuadas lo que hace importante generar campañas donde se socialice las posturas correcciones y campañas de autocuidado para el prevenir evento de riesgo biológico, y el correcto uso de la manipulación de cargas. 

•	La población encuestada de la empresa ${init.name.toUpperCase()} el XX% trabaja de manera acelerada, y han ampliado su jornada laboral para cumplir con sus trabajos, se recomienda capacitar al personal en manejo del estrés frente a situaciones desencadenantes como el trabajo bajo presión, incremento de la carga laboral. 

•	El XX% de la población encuestada indico que ha presentado dificultad para conciliar el sueño, por causa de la labor desempeñada, por lo que se  considera indispensable generar campañas de capacitación o acompañamiento psicolaboral. 

•	el XX% de los trabajadores de la empresa ${init.name.toUpperCase()} no ha presentado COVID-19

•	El XX% de la población encuestada de la empresa  ${init.name.toUpperCase()}, se vacunaría contra COVID-19

        `;
        setChartTables(auxTable);
        setContentS(contentS);
        break;

      default:
        break;
    }
    GenerateData();
  };

  const GenerateData = () => {
    const { cover: cov, toc: t, content: cont } = data(init, contentS, chartTables);
    setCover(cov);
    setToc(t);
    setContent(cont);
  };

  const setInitialContent = (data) => {
    setisData(true);

    const tmpChartTables = [...chartTables];

    //DISTRIBUCIÓN POR EDAD

    //Tabla 1. Distribución por edad
    tmpChartTables[0].table = [
      ["<18 años", data.jsonAnswer.ageRange18, data.jsonAnswer.percentageRange18],
      ["18-22 años", data.jsonAnswer.ageRange1822, data.jsonAnswer.percentaageRange1822],
      ["23-27 años", data.jsonAnswer.ageRange2327, data.jsonAnswer.percentaageRange2327],
      ["28-32 años", data.jsonAnswer.ageRange2832, data.jsonAnswer.percentaageRange2832],
      ["33-37 años", data.jsonAnswer.ageRange3337, data.jsonAnswer.percentaageRange3337],
      ["38-42 años", data.jsonAnswer.ageRange3842, data.jsonAnswer.percentaageRange3842],
      ["43-47 años", data.jsonAnswer.ageRange4347, data.jsonAnswer.percentaageRange4347],
      ["48-52 años", data.jsonAnswer.ageRange4852, data.jsonAnswer.percentaageRange4852],
      ["53-57 años", data.jsonAnswer.ageRange5357, data.jsonAnswer.percentaageRange5357],
      ["58-62 años", data.jsonAnswer.ageRange5862, data.jsonAnswer.percentaageRange5862],
      [">62 años", data.jsonAnswer.ageRange62, data.jsonAnswer.percentaageRange62],
      ["Total", data.jsonAnswer.workersSurvey, "100%"],
    ];

    //Grafico 1. Distribución por edad
    tmpChartTables[1].table = [
      ["<18 años", parseInt(data.jsonAnswer.percentageRange18)],
      ["18-22 años", parseInt(data.jsonAnswer.percentaageRange1822)],
      ["23-27 años", parseInt(data.jsonAnswer.percentaageRange2327)],
      ["28-32 años", parseInt(data.jsonAnswer.percentaageRange2832)],
      ["33-37 años", parseInt(data.jsonAnswer.percentaageRange3337)],
      ["38-42 años", parseInt(data.jsonAnswer.percentaageRange3842)],
      ["43-47 años", parseInt(data.jsonAnswer.percentaageRange4347)],
      ["48-52 años", parseInt(data.jsonAnswer.percentaageRange4852)],
      ["53-57 años", parseInt(data.jsonAnswer.percentaageRange5357)],
      ["58-62 años", parseInt(data.jsonAnswer.percentaageRange5862)],
      [">62 años", parseInt(data.jsonAnswer.percentaageRange62)],
    ];

    const orderAge = tmpChartTables[1].table.sort((a, b) => b[1] - a[1]);

    tmpChartTables[0].analysis =
      orderAge[1][1] === 0
        ? `La mayoría de los trabajadores encuestados se encuentran en el rango de ${orderAge[0][0]} equivalente a un ${orderAge[0][1]}%.`
        : `La mayoría de los trabajadores encuestados se encuentran en el rango de ${orderAge[0][0]} equivalente a un ${orderAge[0][1]}% seguido de un ${orderAge[1][1]}% el rango de ${orderAge[1][0]}, entre las edades de ${orderAge[2][0]} un ${orderAge[2][1]}%, con un ${orderAge[3][1]}% las edades entre ${orderAge[3][0]}.`;

    //DISTRIBUCIÓN POR TIPO DOCUMENTO

    //Tabla 2. Distribución por tipo de documento
    tmpChartTables[3].table = [
      ["C.C", data.jsonAnswer.cedula, data.jsonAnswer.percentacedula],
      ["C.E", data.jsonAnswer.cedulaE, data.jsonAnswer.percentacedulaE],
      ["Otro", data.jsonAnswer.otherCC, data.jsonAnswer.percentaotherCC],
      ["Total", data.jsonAnswer.workersSurvey, "100%"],
    ];

    //Grafico 2. Distribución por tipo de documento
    tmpChartTables[2].table = [
      ["C.C", parseInt(data.jsonAnswer.percentacedula)],
      ["C.E", parseInt(data.jsonAnswer.percentacedulaE)],
      ["Otro", parseInt(data.jsonAnswer.percentaotherCC)],
    ];

    const orderCC = tmpChartTables[2].table.sort((a, b) => b[1] - a[1]);

    tmpChartTables[3].analysis =
      orderCC[0][1] === 100
        ? `De acuerdo con la encuesta aplicada el tipo de documento que se encontró en todos los trabajadores fue cédula de ciudadanía colombiana, para un total del 100%.`
        : `De acuerdo con la encuesta aplicada el tipo de documento que más predominó fue ${orderCC[0][0]}, para un total del ${orderCC[0][1]}.`;

    //DISTRIBUCIÓN POR GENERO
    //Tabla 3. Distribución por genero
    tmpChartTables[4].table = [
      ["Femenino", data.jsonAnswer.womans, data.jsonAnswer.percentwomans],
      ["Masculino", data.jsonAnswer.mens, data.jsonAnswer.percentmens],
      ["Otro", data.jsonAnswer.otherGenre, data.jsonAnswer.percentotherGenre],
      ["Total", data.jsonAnswer.workersSurvey, "100%"],
    ];

    //Grafico 3. Distribución por genero
    tmpChartTables[7].table = [
      ["Femenino", parseInt(data.jsonAnswer.percentwomans)],
      ["Masculino", parseInt(data.jsonAnswer.percentmens)],
      ["OTRO", parseInt(data.jsonAnswer.percentotherGenre)],
    ];

    const orderGenro = tmpChartTables[7].table.sort((a, b) => b[1] - a[1]);

    tmpChartTables[4].analysis = `En los trabajadores encuestados, se evidencia un ${orderGenro[0][1]}% en el género ${orderGenro[0][0]} y un ${orderGenro[1][1]}% en el género ${orderGenro[1][0]}.`;

    //DISTRIBUCION ESTADO CIVIL

    //Tabla 4. Distribución de la población según el estado civil

    tmpChartTables[5].table = [
      ["Soltero/a", data.jsonAnswer.soltero, data.jsonAnswer.percentsoltero],
      ["Casado/a", data.jsonAnswer.casado, data.jsonAnswer.percentcasado],
      ["Unión libre ", data.jsonAnswer.uLibre, data.jsonAnswer.percentuLibre],
      ["Separado/a", data.jsonAnswer.divorciado, data.jsonAnswer.percentdivorciado],
      ["Viudo/a", data.jsonAnswer.viudo, data.jsonAnswer.percentviudo],
      ["Total", data.jsonAnswer.workersSurvey, "100%"],
    ];

    //Grafico 4. Distribución de la población según el estado civil
    tmpChartTables[6].table = [
      ["Soltero/a", data.jsonAnswer.percentsoltero],
      ["Casado/a", data.jsonAnswer.percentcasado],
      ["Unión libre", data.jsonAnswer.percentuLibre],
      ["Separado/a", data.jsonAnswer.percentdivorciado],
      ["Viudo/a", data.jsonAnswer.percentviudo],
    ];

    const copyCivil = tmpChartTables[5].table.concat();
    const orderCivil = copyCivil.sort((a, b) => b[1] - a[1]);
    tmpChartTables[5].analysis =
      orderCivil[0][2] === "100%"
        ? `Dentro de la población encuestada, predominan los trabajadores ${orderCivil[0][0]}, que representan el 100%`
        : `Dentro de la población encuestada, predominan los trabajadores ${orderCivil[0][0]}, que representan el ${orderCivil[0][2]}, seguido de las personas ${orderCivil[1][0]} con el ${orderCivil[1][2]} y ${orderCivil[2][0]} con el ${orderCivil[2][2]}.`;

    //4.5.	Distribución por ocupación actual

    //Tabla 5. Distribución por ocupación actual
    tmpChartTables[8].table = [
      ["Analista", data.jsonAnswer.analista, data.jsonAnswer.percentanalista],
      ["Asistente", data.jsonAnswer.asistente, data.jsonAnswer.percentasistente],
      ["Auxiliar", data.jsonAnswer.auxiliar, data.jsonAnswer.percentauxiliar],
      ["Auxiliar Administrativo", data.jsonAnswer.auxAdm, data.jsonAnswer.percentauxAdm],
      ["Auxiliar Operativo", data.jsonAnswer.auxOpe, data.jsonAnswer.percentauxOpe],
      ["Atención Al Cliente", data.jsonAnswer.atenCliente, data.jsonAnswer.percentatenCliente],
      ["Almacenista", data.jsonAnswer.almacenista, data.jsonAnswer.percentalmacenista],
      ["Ayudante", data.jsonAnswer.ayudante, data.jsonAnswer.percentayudante],
      ["Administrador", data.jsonAnswer.administrador, data.jsonAnswer.percentadministrador],
      ["Auditor", data.jsonAnswer.auditor, data.jsonAnswer.percentauditor],
      ["Asesor Call Center", data.jsonAnswer.AsesorCallCen, data.jsonAnswer.percentAsesorCallCen],
      ["Asesor", data.jsonAnswer.asesor, data.jsonAnswer.percentasesor],
      ["Aprendiz", data.jsonAnswer.aprendiz, data.jsonAnswer.percentaprendiz],
      ["Barista", data.jsonAnswer.barista, data.jsonAnswer.percentbarista],
      ["Bodeguero", data.jsonAnswer.bodeguero, data.jsonAnswer.percentbodeguero],
      ["Carpintero", data.jsonAnswer.carpintero, data.jsonAnswer.percentcarpintero],
      ["Cajero", data.jsonAnswer.cajero, data.jsonAnswer.percentcajero],
      ["Coordinador", data.jsonAnswer.coordinador, data.jsonAnswer.percentcoordinador],
      ["Cocinero", data.jsonAnswer.cocinero, data.jsonAnswer.percentcocinero],
      ["Chef", data.jsonAnswer.chef, data.jsonAnswer.percentchef],
      ["Cortador", data.jsonAnswer.cortador, data.jsonAnswer.percentcortador],
      ["Contador", data.jsonAnswer.contador, data.jsonAnswer.percentcontador],
      ["Conductor", data.jsonAnswer.conductor, data.jsonAnswer.percentconductor],
      ["Contratista ", data.jsonAnswer.contratista, data.jsonAnswer.percentcontratista],
      [
        "Director Regional",
        data.jsonAnswer.directorRegional,
        data.jsonAnswer.percentdirectorRegional,
      ],
      ["Director De Area", data.jsonAnswer.directorArea, data.jsonAnswer.percentdirectorArea],
      ["Director General", data.jsonAnswer.directorGeneral, data.jsonAnswer.percentdirectorGeneral],
      ["Despachador", data.jsonAnswer.despachador, data.jsonAnswer.percentdespachador],
      ["Diseño", data.jsonAnswer.diseño, data.jsonAnswer.percentdiseño],
      ["Dibujante", data.jsonAnswer.dibujante, data.jsonAnswer.percentdibujante],
      ["Domiciliario", data.jsonAnswer.domiciliario, data.jsonAnswer.percentdomiciliario],
      ["Ensamblador", data.jsonAnswer.ensamblador, data.jsonAnswer.percentensamblador],
      ["Encargado Sst", data.jsonAnswer.encargadosst, data.jsonAnswer.percentencargadosst],
      ["Estilista", data.jsonAnswer.estilista, data.jsonAnswer.percentestilista],
      ["Editor", data.jsonAnswer.editor, data.jsonAnswer.percenteditor],
      ["Facturación ", data.jsonAnswer.facturacion, data.jsonAnswer.percentfacturacion],
      ["Gerente", data.jsonAnswer.gerente, data.jsonAnswer.percentgerente],
      ["Hornero", data.jsonAnswer.hornero, data.jsonAnswer.percenthornero],
      ["Instructor", data.jsonAnswer.instructor, data.jsonAnswer.percentinstructor],
      ["Ingeniero", data.jsonAnswer.ingeniero, data.jsonAnswer.percentingeniero],
      ["Jefe De Area", data.jsonAnswer.jefeArea, data.jsonAnswer.percentjefeArea],
      ["Jardinero", data.jsonAnswer.jardinero, data.jsonAnswer.percentjardinero],
      ["Logistica", data.jsonAnswer.logistica, data.jsonAnswer.percentlogistica],
      ["Lider", data.jsonAnswer.lider, data.jsonAnswer.percentlider],
      ["Mesero", data.jsonAnswer.mesero, data.jsonAnswer.percentmesero],
      ["Mecanico", data.jsonAnswer.mecanico, data.jsonAnswer.percentmecanico],
      ["Mensajero", data.jsonAnswer.mensajero, data.jsonAnswer.percentmensajero],
      ["Maestro De Obra", data.jsonAnswer.maestroObra, data.jsonAnswer.percentmaestroObra],
      ["Mercaderista", data.jsonAnswer.mercaderista, data.jsonAnswer.percentmercaderista],
      ["Operario ", data.jsonAnswer.operario, data.jsonAnswer.percentoperario],
      ["Operario De Aseo", data.jsonAnswer.operarioAseo, data.jsonAnswer.percentoperarioAseo],
      [
        "Operario De Maquina",
        data.jsonAnswer.operarioMaquina,
        data.jsonAnswer.percentoperarioMaquina,
      ],
      ["Oficios Varios", data.jsonAnswer.oficiosVarios, data.jsonAnswer.percentoficiosVarios],
      ["Obrero", data.jsonAnswer.obrero, data.jsonAnswer.percentobrero],
      ["Oficial", data.jsonAnswer.oficial, data.jsonAnswer.percentoficial],
      ["Practicante", data.jsonAnswer.practicante, data.jsonAnswer.percentpracticante],
      [
        "Profesional En Salud",
        data.jsonAnswer.profesionalSalud,
        data.jsonAnswer.percentprofesionalSalud,
      ],
      ["Procesador", data.jsonAnswer.procesador, data.jsonAnswer.percentprocesador],
      ["Pintor", data.jsonAnswer.pintor, data.jsonAnswer.percentpintor],
      ["Recepcionista", data.jsonAnswer.recepcionista, data.jsonAnswer.percentrecepcionista],
      [
        "Representante Legal",
        data.jsonAnswer.representanteLegal,
        data.jsonAnswer.percentrepresentanteLegal,
      ],
      ["Supervisor ", data.jsonAnswer.supervisor, data.jsonAnswer.percentsupervisor],
      ["Secretario/a", data.jsonAnswer.secretario, data.jsonAnswer.percentsecretario],
      [
        "Servicio Al Cliente",
        data.jsonAnswer.servicioCliente,
        data.jsonAnswer.percentservicioCliente,
      ],
      ["Seguridad", data.jsonAnswer.seguridad, data.jsonAnswer.percentseguridad],
      ["Soporte Tecnico", data.jsonAnswer.soporteTecnico, data.jsonAnswer.percentsoporteTecnico],
      ["Supernumerario", data.jsonAnswer.supernumerario, data.jsonAnswer.percentsupernumerario],
      ["Tecnico", data.jsonAnswer.tecnicoCargo, data.jsonAnswer.percenttecnicoCargo],
      ["Vendedor", data.jsonAnswer.vendedor, data.jsonAnswer.percentvendedor],
    ];

    //Gráfica 5. Distribución por ocupación actual

    tmpChartTables[12].table = [
      ["Analista", data.jsonAnswer.percentanalista],
      ["Asistente", data.jsonAnswer.percentasistente],
      ["Auxiliar", data.jsonAnswer.percentauxiliar],
      ["Auxiliar Administrativo", data.jsonAnswer.percentauxAdm],
      ["Auxiliar Operativo", data.jsonAnswer.percentauxOpe],
      ["Atención Al Cliente", data.jsonAnswer.percentatenCliente],
      ["Almacenista", data.jsonAnswer.percentalmacenista],
      ["Ayudante", data.jsonAnswer.percentayudante],
      ["Administrador", data.jsonAnswer.percentadministrador],
      ["Auditor", data.jsonAnswer.auditor],
      ["Asesor Call Center", data.jsonAnswer.percentAsesorCallCen],
      ["Asesor", data.jsonAnswer.percentasesor],
      ["Aprendiz", data.jsonAnswer.percentaprendiz],
      ["Barista", data.jsonAnswer.percentbarista],
      ["Bodeguero", data.jsonAnswer.percentbodeguero],
      ["Carpintero", data.jsonAnswer.percentcarpintero],
      ["Cajero", data.jsonAnswer.percentcajero],
      ["Coordinador", data.jsonAnswer.percentcoordinador],
      ["Cocinero", data.jsonAnswer.percentcocinero],
      ["Chef", data.jsonAnswer.percentchef],
      ["Cortador", data.jsonAnswer.percentcortador],
      ["Contador", data.jsonAnswer.percentcontador],
      ["Conductor", data.jsonAnswer.percentconductor],
      ["Contratista ", data.jsonAnswer.percentcontratista],
      ["Director Regional", data.jsonAnswer.percentdirectorRegional],
      ["Director De Area", data.jsonAnswer.percentdirectorArea],
      ["Director General", data.jsonAnswer.percentdirectorGeneral],
      ["Despachador", data.jsonAnswer.percentdespachador],
      ["Diseño", data.jsonAnswer.percentdiseño],
      ["Dibujante", data.jsonAnswer.percentdibujante],
      ["Domiciliario", data.jsonAnswer.percentdomiciliario],
      ["Ensamblador", data.jsonAnswer.percentensamblador],
      ["Encargado Sst", data.jsonAnswer.percentencargadosst],
      ["Estilista", data.jsonAnswer.percentestilista],
      ["Editor", data.jsonAnswer.percenteditor],
      ["Facturación ", data.jsonAnswer.percentfacturacion],
      ["Gerente", data.jsonAnswer.percentgerente],
      ["Hornero", data.jsonAnswer.percenthornero],
      ["Instructor", data.jsonAnswer.percentinstructor],
      ["Ingeniero", data.jsonAnswer.percentingeniero],
      ["Jefe De Area", data.jsonAnswer.percentjefeArea],
      ["Jardinero", data.jsonAnswer.percentjardinero],
      ["Logistica", data.jsonAnswer.percentlogistica],
      ["Lider", data.jsonAnswer.percentlider],
      ["Mesero", data.jsonAnswer.percentmesero],
      ["Mecanico", data.jsonAnswer.percentmecanico],
      ["Mensajero", data.jsonAnswer.percentmensajero],
      ["Maestro De Obra", data.jsonAnswer.percentmaestroObra],
      ["Mercaderista", data.jsonAnswer.percentmercaderista],
      ["Operario ", data.jsonAnswer.percentoperario],
      ["Operario De Aseo", data.jsonAnswer.percentoperarioAseo],
      ["Operario De Maquina", data.jsonAnswer.percentoperarioMaquina],
      ["Oficios Varios", data.jsonAnswer.percentoficiosVarios],
      ["Obrero", data.jsonAnswer.percentobrero],
      ["Oficial", data.jsonAnswer.percentoficial],
      ["Practicante", data.jsonAnswer.percentpracticante],
      ["Profesional En Salud", data.jsonAnswer.percentprofesionalSalud],
      ["Procesador", data.jsonAnswer.percentprocesador],
      ["Pintor", data.jsonAnswer.percentpintor],
      ["Recepcionista", data.jsonAnswer.percentrecepcionista],
      ["Representante Legal", data.jsonAnswer.percentrepresentanteLegal],
      ["Supervisor ", data.jsonAnswer.percentsupervisor],
      ["Secretario/a", data.jsonAnswer.percentsecretario],
      ["Servicio Al Cliente", data.jsonAnswer.percentservicioCliente],
      ["Seguridad", data.jsonAnswer.percentseguridad],
      ["Soporte Tecnico", data.jsonAnswer.percentsoporteTecnico],
      ["Supernumerario", data.jsonAnswer.percentsupernumerario],
      ["Tecnico", data.jsonAnswer.percenttecnicoCargo],
      ["Vendedor", data.jsonAnswer.percentvendedor],
      ["Total", data.jsonAnswer.workersSurvey],
    ];

    const newTableFive = tmpChartTables[8].table.concat();
    newTableFive.pop();

    const copyOcupacion = newTableFive;
    const orderOcupacion = copyOcupacion.sort((a, b) => b[1] - a[1]);

    tmpChartTables[8].analysis =
      orderOcupacion[0][2] === "100%"
        ? `El ${orderOcupacion[0][2]} de los trabajadores encuestados ocupan el cargo de ${orderOcupacion[0][0]}.`
        : `El ${orderOcupacion[0][2]} de los trabajadores encuestados ocupan el cargo de ${orderOcupacion[0][0]}, el ${orderOcupacion[1][2]} de los trabajadores encuestados ocupan el cargo de ${orderOcupacion[1][0]}, el ${orderOcupacion[2][2]} de los trabajadores encuestados ocupan el cargo de ${orderOcupacion[2][0]} y finalmente el ${orderOcupacion[3][2]} de los trabajadores encuestados ocupan el cargo de ${orderOcupacion[3][0]}`;

    //4.6.	Distribución de la población según el medio de transporte

    // Tabla 6. Distribución de la población según el medio de transporte

    tmpChartTables[9].table = [
      ["Auto particular", data.jsonAnswer.automovil, data.jsonAnswer.percentautomovil],
      ["Moto particular", data.jsonAnswer.moto, data.jsonAnswer.percentmoto],
      ["Bicicleta", data.jsonAnswer.bici, data.jsonAnswer.percentbici],
      ["Transporte publico", data.jsonAnswer.tpublico, data.jsonAnswer.percenttpublico],
      ["Otro", data.jsonAnswer.transporteOtro, data.jsonAnswer.percenttransporteOtro],
      ["Total", data.jsonAnswer.workersSurvey, "100%"],
    ];

    //Gráfica 6. Distribución de la población según el medio de transporte
    tmpChartTables[15].table = [
      ["Auto particular", data.jsonAnswer.percentautomovil],
      ["Moto particular", data.jsonAnswer.percentmoto],
      ["Bicicleta", data.jsonAnswer.percentbici],
      ["Transporte publico", data.jsonAnswer.percenttpublico],
      ["Otro", data.jsonAnswer.percenttransporteOtro],
    ];

    const copyTransporte = tmpChartTables[15].table.concat();
    const orderTransporte = copyTransporte.sort((a, b) => b[1] - a[1]);

    tmpChartTables[9].analysis =
      orderTransporte[0][1] === "100%"
        ? `El ${orderTransporte[0][1]} de los empleados de la empresa se desplaza en ${orderTransporte[0][0]}.`
        : `El ${orderTransporte[0][1]} de los empleados de la empresa se desplaza en ${orderTransporte[0][0]}, el ${orderTransporte[1][1]} en ${orderTransporte[1][0]}, el ${orderTransporte[2][1]} en ${orderTransporte[2][0]}, el ${orderTransporte[3][1]} en ${orderTransporte[3][0]} y el ${orderTransporte[4][1]} en ${orderTransporte[4][0]}.`;

    //4.7.	Distribución según la zona de residencia

    //  Tabla 7. Distribución según la zona de residencia
    tmpChartTables[10].table = [
      ["Urbana", data.jsonAnswer.urbana, data.jsonAnswer.percenturbana],
      ["Rural", data.jsonAnswer.rural, data.jsonAnswer.percentrural],
      ["Total", data.jsonAnswer.workersSurvey, "100%"],
    ];

    // Gráfica 7. Distribución según la zona de residencia

    tmpChartTables[11].table = [
      ["Urbana", data.jsonAnswer.percenturbana],
      ["Rural", data.jsonAnswer.percentrural],
    ];

    const orderZona = tmpChartTables[11].table.sort((a, b) => b[1] - a[1]);

    tmpChartTables[10].analysis =
      orderZona[0][1] === "100%"
        ? `Se evidenció que el ${orderZona[0][1]} de los trabajadores residen en el ${orderZona[0][0]}.`
        : `Se evidenció que el ${orderZona[0][1]} de los trabajadores residen en el ${orderZona[0][0]} y el ${orderZona[1][1]} en ${orderZona[1][0]} .`;

    //4.8.	Distribución según el tipo de vivienda donde habita

    //Tabla 8. Distribución según el tipo de vivienda donde habita
    tmpChartTables[17].table = [
      ["Propia", data.jsonAnswer.propia, data.jsonAnswer.percentpropia],
      ["Alquilada", data.jsonAnswer.arrendada, data.jsonAnswer.percentarrendada],
      ["Familiar", data.jsonAnswer.familiar, data.jsonAnswer.percentfamiliar],
      ["Total", data.jsonAnswer.workersSurvey, "100%"],
    ];

    //Gráfica 8. Distribución según el tipo de vivienda donde habita

    tmpChartTables[20].table = [
      ["Propia", data.jsonAnswer.percentpropia],
      ["Alquilada", data.jsonAnswer.percentarrendada],
      ["Familiar", data.jsonAnswer.percentfamiliar],
    ];

    const orderVivienda = tmpChartTables[20].table.sort((a, b) => b[1] - a[1]);

    tmpChartTables[17].analysis =
      orderVivienda[0][1] === "100%"
        ? `Se evidenció que el ${orderVivienda[0][1]} de la población de trabajadores encuestados, habita en vivienda ${orderVivienda[0][0]}.`
        : `Se evidenció que el ${orderVivienda[0][1]} de la población de trabajadores encuestados, habita en vivienda ${orderVivienda[0][0]}, el otro ${orderVivienda[1][1]} en vivienda ${orderVivienda[1][0]} y un ${orderVivienda[2][1]} en vivienda ${orderVivienda[2][0]}.`;

    //4.9.	Distribución si es o no cabeza de familia

    //Tabla 9. Distribución según la clasificación si es o no cabeza de familia

    tmpChartTables[16].table = [
      ["Si", data.jsonAnswer.siCabezaFamilia, data.jsonAnswer.percentsiCabezaFamilia],
      ["No", data.jsonAnswer.noCabezaFamilia, data.jsonAnswer.percentnoCabezaFamilia],
      ["Total", data.jsonAnswer.workersSurvey, "100%"],
    ];

    //Gráfica 9. Distribución según la clasificación si es o no cabeza de familia
    tmpChartTables[14].table = [
      ["Si", data.jsonAnswer.percentsiCabezaFamilia],
      ["No", data.jsonAnswer.percentnoCabezaFamilia],
    ];

    const orderCabeza = tmpChartTables[14].table.sort((a, b) => b[1] - a[1]);

    tmpChartTables[16].analysis =
      tmpChartTables[14].table[0][1] > tmpChartTables[14].table[0][2]
        ? `Según la clasificación si es o no cabeza de familia, la mayor proporción de trabajadores encuestados reportan si ser cabeza de familia con un ${orderCabeza[0][1]}.`
        : `Según la clasificación si es o no cabeza de familia, la mayor proporción de trabajadores encuestados reportan no ser cabeza de familia con un ${orderCabeza[1][1]}.`;

    //4.10.	Distribución según el número de personas a cargo

    //Tabla 10. Distribución según el número de personas a cargo
    tmpChartTables[18].table = [
      [
        "Menor o igual a 2",
        data.jsonAnswer.personasCargo1a2,
        data.jsonAnswer.percentpersonasCargo1a2,
      ],
      ["De 3 a 5", data.jsonAnswer.personasCargo3a5, data.jsonAnswer.percentpersonasCargo3a5],
      ["Mas de 5", data.jsonAnswer.personasCargo5, data.jsonAnswer.percentpersonasCargo5],
      [
        "No aplica",
        data.jsonAnswer.personasCargoNinguna,
        data.jsonAnswer.percentpersonasCargoNinguna,
      ],
      ["Total", data.jsonAnswer.workersSurvey, "100%"],
    ];

    //Gráfica 10. Distribución según el número de personas a cargo
    tmpChartTables[19].table = [
      ["Menor o igual a 2", parseInt(data.jsonAnswer.percentpersonasCargo1a2)],
      ["De 3 a 5", parseInt(data.jsonAnswer.percentpersonasCargo3a5)],
      ["Mas de 5", parseInt(data.jsonAnswer.percentpersonasCargo5)],
      ["No aplica", parseInt(data.jsonAnswer.percentpersonasCargoNinguna)],
    ];

    const copyCargo = tmpChartTables[19].table.concat();
    const orderCargo = copyCargo.sort((a, b) => b[1] - a[1]);

    tmpChartTables[18].analysis =
      orderCargo[0][0] === "No aplica"
        ? `De la población general de trabajadores, el ${orderCargo[0][1]}% de estos no tiene personas a cargo.`
        : `De la población general de trabajadores, el ${orderCargo[0][1]}% de estos, cuenta con ${orderCargo[0][0]} personas a cargo, el ${orderCargo[1][1]}% de ${orderCargo[1][0]} personas y el ${orderCargo[2][1]}% ${orderCargo[2][0]} personas a cargo y el ${orderCargo[3][1]}% ${orderCargo[3][0]} personas a cargo.`;

    //4.11.	Cuál es el nivel de educación más alto que ha recibido

    //Tabla 11. Nivel más alto de educación recibido

    tmpChartTables[21].table = [
      ["Primaria", data.jsonAnswer.primaria, data.jsonAnswer.percentprimaria],
      ["Bachillerato", data.jsonAnswer.bachillerato, data.jsonAnswer.percentbachillerato],
      ["Técnico/Tecnólogo", data.jsonAnswer.tecnico, data.jsonAnswer.percenttecnico],
      [
        "Profesional",
        data.jsonAnswer.profesionalEducacion,
        data.jsonAnswer.percentprofesionalEducacion,
      ],
      ["Postgrado", data.jsonAnswer.posgrado, data.jsonAnswer.percentposgrado],
      ["Ninguno", data.jsonAnswer.ningunoEducacion, data.jsonAnswer.percentningunoEducacion],
      ["Total", data.jsonAnswer.workersSurvey, "100%"],
    ];

    //Gráfica 11. Nivel más alto de educación recibido
    tmpChartTables[23].table = [
      ["Primaria", data.jsonAnswer.percentprimaria, data.jsonAnswer.percentprimaria],
      ["Bachillerato", data.jsonAnswer.percentbachillerato, data.jsonAnswer.percentbachillerato],
      ["Técnico/Tecnólogo", data.jsonAnswer.percenttecnico, data.jsonAnswer.percenttecnico],
      [
        "Profesional",
        data.jsonAnswer.percentprofesionalEducacion,
        data.jsonAnswer.percentprofesionalEducacion,
      ],
      ["Postgrado", data.jsonAnswer.percentposgrado, data.jsonAnswer.percentposgrado],
      ["Ninguno", data.jsonAnswer.percentningunoEducacion, data.jsonAnswer.percentningunoEducacion],
    ];

    const copyEducacion = tmpChartTables[23].table.concat();
    const orderEducacion = copyEducacion.sort((a, b) => b[1] - a[1]);

    tmpChartTables[21].analysis =
      orderEducacion[0][2] === "100%"
        ? `Según la población encuestada, el ${orderEducacion[0][2]} ha recibido educación ${orderEducacion[0][0]}.`
        : `Según la población encuestada, el ${orderEducacion[0][2]} ha recibido educación ${orderEducacion[0][0]}, seguido de educación ${orderEducacion[1][0]} con un ${orderEducacion[1][2]}, y ${orderEducacion[2][0]} con un ${orderEducacion[2][2]}.`;

    //4.12.	Promedio de ingresos mensuales

    //Tabla 12. Ingresos mensuales

    tmpChartTables[22].table = [
      ["Menos de 1 SMLV", data.jsonAnswer.smlv1, data.jsonAnswer.percentsmlv1],
      ["De 1 a 2 SMLV", data.jsonAnswer.smlv12, data.jsonAnswer.percentsmlv12],
      ["De 2 a 3 SMLV", data.jsonAnswer.smlv23, data.jsonAnswer.percentsmlv23],
      ["De 3 a 4 SMLV", data.jsonAnswer.smlv34, data.jsonAnswer.percentsmlv34],
      ["Mas de 4 SMLV", data.jsonAnswer.smlv4, data.jsonAnswer.percentsmlv4],
      ["Total", data.jsonAnswer.workersSurvey, "100%"],
    ];

    //Gráfica 12. Ingresos mensuales
    tmpChartTables[25].table = [
      ["Menos de 1 SMLV", data.jsonAnswer.percentsmlv1, data.jsonAnswer.percentsmlv1],
      ["De 1 a 2 SMLV", data.jsonAnswer.percentsmlv12, data.jsonAnswer.percentsmlv12],
      ["De 2 a 3 SMLV", data.jsonAnswer.percentsmlv23, data.jsonAnswer.percentsmlv23],
      ["De 3 a 4 SMLV", data.jsonAnswer.percentsmlv34, data.jsonAnswer.percentsmlv34],
      ["Mas de 4 SMLV", data.jsonAnswer.percentsmlv4, data.jsonAnswer.percentsmlv4],
    ];

    const copyIngresos = tmpChartTables[25].table.concat();
    const orderIngresos = copyIngresos.sort((a, b) => b[1] - a[1]);

    tmpChartTables[22].analysis =
      orderIngresos[0][2] === "100%"
        ? `Se evidencia que los ingresos mensuales recibidos por los encuestados son ${orderIngresos[0][0]} con un ${orderIngresos[0][2]}`
        : `Se evidencia que los ingresos mensuales recibidos por los encuestados son ${orderIngresos[0][0]} con un ${orderIngresos[0][2]}, seguido de ${orderIngresos[1][0]} con un ${orderIngresos[1][2]} y de ${orderIngresos[2][0]} con un ${orderIngresos[2][2]} y ${orderIngresos[3][0]} con un ${orderIngresos[3][2]}.`;

    //4.13.	Antigüedad en el oficio actual

    //Tabla 13. Antigüedad en el oficio actual

    tmpChartTables[24].table = [
      ["Menos de 1 año", data.jsonAnswer.antiguedad1, data.jsonAnswer.percentantiguedad1],
      ["De 1 a 5 años", data.jsonAnswer.antiguedad15, data.jsonAnswer.percentantiguedad15],
      ["De 6 a 10 años", data.jsonAnswer.antiguedad610, data.jsonAnswer.percentantiguedad610],
      ["Más de 10 años", data.jsonAnswer.antiguedad10, data.jsonAnswer.percentantiguedad10],
      ["Total", data.jsonAnswer.workersSurvey, "100%"],
    ];

    //Gráfica 13. Antigüedad en el oficio actual
    tmpChartTables[26].table = [
      ["Menos de 1 año", data.jsonAnswer.percentantiguedad1],
      ["De 1 a 5 años", data.jsonAnswer.percentantiguedad15],
      ["De 6 a 10 años", data.jsonAnswer.percentantiguedad610],
      ["Más de 10 años", data.jsonAnswer.percentantiguedad10],
    ];

    const copyAntiguedad = tmpChartTables[26].table.concat();
    const orderAntiguedad = copyAntiguedad.sort((a, b) => b[1] - a[1]);

    tmpChartTables[24].analysis =
      orderAntiguedad[0][2] === "100%"
        ? `De acuerdo con la población encuestada, refiere que el ${orderAntiguedad[0][2]} tiene una antigüedad ${orderAntiguedad[0][0]}.`
        : `De acuerdo con la población encuestada, refiere que el ${orderAntiguedad[0][1]} tiene una antigüedad ${orderAntiguedad[0][0]}, entre ${orderAntiguedad[1][0]} un ${orderAntiguedad[1][2]}, de ${orderAntiguedad[2][0]} se evidencia un ${orderAntiguedad[2][2]}.`;

    //4.14.	Cuál es su forma de vinculación laboral

    //Tabla 14. Vinculación laboral

    tmpChartTables[27].table = [
      [
        "Contrato termino fijo o indefinido",
        data.jsonAnswer.indefinido,
        data.jsonAnswer.percentindefinido,
      ],
      ["Obra labor contratada", data.jsonAnswer.obraLabor, data.jsonAnswer.percentobraLabor],
      ["Prestación de servicios", data.jsonAnswer.pservicios, data.jsonAnswer.percentpservicios],
      ["Otro (especifique)", data.jsonAnswer.otro, data.jsonAnswer.percentotro],
      ["Total", data.jsonAnswer.workersSurvey, "100%"],
    ];

    //Gráfica 14. Vinculación laboral
    tmpChartTables[28].table = [
      ["Contrato termino fijo o indefinido", parseInt(data.jsonAnswer.percentindefinido)],
      ["Obra labor contratada", parseInt(data.jsonAnswer.percentobraLabor)],
      ["Prestación de servicios", parseInt(data.jsonAnswer.percentpservicios)],
      ["Otro (especifique)", parseInt(data.jsonAnswer.percentotro)],
    ];

    tmpChartTables[27].analysis =
      tmpChartTables[27].table[0][2] === "100%"
        ? `El ${tmpChartTables[27].table[0][2]} de los encuestados manifiestan tener contrato laboral fijo o indefinido, siendo este generador de una estabilidad laboral`
        : `El ${tmpChartTables[27].table[0][2]} de los encuestados manifiestan tener contrato laboral fijo o indefinido, siendo este generador de una estabilidad laboral, al igual que el contrato por obra labor con un ${tmpChartTables[27].table[1][2]}, lo cual garantiza prestaciones económicas al trabajador.`;

    //4.15.	Como es su jornada laboral

    //Tabla 15. Jornada Laboral
    tmpChartTables[29].table = [
      ["Diurna", data.jsonAnswer.WorkdayDiu, data.jsonAnswer.percentWorkdayDiu],
      ["Nocturna", data.jsonAnswer.WorkdayNoc, data.jsonAnswer.percentWorkdayNoc],
      ["Turnos", data.jsonAnswer.WorkdayTur, data.jsonAnswer.percentWorkdayTur],
      ["Por horas", data.jsonAnswer.WorkdayHor, data.jsonAnswer.percentWorkdayHor],
      ["Total", data.jsonAnswer.workersSurvey, "100%"],
    ];

    //Gráfica 15. Jornada Laboral
    tmpChartTables[31].table = [
      ["Diurna", data.jsonAnswer.percentWorkdayDiu],
      ["Nocturna", data.jsonAnswer.percentWorkdayNoc],
      ["Turnos", data.jsonAnswer.percentWorkdayTur],
      ["Por horas", data.jsonAnswer.percentWorkdayHor],
    ];

    const newTable = tmpChartTables[29].table.concat();
    newTable.pop();

    const order15 = newTable.sort((a, b) => b[1] - a[1]);

    tmpChartTables[29].analysis =
      order15[0][2] === "100%"
        ? `De acuerdo con la población encuestada se evidencia que la mayoría de los trabajadores realizan sus labores en la jornada ${order15[0][0]} con un ${order15[0][2]}.`
        : `De acuerdo con la población encuestada se evidencia que la mayoría de los trabajadores realizan sus labores en la jornada ${order15[0][0]} con un ${order15[0][2]}, seguido de labores por ${order15[1][0]} con un ${order15[1][2]}.`;

    //5.1.	Distribución de la población según el uso del tiempo libre

    //Gráfica 16. Distribución según el uso del tiempo libre
    //Gráfica 15. Jornada Laboral
    tmpChartTables[35].table = [
      ["A diario", data.jsonAnswer.percentsportsDia],
      ["1 o 2 veces al dia", data.jsonAnswer.percentsports1Sem],
      ["15 días", data.jsonAnswer.percentsports15Dia],
      ["Ocasionalmente", data.jsonAnswer.percentsportsOca],
      ["No practica", data.jsonAnswer.percentsportsNoPra],
    ];

    const totalPractica =
      parseInt(data.jsonAnswer.percentsportsDia) +
      parseInt(data.jsonAnswer.percentsports1Sem) +
      parseInt(data.jsonAnswer.percentsports15Dia) +
      parseInt(data.jsonAnswer.percentsportsOca);

    tmpChartTables[35].analysis = `La recreación constituye un derecho fundamental del ser humano, de acuerdo con la O.N.U es la sexta necesidad básica después de la nutrición, la educación, la vivienda, el trabajo y la seguridad social, constituye un medio de unidad integral e integradora que promueve el desarrollo intelectual, emocional, físico y psicológico del individuo. Entendemos por tiempo libre el tiempo disponible, es decir, el que no utilizamos para trabajar, comer o dormir. El tiempo libre tiene una capacidad virtual, es tiempo a nuestra disposición que podemos utilizar adecuadamente o malgastar.

    Teniendo en cuenta lo anterior, la utilización del tiempo libre de la población estudiada de la empresa evidenció los siguientes resultados:\n 
    •	El ${totalPractica}% de los trabajadores refiere que realiza prácticas deportivas.
    • El ${tmpChartTables[35].table[4][1]} de los mismos, presentan un estilo de vida más sedentario, lo que puede generar afectaciones para la salud a nivel cardiovascular.`;

    //5.2.	Distribución de la población según los hábitos

    //Gráfica 17. Población fumadora

    tmpChartTables[33].table = [
      ["A diario", data.jsonAnswer.percentssmokeDia],
      ["Ocasionalmente", data.jsonAnswer.percentssmokeOca],
      ["No fuma", data.jsonAnswer.percentssmokeNoF],
    ];

    //Gráfica 18. Población que consume licor

    tmpChartTables[34].table = [
      ["A diario", data.jsonAnswer.percentsalcoholDia],
      ["1 o 2 veces al dia", data.jsonAnswer.percentsalcohol1Sem],
      ["15 días", data.jsonAnswer.percentsalcoholC15D],
      ["Ocasionalmente", data.jsonAnswer.percentsalcoholOca],
      ["No consume", data.jsonAnswer.percentsalcoholNoCo],
    ];

    tmpChartTables[33].analysis = `Se consideraron dos variables para el estudio de los hábitos de la población y se encontró la siguiente distribución en los trabajadores encuestados:\n
    El ${data.jsonAnswer.percentsalcoholDia} de los trabajadores examinados consume licor y el ${data.jsonAnswer.percentssmokeDia} consume cigarrillo, los cuales son hábitos que no favorecen un estado de salud óptimo.
    `;

    //5.3.	Distribución de la población según el número de alimentos que recibe en el día

    //Gráfica 19. Cuantas veces se alimenta al día

    tmpChartTables[36].table = [
      ["Menos de 3 veces", data.jsonAnswer.percentsfood3],
      ["Igual o mas de 3 veces", data.jsonAnswer.percentsfoodM3],
    ];

    tmpChartTables[36].analysis = `La población de trabajadores encuestados fue analizada según la cantidad de alimentos que consume en su vida cotidiana, donde se evidencia que el ${tmpChartTables[36].table[1][1]} manifestó tener una distribución alimenticia entre 3 o más comidas al día, y el ${tmpChartTables[36].table[0][1]} manifestó tener menos de 3 comidas al día, lo que evidencia oportunidades de mejora hábitos de vida saludables, específicamente en dieta balanceada, actividad que puede llevarse a cabo con el apoyo de las EPS.`;

    //5.4.	Estado de salud general

    //Gráfica 20. Estado de salud en general
    tmpChartTables[37].table = [
      ["Excelente", data.jsonAnswer.percentshealthExc],
      ["Buena", data.jsonAnswer.percentshealthBue],
      ["Regular", data.jsonAnswer.percentshealthReg],
      ["Malo", data.jsonAnswer.percentshealthMal],
    ];

    tmpChartTables[37].analysis = `El ${data.jsonAnswer.percentshealthBue} de los trabajadores encuestados, consideran su estado de salud como bueno, el otro ${data.jsonAnswer.percentshealthExc} la considera excelente y el ${data.jsonAnswer.percentshealthReg} regular, siendo esta última una visión menos alentadora, motivada seguramente por la presencia crónica o aguda de alguna patología (enfermedad) de base.`;

    //5.5.	Distribución de la población según diagnóstico de enfermedades
    //Gráfica 21. Diagnóstico de enfermedades
    tmpChartTables[38].table = [
      ["Cáncer", data.jsonAnswer.percentsdiseaseCan, data.jsonAnswer.percentsdiseaseCan],
      ["Diabetes", data.jsonAnswer.percentsdiseaseDia, data.jsonAnswer.percentsdiseaseDia],
      [
        "Enfermedad pulmonar (EPOC)",
        data.jsonAnswer.percentsdiseaseEpoc,
        data.jsonAnswer.percentsdiseaseEpoc,
      ],
      [
        "Hipertensión Arterial",
        data.jsonAnswer.percentsdiseaseHipAr,
        data.jsonAnswer.percentsdiseaseHipAr,
      ],
      ["Infarto", data.jsonAnswer.percentsdiseaseInf, data.jsonAnswer.percentsdiseaseInf],
      [
        "Insuficiencia renal",
        data.jsonAnswer.percentsdiseaseInsRen,
        data.jsonAnswer.percentsdiseaseInsRen,
      ],
      ["Obesidad", data.jsonAnswer.percentsdiseaseObe, data.jsonAnswer.percentsdiseaseObe],
      ["Otra", data.jsonAnswer.percentsdiseaseOtr, data.jsonAnswer.percentsdiseaseOtr],
      [
        "Perdida de audición",
        data.jsonAnswer.percentsdiseasePerAu,
        data.jsonAnswer.percentsdiseasePerAu,
      ],
      [
        "Perdida de la visión",
        data.jsonAnswer.percentsdiseasePerVi,
        data.jsonAnswer.percentsdiseasePerVi,
      ],
      ["Ninguna", data.jsonAnswer.percentsdiseaseNin, data.jsonAnswer.percentsdiseaseNin],
    ];

    const newTable38 = tmpChartTables[38].table.concat();
    const orderEnfermedad = newTable38.sort((a, b) => b[1] - a[1]);

    tmpChartTables[38].analysis = `Dentro de la población de trabajadores encuestados con antecedente de enfermedad, se encontró una distribución mayor del ${orderEnfermedad[0][2]} con la enfermad de ${orderEnfermedad[0][0]}.`;

    // +-----------------------+
    // |        REVISAR        |
    // +-----------------------+

    //5.6.	Distribución de la población según molestias en los últimos seis (6) meses
    //Tabla 16. Distribución según molestias en los últimos seis (6) meses

    tmpChartTables[39].table = [
      ["Dolor de cabeza", data.jsonAnswer.diseaseMonthsDolCa],
      ["Dolor de cuello, espalda y cintura", data.jsonAnswer.diseaseMonthsDolCueEs],
      ["Dolores musculares", data.jsonAnswer.diseaseMonthsDolMus],
      ["Dificultad para algun movimiento", data.jsonAnswer.diseaseMonthsDifMov],
      ["Tos frecuente", data.jsonAnswer.diseaseMonthsTos],
      ["Dificultad respiratoria", data.jsonAnswer.diseaseMonthsDifRes],
      ["Alteraciones del sueño", data.jsonAnswer.diseaseMonthsAltSue],
      ["Dificultad para concentrarse", data.jsonAnswer.diseaseMonthsDifCon],
      ["Mal genio", data.jsonAnswer.diseaseMonthsMalGe],
      ["Nerviosismo", data.jsonAnswer.diseaseMonthsNerv],
      ["Cansancio mental", data.jsonAnswer.diseaseMonthsCanMe],
      ["Palpitaciones", data.jsonAnswer.diseaseMonthsPalp],
      ["Dolor en el pecho", data.jsonAnswer.diseaseMonthsDolPe],
      ["Cambios visuales", data.jsonAnswer.diseaseMonthsCamVi],
      ["Gastritis, ulcera", data.jsonAnswer.diseaseMonthsGas],
      ["Otras alteraciones no anotadas", data.jsonAnswer.diseaseMonthsOtr],
      ["Alteraciones digestivas(diarrea, vomito)", data.jsonAnswer.diseaseMonthsAltDig],
      ["Total", data.jsonAnswer.workersSurvey],
    ];

    tmpChartTables[13].table = [
      ["Dolor de cabeza", data.jsonAnswer.percentsdiseaseMonthsDolCa],
      ["Dolor de cuello, espalda y cintura", data.jsonAnswer.percentsdiseaseMonthsDolCueEs],
      ["Dolores musculares", data.jsonAnswer.percentsdiseaseMonthsDolMus],
      ["Dificultad para algun movimiento", data.jsonAnswer.percentsdiseaseMonthsDifMov],
      ["Tos frecuente", data.jsonAnswer.percentsdiseaseMonthsTos],
      ["Dificultad respiratoria", data.jsonAnswer.percentsdiseaseMonthsDifRes],
      ["Alteraciones del sueño", data.jsonAnswer.percentsdiseaseMonthsAltSue],
      ["Dificultad para concentrarse", data.jsonAnswer.percentsdiseaseMonthsDifCon],
      ["Mal genio", data.jsonAnswer.percentsdiseaseMonthsMalGe],
      ["Nerviosismo", data.jsonAnswer.percentsdiseaseMonthsNerv],
      ["Cansancio mental", data.jsonAnswer.percentsdiseaseMonthsCanMe],
      ["Palpitaciones", data.jsonAnswer.percentsdiseaseMonthsPalp],
      ["Dolor en el pecho", data.jsonAnswer.percentsdiseaseMonthsDolPe],
      ["Cambios visuales", data.jsonAnswer.percentsdiseaseMonthsCamVi],
      ["Gastritis, ulcera", data.jsonAnswer.percentsdiseaseMonthsGas],
      ["Alteraciones digestivas(diarrea, vomito)", data.jsonAnswer.percentsdiseaseMonthsAltDig],
      ["Otras alteraciones no anotadas", data.jsonAnswer.percentsdiseaseMonthsOtr],
    ];

    const newTable16 = [
      ["DOLOR DE CABEZA", parseInt(data.jsonAnswer.percentsdiseaseMonthsDolCa)],
      [
        "DOLOR DE CUELLO, ESPALDA Y CINTURA",
        parseInt(data.jsonAnswer.percentsdiseaseMonthsDolCueEs),
      ],
      ["DOLORES MUSCULARES", parseInt(data.jsonAnswer.percentsdiseaseMonthsDolMus)],
      ["DIFICULTAD PARA ALGUN MOVIMIENTO", parseInt(data.jsonAnswer.percentsdiseaseMonthsDifMov)],
      ["TOS FRECUENTE", parseInt(data.jsonAnswer.percentsdiseaseMonthsTos)],
      ["DIFICULTAD RESPIRATORIA", parseInt(data.jsonAnswer.percentsdiseaseMonthsDifRes)],
      ["ALTERACIONES DEL SUEÑO", parseInt(data.jsonAnswer.percentsdiseaseMonthsAltSue)],
      ["DIFICULTAD PARA CONCENTRARSE", parseInt(data.jsonAnswer.percentsdiseaseMonthsDifCon)],
      ["MAL GENIO", parseInt(data.jsonAnswer.percentsdiseaseMonthsMalGe)],
      ["NERVIOSISMO", parseInt(data.jsonAnswer.percentsdiseaseMonthsNerv)],
      ["CANSANCIO MENTAL", parseInt(data.jsonAnswer.percentsdiseaseMonthsCanMe)],
      ["PALPITACIONES", parseInt(data.jsonAnswer.percentsdiseaseMonthsPalp)],
      ["DOLOR EN EL PECHO", parseInt(data.jsonAnswer.percentsdiseaseMonthsDolPe)],
      ["CAMBIOS VISUALES", parseInt(data.jsonAnswer.percentsdiseaseMonthsCamVi)],
      ["GASTRITIS, ULCERA", parseInt(data.jsonAnswer.percentsdiseaseMonthsGas)],
      [
        "ALTERACIONES DIGESTIVAS(DIARREA, VOMITO)",
        parseInt(data.jsonAnswer.percentsdiseaseMonthsAltDig),
      ],
      ["OTRAS ALTERACIONES NO ANOTADAS", parseInt(data.jsonAnswer.percentsdiseaseMonthsOtr)],
    ];

    const orderMolestias = newTable16.sort((a, b) => b[1] - a[1]);

    tmpChartTables[39].analysis =
      orderMolestias[0][1] === 100
        ? `Dentro de la población de trabajadores encuestados se evidenció que la molestia que más predomina en un plazo de últimos 6 meses es ${orderMolestias[0][0]} con un ${orderMolestias[0][1]}%. `
        : `Dentro de la población de trabajadores encuestados se evidenció que las molestias que más predominan en un plazo de últimos 6 meses son ${orderMolestias[0][0]}, ${orderMolestias[1][0]}, ${orderMolestias[2][0]}, representando porcentajes del ${orderMolestias[0][1]}%, ${orderMolestias[1][1]}% y ${orderMolestias[2][1]}%.`;

    //5.7.	Distribución de la población que presenta alguna discapacidad
    //Tabla 17. Distribución de población que presenta discapacidad

    tmpChartTables[40].table = [
      ["Física o Motora", data.jsonAnswer.disabilityFis, data.jsonAnswer.percentsdisabilityFis],
      [
        "Intelectual o Mental",
        data.jsonAnswer.disabilityInt,
        data.jsonAnswer.percentsdisabilityInt,
      ],
      [
        "Sensorial (Visual, Auditiva)",
        data.jsonAnswer.disabilitySen,
        data.jsonAnswer.percentsdisabilitySen,
      ],
      ["Ninguna", data.jsonAnswer.disabilityNin, data.jsonAnswer.percentsdisabilityNin],
      ["Total", data.jsonAnswer.workersSurvey, "100%"],
    ];

    tmpChartTables[30].table = [
      ["Física o Motora", data.jsonAnswer.percentsdisabilityFis],
      [
        "Intelectual o Mental",
        data.jsonAnswer.percentsdisabilityInt,
        data.jsonAnswer.percentsdisabilityInt,
      ],
      ["Sensorial (Visual, Auditiva)", data.jsonAnswer.percentsdisabilitySen],
      ["Ninguna", data.jsonAnswer.percentsdisabilityNin],
    ];

    const newMolestias = tmpChartTables[40].table.concat();
    newMolestias.pop();
    const orderDiscapacidad = newMolestias.sort((a, b) => b[1] - a[1]);

    tmpChartTables[40].analysis =
      orderDiscapacidad[0][2] === "100%"
        ? `Según la población de trabajadores encuestados, se evidenció que el ${orderDiscapacidad[0][2]} presenta discapacidad ${orderDiscapacidad[0][0]}, lo que resulta que a pesar de dichas discapacidades, la empresa es incluyente a nivel laboral.`
        : `Según la población de trabajadores encuestados, se evidenció que el ${orderDiscapacidad[0][2]} presenta discapacidad ${orderDiscapacidad[0][0]}, el ${orderDiscapacidad[1][2]} reporta discapacidad ${orderMolestias[1][0]}, lo que resulta que a pesar de dichas discapacidades, la empresa es incluyente a nivel laboral.`;

    //6.1.	Conoce el Sistema de Gestión de Seguridad y Salud en el Trabajo de la empresa en la que trabaja

    //Tabla 18. ¿Conoce el SG-SST?

    tmpChartTables[41].table = [
      ["Si", data.jsonAnswer.sstCapacitationSi, data.jsonAnswer.percentssstCapacitationSi],
      ["No", data.jsonAnswer.sstCapacitationNo, data.jsonAnswer.percentssstCapacitationNo],
      [
        "No sabe / No responde",
        data.jsonAnswer.sstCapacitationNoSe,
        data.jsonAnswer.percentssstCapacitationNoSe,
      ],
      ["Total", data.jsonAnswer.workersSurvey, "100%"],
    ];
    //Gráfica 24. ¿Conoce le SG-SST?
    tmpChartTables[32].table = [
      ["Sí", data.jsonAnswer.percentssstCapacitationSi],
      ["No", data.jsonAnswer.percentssstCapacitationNo],
      ["No sabe / No responde", data.jsonAnswer.percentssstCapacitationNoSe],
    ];

    tmpChartTables[41].analysis = `Dentro de la población de trabajadores encuestados, se encontró que el ${data.jsonAnswer.percentssstCapacitationSi} conoce el sistema de gestión de seguridad y salud en el trabajo para la empresa en que labora.`;

    //6.2.	Ha sufrido alguno de los siguientes accidentes durante la ejecución de su oficio
    //Gráfica 25. Accidente durante la ejecución de su oficio
    tmpChartTables[42].table = [
      [
        "Accidente de transito",
        data.jsonAnswer.percentsaccidentAcc,
        data.jsonAnswer.percentsaccidentAcc,
      ],
      ["Amputación", data.jsonAnswer.percentsaccidentAmp, data.jsonAnswer.percentsaccidentAmp],
      ["Caída", data.jsonAnswer.percentsaccidentCai, data.jsonAnswer.percentsaccidentCai],
      ["Fractura", data.jsonAnswer.percentsaccidentFra, data.jsonAnswer.percentsaccidentFra],
      ["Golpe", data.jsonAnswer.percentsaccidentGol, data.jsonAnswer.percentsaccidentGol],
      ["Herida", data.jsonAnswer.percentsaccidentHer, data.jsonAnswer.percentsaccidentHer],
      ["No aplica", data.jsonAnswer.percentsaccidentNoAp, data.jsonAnswer.percentsaccidentNoAp],
      ["Otro", data.jsonAnswer.percentsaccidentOtr, data.jsonAnswer.percentsaccidentOtr],
      ["Quemadura", data.jsonAnswer.percentsaccidentQuem, data.jsonAnswer.percentsaccidentQuem],
      [
        "Torcedura (Esguince)",
        data.jsonAnswer.percentsaccidentTor,
        data.jsonAnswer.percentsaccidentTor,
      ],
    ];

    const copyAccident = tmpChartTables[42].table.concat();
    const orderAccident = copyAccident.sort((a, b) => b[1] - a[1]);

    tmpChartTables[42].analysis =
      orderAccident[0][2] === "100%"
        ? `Después de haber analizado los resultados de la población encuestada se evidencia, en primer lugar, se encuentran ${orderAccident[0][0]} con un ${orderAccident[0][2]}`
        : `Después de haber analizado los resultados de la población encuestada se evidencia, en primer lugar, se encuentran ${orderAccident[0][0]}, en segundo ${orderAccident[1][0]}, en tercer lugar, ${orderAccident[2][0]}, seguido de ${orderAccident[3][0]}.`;

    // 6.3.	Con que se generó el accidente durante la ejecución de su oficio
    // Tabla 19. Con que se generó el accidente en su oficio
    tmpChartTables[43].table = [
      [
        "Maquinas, equipos o herramientas",
        data.jsonAnswer.accidentTypeMaq,
        data.jsonAnswer.percentsaccidentTypeMaq,
      ],
      [
        "Medios de transporte",
        data.jsonAnswer.accidentTypeMed,
        data.jsonAnswer.percentsaccidentTypeMed,
      ],
      [
        "Materiales o sustancias",
        data.jsonAnswer.accidentTypeMat,
        data.jsonAnswer.percentsaccidentTypeMat,
      ],
      [
        "Ambiente de trabajo (Escaleras, desniveles, etc.)",
        data.jsonAnswer.accidentTypeAmb,
        data.jsonAnswer.percentsaccidentTypeAmb,
      ],
      ["Animales", data.jsonAnswer.accidentTypeAni, data.jsonAnswer.percentsaccidentTypeAni],
      ["Ninguno", data.jsonAnswer.accidentTypeNoAp, data.jsonAnswer.percentsaccidentTypeNoAp],
      [
        "Total",
        data.jsonAnswer.accidentTypeMaq +
          data.jsonAnswer.accidentTypeAmb +
          data.jsonAnswer.accidentTypeMed +
          data.jsonAnswer.accidentTypeMat +
          data.jsonAnswer.accidentTypeAni +
          data.jsonAnswer.accidentTypeNoAp,
        "100%",
      ],
    ];
    //Gráfica 26. Con que se generó el accidente en su oficio
    tmpChartTables[44].table = [
      ["Maquinas, equipos o herramientas", data.jsonAnswer.percentsaccidentTypeMaq],
      ["Medios de transporte", data.jsonAnswer.percentsaccidentTypeMed],
      ["Materiales o sustancias", data.jsonAnswer.percentsaccidentTypeMat],
      [
        "Ambiente de trabajo (Escaleras, desniveles, etc.)",
        data.jsonAnswer.percentsaccidentTypeAmb,
      ],
      ["Animales", data.jsonAnswer.percentsaccidentTypeAni],
      [
        "Ninguno",
        data.jsonAnswer.percentsaccidentTypeNoAp + data.jsonAnswer.percentsaccidentTypeOtr,
      ],
    ];

    const copyAccidentType = tmpChartTables[44].table.concat();
    const orderAccidentType = copyAccidentType.sort((a, b) => b[2] - a[2]);

    tmpChartTables[43].analysis =
      orderAccidentType[0][2] === "100%"
        ? `En los trabajadores encuestados, de acuerdo con las causas de accidente, se evidenció en primer lugar, ${orderAccidentType[0][0]}.`
        : `En los trabajadores encuestados, de acuerdo con las causas de accidente, se evidenció en primer lugar, ${orderAccidentType[0][0]}. En segundo lugar, encontramos, ${orderAccidentType[1][0]}, lo que indica que los programas de prevención en riesgos laborales deben estar orientados a prevenir las condiciones de seguridad y condiciones de peligros biomecánicos para identificar actividades que puedan generar sobre esfuerzos o esfuerzos excesivos.`;

    // 6.4.	Parte del cuerpo afectada
    // Gráfica 27. Parte del cuerpo afectada
    tmpChartTables[45].table = [
      ["Cabeza o Cuello", data.jsonAnswer.percentsbodyPartsCab],
      ["Tórax", data.jsonAnswer.percentsbodyPartsTor],
      ["Abdomen", data.jsonAnswer.percentsbodyPartsAbd],
      ["Tronco (Espalda)", data.jsonAnswer.percentsbodyPartsTro],
      ["Brazos o manos", data.jsonAnswer.percentsbodyPartsBra],
      ["Piernas o pies", data.jsonAnswer.percentsbodyPartsPie],
      ["Ubicaciones múltiples", data.jsonAnswer.percentsbodyPartsUbiM],
      ["Ninguno", data.jsonAnswer.percentsbodyPartsNoAp + data.jsonAnswer.percentsbodyPartsOtr],
    ];

    const table_percents = [
      ["Cabeza o Cuello", parseInt(data.jsonAnswer.percentsbodyPartsCab)],
      ["Tórax", parseInt(data.jsonAnswer.percentsbodyPartsTor)],
      ["Abdomen", parseInt(data.jsonAnswer.percentsbodyPartsAbd)],
      ["Tronco (Espalda)", parseInt(data.jsonAnswer.percentsbodyPartsTro)],
      ["Brazos o manos", parseInt(data.jsonAnswer.percentsbodyPartsBra)],
      ["Piernas o pies", parseInt(data.jsonAnswer.percentsbodyPartsPie)],
      ["Ubicaciones múltiples", parseInt(data.jsonAnswer.percentsbodyPartsUbiM)],
      [
        "Ninguno",
        parseInt(data.jsonAnswer.percentsbodyPartsNoAp + data.jsonAnswer.percentsbodyPartsOtr),
      ],
    ];
    const orderbodyParts = table_percents.sort((a, b) => b[1] - a[1]);

    tmpChartTables[45].analysis =
      orderbodyParts[0][1] === 100
        ? `En los trabajadores encuestados, de acuerdo con la parte del cuerpo afectada, se evidencia en el primer lugar, con una distribución del ${orderbodyParts[0][1]}% ${orderbodyParts[0][0]}.`
        : `En los trabajadores encuestados, de acuerdo con la parte del cuerpo afectada, se evidencia en el primer lugar, con una distribución del ${orderbodyParts[0][1]}% ${orderbodyParts[0][0]}, seguido de un ${orderbodyParts[1][1]}% en ${orderbodyParts[1][0]}.`;

    // 6.5.	Considera que al desarrollar su oficio, está expuesto a los siguientes peligros o riesgos laborales
    // Tabla 20. Está expuesto a peligros o riesgos laborales
    tmpChartTables[46].table = [
      [
        "Infecciones por virus, bacterias u otros",
        data.jsonAnswer.exposedHazardsInf,
        data.jsonAnswer.percentsexposedHazardsInf,
      ],
      [
        "Ruidos fuertes",
        data.jsonAnswer.exposedHazardsRui,
        data.jsonAnswer.percentsexposedHazardsRui,
      ],
      [
        "Exceso de calor o frio",
        data.jsonAnswer.exposedHazardsExc,
        data.jsonAnswer.percentsexposedHazardsExc,
      ],
      [
        "Contacto con químicos peligrosos y no peligrosos",
        data.jsonAnswer.exposedHazardsContQui,
        data.jsonAnswer.percentsexposedHazardsContQui,
      ],
      [
        "Contacto con máquinas o herramientas",
        data.jsonAnswer.exposedHazardsContMa,
        data.jsonAnswer.percentsexposedHazardsContMa,
      ],
      [
        "Pisos irregulares o con desniveles",
        data.jsonAnswer.exposedHazardsPis,
        data.jsonAnswer.percentsexposedHazardsPis,
      ],
      [
        "Caída de objetos",
        data.jsonAnswer.exposedHazardsCai,
        data.jsonAnswer.percentsexposedHazardsCai,
      ],
      [
        "Accidentes de tránsito",
        data.jsonAnswer.exposedHazardsAcc,
        data.jsonAnswer.percentsexposedHazardsAcc,
      ],
      [
        "Robos, atentados terroristas o desorden público",
        data.jsonAnswer.exposedHazardsRob,
        data.jsonAnswer.percentsexposedHazardsRob,
      ],
      [
        "Incendios o explosiones ",
        data.jsonAnswer.exposedHazardsInc,
        data.jsonAnswer.percentsexposedHazardsInc,
      ],
      [
        "Posturas inadecuadas",
        data.jsonAnswer.exposedHazardsPost,
        data.jsonAnswer.percentsexposedHazardsPost,
      ],
      [
        "Esfuerzos inadecuados o movimientos repetitivos",
        data.jsonAnswer.exposedHazardsEsf,
        data.jsonAnswer.percentsexposedHazardsEsf,
      ],
      [
        "Levantamiento o cargue de elementos pesados",
        data.jsonAnswer.exposedHazardsLev,
        data.jsonAnswer.percentsexposedHazardsLev,
      ],
      [
        "Horarios y cargas de trabajo excesivas",
        data.jsonAnswer.exposedHazardsHor,
        data.jsonAnswer.percentsexposedHazardsHor,
      ],
      [
        "Falta de claridad de las funciones del oficio",
        data.jsonAnswer.exposedHazardsFaCla,
        data.jsonAnswer.percentsexposedHazardsFaCla,
      ],
      [
        "Falta de participación en la toma de decisiones que afectan al trabajador",
        data.jsonAnswer.exposedHazardsFaPa,
        data.jsonAnswer.percentsexposedHazardsFaPa,
      ],
      ["Ninguno", data.jsonAnswer.exposedHazardsNin, data.jsonAnswer.percentsexposedHazardsNin],
    ];

    tmpChartTables[51].table = [
      ["Infecciones por virus, bacterias u otros", data.jsonAnswer.percentsexposedHazardsInf],
      ["Ruidos fuertes", data.jsonAnswer.percentsexposedHazardsRui],
      ["Exceso de calor o frio", data.jsonAnswer.percentsexposedHazardsExc],
      [
        "Contacto con químicos peligrosos y no peligrosos",
        data.jsonAnswer.percentsexposedHazardsContQui,
      ],
      ["Contacto con máquinas o herramientas", data.jsonAnswer.percentsexposedHazardsContMa],
      ["Pisos irregulares o con desniveles", data.jsonAnswer.percentsexposedHazardsPis],
      ["Caída de objetos", data.jsonAnswer.percentsexposedHazardsCai],
      ["Accidentes de tránsito", data.jsonAnswer.percentsexposedHazardsAcc],
      [
        "Robos, atentados terroristas o desorden público",
        data.jsonAnswer.percentsexposedHazardsRob,
      ],
      ["Incendios o explosiones", data.jsonAnswer.percentsexposedHazardsInc],
      ["Posturas inadecuadas", data.jsonAnswer.percentsexposedHazardsPost],
      [
        "Esfuerzos inadecuados o movimientos repetitivos",
        data.jsonAnswer.percentsexposedHazardsEsf,
      ],
      ["Levantamiento o cargue de elementos pesados", data.jsonAnswer.percentsexposedHazardsLev],
      ["Horarios y cargas de trabajo excesivas", data.jsonAnswer.percentsexposedHazardsHor],
      [
        "Falta de claridad de las funciones del oficio",
        data.jsonAnswer.percentsexposedHazardsFaCla,
      ],
      [
        "Falta de participación en la toma de decisiones que afectan al trabajador",
        data.jsonAnswer.percentsexposedHazardsFaPa,
      ],
      ["Ninguno", data.jsonAnswer.percentsexposedHazardsNin],
    ];

    const copyExposedHazards = tmpChartTables[46].table.concat();
    const orderExposedHazards = copyExposedHazards.sort((a, b) => b[1] - a[1]);

    tmpChartTables[46].analysis =
      orderExposedHazards[0][2] === "100%"
        ? `Los trabajadores encuestados, el primer lugar, indican que en la ejecución de sus laborales se encuentran expuesto a ${orderExposedHazards[0][0]} con un ${orderExposedHazards[0][2]}.`
        : `Los trabajadores encuestados, el primer lugar, indican que en la ejecución de sus laborales se encuentran expuesto a ${orderExposedHazards[0][0]} con un ${orderExposedHazards[0][2]}, seguido por ${orderExposedHazards[1][0]} con un ${orderExposedHazards[1][2]}, las demás exposiciones oscilan entre el ${orderExposedHazards[2][2]} al ${orderExposedHazards[15][2]}.`;

    // 6.6.	Ha tenido COVID-19 con prueba positiva
    // Tabla 21. ¿Ha tenido Covid-19?
    tmpChartTables[47].table = [
      ["Si", data.jsonAnswer.covidSi, data.jsonAnswer.percentscovidSi],
      ["No", data.jsonAnswer.covidNo, data.jsonAnswer.percentscovidNo],
      ["Total", data.jsonAnswer.covidSi + data.jsonAnswer.covidNo, "100%"],
    ];

    tmpChartTables[48].table = [
      ["Si", data.jsonAnswer.percentscovidSi],
      ["No", data.jsonAnswer.percentscovidNo],
    ];

    tmpChartTables[47].analysis = `El ${data.jsonAnswer.percentscovidSi} de los trabajadores encuestados han presentado Covid-19.`;

    // 6.7.	Se encuentra vacunado contra Covid-19
    // Tabla 22. Se encuentra vacunado contra Covid-19
    tmpChartTables[49].table = [
      ["Si", data.jsonAnswer.vaccineCovidSi, data.jsonAnswer.percentsvaccineCovidSi],
      ["No", data.jsonAnswer.vaccineCovidNo, data.jsonAnswer.percentsvaccineCovidNo],
      ["Total", data.jsonAnswer.vaccineCovidSi + data.jsonAnswer.vaccineCovidNo, "100%"],
    ];

    tmpChartTables[50].table = [
      ["Si", data.jsonAnswer.percentsvaccineCovidSi],
      ["No", data.jsonAnswer.percentsvaccineCovidNo],
    ];

    tmpChartTables[49].analysis = `El ${data.jsonAnswer.percentsvaccineCovidSi} de los trabajadores encuestados haría parte del programa de vacunación contra Covid-19.`;

    tmpChartTables[51].table = Object.entries(data.stats.yearDiseases).map(([k, v]) => [
      k.toUpperCase(),
      Math.round((v / data.stats.totalUsers) * 100) + "",
    ]);

    setChartTables({ ...tmpChartTables });
  };
  const downloadWord = () => {
    var link = document.createElement("a");
    link.href = "https://www.ilovepdf.com/es/pdf_a_word";
    link.target = "_blank";
    link.click();
  };

  return (
    <Container className="container">
      <Row className="justify-content-center mb-2">
        <Col md={{ span: 5 }}>
          <h3 style={{ textAlign: "center", color: "#3A4297" }}>PERFIL SOCIODEMOGRÁFICO</h3>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={6}>
          {viewPDF && <PdfReportGenerator content={content} cover={cover} toc={toc} />}
        </Col>
        <Col md={6}>
          <Button variant="info" className="mb-2 btn-word" block onClick={downloadWord}>
            Ir al convertidor
          </Button>
          {isData ? (
            <FormSocioDemographic
              GeneratePDF={GeneratePDF}
              viewPDF={viewPDF}
              dataCharts={[...chartTables]}
            />
          ) : (
            <>
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
              Este proceso puede tardar algunos segundos. Por favor, no recargue la página.
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
};

PdfSocioDemographic.propTypes = {};

PdfSocioDemographic.defaultProps = {};

export default PdfSocioDemographic;
