import React from 'react';
import PropTypes from 'prop-types';
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import { Container, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import ArrowBack from "../../img/icons/iconArrowBack.svg";
import { useLocation } from "react-router-dom";


import PdfDangerMatrix from "./../PdfDangerMatrix/PdfDangerMatrix";
import PdfMorbidity from "./../PdfMorbidity/PDFMorbidity";
import PdfPsychosocial from "./../PdfPsychosocial/PdfPsychosocial";
import PdfSelfEvaluation from "./../PdfSelfEvaluation/PdfSelfEvaluation";
import PdfSocioDemographic from "./../PdfSocioDemographic/PdfSocioDemographic";
import PlanBiosecurity from './../PlanBiosecurity/PlanBiosecurity'

import './PDFComponent.scss';

const PDFComponent = () => {
  let location = useLocation();

  const renderPDF = () => {
    switch ( location.state.form_id ) {
      case 0:
        return  <PdfSelfEvaluation />;
      case 1:
        return <PdfDangerMatrix />;
      case 2:
        return <PdfSocioDemographic />;
      case 3:
        return <PdfMorbidity />;
      case 4:
        return <PdfPsychosocial />;
      case 5:
        return <PlanBiosecurity />;

      // default:
        // return <PdfSocioDemographic />;
    }
  };

  return (
    <Container className="container">
      <Row>
        <div>
          <Breadcrumbs
            view={[
              { name: "Inicio", path: "/graphics" },
              { name: "Informes", path: "/document" },
              { name: "informe", path: "" },
            ]}
          ></Breadcrumbs>
          <NavLink to="/init/document" className="styleArrowLink">
            <img src={ArrowBack} width="50" />
          </NavLink>
        </div>
      </Row>
      <Row className="justify-content-center">
        <Col md={{ span: 5 }}>
          <h3 style={{ textAlign: "center", color: "#3A4297" }}>Informes</h3>
        </Col>
      </Row>
      {/* <Row className="justify-content-center">

      </Row> */}
      <Row className="justify-content-center">
        <Col md={12}>{renderPDF()}</Col>
      </Row>
    </Container>
  )
}

PDFComponent.propTypes = {};

PDFComponent.defaultProps = {};

export default PDFComponent;
