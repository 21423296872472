import React from "react";
import PropTypes from "prop-types";
import "./ModalUploadResponse.scss";
import { Button, Modal } from "react-bootstrap";
import checkImage from "../../img/icons/check.svg";
import errorImage from "../../img/icons/error.svg";

const ModalUploadResponse = ({ show, handleClose, url, state, title, error }) => (
  <>
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        {state ? (
          <Modal.Title>
            {" "}
            <img src={checkImage} alt="correcto" style={{ width: "50px" }} />
              Cargado con éxito
          </Modal.Title>
        ) : (
            <Modal.Title>
              <img src={errorImage} alt="error" style={{ width: "50px" }} />
                Error al cargar el archivo
            </Modal.Title>
          )}
      </Modal.Header>
      {state ? (
        ""
      ) : (
          <Modal.Body>
            {" "}
            <ul>
              {error.map((value, i) => {
                return (
                  <>
                    <li>
                      <b>
                        {value.message}
                      </b>
                    </li>
                    {/* Object.entries(value).map((responseError) => (
                    <li>
                      <b>{responseError[0]}</b>
                      {": "}
                      {responseError[1]}
                    </li>
                  )) */}
                  </>
                );
              })}
            </ul>
          </Modal.Body>
        )}
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  </>
);

ModalUploadResponse.propTypes = {};

ModalUploadResponse.defaultProps = {};

export default ModalUploadResponse;
