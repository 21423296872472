import React, { useReducer, useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./Documents.scss";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import ArrowBack from "../../img/icons/iconArrowBack.svg";
import { NavLink, useParams } from "react-router-dom";
import { Container, Row, Col, Form } from "react-bootstrap";
import { reducer } from "../../helpers/reducer";
import Accordion from "../../components/Accordion/Accordion";
import AccordionMobile from "../../components/AccordionMobile/AccordionMobile";
import { GET } from "../../constants/ApiRest";
import { Pagination } from "../../components/Pagination/Pagination";

//import
import PdfDangerMatrix from "../PdfDangerMatrix/PdfDangerMatrix";
import PdfMorbidity from "./../PdfMorbidity/PDFMorbidity";
import PdfPsychosocial from "./../PdfPsychosocial/PdfPsychosocial";
import PdfSelfEvaluation from "./../PdfSelfEvaluation/PdfSelfEvaluation";
import PdfSocioDemographic from "./../PdfSocioDemographic/PdfSocioDemographic";

const init = () => {
  return [];
};

const Documents = () => {
  let { typeDoc } = useParams();

  const [show, setShow] = useState([false]);
  const [target, setTarget] = useState(null);
  const [WindosW, setWindosW] = useState(window.innerWidth);
  const [pageCount, setPageCount] = useState(0);
  const [, setRender] = useState(0);
  const [compani, dispatch] = useReducer(reducer, [], init);
  const [nit, setNit] = useState(0)


  const renderPDF = () => {
    switch (typeDoc) {
      case 1:
        return <PdfSocioDemographic />;
        break;
      case 2:
        return <PdfDangerMatrix />;
        break;
      case 3:
        return <PdfPsychosocial />;
        break;
      case 4:
        return <PdfSelfEvaluation />;
        break;
      case 5:
        return <PdfMorbidity />;
        break;

      default:
        return <PdfSocioDemographic />;
        break;
    }
  };

  useEffect(() => {
    (async function () {
      const idUser = localStorage.getItem("idUser");
      // console.log('localstorage', localStorage.getItem('idUser'));
      // GET(`${process.env.REACT_APP_COMPANY_ENDPOINT}/company/${idUser}`)
      GET(`${process.env.REACT_APP_COMPANY_ENDPOINT}/company/allcompanies`)
        .then((res) => {
          // setCompani(res.data);
          dispatch({ type: "addSort", payload: res.data });
          setPageCount(Math.ceil(res.data.length / 6));
          return res.data;
          // console.log(res.data)
        })
        .then((res) => {
          Array.isArray(res)
            ? setShow(
              res.map(() => {
                return false;
              })
            )
            : setShow([false]);
        })
        .catch((error) => {
          // setLogin(false);
        });

      // setCompani([])
    })();
  }, []);

  const handleSubmitNit = async (e) => {

    e.preventDefault();
    const { data } = await GET(`${process.env.REACT_APP_COMPANY_ENDPOINT}/company/formless/bynit/${nit}`);
    // console.log(data)
    dispatch({ type: 'addSort', payload: data });

  }

  const handleNit = ({ target }) => {
    setNit(target.value)
  }

  const handlePage = ({ selected }) => {
    const action = {
      type: "change",
      payload: selected, //cambiar
    };

    dispatch(action);
    setRender(selected);
  };

  const buttons = [
    { title: "Autoevaluación SG-SST" /* , val: 50 */, key: "Autoevaluacion", id: 0 },
    { title: "Matriz de Peligros" /* , val: 30 */, key: "Matriz", id: 1 },
    { title: "Perfil Sociodemográfico" /* , val: 90 */, key: "Perfil", id: 2 },
    { title: "Morbilidad Sentida" /* , val: 40 */, key: "Morbilidad", id: 3 },
    { title: "Efectos Psicosociales" /* , val: 10 */, key: "Efectos", id: 4 },
    { title: "Protocolo de bioseguridad" /* , val: 10 */, key: "Protocolo", id: 5 },
  ];

  return (
    <Container className="container">
      <Row>
        <div>
          <Breadcrumbs
            view={[
              { name: "Inicio", path: "/graphics" },
              { name: "Informes", path: "" },
            ]}
          ></Breadcrumbs>
          <NavLink to="/init/graphics" className="styleArrowLink">
            <img src={ArrowBack} width="50" />
          </NavLink>
        </div>
      </Row>
      <Row className="justify-content-center">
        <Col md={{ span: 5 }}>
          <h3 style={{ textAlign: "center", color: "#3A4297" }}>Informes</h3>
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col md="auto">
          <Form onSubmit={handleSubmitNit}>
            <Form.Row>
              <Form.Group as={Col} controlId="nit-text">
                <Form.Label>Nit</Form.Label>
                <Form.Control type="text" onChange={handleNit} />
              </Form.Group>
              <button className="btn btn-primary ml-3" style={{ height: "2.5rem", alignSelf: "center" }} type="submit">Buscar</button>
            </Form.Row>
          </Form>
        </Col>
      </Row>
      <Row className="justify-content-center">
        {WindosW < 992 ? (
          <Col md={8} className="styleAccordion">
            <AccordionMobile compani={compani[0]} buttons={buttons} path="pdf"></AccordionMobile>
          </Col>
        ) : (
          <Col md={4} className="styleAccordion">
            {Array.isArray(compani[0])
              ? compani[1]?.map((value, i) => {
                return (
                  <Accordion
                    key={i + "accordion"}
                    show={show[i]}
                    shows={show}
                    index={i}
                    target={target}
                    // targets={target}
                    title={value.company.name}
                    imageUrl={
                      value.company.files.length !== 0 ? value.company.files[0].url : null
                    }
                    idCompany={value.company.id}
                    dataCompany={value.company}
                    setS={setShow}
                    setT={setTarget}
                    buttons={buttons}
                    nit={value.company.nit}
                    path="pdf"
                    origin={2}
                  />
                );
              })
              : console.log("fail")}
          </Col>
        )}
      </Row>
      <Row className="justify-content-center align-self-center text-center w-100 m-0 p-2">
        <Pagination pageCount={pageCount} handlePage={handlePage} />
      </Row>
      {/* <Row className="justify-content-center">
        <Col md={12}>{renderPDF()}</Col>
      </Row> */}
    </Container>
  );
};

Documents.propTypes = {};

Documents.defaultProps = {};

export default Documents;
