import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { Container, Row, Col, Button, Spinner } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import ArrowBack from "../../img/icons/iconArrowBack.svg";
import { useLocation } from "react-router-dom";

import { GET, POSTNORMAL } from "./../../constants/ApiRest";

//import components
import PdfReportGenerator from "./../../components/PdfReportGenerator/PdfReportGenerator";
import FormSelfEvaluation from "./../../components/FormSelfEvaluation/FormSelfEvaluation";

//import data
import data from "../../abstract/selfEvaluation";
import TablePdf from "../../components/TablePdf/TablePdf";

const PdfSelfEvaluation = ({ setLogin }) => {
  let location = useLocation();

  const [viewPDF, setViewPDF] = useState(false);
  const [cover, setCover] = useState([]);
  const [toc, setToc] = useState([]);
  const [content, setContent] = useState([]);
  const [isData, setisData] = useState(false);
  const [listPlan, setListPlan] = useState([]);

  //Mes de descarga del informe
  const monthNames = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  const date_application = new Date();
  const date = `${monthNames[date_application.getMonth()]}, ${date_application.getFullYear()}`;

  //datos que se colocan al pdf
  const [init, setInit] = useState({
    name: "",
    advisor: "",
    date: date,
    department: "",
    city: "",
    lic: "N° 1701 del 15 septiembre 2016",
    idDepartment: "",
    idCity: "",
  });
  const [contentS, setContentS] = useState({
    NIT: "",
    questAplication: "",
    samplePorcentage: "",
    statePorcentage: "",
    action: "",
    professionalSignature: "",
    SSTlicense: "",
  });
  const [chartTables, setChartTables] = useState([
    {
      chart: "data:image/png; base64, ",
      titile: "Tabla 1 . Comparativo de resultados obtenidos en la autoevaluación",
      table: [
        ["key1", "1"],
        ["key2", "2"],
        ["key4", "3"],
        ["key1", "4"],
        ["key1", "5"],
        ["key1", "6"],
        ["key1", "7"],
      ],
      analysis: "cositas varias",
    },
    {
      title: "Ilustración 1 . Resultado obtenido en cada uno de los pilares del SG-SST",
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "1"],
        ["key2", "2"],
        ["key3", "3"],
        ["key4", "4"],
        ["key5", "5"],
        ["key6", "6"],
        ["key7", "7"],
      ],
      analysis: "cositas varias",
      height: 200,
    },
    {
      title: "Ilustración 2 . Resultados de la evaluación por ciclos",
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
  ]);

  useEffect(() => {
    GET(`${process.env.REACT_APP_REPORT_ENDPOINT}/formless/data/${location.state.idCompany}/6`)
      .then((result) => {
        // console.log("6", result);
        setInitialData(result.data);
      })
      .catch((err) => {
        GET(`${process.env.REACT_APP_REPORT_ENDPOINT}/formless/data/${location.state.idCompany}/7`)
          .then((result) => {
            // console.log("7", result);
            setInitialData(result.data);
          })
          .catch((err) => {
            GET(
              `${process.env.REACT_APP_REPORT_ENDPOINT}/formless/data/${location.state.idCompany}/${location.state.form_id}`
            )
              .then((result) => {
                //  console.log("0", result);
                setInitialData(result.data);
              })
              .catch((err) => {
                if (err.response) {
                  if (err.response.data.reload === true) {
                    POSTNORMAL(process.env.REACT_APP_FILE_LOADER_ENDPOINT + "validate", {
                      idCompany: location.state.idCompany,
                      nitCompany: location.state.dataCompany.nit,
                      form_id: location.state.form_id,
                    })
                      .then((response) => {
                        window.location.reload();
                      })
                      .catch(console.log(err));
                  }
                }
                console.log(err);
              });
          });
      });
  }, []);

  //Obtener empresa
  useEffect(() => {
    GET(`${process.env.REACT_APP_COMPANY_ENDPOINT}/company/getnit/${location.state.idCompany}}`)
      .then((result) => {
        const data = result.data;
        setInit({
          ...init,
          name: data.name,
          idDepartment: data.deparment_id,
          idCity: data.town_id,
          advisor: data.responsible,
        });
        contentS.NIT = data.nit;
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //Obtener ciudad
  useEffect(() => {
    if (init.idDepartment !== "") {
      GET(`${process.env.REACT_APP_POLITICAL_SEGMENTATION_ENDPOINT}/${init.idDepartment}/children`)
        .then((result) => {
          const data = result.data;
          const nameCity = data.find((el) => el.id === init.idCity);
          setInit({ ...init, city: nameCity.name });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [init.idDepartment]);

  //Obtener departamento
  useEffect(() => {
    if (init.idDepartment !== "") {
      GET(`${process.env.REACT_APP_POLITICAL_SEGMENTATION_ENDPOINT}/13/children`)
        .then((result) => {
          const nameDepartment = result.data.find((el) => el.id === init.idDepartment);
          setInit({ ...init, department: nameDepartment.name });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [init.city]);

  const GeneratePDF = (key, value) => {
    setViewPDF(!viewPDF);
    switch (key) {
      case 1:
        let auxTable = [...chartTables];
        //auxTable[19].table = value.distributionCharges.map((val) => Object.values(val));
        //auxTable[30].table = value.distributionPersonalBackground.map((val) => Object.values(val));
        contentS.conclusions = value.conclution;
        contentS.resultsAnalysis = value.resultsAnalysis;
        contentS.action = value.action;
        setChartTables(auxTable);
        setContentS(contentS);
        break;
      default:
        break;
    }
    GenerateData();
  };

  //19 dato a cambiar manual
  const GenerateData = () => {
    const { cover: cov, toc: t, content: cont } = data(init, contentS, chartTables);
    setCover(cov);
    setToc(t);
    setContent(cont);
  };

  const generateTable = (statsByCicle) => {
    let tmpChartTables = [];
    if (statsByCicle.expected.plan != undefined) {
      tmpChartTables.push([
        "PLANEAR",
        Math.round(statsByCicle.expected.plan) + "%",
        Math.round(statsByCicle.result.plan) + "%",
        Math.round(statsByCicle.percentage.plan) + "%",
      ]);
    }
    if (statsByCicle.expected.do != undefined) {
      let doExpected = statsByCicle.expected.do;
      let doResult = statsByCicle.result.do;
      let doPercentage = statsByCicle.percentage.do;

      if (doResult > doExpected) {
        doResult = doExpected;
        doPercentage = doResult - doExpected;
      }

      tmpChartTables.push([
        "HACER",
        Math.round(doExpected) + "%",
        Math.round(doResult) + "%",
        Math.round(doPercentage) + "%",
      ]);
    }

    if (statsByCicle.expected.verify != undefined) {
      let valorExpected = statsByCicle.expected.verify;
      let valorResult = statsByCicle.result.verify;
      let valorPercentage = statsByCicle.percentage.verify;

      if (valorResult > valorExpected) {
        valorResult = valorExpected;
        valorPercentage = valorResult - valorExpected;
      }

      tmpChartTables.push([
        "VERIFICAR",
        Math.round(valorExpected) + "%",
        Math.round(valorResult) + "%",
        Math.round(valorPercentage) + "%",
      ]);
    }
    if (statsByCicle.expected.act != undefined) {
      tmpChartTables.push([
        "ACTUAR",
        Math.round(statsByCicle.expected.act) + "%",
        Math.round(statsByCicle.result.act) + "%",
        Math.round(statsByCicle.percentage.act) + "%",
      ]);
    }
    if (statsByCicle.expected.total != undefined) {
      tmpChartTables.push([
        "TOTAL",
        Math.round(statsByCicle.expected.total) + "%",
        Math.round(statsByCicle.result.total) + "%",
        Math.round(statsByCicle.percentage.total) + "%",
      ]);
    }
    return tmpChartTables;
  };

  const generateBarChart = (statsByCicle) => {
    let tmpChartTables = [];
    if (statsByCicle.expected.plan != undefined) {
      tmpChartTables.push(["PLANEAR", Math.round(statsByCicle.percentage.plan)]);
    }
    if (statsByCicle.expected.do != undefined) {
      let doExpected = statsByCicle.expected.do;
      let doResult = statsByCicle.result.do;
      let doPercentage = statsByCicle.percentage.do;

      if (doResult > doExpected) {
        doResult = doExpected;
        doPercentage = doResult - doExpected;
      }
      tmpChartTables.push(["HACER", Math.round(doPercentage)]);
    }
    if (statsByCicle.expected.verify != undefined) {
      let valorExpected = statsByCicle.expected.verify;
      let valorResult = statsByCicle.result.verify;
      let valorPercentage = statsByCicle.percentage.verify;

      if (valorResult > valorExpected) {
        valorResult = valorExpected;
        valorPercentage = valorResult - valorExpected;
      }
      tmpChartTables.push(["VERIFICAR", Math.round(valorPercentage)]);
    }
    if (statsByCicle.expected.act != undefined) {
      tmpChartTables.push(["ACTUAR", Math.round(statsByCicle.percentage.act)]);
    }
    return tmpChartTables;
  };

  const generateHorizontalBarChart = (statsByCicle) => {
    let tmpChartTables = [];
    if (statsByCicle.expected.plan != undefined) {
      tmpChartTables.push([
        "PLANEAR",
        Math.round(statsByCicle.expected.plan),
        Math.round(statsByCicle.result.plan),
      ]);
    }
    if (statsByCicle.expected.do != undefined) {
      let doExpected = statsByCicle.expected.do;
      let doResult = statsByCicle.result.do;

      if (doResult > doExpected) {
        doResult = doExpected;
        ///doPercentage = doResult - doExpected
      }
      tmpChartTables.push(["HACER", Math.round(doExpected), Math.round(doResult)]);
    }
    if (statsByCicle.expected.verify != undefined) {
      let valorExpected = statsByCicle.expected.verify;
      let valorResult = statsByCicle.result.verify;
      let valorPercentage = statsByCicle.percentage.verify;

      if (valorResult > valorExpected) {
        valorResult = valorExpected;
        valorPercentage = valorResult - valorExpected;
      }
      tmpChartTables.push(["VERIFICAR", Math.round(valorExpected), Math.round(valorResult)]);
    }
    if (statsByCicle.expected.act != undefined) {
      tmpChartTables.push([
        "ACTUAR",
        Math.round(statsByCicle.expected.act),
        Math.round(statsByCicle.result.act),
      ]);
    }
    return tmpChartTables;
  };

  const generateRadarBarChart = (statsByCicle) => {
    let tmpChartTables = [];
    if (statsByCicle.expected.plan != undefined) {
      tmpChartTables.push([
        "PLANEAR",
        Math.round(statsByCicle.expected.plan),
        Math.round(statsByCicle.result.plan),
      ]);
    }
    if (statsByCicle.expected.do != undefined) {
      let doExpected = statsByCicle.expected.do;
      let doResult = statsByCicle.result.do;

      if (doResult > doExpected) {
        doResult = doExpected;
      }

      tmpChartTables.push(["HACER", Math.round(doExpected), Math.round(doResult)]);
    }
    if (statsByCicle.expected.verify != undefined) {
      let valorExpected = statsByCicle.expected.verify;
      let valorResult = statsByCicle.result.verify;
      let valorPercentage = statsByCicle.percentage.verify;

      if (valorResult > valorExpected) {
        valorResult = valorExpected;
        valorPercentage = valorResult - valorExpected;
      }
      tmpChartTables.push(["VERIFICAR", Math.round(valorExpected), Math.round(valorResult)]);
    }
    if (statsByCicle.expected.act != undefined) {
      tmpChartTables.push([
        "ACTUAR",
        Math.round(statsByCicle.expected.act),
        Math.round(statsByCicle.result.act),
      ]);
    }
    return tmpChartTables;
  };

  const setInitialData = (data) => {
    setisData(true);
    // console.log(data);

    contentS.samplePorcentage = data.statsByCicle.result.total;
    contentS.statePorcentage = data.statsByCicle.state;

    const tmpChartTables = [...chartTables];
    tmpChartTables[0].table = generateTable(data.statsByCicle);
    tmpChartTables[1].table = generateBarChart(data.statsByCicle);
    tmpChartTables[2].table = generateHorizontalBarChart(data.statsByCicle);
    tmpChartTables[3].table = generateRadarBarChart(data.statsByCicle);

    setChartTables([...tmpChartTables]);
  };

  const handleListPlan = () => {
    GET(`${process.env.REACT_APP_REPORT_ENDPOINT}/formless/data/plan/${location.state.idCompany}/6`)
      .then((result) => setListPlan(result.data.list))
      .catch((err) => {
        GET(
          `${process.env.REACT_APP_REPORT_ENDPOINT}/formless/data/plan/${location.state.idCompany}/7`
        )
          .then((result) => setListPlan(result.data.list))
          .catch((err) => {
            GET(
              `${process.env.REACT_APP_REPORT_ENDPOINT}/formless/data/plan/${location.state.idCompany}/${location.state.form_id}`
            ).then((result) => setListPlan(result.data.list));
          });
      });
  };

  const downloadWord = () => {
    var link = document.createElement("a");
    link.href = "https://www.ilovepdf.com/es/pdf_a_word";
    link.target = "_blank";
    link.click();
  };

  return (
    <Container className="container">
      <Row className="justify-content-center mb-2">
        <Col md={{ span: 5 }}>
          <h5 style={{ textAlign: "center", color: "#3A4297" }}>AUTO EVALUACIÓN</h5>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={6}>
          {viewPDF && <PdfReportGenerator content={content} cover={cover} toc={toc} />}
        </Col>
        <Col
          md={6}
          className="d-flex flex-column align-items-center justify-center table-responsive"
        >
          <Button variant="info" className="mb-2 btn-word" block onClick={downloadWord}>
            Ir al convertidor
          </Button>
          <Button variant="info" className="mb-2" block onClick={handleListPlan}>
            Plan de mejoramiento
          </Button>
          <div className="table-responsive" style={{ maxHeight: "38rem" }}>
            <TablePdf headers={["A mejorar"]} content={listPlan} />
          </div>
          {isData ? (
            <FormSelfEvaluation
              GeneratePDF={GeneratePDF}
              viewPDF={viewPDF}
              dataCharts={[...chartTables]}
            />
          ) : (
            <>
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
              Este proceso puede tardar algunos segundos. Por favor, no recargue la página.
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
};

PdfSelfEvaluation.propTypes = {};

PdfSelfEvaluation.defaultProps = {};

export default PdfSelfEvaluation;
