import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./UpdateUser.scss";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";

//request
import { DELETE, GET, POSTNORMAL, PUTNORMAL } from "../../constants/ApiRest";

const UpdateUser = ({ setLogin }) => {
  const [user, setUser] = useState([{ id: 0, username: "" }]);
  const [userData, setUserData] = useState({});
  const [department, setDepartment] = useState([]);
  const [town, setTown] = useState([]);
  const [role, setRole] = useState([]);
  const [validated, setValidated] = useState(false);
  const [show, setShow] = useState(false);
  const [showSend, setShowSend] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showSendDelete, setShowSendDelete] = useState(false);

  //FormData
  const [fetchedData, setfetchedData] = useState({});

  useEffect(() => {
    GET(`${process.env.REACT_APP_POLITICAL_SEGMENTATION_ENDPOINT}/13/children`).then((res) => {
      if (res.data) setDepartment(res.data, "name");
    });

    GET(`${process.env.REACT_APP_USER}/user/rol`).then((res) => {
      if (res.data) 
      setRole(res.data.sendRole.filter((rol)=> rol !== '5-Admin'));
    });

    GET(`${process.env.REACT_APP_USER}/user`).then((res) => {
      if (res.data) setUser(res.data);
    });
  }, []);

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setUserData({ ...userData, [name]: value });
  };

  const handleInputChangeUser = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    POSTNORMAL(`${process.env.REACT_APP_USER}/user`, { [name]: value })
      .then((res) => {
        const data = res.data;
        const dataRes = {
          email: data.email,
          userId: data.id,
          username: data.username,
          ...data.person,
          userRole: data.role.id,
        };
        setUserData(dataRes);
      })
      .catch((error) => {
        setShow(true);
      });
  };

  const handleDepartmentChange = (event) => {
    setTown([]);
    let deparment = event.target.value;
    const auxDataTarget = event.target.name;
    const auxDataForm = { ...fetchedData, [auxDataTarget]: deparment };
    setfetchedData(auxDataForm);
    if (deparment) {
      //Petición para traer los municipios con respecto al departamento elegido
      GET(`${process.env.REACT_APP_POLITICAL_SEGMENTATION_ENDPOINT}/${deparment}/children`).then(
        (res) => {
          if (res.data) {
            setTown(res.data, "name");
          }
        }
      );
    } else {
      setTown([]);
    }
  };

  const handleSubmitDelete = (event) => {};

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    PUTNORMAL(`${process.env.REACT_APP_USER}/user/modifys`, userData)
      .then((res) => {
        // console.log(res);
        setShowSend(true);
      })
      .catch((error) => {
        setShow(true);
        // console.log(error);
      });

    setValidated(true);
    event.preventDefault();
  };

  const handleDelete = (e) => {

    DELETE(`${process.env.REACT_APP_USER}/user/delete`, { userId: userData.userId } )
    .then( (res) => {
      // console.log('y si te traigo rosas', res)
      setShowSendDelete( true )
    } )
    .catch( error => setShowDelete(true) )

    // console.log('de cualquier forma se me van a marchitar')


    e.preventDefault();
  }

  return (
    <Container className="CreateUser">
      {!show || (
        <Alert variant="danger" onClose={() => setShow(false)} dismissible>
          <Alert.Heading>¡Oh, vaya!, ha ocurrido un error al crear un usuario.</Alert.Heading>
          <p>Prueba crearlo en 10 minutos.</p>
          <p>Si el error persiste, comunícate con soporte técnico.</p>
        </Alert>
      )}
      {!showDelete || (
        <Alert variant="danger" onClose={() => setShowDelete(false)} dismissible>
          <Alert.Heading>¡Oh, vaya!, ha ocurrido un error al eliminar un usuario.</Alert.Heading>
          <p>Prueba eliminarlo en 10 minutos.</p>
          <p>Si el error persiste, comunícate con soporte técnico.</p>
        </Alert>
      )}
      {!showSend || (
        <Alert variant="success" onClose={() => setShowSend(false)} dismissible>
          <Alert.Heading>El usuario ha sido actualizado con éxito.</Alert.Heading>
        </Alert>
      )}
      {!showSendDelete || (
        <Alert variant="success" onClose={() => setShowSendDelete(false)} dismissible>
          <Alert.Heading>El usuario ha sido eliminado con éxito.</Alert.Heading>
        </Alert>
      )}
      <Row>
        <Col>
          <h3 className="mb-4">Actualizar usuario</h3>
        </Col>
      </Row>

      <Form.Group as={Col} md="4">
        <Form.Label>Usuario</Form.Label>
        <Form.Control onChange={handleInputChangeUser} as="select" name="userId" required>
          <option></option>
          {user.map((value) => (
            <option key={value.id} value={value.id}>
              {value.username}
            </option>
          ))}
        </Form.Control>
        <Form.Control.Feedback>¡Se ve muy bien!</Form.Control.Feedback>
        <Form.Control.Feedback type="invalid">
          Por favor, Seleccionar municipio.
        </Form.Control.Feedback>
      </Form.Group>

      {/* {console.log(!Object.keys(userData).length > 0)} */}
      {Object.keys(userData).length > 2 ? (
        <>
          <h5 className="mb-4">Datos del usuario a actualizar</h5>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            className="container-fluid"
          >
            <Form.Row>
              <Form.Group as={Col} md="6" controlId="formGridName">
                <Form.Label>Nombres</Form.Label>
                <Form.Control
                  onChange={handleInputChange}
                  type="text"
                  name="names"
                  value={userData.names}
                  required
                  placeholder="Nombre..."
                />
                <Form.Control.Feedback>¡Se ve muy bien!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Por favor, colocar nombre completo.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="formGridSurname">
                <Form.Label>Apellidos</Form.Label>
                <Form.Control
                  onChange={handleInputChange}
                  type="text"
                  name="surname"
                  value={userData.surname}
                  required
                  placeholder="Apellido..."
                />
                <Form.Control.Feedback>¡Se ve muy bien!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Por favor, colocar apellido completo.
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="8" controlId="formGridEmail">
                <Form.Label>Correo</Form.Label>
                <Form.Control
                  onChange={handleInputChange}
                  type="email"
                  name="email"
                  value={userData.email}
                  required
                  placeholder="Correo@correo.com"
                />
                <Form.Control.Feedback>¡Se ve muy bien!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Por favor, colocar un correo valido.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="formGridNationalId">
                <Form.Label>Cedula de ciudadania</Form.Label>
                <Form.Control
                  onChange={handleInputChange}
                  type="text"
                  name="national_id"
                  value={userData.national_id}
                  required
                  placeholder="1234567"
                />
                <Form.Control.Feedback>¡Se ve muy bien!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Por favor, colocar identificador.
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="6" controlId="formGridUsername">
                <Form.Label>Nombre de usuario</Form.Label>
                <Form.Control
                  onChange={handleInputChange}
                  type="text"
                  name="username"
                  value={userData.username}
                  required
                  placeholder="Usuario"
                />
                <Form.Control.Feedback>¡Se ve muy bien!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Por favor, colocar nombre de usuario.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="formGroupPassword">
              <Form.Label>Contraseña</Form.Label>
              <Form.Control
                onChange={handleInputChange}
                type="password"
                name="password"
                required
                placeholder="Password"
              />
              <Form.Control.Feedback>¡Se ve muy bien!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Por favor, colocar contraseña
              </Form.Control.Feedback>
            </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="6">
                <Form.Label>Rol del usuario</Form.Label>
                <Form.Control
                  onChange={handleInputChange}
                  value={userData.userRole}
                  as="select"
                  name="userRole"
                  required
                >
                  <option></option>
                  {role.map((val, i) => (
                    <option key={`userRole-${i}`} value={val.split("-")[0]}>
                      {val.split("-")[1]}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback>¡Se ve muy bien!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Por favor, Seleccionar un rol.
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="6" controlId="formGridAddress">
                <Form.Label>Dirección</Form.Label>
                <Form.Control
                  onChange={handleInputChange}
                  type="text"
                  name="address"
                  value={userData.address}
                  required
                  placeholder="Kr 1g #45-69"
                />
                <Form.Control.Feedback>¡Se ve muy bien!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Por favor, colocar la dirección.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} required controlId="formGridPhone">
                <Form.Label>Telefono</Form.Label>
                <Form.Control
                  onChange={handleInputChange}
                  type="tel"
                  value={userData.phone}
                  name="phone"
                  required
                  placeholder="0000"
                />
                <Form.Control.Feedback>¡Se ve muy bien!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Por favor, colocar el telefono.
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Button className="mt-4" variant="primary" size="lg" block type="submit">
              Actualizar
            </Button>
          </Form>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <Button className="mt-4" variant="danger" size="lg" block onClick={ handleDelete }>
                  Eliminar
                </Button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div></div>
      )}
    </Container>
  );
};

UpdateUser.propTypes = {};

UpdateUser.defaultProps = {};

export default UpdateUser;
