import React from 'react';
import PropTypes from 'prop-types';
import './BodyPage.scss';

const BodyPage = () => (
  <div className="BodyPage">
    BodyPage Component
  </div>
);

BodyPage.propTypes = {};

BodyPage.defaultProps = {};

export default BodyPage;
