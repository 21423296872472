import React from "react";
import PropTypes from "prop-types";
import "./ExportCSV.scss";
import Button from "react-bootstrap/Button";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const ExportCSV = ({ headers, csvData, fileName }) => {
  const fileType ='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  csvData = [headers, ...csvData];

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.aoa_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Button variant="warning" onClick={(e) => exportToCSV(csvData, fileName)}>
      Exportar
    </Button>
  );
};

ExportCSV.propTypes = {};

ExportCSV.defaultProps = {};

export default ExportCSV;
