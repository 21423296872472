const socrioDemographic = (
  init = {
    name: "",
    advisor: "",
    date: "/ /",
    department: "",
    city: "",
    lic: "N° 801 del 15 septiembre 2016",
  },
  content = {
    CompanyName: "",
    NIT: "",
    direction: "",
    phone: "",
    celPhone: "",
    CIIU: "",
    economicActi: "",
    riskClass: "",
    colaboratosNum: "",
    // legalRepresent: "",
    cordinatorSGSST: "",
    questAplication: "",
    resultsAnalysis: "",
    conclusions: "",
  },
  chartTables = [
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
  ]
) => {
  return {
    cover: [
      "INFORME DE RESULTADOS DE PERFIL SOCIODEMOGRÁFICO, CONDICIONES DE SALUD Y SEGURIDAD",
      "EMPRESA: " + init.name.toUpperCase(),
      `ASESORÍA TÉCNICA Y METODOLÓGICA DE CORPORACIÓN TALENTUM`,
      `PROFESIONAL: ${init.advisor.toUpperCase()}`,
      `${init.department.toUpperCase()}\n${init.city.toUpperCase()}\n${init.date.toUpperCase()}`,
    ],
    toc: [
      {
        name: "TABLA DE CONTENIDO",
        children: [
          {
            name: "1. INTRODUCCIÓN",
            page: 4,
          },
          {
            name: "2. OBJETIVO GENERAL",
            page: 5,
            children: [
              {
                name: "2.1. OBJETIVOS ESPECÍFICOS",
                page: 5,
              },
            ],
          },
          {
            name: "3. MARCO CONCEPTUAL",
            page: 5,
            text_type: 6,
            children: [
              {
                name: "3.1. ACTIVIDADES DIRIGIDAS AL AMBIENTE",
                page: 6,
                text_type: 4,
              },
              {
                name: "3.2. ACTIVIDADES DIRIGIDAS A LAS PERSONAS",
                page: 7,
                text_type: 4,
              },
            ],
          },
          {
            name: "4. DATOS PERSONALES Y DEMOGRÁFICOS",
            page: 7,
            text_type: 6,
            children: [
              {
                name: "4.1. Distribución de la población por grupos de edad",
                page: 7,
                text_type: 4,
              },
              {
                name: "4.2. Distribución por tipo de documento",
                page: 8,
                text_type: 4,
              },
              {
                name: "4.3. Distribución de la población por género",
                page: 9,
                text_type: 4,
              },
              {
                name: "4.4. Distribución de la población según el estado civil",
                page: 10,
                text_type: 4,
              },
              {
                name: "4.5. Distribución por ocupación actual",
                page: 11,
                text_type: 4,
              },
              {
                name: "4.6. Distribución de la población según el medio de transporte",
                page: 12,
                text_type: 4,
              },
              {
                name: "4.7. Distribución según la zona de residencia",
                page: 13,
                text_type: 4,
              },
              {
                name: "4.8. Distribución según el tipo de vivienda donde \nhabita",
                page: 14,
                text_type: 4,
              },
              {
                name: "4.9. Distribución si es o no cabeza de familia",
                page: 15,
                text_type: 4,
              },
              {
                name: "4.10. Distribución según si se tiene o no persona a \n cargo",
                page: 16,
                text_type: 4,
              },
              {
                name: "4.11. Cuál es el nivel de educación más alto que ha \nrecibido",
                page: 8,
                text_type: 4,
              },
              {
                name: "4.12. Promedio de ingresos mensuales\n",
                page: 8,
                text_type: 4,
              },
              {
                name: "4.13. Antigüedad en el oficio actual",
                page: 8,
                text_type: 4,
              },
              {
                name: "4.14. Cuál es su forma de vinculación laboral",
                page: 8,
                text_type: 4,
              },
              {
                name: "4.15. Como es su jornada laboral",
                page: 8,
                text_type: 4,
              },
            ],
          },
          {
            name: "5. CONDICIONES DE SALUD",
            page: 18,
            text_type: 6,
            children: [
              {
                name: "5.1. Distribución de la población según el uso del tiempo libre",
                page: 18,
                text_type: 4,
              },
              {
                name: "5.2. Distribución de la población según lo hábitos",
                page: 19,
                text_type: 4,
              },
              {
                name: "5.3. Distribución de la población según el número de alimentos que recibe en el día",
                page: 20,
                text_type: 4,
              },
              {
                name: "5.4. Estado de salud general",
                page: 21,
                text_type: 4,
              },
              {
                name: "5.5. Distribución de la población según diagnóstico de enfermedades",
                page: 21,
                text_type: 4,
              },
              {
                name: "5.6. Distribución de la población según molestias en los últimos seis (6) meses",
                page: 23,
                text_type: 4,
              },
              {
                name: "5.7. Distribución de la población que presenta alguna discapacidad",
                page: 25,
                text_type: 4,
              },
            ],
          },
          {
            name: "6. CONDICIONES DE SEGURIDAD",
            page: 26,
            text_type: 6,
            children: [
              {
                name: "6.1. Conoce el Sistema de Gestión de Seguridad y Salud en el Trabajo de la empresa en la que trabaja",
                page: 26,
                text_type: 4,
              },
              {
                name: "6.2. Ha sufrido alguno de los siguientes accidentes durante la ejecución de su oficio",
                page: 27,
                text_type: 4,
              },
              {
                name: "6.3. Con que se generó el accidente durante la ejecución de su oficio",
                page: 28,
                text_type: 4,
              },
              {
                name: "6.4. Parte del cuerpo afectada",
                page: 29,
                text_type: 4,
              },
              {
                name: "6.5. Considera que al desarrollar su oficio, está expuesto a los siguientes peligros o riegos laborales",
                page: 30,
                text_type: 4,
              },
              {
                name: "6.6. Ha tenido COVID-19 con prueba positiva",
                page: 34,
                text_type: 4,
              },
              {
                name: "6.7. Se encuentra vacunado contra COVID-19\n",
                page: 35,
                text_type: 4,
              },
            ],
          },
          {
            name: "7. CONCLUSIONES",
            page: 26,
          },
          {
            name: "8. RECOMENDACIONES",
            page: 28,
          },
        ],
      },
    ],
    content: [
      {
        type: "text",
        value: "1. INTRODUCCIÓN",
        text_type: 6,
      },
      {
        type: "text",
        value: `La implementación de programas de prevención y control en Seguridad y Salud en el Trabajo (SST), constituyen la estrategia más importante para lograr la detección oportuna de patologías tanto de origen común como de origen laboral, que repercuten en la productividad de los trabajadores y afectan a la empresa y a las personas en su contexto laboral, personal, familiar y social.`,
      },
      {
        type: "text",
        value: `Para conservar el máximo nivel de bienestar integral del trabajador, se hace indispensable el desarrollo de actividades fundamentadas en el conocimiento claro de las condiciones de salud de la población objetivo y la identificación de los factores de riesgos a los cuales están expuestos dentro del desempeño de su trabajo. Lo anterior, ofrece elementos fundamentales para proponer las medidas preventivas requeridas, las cuales deben ser la base para la implementación del Sistema de Gestión de Seguridad y Salud en el Trabajo (SG-SST), a partir de la vigilancia epidemiológica que permita tener trabajadores sanos y productivos.`,
      },
      /*  {
        type: "text",
        value: "2. IDENTIFICACIÓN DE LA EMPRESA",
        text_type: 6,
      },
      {
        type: "table",
        value: {
          headers: [],
          rows: [
            { col1: "Razón Social:", col2: `${content.CompanyName.toUpperCase()}` },
            { col1: "NIT:", col2: `${content.NIT.toUpperCase()}` },
            { col1: "Dirección sede principal:", col2: `${content.direction.toUpperCase()}` },
            { col1: "Teléfono:", col2: `${content.phone.toUpperCase()}` },
            { col1: "Celular:", col2: `${content.celPhone.toUpperCase()}` },
            { col1: "Código Actividad (CIIU):", col2: `${content.CIIU.toUpperCase()}` },
            { col1: "Actividad económica:", col2: `${content.economicActi.toUpperCase()}` },
            { col1: "Clase de Riesgo:", col2: `${content.riskClass.toUpperCase()}` },
            { col1: "Número de Trabajadores:", col2: `${content.colaboratosNum.toUpperCase()}` },
            // { col1: "Representante Legal:", col2: `${content.legalRepresent.toUpperCase()}` },
            { col1: "Coordinador de SG-SST:", col2: `${content.cordinatorSGSST.toUpperCase()}` },
            {
              col1: "Fecha de aplicación de la encuesta:",
              col2: `${content.questAplication.toUpperCase()}`,
            },
          ],
        },
      },
     */ {
        type: "text",
        value: "2. OBJETIVOS",
        text_type: 6,
      },
      {
        type: "text",
        value: "2. OBJETIVO GENERAL",
        text_type: 7,
      },
      {
        type: "text",
        value: `Determinar el diagnóstico de las condiciones de salud de los trabajadores de la ${init.name.toUpperCase()}, desde la perspectiva de las enfermedades de origen común y ocupacional para obtener el perfil epidemiológico de la población que permita evaluar los efectos de la exposición a los factores de riesgo presentes en el área de trabajo en que se desempeñan y proponer las medidas de prevención y control correspondientes.`,
      },
      {
        type: "text",
        value: "2.2 OBJETIVOS ESPECÍFICOS",
        text_type: 7,
      },
      {
        type: "text",
        value:
          "Determinar la distribución por las diferentes características demográficas de la población evaluada.",
      },
      {
        type: "text",
        value: "Determinar la distribución por antigüedad en la empresa.",
      },
      {
        type: "text",
        value: "Determinar la distribución según la ocupación dentro de la empresa.",
      },
      {
        type: "text",
        value:
          "Establecer la exposición a factores de riesgo ocupacional en cada uno de los trabajadores evaluados.",
      },
      {
        type: "text",
        value:
          "Determinar los antecedentes personales patológicos de los trabajadores de la empresa.",
      },
      {
        type: "text",
        value:
          "Evaluar las actividades en las cuales invierten el tiempo libre los trabajadores de la empresa.",
      },
      {
        type: "text",
        value:
          "Proponer, con base en los resultados obtenidos, las medidas preventivas con el fin de controlar, sustituir o eliminar los factores de riesgo existentes.",
      },
      {
        type: "text",
        value: "3. MARCO CONCEPTUAL",
        text_type: 6,
      },
      {
        type: "text",
        value:
          "Se busca hacer detección temprana de los cambios en las personas y/o en el medio de trabajo con el fin de determinar las actividades que permitan controlar, sustituir o eliminar los factores de riesgo que afectan a la población objeto.",
      },
      {
        type: "text",
        value:
          "Así mismo, el análisis de resultados orienta la implementación de actividades educativas para los trabajadores, la reubicación de estos, las actividades e intervenciones médicas y sobre el ambiente de trabajo.",
      },
      {
        type: "text",
        value:
          "Los exámenes ocupacionales, se realizan periódicamente a todos los funcionarios de conformidad con los requerimientos establecidos por los sistemas de vigilancia epidemiológica.",
      },
      {
        type: "text",
        value:
          "Los exámenes permiten obtener la impresión diagnóstica y las recomendaciones a nivel individual y grupal.",
      },
      {
        type: "text",
        value:
          "El control de las patologías de origen general halladas durante la evaluación será responsabilidad de la EPS a la cual se encuentra afiliado el trabajador.",
      },
      {
        type: "text",
        value: "3.1 ACTIVIDADES DIRIGIDAS AL AMBIENTE",
        text_type: 7,
      },
      {
        type: "text",
        value:
          "De acuerdo con la percepción del trabajador, se busca identificar las áreas y puestos de trabajo, donde se presenten factores de riesgo que afecten los trabajadores:",
      },
      {
        type: "list",
        value:
          "Físicos: Iluminación, ruido, vibración, temperaturas extremas, radiaciones no ionizantes, Radiaciones ionizantes.",
      },
      {
        type: "list",
        value: "Biológicos: Virus, hongos, bacterias, Parásitos.",
      },
      {
        type: "list",
        value:
          "Ergonómicos: Movimientos repetitivos, aplicación de fuerzas, fatiga por esfuerzo, alteraciones posturales, relación hombre – máquina, uso de pantallas de video terminales, monotonía.",
      },
      {
        type: "list",
        value:
          "Riesgos de accidentes: Caídas, heridas, eléctricos, mecánicos, térmicos, atrapamientos.",
      },
      {
        type: "list",
        value:
          "Psicosociales: Clima laboral, factores propios de la tarea, organización del tiempo, gestión administrativa, sobrecarga laboral.",
      },
      {
        type: "text",
        value: "3.2 ACTIVIDADES DIRIGIDAS A LAS PERSONAS",
        text_type: 7,
      },
      {
        type: "text",
        value:
          "Básicamente comprenden la evaluación médica ocupacional que permite conocer las condiciones y patologías de los trabajadores, mediante la aplicación de la historia clínica completa y todas las actividades que se generen del estudio realizado, dirigidas al control de las variables que afectan las condiciones de salud.",
      },
      {
        type: "text",
        value: "4. DATOS PERSONALES Y DEMOGRÁFICOS",
        text_type: 6,
      },
      {
        type: "text",
        value: "4.1. Distribución de la población por grupos de edad",
        text_type: 7,
      },

      {
        type: "table",
        value: {
          headers: ["EDAD", "CANTIDAD", "DISTRIBUCIÓN"],
          rows: chartTables[0].table.map((val) => {
            return { col1: val[0], col2: val[1], col3: val[2] };
          }),
        },
        name_table: "Tabla 1. Distribución por edad.",
      },
      {
        type: "image",
        value: `${chartTables[1].chart}`,
        title_image: "\n\n\n\n\n\nGráfica 1. Distribución de la población por grupos de edad",
      },
      {
        type: "text",
        value: chartTables[0].analysis,
      },
      {
        type: "text",
        value: "4.2.	Distribución por tipo de documento",
        text_type: 7,
      },
      {
        type: "table",
        value: {
          headers: ["DOCUMENTO", "CANTIDAD", "DISTRIBUCIÓN"],
          rows: chartTables[3].table.map((val) => {
            return { col1: val[0], col2: val[1], col3: val[2] };
          }),
        },
        name_table: "Tabla 2. Distribución por tipo de documento.",
      },
      {
        type: "image",
        value: `${chartTables[2].chart}`,
        title_image: "\n\n\n\n\n\nGráfica 2. Distribución por tipo de documento.",
      },
      {
        type: "text",
        value: chartTables[3].analysis,
      },
      {
        type: "text",
        value: "4.3.	Distribución de la población por género",
        text_type: 7,
      },
      {
        type: "table",
        value: {
          headers: ["GENERO", "CANTIDAD", "DISTRIBUCIÓN"],
          rows: chartTables[4].table.map((val) => {
            return { col1: val[0], col2: val[1], col3: val[2] };
          }),
        },
        name_table: "Tabla 3. Distribución por genero.",
      },
      {
        type: "image",
        value: `${chartTables[7].chart}`,
        title_image: "\n\n\n\n\n\nGráfica 3. Distribución por genero.",
      },
      {
        type: "text",
        value: chartTables[4].analysis,
      },
      {
        type: "text",
        value: "4.4.	Distribución de la población según el estado civil",
        text_type: 7,
      },
      {
        type: "table",
        value: {
          headers: ["ESTADO CIVIL", "CANTIDAD", "DISTRIBUCIÓN"],
          rows: chartTables[5].table.map((val) => {
            return { col1: val[0], col2: val[1], col3: val[2] };
          }),
        },
        name_table: "Tabla 4. Distribución de la población según el estado civil.",
      },
      {
        type: "image",
        value: `${chartTables[6].chart}`,
        title_image: "\n\n\n\nGráfica 4. Distribución de la población según el estado civil.",
      },
      {
        type: "text",
        value: chartTables[5].analysis,
      },
      {
        type: "text",
        value: "4.5.	Distribución por ocupación actual",
        text_type: 7,
      },
      {
        type: "table",
        value: {
          headers: ["CARGO", "CANTIDAD", "DISTRIBUCIÓN"],
          rows: chartTables[8].table.map((val) => {
            return { col1: val[0], col2: val[1], col3: val[2] };
          }),
        },
        name_table: "Tabla 5. Distribución por ocupación actual.",
      },
      {
        type: "image",
        value: `${chartTables[12].chart}`,
        title_image:
          "\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\nGráfica 5. Distribución por ocupación actual.",
      },
      {
        type: "text",
        value: chartTables[8].analysis,
      },
      {
        type: "text",
        value: "4.6.	Distribución de la población según el medio de transporte",
        text_type: 7,
      },
      {
        type: "table",
        value: {
          headers: ["TRANSPORTE", "CANTIDAD", "DISTRIBUCIÓN"],
          rows: chartTables[9].table.map((val) => {
            return { col1: val[0], col2: val[1], col3: val[2] };
          }),
        },
        name_table: "Tabla 6. Distribución de la población según el medio de transporte.",
      },

      {
        type: "image",
        value: `${chartTables[15].chart}`,
        title_image: "Gráfica 6. Distribución de la población según el medio de transporte.",
      },
      {
        type: "text",
        value: chartTables[9].analysis,
      },
      {
        type: "text",
        value: "4.7.	Distribución según la zona de residencia",
        text_type: 7,
      },
      {
        type: "table",
        value: {
          headers: ["ZONA", "CANTIDAD", "DISTRIBUCIÓN"],
          rows: chartTables[10].table.map((val) => {
            return { col1: val[0], col2: val[1], col: val[2] };
          }),
        },
        name_table: "Tabla 7. Distribución según la zona de residencia.",
      },
      {
        type: "image",
        value: `${chartTables[11].chart}`,
        title_image: "\n\n\n\n\n\n\n\nGráfica 7. Distribución según la zona de residencia",
      },
      {
        type: "text",
        value: chartTables[10].analysis,
      },
      {
        type: "text",
        value: "4.8.	Distribución según el tipo de vivienda donde habita",
        text_type: 7,
      },
      {
        type: "table",
        value: {
          headers: ["TIPO DE RESIDENCIA", "CANTIDAD", "DISTRIBUCIÓN"],
          rows: chartTables[17].table.map((val) => {
            return { col1: val[0], col2: val[1], col3: val[2] };
          }),
        },
        name_table: "Tabla 8. Distribución según el tipo de vivienda donde habita",
      },
      {
        type: "image",
        value: `${chartTables[20].chart}`,
        title_image: "\n\n\n\n\n\n\n\nGráfica 8. Distribución según la zona de residencia.",
      },
      {
        type: "text",
        value: chartTables[17].analysis,
      },
      {
        type: "text",
        value: "4.9.	Distribución si es o no cabeza de familia",
        text_type: 7,
      },
      {
        type: "table",
        value: {
          headers: ["CABEZA DE FAMILIA", "CANTIDAD", "DISTRIBUCIÓN"],
          rows: chartTables[16].table.map((val) => {
            return { col1: val[0], col2: val[1], col3: val[2] };
          }),
        },
        name_table: "Tabla 9. Distribución según la clasificación si es o no cabeza de familia",
      },
      {
        type: "image",
        value: `${chartTables[14].chart}`,
        title_image:
          "\n\n\n\n\n\n\n\nGráfica 9. Distribución según la clasificación si es o no cabeza de familia.",
      },
      {
        type: "text",
        value: chartTables[16].analysis,
      },
      {
        type: "text",
        value: "4.10.	Distribución según el número de personas a cargo",
        text_type: 7,
      },
      {
        type: "table",
        value: {
          headers: ["PERSONAS A CARGO", "CANTIDAD", "DISTRIBUCIÓN"],
          rows: chartTables[18].table.map((val) => {
            return { col1: val[0], col2: val[1], col3: val[2] };
          }),
        },
        name_table: "Tabla 10. Distribución según si se tiene o no persona a cargo.",
      },
      {
        type: "image",
        value: `${chartTables[19].chart}`,
        title_image: "\n\n\nGráfica 10. Distribución según si se tiene o no persona a cargo.",
      },
      {
        type: "text",
        value: chartTables[18].analysis,
      },
      {
        type: "text",
        value: "4.11.	Cuál es el nivel de educación más alto que ha recibido",
        text_type: 7,
      },
      {
        type: "table",
        value: {
          headers: ["NIVEL DE EDUCACIÓN", "CANTIDAD", "DISTRIBUCIÓN"],
          rows: chartTables[21].table.map((val) => {
            return { col1: val[0], col2: val[1], col3: val[2] };
          }),
        },
        name_table: "Tabla 11. Nivel más alto de educación recibido",
      },

      {
        type: "image",
        value: `${chartTables[23].chart}`,
        title_image: "\nGráfica 11. Nivel más alto de educación recibido",
      },
      {
        type: "text",
        value: chartTables[21].analysis,
      },
      {
        type: "text",
        value: "4.12.	Promedio de ingresos mensuales",
        text_type: 7,
      },
      {
        type: "table",
        value: {
          headers: ["INGRESOS MENSUALES", "CANTIDAD", "DISTRIBUCIÓN"],
          rows: chartTables[22].table.map((val) => {
            return { col1: val[0], col2: val[1], col3: val[2] };
          }),
        },
        name_table: "Tabla 12.  Ingresos Mensuales.",
      },
      {
        type: "image",
        value: `${chartTables[25].chart}`,
        title_image: "\n\n\nGráfica 12. Ingresos Mensuales.",
      },
      {
        type: "text",
        value: chartTables[22].analysis,
      },
      {
        type: "text",
        value: "4.13.	Antigüedad en el oficio actual",
        text_type: 7,
      },
      {
        type: "table",
        value: {
          headers: ["ANTIGÜEDAD EN EL OFICIO ACTUAL", "CANTIDAD", "DISTRIBUCIÓN"],
          rows: chartTables[24].table.map((val) => {
            return { col1: val[0], col2: val[1], col3: val[2] };
          }),
        },
        name_table: "Tabla 13. Antigüedad en el oficio actual",
      },
      {
        type: "image",
        value: `${chartTables[26].chart}`,
        title_image: "Gráfica 13. Antigüedad en el oficio actual",
      },
      {
        type: "text",
        value: chartTables[24].analysis,
      },
      {
        type: "text",
        value: "4.14.	Cuál es su forma de vinculación laboral",
        text_type: 7,
      },
      {
        type: "table",
        value: {
          headers: ["VINCULACIÓN LABORAL", "CANTIDAD", "DISTRIBUCIÓN"],
          rows: chartTables[27].table.map((val) => {
            return { col1: val[0], col2: val[1], col3: val[2] };
          }),
        },
        name_table: "Tabla 14. Vinculación laboral",
      },
      {
        type: "image",
        value: `${chartTables[28].chart}`,
        title_image: "\n\n\nGráfica 14. Vinculación laboral",
      },
      {
        type: "text",
        value: chartTables[27].analysis,
      },
      {
        type: "text",
        value: "4.15.	Cuál es su forma de vinculación laboral.",
        text_type: 7,
      },
      {
        type: "table",
        value: {
          headers: ["JORNADA LABORAL", "CANTIDAD", "DISTRIBUCIÓN"],
          rows: chartTables[29].table.map((val) => {
            return { col1: val[0], col2: val[1], col3: val[2] };
          }),
        },
        name_table: "Tabla 15. ¿Cómo es su jornada laboral?",
      },
      {
        type: "image",
        value: `${chartTables[31].chart}`,
        title_image: "\n\n\nGráfica 15. Jornada Laboral.",
      },
      {
        type: "text",
        value: chartTables[29].analysis,
      },
      //"5. CONDICIONES DE SALUD"

      {
        type: "text",
        value: "\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n5. CONDICIONES DE SALUD",
        text_type: 6,
      },
      //DEPORTE
      {
        type: "text",
        value: "5.1. Distribución de la población según el uso del tiempo libre",
        text_type: 6,
      },
      {
        type: "image",
        value: `${chartTables[35].chart}`,
        title_image: "Gráfica 16. ¿Práctica algún deporte?",
      },
      {
        type: "text",
        value: chartTables[35].analysis,
      },
      //HABITOS
      {
        type: "text",
        value: "\n5.2. Distribución de la población según los hábitos.",
        text_type: 6,
      },
      {
        type: "image",
        value: `${chartTables[33].chart}`,
        title_image: "Gráfica 17. Población fumadora.",
      },
      {
        type: "image",
        value: `${chartTables[34].chart}`,
        title_image:
          "\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\nGráfica 18. Población que consume licor.",
      },
      {
        type: "text",
        value: chartTables[33].analysis,
      },
      //ALIMENTOS DIA
      {
        type: "text",
        value:
          "\n\n\n\n\n\n\n\n\n\n5.3. Distribución de la población según el número de alimentos que recibe en el día .",
        text_type: 6,
      },
      {
        type: "image",
        value: `${chartTables[36].chart}`,
        title_image: "Gráfica 19. Cuantas veces se alimenta al día.",
      },
      {
        type: "text",
        value: chartTables[36].analysis,
      },
      //SALUD GENERAL
      {
        type: "text",
        value: "\n\n\n\n\n\n\n\n\n\n5.4. Estado de salud general.",
        text_type: 6,
      },
      {
        type: "image",
        value: `${chartTables[37].chart}`,
        title_image: "Gráfica 20. Estado de salud en general.",
      },
      {
        type: "text",
        value: chartTables[37].analysis,
      },
      //ENFERMEDAD
      {
        type: "text",
        value: "\n\n\n\n\n\n\n\n\n\n5.5. Distribución de la población según diagnóstico de enfermedades.",
        text_type: 6,
      },
      {
        type: "text",
        value: "¿Le han diagnosticado una o más enfermedades?",
      },
      {
        type: "image",
        value: `${chartTables[38].chart}`,
        title_image: "Gráfica 21. Diagnóstico de enfermedades.",
      },
      {
        type: "text",
        value: chartTables[38].analysis,
      },
  
      //MOLESTIAS 6 MESES
      {
        type: "text",
        value: "\n\n\n\n\n\n\n\n\n\n5.6. Distribución de la población según molestias en los ultimos seis (6) meses.",
        text_type: 6,
      },
      {
        type: "table",
        value: {
          headers: ["TIPO DE MOLESTIA", "CANTIDAD"],
          rows: chartTables[39].table.map((val) => {
            return { col1: val[0], col2: val[1] };
          }),
        },
        name_table: "Tabla 16. Distribución según molestias en los últimos seis (6) meses.",
      },
      {
        type: "image",
        value: `${chartTables[13].chart}`,
        title_image: "\n\nGráfica 22. Molestias en los últimos (6) seis meses.",
      },
      {
        type: "text",
        value: chartTables[39].analysis,
      },
      //DISCAPACIDAD
      {
        type: "text",
        value: "5.7. Distribución de la población que presenta alguna discapacidad.",
        text_type: 7,
      },
      {
        type: "table",
        value: {
          headers: ["PRESENTA ALGUNA DISCAPACIDAD", "CANTIDAD", "DISTRIBUCIÓN"],
          rows: chartTables[40].table.map((val) => {
            return { col1: val[0], col2: val[1], col3: val[2] };
          }),
        },
        name_table: "Tabla 17. Distribución de la población que presenta discapacidad.",
      },
      {
        type: "image",
        value: `${chartTables[30].chart}`,
        title_image: "\n\nGráfica 23. población que presenta alguna discapacidad.",
      },
      {
        type: "text",
        value: chartTables[40].analysis,
      },

      //CONDICIONES DE SEGURIDAD
      {
        type: "text",
        value: "6. CONDICIONES DE SEGURIDAD",
        text_type: 6,
      },
      //SST
      {
        type: "text",
        value:
          "6.1. Conoce el Sistema de Gestión de Seguridad y Salud en el Trabajo de la empresa en la que trabaja.",
        text_type: 6,
      },
      {
        type: "table",
        value: {
          headers: ["CUENTA CON SG-SST", "CANTIDAD", "DISTRIBUCIÓN"],
          rows: chartTables[41].table.map((val) => {
            return { col1: val[0], col2: val[1], col3: val[2] };
          }),
        },
        name_table: "Tabla 18. ¿Conoce el SG-SST?.",
      },
      {
        type: "image",
        value: `${chartTables[32].chart}`,
        title_image: "Gráfica 24. ¿Conoce el SG-SST?.",
      },
      {
        type: "text",
        value: chartTables[41].analysis,
      },
      //ACCIDENTE DURANTE OFICIO
      {
        type: "text",
        value:
          "\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n6.2. Ha sufrido alguno de los siguientes accidentes durante la ejecución de su oficio.",
        text_type: 5,
      },
      {
        type: "image",
        value: `${chartTables[42].chart}`,
        title_image: "Gráfica 25. Accidente durante la ejecución de su oficio.",
      },
      {
        type: "text",
        value: chartTables[42].analysis,
      },
      //EVENTO RECIENTE ACCIDENTE
      {
        type: "text",
        value: "\n\n\n\n\n\n\n\n6.3. Con que se generó el accidente durante la ejecución de su oficio.",
        text_type: 5,
      },
      {
        type: "table",
        value: {
          headers: ["OBJETO QUE GENERÓ AT", "CANTIDAD", "DISTRIBUCIÓN"],
          rows: chartTables[43].table.map((val) => {
            return { col1: val[0], col2: val[1], col3: val[2] };
          }),
        },
        name_table: "Tabla 19. Con que se generó el accidente en su oficio.",
      },
      {
        type: "image",
        value: `${chartTables[44].chart}`,
        title_image: "\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\nGráfica 26. Con que se generó el accidente en su oficio.",
      },
      {
        type: "text",
        value: chartTables[43].analysis,
      },
      //PARTE DEL CUERPO
      {
        type: "text",
        value: "\n\n\n\n\n\n\n\n\n\n\n\n6.4. Parte del cuerpo afectada.",
        text_type: 5,
      },
      {
        type: "image",
        value: `${chartTables[45].chart}`,
        title_image: "Gráfica 27. Parte del cuerpo afectada.",
      },
      {
        type: "text",
        value: chartTables[45].analysis,
      },
      //EXPUESTO PELIGRO
      {
        type: "text",
        value:
          "6.5. Considera que al desarrollar su oficio, está expuesto a los siguientes peligros o riegos laborales.",
        text_type: 5,
      },
      {
        type: "table",
        value: {
          headers: ["EXPOSICIÓN A PELIGROS O RIESGOS LABORALES", "CANTIDAD", "DISTRIBUCIÓN"],
          rows: chartTables[46].table.map((val) => {
            return { col1: val[0], col2: val[1], col3: val[2] };
          }),
        },
        name_table: "Tabla 20. Está expuesto a peligros o riesgos laborales.",
      },
      {
        type: "image",
        value: `${chartTables[46].chart}`,
        title_image: "\n\n\n\n\n\n\n\n\n\nGráfica 28. Está expuesto a peligros o riesgos laborales.",
      },
      {
        type: "text",
        value: chartTables[46].analysis,
      },
      //PRUEBA COVID
      {
        type: "text",
        value: "6.6. Ha tenido COVID-19 con prueba positiva.",
        text_type: 7,
      },
      {
        type: "table",
        value: {
          headers: ["COVID-19", "CANTIDAD", "DISTRIBUCIÓN"],
          rows: chartTables[47].table.map((val) => {
            return { col1: val[0], col2: val[1], col3: val[2] };
          }),
        },
        name_table: "Tabla 21. ¿Ha tenido COVID 19?.",
      },
      {
        type: "image",
        value: `${chartTables[48].chart}`,
        title_image: "\n\n\n\n\n\n\n\n\n\nGráfica 29. ¿Ha tenido COVID 19?.",
      },
      {
        type: "text",
        value: chartTables[47].analysis,
      },
      //VACUNA COVID
      {
        type: "text",
        value: "6.7. Se encuentra vacunado contra COVID-19 .",
        text_type: 7,
      },
      {
        type: "table",
        value: {
          headers: ["VACUNACIÓN COVID-19", "CANTIDAD", "DISTRIBUCIÓN"],
          rows: chartTables[49].table.map((val) => {
            return { col1: val[0], col2: val[1], col3: val[2] };
          }),
        },
        name_table: "Tabla 22. Se vacunaría contra COVID-19.",
      },
      {
        type: "image",
        value: `${chartTables[50].chart}`,
        title_image: "\n\n\n\n\n\n\n\n\n\nGráfica 30. Se vacunaría contra COVID-19.",
      },
      {
        type: "text",
        value: chartTables[49].analysis,
      },
      {
        type: "text",
        value: "7. CONCLUSIONES",
        text_type: 6,
      },
      {
        type: "text",
        value: content.conclusions,
      },
      {
        type: "text",
        value: "8. RECOMENDACIONES",
        text_type: 6,
      },
    ],
  };
};

export default socrioDemographic;
