import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./AccordionMobile.scss";
import { ButtonGroup, Button, Accordion as Accordion2, Card } from "react-bootstrap";

import { NavLink } from "react-router-dom";
import { Row, Col } from "react-bootstrap";

import { GET } from "../../constants/ApiRest";

import { ReactComponent as Done } from "../../img/icons/status/done.svg";
import { ReactComponent as Process } from "../../img/icons/status/process.svg";
import { ReactComponent as Clock } from "../../img/icons/status/wall-clock.svg";

const AccordionMobile = ({ buttons, compani, path, form_id }) => {
  const [status, setStatus] = useState();
  // const [formId, setFormId] = useState(null);

  const handleClick = async (idCompany) => {
    const request = await GET(
      `${process.env.REACT_APP_COMPANY_ENDPOINT}/company/status/${idCompany}`
    );
    // const data = await request.data;
    setStatus(await request.data);
  };

  // useEffect(() => {

  //   if ( compani.risk <= 3 && compani.workers <= 10) {
  //     setFormId(6);
  //   } else if (compani.risk <= 3 && compani.workers > 10 && compani.workers < 50) {
  //     setFormId(0);
  //   } else if (compani.risk <= 5 && compani.workers > 50) {
  //     setFormId(7);
  //   } else if (compani.risk == 4 || (compani.risk == 5 && compani.workers < 50)) {
  //     setFormId(7);
  //   }

  // }, [])

  return (
    <>
      <Accordion2 defaultActiveKey="0" className="w-100">
        { Array.isArray(compani) && compani.map((value, j) => {
          return (
            <Card>
              <Row className="sm-buttonlist w-100 m-0">
                <Col className="m-0 p-0 d-flex align-items-center sm-logoContent" md={2} xs={2}>
                  <img className="w-100" src={ process.env.REACT_APP_COMPANY_ENDPOINT + value.company.files[0].url}></img>
                </Col>
                <Col className="p-0 m-0" md={10} xs={10}>
                  <Card.Header
                    className="h-100"
                    style={{ padding: "0em", backgroundColor: "#3F4DA0" }}
                  >
                    <Accordion2.Toggle
                      as={Button}
                      className="text-light w-100 text-left"
                      variant="link"
                      eventKey={j + 1}
                      onClick={() => handleClick(value.company.id)}
                    >
                      <p className="sm-titleButton">{value.company.name}</p>
                      <p className="sm-titleNit">Nit: {value.company.nit}</p>
                    </Accordion2.Toggle>
                  </Card.Header>
                </Col>
              </Row>
              {/* <Card.Header style={{ padding: "0em", backgroundColor: "#3F4DA0" }}>
              <Accordion2.Toggle
                as={Button}
                className="text-light w-100 text-left"
                variant="link"
                eventKey={j + 1}
              >
                {value}
              </Accordion2.Toggle>
            </Card.Header> */}
              <Accordion2.Collapse eventKey={j + 1}>
                <Card.Body style={{ padding: "0em" }}>
                  <ButtonGroup vertical className="stylePopoverButtonGroup">
                    {buttons.map((data, i) => (
                      <NavLink
                        className="stylePopoverButton w-100 text-decoration-none"
                        to={{
                          pathname: `/init/ ${ path ? path : "diagnosis" } }`,
                          state: {
                            ActiveKey: `${data.key}`,
                            dataCompany: value,
                            idCompany: value.id,
                            form_id: form_id ? form_id : null,
                            title: value.name,
                          },
                        }}
                      >
                        <Button
                          className="stylePopoverButton w-100"
                          key={i} /* onClick={handleClick} */
                        >
                          <div>{data.title}</div>
                          <div>
                            {/* {props o context} */}
                            {Array.isArray(status) && status[i].status}
                            {/* %`${Math.ceil(Math.random() * (100 - 0) + 0)}%` */}
                            {Array.isArray(status) &&
                              (status[i].status === "Pendiente" ? (
                                <Clock style={{ width: "1.5rem", marginLeft: "1rem" }} />
                              ) : status[i].status === "En ejecución" ? (
                                <Process style={{ width: "1.5rem", marginLeft: "1rem" }} />
                              ) : (
                                <Done style={{ width: "1.5rem", marginLeft: "1rem" }} />
                              ))}
                          </div>
                        </Button>
                      </NavLink>
                    ))}
                  </ButtonGroup>
                </Card.Body>
              </Accordion2.Collapse>
            </Card>
          );
        })}
      </Accordion2>
    </>
  );
};

AccordionMobile.propTypes = {};

AccordionMobile.defaultProps = { buttons: PropTypes.array };

export default AccordionMobile;
