import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Form, Button } from "react-bootstrap";
import ReactChart from "../ReactChart/ReactChart";
import './FormSocioDemographic.scss'

//import component

import InputFormTable from "./../InputFormTable/InputFormTable";

const FormSocioDemographic = ({ GeneratePDF, dataCharts }) => {
  const [data, setData] = useState({
    conclution: "",
    resultsAnalysis: "",
    distributionCharges: [["", "", ""]],
    distributionPersonalBackground: [["", "", ""]],
    analysis: [""],
  });
  /* const [inputArray, setInputArray] = useState([{ Dato1: "", Dato2: "", Dato3: "" }]);
  const [inputArray2, setInputArray2] = useState([{ Dato1: "", Dato2: "", Dato3: "" }]); */
  const [chartsAllBolean, setchartsBolean] = useState([])
  const setInputArray = (dataCurrent) => {
    let auxArray = { ...data };
    auxArray["distributionCharges"] = dataCurrent;
    setData(auxArray);
  };

  useEffect(() => {
    data.distributionCharges = dataCharts[19].table;
    data.distributionPersonalBackground = dataCharts[31].table;
    data.analysis = dataCharts.map((val) => {
      return val.analysis;
    });
    setData({ ...data });
  }, [dataCharts]);

  const setInputArray2 = (dataCurrent) => {
    let auxArray = { ...data };
    auxArray["distributionPersonalBackground"] = dataCurrent;
    setData(auxArray);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    GeneratePDF(1, data);
  };

  const handleInputChangeArray = (event) => {
    let auxData = [...data.analysis];
    const target = event.target;
    const value = target.value;
    const name = target.name.split("-");
    auxData[name[1]] = value;
    setData({ ...data, analysis: auxData });
  };

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setData({ ...data, [name]: value });
  };
  //graficas
  const [arrayOfCharts, setArrayOfCharts] = useState([]);
  useEffect(() => {
    chart();
    setchartsBolean(chartsAllBolean.map(val => false));
  }, [data]);

  const chart = () => {
    let auxArrayOfCharts = [...arrayOfCharts];
    dataCharts.map((c, i) => {
      renderObjTable(c, i, auxArrayOfCharts);
    });
    setArrayOfCharts(auxArrayOfCharts);
  };

  const renderObjTable = (value, index, auxArrayOfCharts) => {
    let arrayValue = value.table;
    let labelArray = arrayValue.map((x) => x[0]);
    let labelDataArray = arrayValue.map((x) => {
      return x[1].toString().replace(/%/, "").replace(/-/, "");
    });
    let chartObj = {
      chartsData: {
        labels: labelArray,
        animation: {
          duration: 0,
        },
        hover: {
          animationDuration: 0, // duration of animations when hovering an item
        },
        responsiveAnimationDuration: 0, // animation duration after a resize
        datasets: [
          {
            label: "",
            data: labelDataArray,
            backgroundColor: "#33AEEF",
            borderWidth: 4,
          },
        ],
      },
      chartsOptions: {
        title: {
          display: true,
          text: "Grafica",
          fontSize: 20,
        },
        legend: {
          display: false,
        },
      },
    };
    auxArrayOfCharts[index] = chartObj;
  };

  const [isDisableButton, setIsDisableButton] = useState(true);
  const updateChart = (indexId, isReady, auxChartsAllBolean) => {
    auxChartsAllBolean[indexId] = isReady;
    if (auxChartsAllBolean.length - 1 == indexId) {
      // console.log(auxChartsAllBolean);
      setIsDisableButton(auxChartsAllBolean.includes(false));
    }
  }

  const renderCharts = () => {
    const pies = [1, 2, 7, 8, 9, 10, 11, 14, 20, 21, 22, 32, 35, 48, 50];
    const horizontal = [19];
    const isNotPercentage = [16, 18, 24];

    let type = 1;
    const auxChartsAllBolean = [...chartsAllBolean];
    return arrayOfCharts.map((t, i) => {
      if (pies.includes(i)) {
        t.chartsOptions.legend = { display: true, position: "bottom" };
        type = 4;
      } else if (horizontal.includes(i)) {
        type = 2;
      } else {
        type = 1;
      }
      return (
        <ReactChart
          key={i}
          dataChartPdfReportGenerator={dataChartPdfReportGenerator}
          dataCharts={t.chartsData}
          optionsCharts={t.chartsOptions}
          value={i}
          typeP={type}
          isPercentage={!isNotPercentage.includes(i)}
          isCharReady={updateChart}
          auxChartsAllBolean={auxChartsAllBolean} />
      );
    });
  };

  const dataChartPdfReportGenerator = (data, i) => {
    const base64Image = data;
    dataCharts[i].chart = base64Image;
  };
  //fin graficas
  return (
    <Form onSubmit={handleSubmit}>
      <Button variant="danger" type="submit" disabled={isDisableButton} block>
        Visualizar PDF
      </Button>
      {/**
      <InputFormTable
        inputArray={data.distributionCharges}
        setInputArray={setInputArray}
        title="Distribución de la población por cargos"
        defaultCols={[...dataCharts[19].table[0]]}
      />
      <InputFormTable
        inputArray={data.distributionPersonalBackground}
        setInputArray={setInputArray2}
        title="Distribución según Antecedentes Personales"
        defaultCols={[...dataCharts[31].table[0]]}
      />  */}
      {/*data.analysis.map((inputData, i) => {
        return (
          <Form.Group key={`analisis-${i}`} controlId="exampleForm.ControlTextarea1">
            <Form.Label>ANÁLISIS DE RESULTADOS (tabla-{i + 1})</Form.Label>
            <Form.Control
              as="textarea"
              onChange={handleInputChangeArray}
              name={`analysis-${i}`}
              value={inputData}
              rows={3}
            />
          </Form.Group>
        );
      })*//*}
      <Form.Group controlId="exampleForm.ControlTextarea1">
        <Form.Label>ANÁLISIS DE RESULTADOS</Form.Label>
        <Form.Control as="textarea" onChange={handleInputChange} name="resultsAnalysis" rows={3} />
      </Form.Group>
      <Form.Group controlId="exampleForm.ControlTextarea1">
        <Form.Label>Conclusiones</Form.Label>
        <Form.Control as="textarea" onChange={handleInputChange} name="conclution" rows={3} />
      </Form.Group>
      {/* charts */}
      {renderCharts()}
    </Form>
  );
};

FormSocioDemographic.propTypes = { GeneratePDF: PropTypes.func };

FormSocioDemographic.defaultProps = { GeneratePDF: function blank(params) { } };

export default FormSocioDemographic;
