import React, { useState, useEffect } from "react";
import "./GeneralData.scss";
import { Row, Col } from "react-bootstrap";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import ArrowBack from "../../img/icons/iconArrowBack.svg";
import { NavLink, useLocation } from "react-router-dom";
import FormGeneralData from "../../components/InputGeneralData/InputGeneralData";
import FormGeneralData2 from "../../components/InputGeneralDataComplete/InputGeneralDataComplete";

function GeneralData() {
  const location = useLocation();

  const [company, setCompany] = useState([]);

  useEffect(() => {
    // let idUser = localStorage.getItem("idUser")
    // console.log(location.state.array);
    setCompany(location.state.array);
    //Petición para traer las comapañias de la base de datos
    // GET("http://localhost:3003/company/all")
    //   .then((res) => {
    //     if (res.data) {
    //       console.log(res);
    //       if (res.data != "la sesión ha expirado") {
    //         setCompany(res.data, "name");
    //       } else {
    //         setCompany([]);
    //       }
    //     }
    //   });
    // GET(`${process.env.REACT_APP_COMPANY_ENDPOINT}/company/${idUser}`).then(res=>{
    //   console.log(res)
    //  if(res.data) {
    //    if(res.data !="la sesión ha expirado"){
    //    setCompany(res.data, "name")
    //    }else{
    //      setCompany([])
    //    }
    //  }
    //  })
  }, []);

  let ruteCompany = '/init/companies';

  return (
    <div className="container GeneralData px-xl-5">

      {/* Menu */}
      <Row>
        <div>
          <Breadcrumbs
            view={[
              { name: "Inicio", path: "/init/graphics" },
              { name: "Empresas", path: ruteCompany },
              { name: `${location.state.name}`, path: "" },
            ]}
          ></Breadcrumbs>
          <NavLink to={ruteCompany} className="styleArrowLink">
            <img src={ArrowBack} width="50" />
          </NavLink>
        </div>
      </Row>

      {/* Titulo */}
      <p className="titleGeneralData text-center">Información de la empresa</p>

      {/* Formulario */}
      <Row>
        <Col>
          {company && location.state.array ? (
            <FormGeneralData2 nameArray={location.state.array} />
          ) : (
            <FormGeneralData />
          )}
          {/* {localStorage.getItem("company") &&
          JSON.parse(localStorage.getItem("company"))[location.state.name] ? (
            <FormGeneralData2 nameArray={location.state.name} />
          ) : (
            <FormGeneralData />
          )} */}
        </Col>
      </Row>
    </div>
  );
}

GeneralData.propTypes = {};

GeneralData.defaultProps = {};

export default GeneralData;
