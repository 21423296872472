import React, { useState, useEffect } from "react";
import "./GraphicsPage.scss";

//Components native React
import { Row, Col } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";

//Components and views
import ProgressBar from "./../../components/ProgressBar/ProgressBar";

const GraphicsPage = ({ login, setLogin }) => {
  const history = useHistory();
  //Hooks Definition
  const [graphics] = useState([
    {
      percentage: "100",
      subclass: "2",
      authorization: "2",
      classAplicate: "1",
      text: "Diagnóstico",
      routes: "/diagnose",
    },
    {
      percentage: "30",
      subclass: "3",
      authorization: "3",
      classAplicate: "1",
      text: "Informes - Planes de Acción",
      routes: "/document",
    },
    {
      percentage: "50",
      subclass: "4",
      authorization: "4",
      classAplicate: "1",
      text: "Reportes",
      routes: "/reports",
    },
    {
      percentage: "80",
      subclass: "5",
      authorization: "1",
      classAplicate: "2",
      text: "Documentos de consulta",
      routes: "/reference_documents",
    },
  ]);


  return (
    <div className="container GraphicsPage">
      {
        window.screen.width < 767 ?
          <Row>
            <Col sm={6}>
              <p className="sm-titleGraphics">Ruta de gestión y control SST-SMART®</p>
              <p className="sm-text">Estos son los módulos donde podrá encontrar la gestión detallada de cada una de las empresas, actividades de Seguridad y Salud en el Trabajo, evidencias de ejecución, seguimiento y control, así como documentos para cada grupo de interés para la Fundación Nuevo Horizonte.</p>
            </Col>
            <Col sm={4}>
              <Link className="sm-circle-10" to="/init/companies">
                <ProgressBar percentage="100" subclass="1"></ProgressBar>
              </Link>
              <p className="sm-circleTop">Datos Generales <br />Empresa</p>
            </Col>
          </Row>
          :
          <Row>
            <Col sm={4} className="ml-4">
              <p className="sm-circleTop">Datos Generales</p>
              <Link className="sm-circle-10" to="/init/companies">
                <ProgressBar percentage="100" subclass="1"></ProgressBar>
              </Link>
            </Col>
            <Col sm={6}>
              <p className="sm-titleGraphics">Ruta de gestión y control SST-SMART®</p>
              <p className="sm-text">Estos son los módulos donde podrá encontrar la gestión detallada de cada una de las empresas, actividades de Seguridad y Salud en el Trabajo, evidencias de ejecución, seguimiento y control, así como documentos para cada grupo de interés para la Fundación Nuevo Horizonte.</p>
            </Col>
          </Row>
      }


      <Row className="row-bottom">
        {graphics.map((obj, i) => {
          return login.authorizations.includes(obj.authorization) ? (
            <Col sm className="sm-graphics" key={`graphics-${i}`}>
              <Link to={`/init${obj.routes}`} className={`sm-circle-${obj.subclass}0`}>
                {/* <ProgressBar percentage={obj.percentage} subclass={obj.subclass}></ProgressBar> */}
                <ProgressBar percentage={"100"} subclass={obj.subclass}></ProgressBar>
              </Link>
              <div className={`sm-circle sm-circle-${obj.subclass}`}>{obj.text}</div>
            </Col>
          ) : (
            <Col sm className="sm-graphics disable" key={`graphics-${i}`}>
              <div to={`/init${obj.routes}`} className={`sm-circle-${obj.subclass}0`}>
                {/* <ProgressBar percentage={obj.percentage} subclass={obj.subclass}></ProgressBar> */}
                <ProgressBar percentage={"100"} subclass={obj.subclass}></ProgressBar>
              </div>
              <div className={`sm-circle sm-circle-${obj.subclass}`}>{obj.text}</div>
            </Col>
          );
        })}

        <Col className="sm-graphics">
          <Link className="sm-circle-60" to="/init/diagnose">
            <ProgressBar percentage="100" subclass="6"></ProgressBar>
          </Link>
          <div className="sm-circle sm-circle-6">Ayuda</div>
        </Col>
      </Row>
    </div>
  );
};

export default GraphicsPage;
