import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

//Components and views
import RouterPage from "./Router";


function App() {
  return (
    <div className="sm-background">
   <RouterPage></RouterPage>
    </div>
  );
}

export default App;
