import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./DeleteCompany.scss";
import { Container, Row, Col, Form, Button, Alert, Table } from "react-bootstrap";
import ModalConfirm from "../../components/ModalConfirm/ModalConfirm";

import { ReactComponent as Done } from "../../img/icons/status/done.svg";
import { ReactComponent as Process } from "../../img/icons/status/process.svg";
import { ReactComponent as Clock } from "../../img/icons/status/wall-clock.svg";

// Request
import { DELETE, GET } from "../../constants/ApiRest";

const DelCompany = ({ setLogin }) => {
  const [companyToDel, setCompanyToDel] = useState({
    nit: "",
    name: "",
    responsible: "",
    id: "",
  });
  const [isCompany, setIsCompany] = useState(false);
  const [status, setStatus] = useState({});
  const [showDelete, setShowDelete] = useState(false);
  const [showSendDelete, setShowSendDelete] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [nitCompany, setNitCompany] = useState("");
  const [modalInfo, setModalInfo] = useState({
    title: "",
    message: "",
    typeBtn: "danger",
  });

  const handleDelete = (e) => {
    setModalInfo({
      title: "Confirmar eliminación de empresa",
      message: "Se eliminarán los datos de la empresa y los estados de las herramientas. ESTA ACCIÓN NO SE PUEDE DESHACER!",
      typeBtn: "danger"
    });

    setShowModal(true);
  };

  useEffect(() => {
    if (nitCompany !== "") {
      GET(`${process.env.REACT_APP_COMPANY_ENDPOINT}/company/byNit/${nitCompany}`)
        .then((result) => {
          const data = result.data;
          setCompanyToDel({
            nit: nitCompany,
            name: data.name,
            responsible: data.responsible,
            id: data.id,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (companyToDel.id !== "") {
      GET(`${process.env.REACT_APP_COMPANY_ENDPOINT}/company/status/${companyToDel.id}`)
        .then((result) => {
          const data2 = result.data;
          setStatus(data2);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [nitCompany, companyToDel.id]);

  const handleSubmit = (event) => {
    event.preventDefault();
    let nit = event.target.nit.value;
    let valNit = nit.length > 7;

    !valNit ? alert("Ingrese Nit valido") : setNitCompany(nit);

    setIsCompany(valNit);
  };

  const handleClose = () => setShowModal(false);

  const acceptModal = () => {
    DELETE(`${process.env.REACT_APP_COMPANY_ENDPOINT}/company/delete/${companyToDel.nit}`)
      .then((res) => {
        setShowSendDelete(true);
        document.getElementById("nit").value = "";
        setIsCompany(false);

        handleClose();
      })
      .catch((error) => setShowDelete(true));
    handleClose();
  };

  return (
    <Container className="DelCompany">
      {!showDelete || (
        <Alert variant="danger" onClose={() => setShowDelete(false)} dismissible>
          <Alert.Heading>¡Oh, vaya!, ha ocurrido un error al eliminar la empresa.</Alert.Heading>
          <p>Prueba eliminarla en 10 minutos.</p>
          <p>Si el error persiste, comunícate con soporte técnico.</p>
        </Alert>
      )}

      {!showSendDelete || (
        <Alert variant="success" onClose={() => setShowSendDelete(false)} dismissible>
          <Alert.Heading>La empresa ha sido eliminada con éxito.</Alert.Heading>
        </Alert>
      )}

      <Row>
        <Col>
          <h3 className="mb-4">Eliminar Empresa</h3>
        </Col>
      </Row>

      <Form md="4" className="px-0" onSubmit={handleSubmit}>
        <Row>
          <Col md="6" d="flex">
            <Form.Label>* NIT:</Form.Label>
            <Form.Control id="nit" type="text" name="nit" placeholder="094********" required />
          </Col>

          <Col md="2">
            <Button type="submit" variant="info" className="sm-buttonSaveForm">
              Buscar
            </Button>
          </Col>
        </Row>
      </Form>

      {isCompany && (
        <>
          <br />
          <Row>
            <Col>
              <h3 className="mb-4">Datos de la empresa</h3>
              <Table striped bordered hover size="sm">
                <tbody>
                  <>
                    <tr>
                      <th>NIT</th>
                      <td>{companyToDel.nit}</td>
                    </tr>
                    <tr>
                      <th>Nombre Empresa</th>
                      <td>{companyToDel.name}</td>
                    </tr>
                    <tr>
                      <th>Responsable</th>
                      <td>{companyToDel.responsible}</td>
                    </tr>
                  </>
                </tbody>
              </Table>
            </Col>

            <Col>
              <h3 className="mb-4">Estado de Herramientas</h3>
              <Table striped bordered hover size="sm">
                <tbody>
                  {status.length > 0 ? (
                    <>
                      <tr>
                        <th>Autoevaluación SG-SST</th>
                        <td>
                          {Array.isArray(status) && status[0].status}
                          {Array.isArray(status) &&
                            (status[0].status === "Pendiente" ? (
                              <Clock style={{ width: "1.5rem", marginLeft: "1rem" }} />
                            ) : status[0].status === "En ejecución" ? (
                              <Process style={{ width: "1.5rem", marginLeft: "1rem" }} />
                            ) : (
                              <Done style={{ width: "1.5rem", marginLeft: "1rem" }} />
                            ))}
                        </td>
                      </tr>
                      <tr>
                        <th>Matriz de Peligros</th>
                        <td>
                          {Array.isArray(status) && status[1].status}
                          {Array.isArray(status) &&
                            (status[1].status === "Pendiente" ? (
                              <Clock style={{ width: "1.5rem", marginLeft: "1rem" }} />
                            ) : status[1].status === "En ejecución" ? (
                              <Process style={{ width: "1.5rem", marginLeft: "1rem" }} />
                            ) : (
                              <Done style={{ width: "1.5rem", marginLeft: "1rem" }} />
                            ))}
                        </td>
                      </tr>
                      <tr>
                        <th>Perfil Sociodemográfico</th>
                        <td>
                          {Array.isArray(status) && status[2].status}
                          {Array.isArray(status) &&
                            (status[2].status === "Pendiente" ? (
                              <Clock style={{ width: "1.5rem", marginLeft: "1rem" }} />
                            ) : status[2].status === "En ejecución" ? (
                              <Process style={{ width: "1.5rem", marginLeft: "1rem" }} />
                            ) : (
                              <Done style={{ width: "1.5rem", marginLeft: "1rem" }} />
                            ))}
                        </td>
                      </tr>
                      <tr>
                        <th>Morbilidad Sentida</th>
                        <td>
                          {Array.isArray(status) && status[3].status}
                          {Array.isArray(status) &&
                            (status[3].status === "Pendiente" ? (
                              <Clock style={{ width: "1.5rem", marginLeft: "1rem" }} />
                            ) : status[3].status === "En ejecución" ? (
                              <Process style={{ width: "1.5rem", marginLeft: "1rem" }} />
                            ) : (
                              <Done style={{ width: "1.5rem", marginLeft: "1rem" }} />
                            ))}
                        </td>
                      </tr>
                      <tr>
                        <th>Peligros Psicosociales</th>
                        <td>
                          {Array.isArray(status) && status[4].status}
                          {Array.isArray(status) &&
                            (status[4].status === "Pendiente" ? (
                              <Clock style={{ width: "1.5rem", marginLeft: "1rem" }} />
                            ) : status[4].status === "En ejecución" ? (
                              <Process style={{ width: "1.5rem", marginLeft: "1rem" }} />
                            ) : (
                              <Done style={{ width: "1.5rem", marginLeft: "1rem" }} />
                            ))}
                        </td>
                      </tr>
                      <tr>
                        <th>Protocolo de Bioseguridad</th>
                        <td>
                          {Array.isArray(status) && status[5].status}
                          {Array.isArray(status) &&
                            (status[5].status === "Pendiente" ? (
                              <Clock style={{ width: "1.5rem", marginLeft: "1rem" }} />
                            ) : status[5].status === "En ejecución" ? (
                              <Process style={{ width: "1.5rem", marginLeft: "1rem" }} />
                            ) : (
                              <Done style={{ width: "1.5rem", marginLeft: "1rem" }} />
                            ))}
                        </td>
                      </tr>
                    </>
                  ) : (
                    <tr>
                      <td>No hay nada para mostrar</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
        </>
      )}

      {isCompany && (
        <Form md="4" className="px-0">
          <Row>
            <Col md="2">
              <Button variant="danger" className="sm-buttonSaveForm" onClick={handleDelete}>
                Eliminar
              </Button>
            </Col>
          </Row>
          {showModal && (
            <ModalConfirm
              show={showModal}
              handleClose={handleClose}
              url=""
              typeBtn={modalInfo.typeBtn}
              msg={modalInfo.message}
              title={modalInfo.title}
              acceptModal={acceptModal}
            />
          )}
        </Form>
      )}
    </Container>
  );
};

DelCompany.propTypes = {};

DelCompany.defaultProps = {};

export default DelCompany;
