const physhosocial = (
  init = {
    name: "",
    advisor: "",
    date: "/ /",
    department: "",
    city: "",
    lic: "N° 1701 del 15 septiembre 2016",
  },
  content = {
    NIT: "",
    economicActi: "",
    colaboratosNum: "",
    questAplication: "",
    resultsAnalysis: "",
    conclusions: "",
    sector: "",
    improvementOpportunity: "",
    samplePorcentage: "",
    dataRangesLow: 0,
    dataRangesHalf: 0,
    dataRangesHigh: 0,
  },
  chartTables = [
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    }, //0
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    }, //1
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
    {
      chart: "data:image/png; base64, ",
      table: [
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
        ["key1", "value1"],
      ],
      analysis: "cositas varias",
    },
  ]
) => {
  return {
    cover: [
      "INFORME DE IDENTIFICACIÓN DE EFECTOS PSICOSOCIALES",
      "NOMBRE EMPRESA:" + init.name.toUpperCase(),
      `ASESORADO POR: \n${init.advisor.toUpperCase()}\nFUNDACIÓN NUEVO HORIZONTE\n LICENCIA SST ${init.lic.toUpperCase()}`,
      `${init.department.toUpperCase()}\n${init.city.toUpperCase()}\n${init.date.toUpperCase()}`,
    ],
    toc: [
      {
        name: "TABLA DE CONTENIDO",
        children: [
          {
            name: "1. IDENTIFICACIÓN DE LA EMPRESA",
            page: 3,
          },
          {
            name: "2. INTRODUCCION",
            page: 3,
          },
          {
            name: "3. OBJETIVOS ",
            page: 4,
            children: [
              {
                name: "3.1. OBJETIVOS GENERAL",
                page: 4,
              },
              {
                name: "3.1. OBJETIVOS ESPECÍFICOS",
                page: 4,
              },
            ],
          },
          {
            name: "4. MARCO CONCEPTUAL",
            page: 4,
          },
          {
            name: "5. METODOLOGÍA",
            page: 7,
          },
          {
            name: "6. RESULTADOS GENERALES",
            page: 8,
            children: [
              {
                name: "6.1. Estado de afectación de los trabajadores en general",
                page: 8,
              },
              {
                name: "6.2. Estado de afectación según factores psicosociales",
                page: 9,
              },
              {
                name: "6.3. Análisis individualizado por cada factor",
                page: 9,
              },
              {
                name: "6.3.1 Burnout",
                page: 10,
              },
              {
                name: "6.3.2 Acoso laboral",
                page: 10,
              },
              {
                name: "6.3.3 Estrés agudo",
                page: 11,
              },
              {
                name: "6.3.4 Depresión",
                page: 12,
              },
              {
                name: "6.3.5 Estrés postraumático",
                page: 13,
              },
              {
                name: "6.3.6 Situaciones de duelo",
                page: 14,
              },
            ],
          },
          {
            name: "7. ANALISIS Y CONCLUSIONES",
            page: 15,
          },
          {
            name: "8. BIBLIOGRAFÍA",
            page: 15,
          },
        ],
      },
    ],
    content: [
      {
        type: "text",
        value: "1. IDENTIFICACIÓN DE LA EMPRESA",
        text_type: 6,
      },
      {
        type: "table",
        value: {
          headers: [],
          rows: [
            { col1: "Nombre de la empresa:", col2: init.name.toUpperCase() },
            { col1: "Fecha evaluación:", col2: `${content.questAplication.toUpperCase()}` },
            { col1: "Número de trabajadores:", col2: `${content.colaboratosNum}` },
            {
              col1: "Código de actividad económico:",
              col2: `${content.economicActi}`,
            },
            {
              col1: "Porcentaje de la muestra:",
              col2: `${content.samplePorcentage}%`,
            },
            { col1: "NIT:", col2: `${content.NIT.toUpperCase()}` },
            { col1: "departmento:", col2: `${init.department.toUpperCase()}` },
            { col1: "Municipio:", col2: `${init.city.toUpperCase()}` },
            { col1: "Sector económico:", col2: `${content.sector.toUpperCase()}` },
          ],
        },
      },
      {
        type: "text",
        value: "2. INTRODUCCIÓN",
        text_type: 6,
      },
      {
        type: "text",
        value: `El ambiente de trabajo tiene una alta influencia en la salud de los trabajadores y su desempeño laboral, al igual que también inciden en las características de la dinámica social y familiar que tienen relación con los trabajadores, es por eso que la importancia de identificar todos aquellos efectos psicosociales que pueden evidenciar la afectación de la salud de las personas que desarrollan una actividad de trabajo, ya que estos no son fácilmente identificables. Es por eso por lo que se desarrolla esta herramienta, con el ánimo que los empleadores y mejor aún en compañía de áreas como gestión humana y seguridad y salud en el trabajo, puedan hacer la gestión de forma adecuada y servirá de ayuda para dar cumplimiento a lo contenido en la resolución 2404 de 2019 en lo que respecta al cumplimiento de los protocolos de prevención y actuación referidos en dicha norma.`,
      },
      {
        type: "text",
        value: `Es de tal importancia la identificación y control de estos factores, que la adecuada o inadecuada gestión psicosocial en ambientes de trabajo, puede influir en las esferas sociales y familiares de un trabajador, lo que determinará que, si dicha gestión es positiva, esta generará factores de protección, lo que influirá significativamente en la salud mental de los trabajadores y grupos asociados a estos. Pero si la gestión es inadecuada, se presentarán condiciones que podrían afectar al trabajador, su desempeño y su familia, al ser quien recibe la carga emocional presente en el trabajador.`,
      },
      {
        type: "text",
        value: `Es importante aclarar que, con la aplicación del instrumento y posterior informe, no se busca omitir o reemplazar lo contenido en esta y otras normas vigentes frente al factor psicosocial, pero sí servirá de apoyo para la gestión frente a los efectos en la salud identificados.`,
      },
      {
        type: "text",
        value: "3. OBJETIVOS",
        text_type: 6,
      },
      {
        type: "text",
        value: "3.1 OBJETIVO GENERAL",
        text_type: 7,
      },
      {
        type: "text",
        value:
          "Identificar los efectos en la salud de los trabajadores, asociados a condiciones propias del ambiente de trabajo y de la condición individual de los trabajadores. Con el fin de generar un adecuado plan de acción y que en su cumplimiento la empresa logre contribuir a evitar situaciones que generen alteración de la salud mental de sus trabajadores.",
      },
      {
        type: "text",
        value: "3.2 OBJETIVOS ESPECÍFICOS",
        text_type: 7,
      },
      {
        type: "list",
        value:
          "Aplicar una encuesta que permita identificar efectos psicosociales de acuerdo a los protocolos establecidos en la resolución 2404 de 2019 frente a los sectores económicos establecidos.",
      },
      {
        type: "list",
        value:
          "Desarrollar un plan de mejora que permita a la empresa mitigar el efecto identificado.",
      },
      {
        type: "text",
        value: "4. MARCO CONCEPTUAL",
        text_type: 6,
      },
      {
        type: "text",
        value:
          "El instrumento que se aplicó para el desarrollo del presente informe está dividido de la siguiente forma:",
      },
      {
        type: "list",
        value: "Burnout",
      },
      {
        type: "list",
        value: "Acoso laboral",
      },
      {
        type: "list",
        value: "Reacciones al estrés agudo",
      },
      {
        type: "list",
        value: "Depresión",
      },
      {
        type: "list",
        value: "Estrés postraumático",
      },
      {
        type: "list",
        value: "Duelo",
      },
      {
        type: "text",
        value:
          "Lo anterior invita a desarrollar algunas características esenciales de cada una de las categorías listadas arriba.",
      },
      {
        type: "text",
        value: "Burnout o síndrome de estar quemado:",
        text_type: 8,
      },
      {
        type: "text",
        value:
          "El Burnout o síndrome de estar quemado es normalmente conceptualizado como el resultado de la exposición prolongada al estrés crónico y que se caracteriza por presentar agotamiento emocional, actitud fría y despersonalizada y una baja realización profesional.",
      },
      {
        type: "text",
        value: "Acoso laboral:",
        text_type: 8,
      },
      {
        type: "text",
        value:
          "En Colombia, la ley 1010 de 2006, define el acoso laboral o Mobbing como “toda conducta persistente y demostrable, ejercida sobre un empleado o trabajador por parte de un empleador, un jefe o superior jerárquico inmediato o mediato, un compañero de trabajo o un subalterno, encaminada a infundir miedo, intimidación, terror y angustia, a causar perjuicio laboral, generar desmotivación en el trabajo, o inducir a la renuncia del mismo.”",
      },
      {
        type: "text",
        value: "Reacción al estrés agudo:",
        text_type: 8,
      },
      {
        type: "text",
        value:
          "La reacción al estrés agudo puede ser catalogada como un trastorno grave de estrés o de adaptación que puede ser desencadenado por una situación devastadora grave como desastres naturales, accidentes, guerras, atracos, violaciones, pérdida de la vivienda o varios seres queridos. Las situaciones de trabajo que podrían desencadenarlo podrían ser la experiencia de un accidente grave o mortal de un compañero de trabajo, situaciones de violencia sexual o agresiones graves a nivel físico de los trabajadores. Los síntomas suelen aparecer inmediatamente después del evento y pueden perdurar durante 30 días. Los principales síntomas son embotamiento, dificultades para comprender, desorientación, comportamiento defensivo o de huida, además de signos fisiológicos característicos de la ansiedad.",
      },
      {
        type: "text",
        value: "Depresión:",
        text_type: 8,
      },
      {
        type: "text",
        value:
          "Es una enfermedad que se presenta con frecuencia en la población colombiana. Es comúnmente entendida como un estado de ánimo de tristeza, pérdida o desinterés, sentimientos de culpa o falta de autoestima, que posiblemente sea acompañado por trastornos de sueño como insomnio (dificultad para conciliar el sueño) o hipersomnio (deseo de dormir excesivamente), sensación de cansancio y falta de concentración. Existen 3 clase de etiologías de la depresión, una de ellas, la psicosocial, es la más asociada a la depresión en el trabajo. Los factores más relevantes son la pérdida o riesgo de perder el empleo, el continuo cambio de puestos de trabajo y tareas, la constante introducción de nuevas tecnologías (que hacen que el sujeto se sienta inferior o sin capacidad de responder), la permanente demanda de cualificación o adquisición de habilidades y competencias, y la sensación de pérdida del control de los planes a largo plazo o las actividades cotidianas.",
      },
      {
        type: "text",
        value: "Estrés postraumático:",
        text_type: 8,
      },
      {
        type: "text",
        value:
          "El trastorno por estrés postraumático hace parte de los trastornos de adaptación, como respuesta tardía a un evento vital extremo, o situación estresante altamente amenazante o catastrófica, que podría causar por sí mismo malestar generalizado similar a las presentadas en la reacción al estrés agudo. La principal diferencia entre la respuesta al estrés agudo y el estrés postraumático es que la primera se manifiesta inmediatamente después del evento estresor hasta 30 días, mientras que, en el estrés postraumático, por ser una reacción tardía, los efectos se presentan hasta 4 semanas después de vivida la situación que lo desencadena.",
      },
      {
        type: "text",
        value: "Duelo:",
        text_type: 8,
      },
      {
        type: "text",
        value:
          "Es un proceso natural que enfrenta una persona al enfrentarse a una pérdida de un ser querido o una pérdida significativa para la persona (como pérdida de un bien preciado, una mascota, un proceso de divorcio, etc.). El doliente atraviesa una serie de fases o estadios necesarios e importantes para culminar su proceso y elaborar efectivamente la pérdida. La comprensión de las etapas de este proceso puede ayudar significativamente a que el trabajador pueda ser manejado más fácilmente en el ámbito laboral.",
      },
      {
        type: "text",
        value: "Efectos psicosociales:",
        text_type: 8,
      },
      {
        type: "text",
        value:
          "Es la consecuencia que se obtiene por la exposición a factores psicosociales. La manifestación de esta consecuencia podría ser positiva, y se tomaría como respuesta a factores de protección, como también podría ser negativa, si la exposición es a factores de riesgo, por lo que esto generaría afectación en la salud física y mental de una persona.",
      },
      {
        type: "text",
        value: "Factores de riesgo psicosociales:",
        text_type: 8,
      },
      {
        type: "text",
        value:
          "Condiciones psicosociales cuya identificación y evaluación muestra efectos negativos en la salud de los trabajadores o en el trabajo. Como ejemplo se muestran a continuación algunos de estos factores de origen Intralaboral que podrían influir en los efectos psicosociales sobre todo en los relacionados al burnout, acoso laboral, estrés agudo y depresión.",
      },
      {
        type: "list",
        value: "Gestión organizacional:",
        text_type: 8,
      },
      {
        type: "text",
        value:
          "Se refiere a los aspectos propios de la administración del recurso humano, que incluyen el estilo de mando, las modalidades de pago y de contratación, la participación, el acceso a actividades de inducción y capacitación, los servicios de bienestar social, los mecanismos de evaluación del desempeño y las estrategias para el manejo de los cambios que afecten a las personas, entre otros.",
      },
      {
        type: "list",
        value: "Características de la organización del trabajo:",
      },
      {
        type: "text",
        value:
          "Contempla las formas de comunicación, la tecnología, la modalidad de organización del trabajo y las demandas cualitativas y cuantitativas de la labor.",
      },
      {
        type: "list",
        value: "Características del grupo social de trabajo:",
      },
      {
        type: "text",
        value:
          "Comprende el clima de relaciones, la cohesión y la calidad de las interacciones, así como el trabajo en equipo.",
      },
      {
        type: "list",
        value: "Condiciones de la tarea:",
      },
      {
        type: "text",
        value:
          "Incluyen las demandas de carga mental (velocidad, complejidad, atención, minuciosidad, variedad y apremio de tiempo); el contenido mismo de la tarea que se define a través del nivel de responsabilidad directo (por bienes, por la seguridad de otros, por información confidencial, por vida y salud de otros, por dirección y por resultados); las demandas emocionales (por atención de clientes); especificación de los sistemas de control y definición de roles.",
      },
      {
        type: "list",
        value: "Carga física:",
      },
      {
        type: "text",
        value:
          "Comprende el esfuerzo fisiológico que demanda la ocupación, generalmente en términos de postura corporal, fuerza, movimiento y traslado de cargas e implica el uso de los componentes del sistema osteomuscular, cardiovascular y metabólico.",
      },
      {
        type: "list",
        value: "Condiciones del medioambiente de trabajo:",
      },
      {
        type: "text",
        value:
          "Aspectos físicos (temperatura, ruido, iluminación, ventilación, vibración); químicos; biológicos; de diseño del puesto y de saneamiento, como agravantes o coadyuvantes de factores psicosociales.",
      },
      {
        type: "list",
        value: "Interfáz persona–tarea:",
      },
      {
        type: "text",
        value:
          "Evaluar la pertinencia del conocimiento y habilidades que tiene la persona en relación con las demandas de la tarea, los niveles de iniciativa y autonomía que le son permitidos y el reconocimiento, así como la identificación de la persona con la tarea y con la organización.",
      },
      {
        type: "list",
        value: "Jornada de trabajo:",
      },
      {
        type: "text",
        value:
          "Contar con información detallada por áreas y cargos de la empresa sobre duración de la jornada laboral; existencia o ausencia de pausas durante la jornada, diferentes al tiempo para las comidas; trabajo nocturno; tipo y frecuencia de rotación de los turnos; número y frecuencia de las horas extras mensuales y duración y frecuencia de los descansos semanales.",
      },
      {
        type: "text",
        value: "Sintomático:",
        text_type: 8,
      },
      {
        type: "text",
        value:
          "Son signos de enfermedad o lesión y las manifestaciones de alteración de la salud que refiere la persona.",
      },
      {
        type: "text",
        value: "5. METODOLOGÍA",
        text_type: 6,
      },
      {
        type: "text",
        value:
          "Los trabajadores realizaron el diligenciamiento de la encuesta de efectos psicosociales a través del aplicativo SMART SST.",
      },
      {
        type: "text",
        value:
          "La aplicación de la encuesta se realizó de manera individual con una duración aproximada de 10 minutos por trabajador.",
      },
      {
        type: "text",
        value:
          "Las preguntas fueron respondidas por el grupo de trabajadores definido según la cantidad de estos con que cuenta la empresa, en la cual el trabajador lleno al menos 38 preguntas que eran de vital importancia para la encuesta, y de acuerdo a las 4 preguntas condicionales, de respuesta SI o NO, aparecieron hasta 17 preguntas más, para un total de 55 ítems con respuestas escala Likert, que hacen alusión a 5 opciones de respuesta referidas a continuación:",
      },
      {
        type: "list",
        value: "Nunca",
      },
      {
        type: "list",
        value: "Casi nunca",
      },
      {
        type: "list",
        value: "Algunas veces",
      },
      {
        type: "list",
        value: "Casi siempre",
      },
      {
        type: "list",
        value: "Siempre",
      },
      {
        type: "text",
        value:
          "Este modelo de escala de respuesta permitirá a quien responde la encuesta brindar la respuesta más aproximada a su realidad, contestando en su totalidad la encuesta.",
      },
      {
        type: "text",
        value:
          "Para el cumplimiento de los objetivos propuestos se ha realizado una serie de preguntas con base en los 6 protocolos definidos en la resolución 2404 de 2019 a través de las cuales los trabajadores identificaran condiciones asociadas a efectos como el burnout, reacciones al estrés agudo, depresión, acoso laboral, estrés postraumático y situaciones de duelo. Esto con el fin que la empresa establezca acciones de promoción de la salud y prevención de la enfermedad en la población trabajadora.",
      },
      {
        type: "text",
        value:
          "Posterior al resultado obtenido en el instrumento de recolección de la información, se establecen puntajes por cada efecto identificado, los cuales mostraran la afectación general de los trabajadores frente a dicho efecto.",
      },
      {
        type: "text",
        value:
          "Con base a lo anteriormente referido, el psicólogo emite un concepto del estado actual del grupo de trabajadores de la empresa, y elabora un plan de mejora en el que la empresa debe hacer gestión de acuerdo a lo identificado.",
      },
      {
        type: "text",
        value: "6. RESULTADOS GENERALES",
        text_type: 6,
      },
      {
        type: "text",
        value:
          "A continuación, se presentarán los gráficos con los porcentajes de afectación de forma general y particular frente a los efectos que podrían estar alterando la salud de los trabajadores",
      },
      {
        type: "text",
        value: "6.1 Estado de afectación de los trabajadores en general ",
        text_type: 7,
      },
      {
        type: "text",
        value:
          "En esta tabla se evidencia el nivel de afectación que presenta la empresa, con base a las respuestas emitidas por los trabajadores con indicación de alteración en la salud mental.",
      },
      {
        type: "text",
        value: "GRÁFICO 1",
        text_type: 8,
      },
      {
        type: "table",
        value: {
          headers: [],
          rows: [
            { col1: "", col2: "EMPRESA", col3: "" },
            { col1: "Bajo", col2: "Medio", col3: "Alto" },
            {
              col1: `I: ${content.dataRangesLow[0]} - F: ${content.dataRangesLow[1]}`,
              col2: `I: ${content.dataRangesHalf[0]} - F: ${content.dataRangesHalf[1]}`,
              col3: `I: ${content.dataRangesHigh[0]} - F: ${content.dataRangesHigh[1]}`,
            },
          ],
        },
      },
      {
        type: "text",
        value: `\n\n\n\n`,
      },
      {
        type: "table",
        value: {
          headers: [],
          rows: chartTables[0].table.map((val) => {
            return { col1: val[0], col2: val[1] };
          }),
        },
      },
      {
        type: "text",
        value: `\n${chartTables[0].analysis}`,
      },
      {
        type: "text",
        value: "6.2 Estado de afectación según factores psicosociales",
        text_type: 7,
      },
      {
        type: "text",
        value:
          "A continuación, se presenta la afectación general de acuerdo a cada factor evaluado en el que la empresa determinara la priorización de la gestión frente a lo identificado.",
      },
      {
        type: "text",
        value: "GRÁFICO 2",
        text_type: 8,
      },
      {
        type: "image",
        value: `${chartTables[1].chart}`,
      },
      {
        type: "text",
        value: "6.3 Análisis individualizado por cada factor",
        text_type: 7,
      },
      {
        type: "text",
        value:
          "A continuación, se hace un análisis por cada factor evaluado y se emite una explicación con el fin que el empleador pueda hacerse a una idea de la sintomatología que podrían estar presentando los trabajadores y su posible manifestación a nivel laboral.",
      },
      {
        type: "text",
        value:
          "Las gráficas correspondientes a cada factor mostraran el porcentaje de trabajadores que está manifestando síntomas asociados al efecto evaluado. Se presentarán los resultados de acuerdo a la siguiente condición:",
      },
      {
        type: "list",
        value:
          "Si el porcentaje de los trabajadores sintomáticos es mayor o igual al 40% el grado de afectación a nivel de empresa será tomado como ALTO.",
      },
      {
        type: "list",
        value:
          "En caso que el porcentaje de los trabajadores sintomáticos   sea inferior al 40%, el grado de afectación a nivel de empresa será tomado como BAJO.",
      },
      {
        type: "text",
        value:
          "NOTA: El porcentaje de trabajadores sintomáticos es la suma de aquellos que individualmente obtuvieron resultados de medio y alto.",
      },
      {
        type: "text",
        value: "6.3.1 Burnout",
        text_type: 7,
      },
      {
        type: "text",
        value: "GRÁFICO 3",
      },
      {
        type: "image",
        value: `${chartTables[2].chart}`,
      },
      {
        type: "text",
        value: `\n${chartTables[2].analysis}`,
      },
      {
        type: "text",
        value: "6.3.2 Acoso laboral",
        text_type: 7,
      },
      {
        type: "text",
        value: "GRÁFICO 4",
      },
      {
        type: "image",
        value: `${chartTables[3].chart}`,
      },
      {
        type: "text",
        value: `\n${chartTables[3].analysis}`,
      },
      {
        type: "text",
        value: "6.3.3 Estrés agudo",
        text_type: 7,
      },
      {
        type: "text",
        value: "GRÁFICO 5",
      },
      {
        type: "image",
        value: `${chartTables[4].chart}`,
      },
      {
        type: "text",
        value: `\n${chartTables[4].analysis}`,
      },
      {
        type: "text",
        value: "6.3.5 Depresión",
        text_type: 7,
      },
      {
        type: "text",
        value: "GRÁFICO 6",
      },
      {
        type: "image",
        value: `${chartTables[5].chart}`,
      },
      {
        type: "text",
        value: `\n${chartTables[5].analysis}`,
      },
      {
        type: "text",
        value: "6.3.6 Estrés postraumático",
        text_type: 7,
      },
      {
        type: "text",
        value: "GRÁFICO 7",
      },
      {
        type: "image",
        value: `${chartTables[6].chart}`,
      },
      {
        type: "text",
        value: `\n${chartTables[6].analysis}`,
      },
      {
        type: "text",
        value: "6.3.7 Situaciones de duelo",
        text_type: 7,
      },
      {
        type: "text",
        value: "GRÁFICO 8",
      },
      {
        type: "image",
        value: `${chartTables[7].chart}`,
      },
      {
        type: "text",
        value: `\n${chartTables[7].analysis}`,
      },
      {
        type: "text",
        value: "7 ANALISIS Y CONCLUSIONES",
        text_type: 6,
      },
      {
        type: "text",
        value:
          "A continuación, se presenta el análisis de acuerdo a lo evidenciado en las gráficas por parte del psicólogo.",
      },
      {
        type: "text",
        value: `${content.improvementOpportunity.toUpperCase()}`,
      },
      {
        type: "text",
        value: `${content.conclusions.toUpperCase()}`,
      },
      {
        type: "text",
        value: "8 BIBLIOGRAFIA",
        text_type: 6,
      },
      {
        type: "text",
        value:
          "El Ministerio del Trabajo expidió la Resolución 2404 del 22 de julio de 2019, por medio de la cual adopta la Batería de Instrumentos para la Evaluación de Factores de Riesgo Psicosocial, la Guía Técnica General para la promoción, prevención e intervención de los Factores Psicosociales y sus efectos en la población trabajadora, así como los protocolos específicos de intervención por sector.",
      },
      {
        type: "text",
        value: "Ministerio de Trabajo y Seguridad Social. resolución 1016 de 1989. Por la cual se reglamenta la organización, funcionamiento y forma de los Programas de Salud Ocupacional que deben desarrollar los patronos o empleadores.",
      },
      {
        type: "text",
        value: "Ministerio de trabajo. 2015. Protocolo prevención y actuación “acoso laboral”.",
      },
      {
        type: "text",
        value: "Ministerio de trabajo. 2015. Protocolo prevención y actuación síndrome de agotamiento laboral “burnout”.",
      },
      {
        type: "text",
        value: "Ministerio de trabajo. 2015. Protocolo prevención y actuación “depresión “.",
      },
      {
        type: "text",
        value:
          "Ministerio de trabajo. 2015. Protocolo de actuación temprana y manejo de casos en el entorno laboral “trastorno de estrés postraumático”.",
      },
      {
        type: "text",
        value:
          "Ministerio de trabajo. 2015. Protocolo prevención y actuación en el entorno laboral “reacciones al estrés agudo”.",
      },
      {
        type: "text",
        value:
          "Ministerio de trabajo. 2015. Protocolo de actuación en el entorno laboral” situaciones de duelo”.",
      },
    ],
  };
};

export default physhosocial;
