import React from "react";
import PropTypes from "prop-types";
import "./Breadcrumbs.scss";
import { Breadcrumb } from "react-bootstrap";

const Breadcrumbs = ({ view }) => (
  <Breadcrumb>
    {view.map((value) => {
      return (
        <Breadcrumb.Item href={value.path} className="breadcrumb-item" key={value.name}>
          {value.name}
        </Breadcrumb.Item>
      );
    })}
  </Breadcrumb>
);

Breadcrumbs.propTypes = {};

Breadcrumbs.defaultProps = {
  view: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string,
    })
  ),
};

export default Breadcrumbs;
