import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import "./Accordion.scss";
import {
  ButtonGroup,
  Button,
  Row,
  Col,
  Popover,
  Overlay,
  Accordion as Accordion2,
  Card,
} from "react-bootstrap";
import { GET } from "../../constants/ApiRest";
import { NavLink, Link } from "react-router-dom";
import { ReactComponent as Done } from '../../img/icons/status/done.svg'
import { ReactComponent as Process } from '../../img/icons/status/process.svg'
import { ReactComponent as Clock } from '../../img/icons/status/wall-clock.svg'
import { controllers } from "chart.js";

function Accordion(props) {
  var [status, setStatus] = useState();
  const ref = useRef(null);

  const handleClick = async (event) => {
    props.setS((prev) => {
      let newArr = [];
      newArr = [...prev];
      newArr[props.index] = true;
      return newArr;
    });
    props.setT(event.target);

    const { data } = await GET(`${process.env.REACT_APP_COMPANY_ENDPOINT}/company/status/${props.idCompany}`);
    await updateStatus(data);
  };

  const updateStatus = async (resp) => {
    switch (props.origin) {
      case 0:
        originRepoDocuments(resp);
        break;
      case 1:
        originDiagnose(resp);
        break;
      case (typeof props.origin != 'number'):
        // Origin must be a number
        await setStatus(resp);
      default:
        // Origin doesn't exists in props
        await setStatus(resp);
    }
  }

  const originRepoDocuments = async (repo) => {
    var def = [
      { idForm: 10, status: "No Guardado" },
      { idForm: 11, status: "No Guardado" },
      { idForm: 12, status: "No Guardado" },
      { idForm: 13, status: "No Guardado" },
      { idForm: 14, status: "No Guardado" },
      { idForm: 15, status: "No Guardado" }
    ];

    for (let idx = 0; idx < repo.length; idx++) {
      var item = repo[idx];
      if (item.idForm >= 10) {
        for (let idx_def = 0; idx_def < def.length; idx_def++) {
          var item_def = def[idx_def];
          if (item_def.idForm == item.idForm) {
            def[idx_def] = item;
          }
        }
      }
    }
    await setStatus(def);
  }

  const originDiagnose = async (diag) => {
    await setStatus(diag);
    return;
  }

  const handleHide = (event) => {
    props.setS((prev) => {
      let newArr = [];
      newArr = [...prev];
      newArr[props.index] = false;
      return newArr;
    });
  };

  return (
    <>
      <div className="Accordion">
        <Row className="sm-buttonlist w-100 m-0">
          <Col className="m-0 p-0 d-flex align-items-center sm-logoContent" md={2} xs={2}>
            <img
              className="w-100"
              src={process.env.REACT_APP_COMPANY_ENDPOINT + props.imageUrl}
            ></img>
          </Col>
          <Col className="p-0 m-0" md={10} xs={10}>
            <Button on onClick={handleClick} ref={ref} className="styleAccordionButton">
              <p className="sm-titleButton">{props.title}</p>
              <p className="sm-titleNit">Nit: {props.nit}</p>
            </Button>
          </Col>
        </Row>
        <Overlay
          show={props.show}
          target={props.target}
          placement="right"
          container={ref.container}
          containerPadding={20}
          className="Accordion"
          rootClose={true}
          onHide={handleHide}
        >
          <Popover className="stylePopover" id={props.title + props.idCompany}>
            <Popover.Content>
              <ButtonGroup vertical className="stylePopoverButtonGroup">
                {props.buttons.map((data, i) => (
                  <Link
                    key={i + "button"}
                    className="stylePopoverButton w-100 text-decoration-none"
                    to={{
                      pathname: `/init/${props.path}`,
                      state: {
                        ActiveKey: `${data.key}`,
                        dataCompany: props.dataCompany,
                        idCompany: props.idCompany,
                        form_id: data.id,
                        title: props.title
                      },
                    }}>
                    <Button
                      className="stylePopoverButton w-100"
                      key={i} /* onClick={handleClick} */
                    >
                      <div>{data.title}</div>
                      <div>
                        {
                          Array.isArray(status) && status[i].status}
                        {
                          Array.isArray(status) && (status[i].status === "Pendiente" || status[i].status === "No Guardado" ?
                            <Clock style={{ width: '1.5rem', marginLeft: "1rem" }} /> :
                            status[i].status === "En ejecución" || status[i].status === "Guardado" ?
                              <Process style={{ width: '1.5rem', marginLeft: "1rem" }} /> :
                              <Done style={{ width: '1.5rem', marginLeft: "1rem" }} />)
                        }
                      </div>
                    </Button>
                  </Link>
                ))}
              </ButtonGroup>
            </Popover.Content>
          </Popover>
        </Overlay>
      </div>
    </>
  );
}

Accordion.propTypes = {};

Accordion.defaultProps = { buttons: PropTypes.array };

export default Accordion;