import axios from "axios";
/**
 *Retorn promise to request POST in the server
 *@param url route of the request
 *@param data data to be sent in the request, the data is formData
 *@return return promise for get the response to the fetch
 */

/**
 *
 * @param url route for request
 * @param data info to be send
 */
export const POSTNORMAL = (url, data) => {
  const getToken = localStorage.getItem("token");

  if (getToken) {
    return axios.post(url, data, { headers: { Authorization: "Bearer " + getToken } });
  } else {
    return axios.post(url, data, { headers: {} });
  }
};

//post para enviar formData
export const POST = (url, data) => {
  const getToken = localStorage.getItem("token");

  if (getToken) {
    return axios.post(url, data, {
      headers: { Authorization: "Bearer " + getToken, "Content-Type": "multipart/form-data" },
    });
  } else {
    return axios.post(url, data, { headers: {} });
  }
};

//Put para actualizar formData
export const PUTNORMAL = (url, data) => {
  const getToken = localStorage.getItem("token");

  if (getToken) {
    return axios.put(url, data, { headers: { Authorization: "Bearer " + getToken } });
  } else {
    return axios.put(url, data, { headers: {} });
  }
};

//post para enviar formData
export const DELETE = (url, data) => {
  const getToken = localStorage.getItem("token");

  // console.log( getToken )
  if (getToken) {
    return axios.delete(url, {
      headers: { Authorization: "Bearer " + getToken }, data
    } );
  } else {
    return axios.delete(url, data, { headers: {} });
  }
};

//Put para actualizar formData
export const DELETENORMAL = (url, data) => {
  const getToken = localStorage.getItem("token");

  if (getToken) {
    return axios.delete(url, data, { headers: { Authorization: "Bearer " + getToken } });
  } else {
    return axios.delete(url, data, { headers: {} });
  }
};

//Put para actualizar formData
export const PUT = (url, data) => {
  const getToken = localStorage.getItem("token");

  if (getToken) {
    return axios.put(url, data, {
      headers: { Authorization: "Bearer " + getToken, "Content-Type": "multipart/form-data" },
    });
  } else {
    return axios.put(url, data, { headers: {} });
  }
};

//Put para actualizar JSON
export const PUTJSON = (url, data) => {
  const getToken = localStorage.getItem("token");

  if (getToken) {
    return axios.put(url, data, {
      headers: { Authorization: "Bearer " + getToken, "Content-Type": "application/json" },
    });
  } else {
    return axios.put(url, data, { headers: {} });
  }
};

/**
 * Return token in localstorage
 *@return return token in localStorage
 */
// const getToken = localStorage.getItem("token");

/**
 * Retorn promise to request GET in the server
 *@param url route of the request
 *@param data data to be sent in the request, the data is formData
 *@return return promise for get the response to the fetch
 */
export const GET = (url) => {
  const getToken = localStorage.getItem("token");

  if (getToken) {
    return axios.get(url, { headers: { Authorization: "Bearer " + getToken } });
  } else {
    return axios.get(url, { headers: {} });
  }
};
