import React from "react";
import PropTypes from "prop-types";
import "./Reports.scss";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import ArrowBack from "../../img/icons/iconArrowBack.svg";
import { NavLink } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { ReactComponent as Application } from '../../img/application.svg';
import { ReactComponent as CompanyLinked } from '../../img/link.svg'
import UploadDocs from '../../img/UploadDocs.png'

export const Reports = () => {

  return (
    <Container className="container">
      <Row>
        <div>
          <Breadcrumbs
            view={[
              { name: "Inicio", path: "/graphics" },
              { name: "Reportes", path: "" },
            ]}
          ></Breadcrumbs>
          <NavLink to="/init/graphics" className="styleArrowLink">
            <img src={ArrowBack} width="50" />
          </NavLink>
        </div>
      </Row>
      <Row className="justify-content-center">
        <Col md={{ span: 5 }}>
          <h3 style={{ textAlign: "center", color: "#3A4297" }}>Reportes</h3>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col>
          <NavLink to="/init/companylinked" className="link">
            <div className="link-icon">
              <CompanyLinked style={{ width: '7rem' }} />
              <h5 className="mt-4">Empresas vinculadas</h5>
            </div>
          </NavLink>
        </Col>
        <Col>
          <NavLink to="/init/formatapplies" className="link">
            <div className="link-icon">
              <Application style={{ width: '7rem' }} />
              <h5 className="mt-4">Aplicación de las herramientas</h5>
            </div>
          </NavLink>
        </Col>
        <Col>
          <NavLink to="/init/uploadDocs" className="link">
            <div className="link-icon">
              <img src={UploadDocs} style={{ width: '7rem' }} />
              <h5 className="mt-4">Documentos Cargados</h5>
            </div>
          </NavLink>
        </Col>
      </Row>
    </Container>
  );
};

Reports.propTypes = {};

Reports.defaultProps = {};
