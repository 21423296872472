import React from "react";
import PropTypes from "prop-types";
import { Table } from "react-bootstrap";
import "./TableReport.scss";
import { GET } from "../../constants/ApiRest";

const TableReport = ({ headers = [], content = [], links, height = "28rem" }) => {
  const handleDownloadXlsx = async (e, row) => {
    e.preventDefault();
    // href={ process.env.REACT_APP_COMPANY_ENDPOINT + value }
    // console.log( 'row', e.target.id )

    const { data } = await GET(
      `${process.env.REACT_APP_REPORT_ENDPOINT}/report/applies/file/${row[6]}/${e.target.id}`
    );
    window.open(data, "_blank");
  };

  return (
    <Table striped bordered style={{ height: height,background: "white" }}>
      <thead>
        <tr>
          {headers.map((header) => (
            <th>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {content.map((row, i) => {
          return (
            <tr key={`row-${i}`}>
              {row.map((value, i) =>
                i < row.length - links ? (
                  <td key={`td-${i}`}>{value}</td>
                ) : typeof value === "number" ? (
                  <td key={`td-${i}`}>
                    {" "}
                    <a
                      onClick={(e) => handleDownloadXlsx(e, row)}
                      id={value}
                      target="_blank"
                      className="btn btn-success"
                    >
                      XLSX
                    </a>{" "}
                  </td>
                ) : value === "No" ? (
                  <td key={`td-${i}`}>No</td>
                ) : (
                  <td key={`td-${i}`}>
                    {value === undefined ? (
                      "Sin cargar"
                    ) : (
                      <a
                        href={process.env.REACT_APP_COMPANY_ENDPOINT + value}
                        target="_blank"
                        className="btn btn-danger"
                      >
                        PDF
                      </a>
                    )}
                  </td>
                )
              )}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

TableReport.propTypes = {};

TableReport.defaultProps = {};

export default TableReport;
