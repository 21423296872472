import { GET } from "../constants/ApiRest";

export const reducer = (state = [], action) => {
  switch (action.type) {
    case "addSort":
      const array = [...action.payload];
      let mapped = array.map((el, i) => {
        const {
          company: { name },
        } = el;
        return { index: i, value: name.toLowerCase() };
      });

      mapped.sort((a, b) => {
        return a.value.localeCompare(b.value);
      });

      let result = mapped.map((el) => {
        return array[el.index];
      });

      let pageObject = { 0: result, 1: [] };
      let page = 1;
      result.map((obj, i) => {
        if (i <= 5) {
          pageObject[page] = [...pageObject[page], obj];
          // console.log(pageObject)
        }
      });

      return pageObject;

    case "change":
      const index = action.payload * 6;
      state[1] = [];
      state[0].map((arr, i) => {
        if (i < index + 6 && i + 1 > index) {
          state[1] = [...state[1], arr];
        }
      });
      return state;

    // case para crear un arreglo con los valores ordenaros para las filas de los reportes
    case "rowsLinked":
      action.payload.map((value) => {
        state = [
          ...state,
          [
            value.deparment,
            value.town,
            value.username,
            value.membershipDate,
            value.state_id === 1 ? "Activo" : "Inactivo",
            value.nit,
            value.name,
            value.risk_id,
            value.workers,
            value.address,
            value.phone,
            value.phone3,
            value.web_address,
            value.email,
            value.responsible,
            value.indicator_emp,
            value.main_activity,
            value.sector,
            value.url.find((val) => val.match(/rut/i)),
            value.url.find((val) => val.match(/camara y comercio/i)),
            value.url.find((val) => val.match(/seguridad social/i)),
          ],
        ];
      });
      // console.log(action.payload)

      return state;

    case "rowsApplies":
      action.payload.map(async (value, i) => {
        state = [
          ...state,
          [
            value.deparment,
            value.town,
            value.username,
            value.membershipDate,
            value.indicator_emp,
            value.state_id === 1 ? "Activo" : "Inactivo",
            value.nit,
            value.name,
            value.risk_id,
            value.workers,
            value.main_activity,
            value.sector,
            value.form_id.includes(0) || value.form_id.includes(6) || value.form_id.includes(7)
              ? value.form_id.find((val) => val === 0 || val === 6 || val === 7)
              : "No",
            value.form_id.includes(1) ? 1 : "No",
            value.form_id.includes(2) ? 2 : "No",
            value.form_id.includes(3) ? 3 : "No",
            value.form_id.includes(4) ? 4 : "No",
            value.form_id.includes(5) ? 5 : "No",
          ],
        ];
      });
      return state;

    case "UploadDocs":
      action.payload.map(async (value, i) => {
        state = [
          ...state,
          [
            value.company.department.name,
            value.company.town.name,
            value.company.username,
            value.company.nit,
            value.company.name,
            value.idForm.includes(10) ? value.status[value.idForm.indexOf(10)] : "No Guardado",
            value.idForm.includes(11) ? value.status[value.idForm.indexOf(11)] : "No Guardado",
            value.idForm.includes(12) ? value.status[value.idForm.indexOf(12)] : "No Guardado",
            value.idForm.includes(13) ? value.status[value.idForm.indexOf(13)] : "No Guardado",
            value.idForm.includes(14) ? value.status[value.idForm.indexOf(14)] : "No Guardado",
            value.idForm.includes(15) ? value.status[value.idForm.indexOf(15)] : "No Guardado",
          ],
        ];
      });
      return state;

    case "emptyState":
      return [];

    default:
      return state;
  }
};
