import React from "react";
import "./NavVertical.scss";
//se importa el componenete de bootstrap
import { Nav } from "react-bootstrap";
function NavVertical({ Active, setCode, location }) {
  const activeButton = (event, code) => {
    setCode(event.target.id);
  };
  return (
    <>
      <Nav variant="pills" defaultActiveKey={Active} className="flex-column">
        <Nav.Item className="mt-1">
          <Nav.Link
            /* href="/home" */ id="Autoevaluacion"
            onClick={(e) => activeButton(e, 1)}
            eventKey="Autoevaluacion"
            className="arrow-left bg-grey text-truncate"
          >
            Autoevaluación SG-SST
          </Nav.Link>
        </Nav.Item>
        <Nav.Item className="mt-1">
          <Nav.Link
            eventKey="Matriz"
            id="Matriz"
            onClick={(e) => activeButton(e, 2)}
            className="arrow-left bg-grey text-truncate"
          >
            Matriz de peligros
          </Nav.Link>
        </Nav.Item>
        <Nav.Item className="mt-1">
          <Nav.Link
            eventKey="Perfil"
            id="Perfil"
            onClick={(e) => activeButton(e, 3)}
            className="arrow-left bg-grey text-truncate"
          >
            Perfil Sociodemográfico
          </Nav.Link>
        </Nav.Item>
        <Nav.Item className="mt-1">
          <Nav.Link
            eventKey="Morbilidad"
            id="Morbilidad"
            onClick={(e) => activeButton(e, 4)}
            className="arrow-left bg-grey text-truncate"
          >
            Morbilidad Sentida
          </Nav.Link>
        </Nav.Item>
        <Nav.Item className="mt-1">
          <Nav.Link
            eventKey="Efectos"
            id="Efectos"
            onClick={(e) => activeButton(e, 5)}
            className="arrow-left bg-grey text-truncate"
          >
            Efectos Psicosociales
          </Nav.Link>
        </Nav.Item>
        <Nav.Item className="mt-1">
          <Nav.Link
            eventKey="Protocolo"
            id="Protocolo"
            onClick={(e) => activeButton(e, 6)}
            className="arrow-left bg-grey text-truncate"
          >
            Protocolo de Bioseguridad
          </Nav.Link>
        </Nav.Item>
        {location === "repoDocuments" ? (
          <Nav.Item className="mt-1 my-5">
            <Nav.Link
              eventKey="Certificado"
              id="Certificado"
              onClick={(e) => activeButton(e, 6)}
              className="arrow-left bg-grey text-truncate"
            >
              Certificado
            </Nav.Link>
          </Nav.Item>
        ) : null}
      </Nav>
    </>
  );
}
NavVertical.propTypes = {};
NavVertical.defaultProps = {};
export default NavVertical;
