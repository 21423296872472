import React, { useState, useEffect } from "react";
import "./RepoDocuments.scss";
import { Container, Row, Col } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";
import { POST, PUTNORMAL, GET, POSTNORMAL } from "../../constants/ApiRest";

//se importan los componenetes
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import NavVertical from "../../components/NavVertical/NavVertical";
import ButtonWithIcon from "../../components/ButtonWithIcon/ButtonWithIcon";

import ArrowBack from "../../img/icons/iconArrowBack.svg";
import svg from "../../img/iconArrowDownload.svg";
import svg2 from "../../img/iconArrowUp.svg";

import ModalFileUp from "../../components/ModalFileUp/ModalFileUp";
import ModalUploadResponse from "../../components/ModalUploadResponse/ModalUploadResponse";

const RepoDocuments = ({ setLogin }) => {
  let location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [showModalResponse, setShowModalResponse] = useState(false);
  const [StateUpload, setStateUpload] = useState(true);
  const [errors, setErrors] = useState([]);
  const [code, setCode] = useState();
  const [idTemplate, setIdTemplate] = useState();
  const [template, setTemplate] = useState();
  const [role, setRole] = useState();
  //Certifies
  const [certifies, setCertifies] = useState([]);
  // Buttons control
  var [downPlan, setDownPlan] = useState(["", ""]);
  var [downInfo, setDownInfo] = useState(["", ""]);
  const [loadingFile, setLoadingFile] = useState(false);

  const handleClose = () => setShowModal(false);

  useEffect(() => {
    setCode(location.state.ActiveKey);
    setPaths();
  }, []);

  useEffect(() => {
    if (code === "Autoevaluacion") {
      setPaths(10);
      setIdTemplate(10);
    } else if (code === "Matriz") {
      setPaths(11);
      setIdTemplate(11);
    } else if (code === "Perfil") {
      setPaths(12);
      setIdTemplate(12);
    } else if (code === "Morbilidad") {
      setPaths(13);
      setIdTemplate(13);
    } else if (code === "Efectos") {
      setPaths(14);
      setIdTemplate(14);
    } else if (code === "Protocolo") {
      setPaths(15);
      setIdTemplate(15);
    } else if (code === "Certificado") {
      setIdTemplate("c");
      setPaths();
    }
  }, [code]);

  useEffect(() => {
    (async function () {
      const user = parseInt(localStorage.getItem("userRole"));
      let toggle = user == 3 || user == 5 ? false : true;
      setRole(toggle);
    })();
  }, []);

  const setPaths = async (template) => {
    if (code === "Certificado") {
      let urlCertified = `${process.env.REACT_APP_REPORT_ENDPOINT}/report/applies/certified/${location.state.dataCompany.nit}`;

      const downloadCertified = await POSTNORMAL(urlCertified, `${location.state.dataCompany.nit}`)
        .then((res) => {
          setCertifies(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      let url = `${process.env.REACT_APP_REPORT_ENDPOINT}/report/applies/file/${location.state.dataCompany.nit}/${template}`;
      const req_body = {
        nit: `${location.state.dataCompany.nit}`,
        idForm: `${template}`,
      };

      const download = await POSTNORMAL(url, req_body).then((res) => {
        let result = [];
        for (var i in res.data) result.push([i, res.data[i]]);
        return result;
      });

      for (let i = 0; i < download.length; i++) {
        download[i][0] === "pdf"
          ? setDownInfo([download[i][0], download[i][1]])
          : setDownPlan([download[i][0], download[i][1]]);
      }
    }
  };

  const downloadFile = async (directory) => {
    window.open(directory[1], "_blank");

    const data = {
      idCompany: location.state.idCompany,
      idForm: idTemplate,
      status: "Descargado",
    };

    const status = await PUTNORMAL(
      `${process.env.REACT_APP_COMPANY_ENDPOINT}/company/status/`,
      data
    );
  };

  const downloadCertified = async (directory) => {
    window.open(directory, "_blank");

    const data = {
      idCompany: location.state.idCompany,
      idForm: idTemplate,
      status: "Descargado",
    };

    const status = await PUTNORMAL(
      `${process.env.REACT_APP_COMPANY_ENDPOINT}/company/status/`,
      data
    );
  };

  async function upFile() {
    setShowModal(true);
  }

  async function acceptModal() {
    setLoadingFile(true);
    let formData = new FormData();
    formData.append("file", template);
    formData.append("idcompany", location.state.idCompany);
    let req = await POST(
      process.env.REACT_APP_FILE_LOADER_ENDPOINT + `save/${idTemplate}`,
      formData
    );

    if (req.statusText === "OK") {
      setLoadingFile(false);
      if (req.data === "Información guardada") {
        setStateUpload(true);

        const data = {
          idCompany: location.state.idCompany,
          idForm: idTemplate,
          status: "Guardado",
        };

        const status = await PUTNORMAL(
          `${process.env.REACT_APP_COMPANY_ENDPOINT}/company/status/`,
          data
        );

        setPaths(data.idForm);
      } else {
        setLoadingFile(false);
        setErrors(req.data);
        setStateUpload(false);
      }
    } else {
      setLoadingFile(false);
      setStateUpload(false);
    }

    if (req.statusText !== "OK") {
      setLoadingFile(false);
      setLogin(false);
    }

    stateUploadFileShow();
    setShowModal(false);
  }

  function stateUploadFileShow() {
    setShowModalResponse(true);
  }
  function stateUploadFileClose() {
    setShowModalResponse(false);
  }

  return (
    <div className="RepoDocuments">
      <Container>
        <Row>
          <Col>
            <Breadcrumbs
              view={[
                { name: "Inicio", path: "/graphics" },
                { name: location.state.title || "Empresa", path: "/reference_documents" },
                { name: "Documentos de Consulta", path: "/repo_documents" },
              ]}
            ></Breadcrumbs>
            <NavLink
              to="/init/reference_documents"
              className="styleArrowLink"
              style={{ width: "100px" }}
            >
              <img src={ArrowBack} width="50" />
            </NavLink>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={6}>
            <h5 className="sm-h-titleLogin text-left">Empresa: {location.state.title || ""}</h5>
            <NavVertical
              Active={location.state.ActiveKey}
              setCode={setCode}
              location="repoDocuments"
            ></NavVertical>
          </Col>
          <Col md={6}>
            <h2 className="sm-h-titleLogin text-center">Repositorio de Documentos</h2>
            {code === "Certificado" ? (
              <>
                <p className="sm-text text-center">
                  Aquí podrá descargar los <b>Certificados</b> en formato PDF correspondientes a
                  cada una de las empresas gestionadas.
                </p>
                {certifies &&
                  certifies.map((el) => (
                    <ButtonWithIcon
                      /* clic={stateUploadFileShow} */
                      key={el.name}
                      clic={() => downloadCertified(el.url)}
                      svg={svg}
                      text={`Descargar Certificado: ${el.name} `}
                      color="#253b82"
                    />
                  ))}
                <ButtonWithIcon
                  clic={upFile}
                  svg={svg2}
                  text="Cargar Certificado"
                  color="#0b193f"
                  toogle={role}
                />
              </>
            ) : (
              <>
                <p className="sm-text text-center">
                  Aquí podrá descargar los <b>Informes</b> en formato PDF y los{" "}
                  <b>Planes de acción</b> en formato Excel, por cada una de las herramientas
                  diligenciadas y cargadas en la plataforma, correspondientes a cada una de las
                  empresas gestionadas.
                </p>
                {downPlan[1] != "" && (
                  <ButtonWithIcon
                    /* clic={stateUploadFileShow} */
                    clic={() => downloadFile(downPlan)}
                    svg={svg}
                    text="Descargar Plan de Acción"
                    color="#253b82"
                  />
                )}
                {downInfo[1] != "" && (
                  <ButtonWithIcon
                    /* clic={stateUploadFileShow} */
                    clic={() => downloadFile(downInfo)}
                    svg={svg}
                    text="Descargar Informe"
                    color="#253b82"
                  />
                )}
                <ButtonWithIcon
                  clic={upFile}
                  svg={svg2}
                  text="Cargar Informe / Plan de Acción"
                  color="#0b193f"
                  toogle={role}
                />
              </>
            )}
          </Col>
        </Row>
      </Container>

      {showModal && (
        <ModalFileUp
          show={showModal}
          handleClose={handleClose}
          url=""
          msg="Selecciona el archivo que desea guardar"
          title="Guardar Documento"
          acceptModal={acceptModal}
          setTemplate={setTemplate}
          loadingFile={loadingFile}
        />
      )}
      {showModalResponse && (
        <ModalUploadResponse
          show={showModalResponse}
          url=""
          // title={"Se ha subido el Archivo con éxito"}
          handleClose={stateUploadFileClose}
          state={StateUpload}
          error={errors}
        />
      )}
    </div>
  );
};

RepoDocuments.propTypes = {};

RepoDocuments.defaultProps = {};

export default RepoDocuments;
